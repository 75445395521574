<template>
  <div class="app-container">
    <div class="layout-content">
      <Head v-if="!$route.meta.noHasHeader" @clickHeader="$router.push(`/hot_tag`)" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive && !isFirstLogin"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive && !isFirstLogin"></router-view>
    </div>
    <div class="layout-footer" v-if="$route.meta.hasFooter">
      <EFootBar :data="tabbars" height="auto" @change="handleChange" :placeholder="false" background="#535353" />
    </div>
    <ActivityPopup v-if="showActivityImg" />

    <!-- 应用列表弹窗 -->
    <van-popup
      class="app-list-pop"
      v-model="getshowAppList"
      @close="closeAppLisy"
      v-if="lotteryAd && lotteryAd.length > 0 && getshowAppList"
    >
      <EADSwiper
        :list="lotteryTopAd"
        class="swiper"
        keyName="cover"
        :EADconfig="EADconfig"
        :imgCDN="imgCDN"
        @clickImg="clickImg"
      >
      </EADSwiper>
      <ul class="app-list">
        <li @click="clickItem(item)" v-for="(item, index) in lotteryAd" :key="index">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item.avatar" class="app_item"> </EDecryptImg>
          <div class="btn">立即下载</div>
        </li>
        <li></li>
        <li></li>
      </ul>
      <div class="close_btn" @click.stop="closeAppLisy"><van-icon name="close" size="40" color="#fff" /></div>
    </van-popup>

    <!-- 活动公告弹窗 -->
    <van-popup class="account-img-pop" v-model="showAnnouncemnetPop" @close="closeAccountImg">
      <div class="account-img">
        <!-- <EDecryptImg
          :imgCDN="imgCDN"
          v-if="announcement && announcement.cover"
          :imgURL="announcement.cover ? announcement.cover : ''"
        >
        </EDecryptImg> -->
        <div class="topText">-- 活动公告 --</div>
        <div class="titlePack" @click="submit">
          <p class="title" v-if="announcement && announcement.title">{{ announcement.title }}</p>
          <p class="desc" v-if="announcement && announcement.content">{{ announcement.content }}</p>
        </div>

        <div class="btn" @click="showAnnouncemnetPop = false">我知道了</div>
      </div>
    </van-popup>

    <!--启动页广告-->
    <div class="splashAD" v-if="splashShow">
      <JavSwiper
        :height="'100vh'"
        v-if="splashAD.length > 0"
        @clickImg="clickImg"
        class="top-swiper"
        :imgs="newSplashAD"
      >
      </JavSwiper>
      <img v-else class="splash-ad-imgs splashAD" src="../../assets/imgs/qidong.gif" alt="" />
    </div>
    <van-button class="splash-close" plain type="info" v-if="msg" @click="closeSplashAD"> {{ msg }}</van-button>

    <!--启动页广告-->
    <van-overlay z-index="10000" :show="splashStaticShow">
      <img class="splash-ad-imgs splashAD" src="../../assets/imgs/qidong.gif" alt="" />
    </van-overlay>

    <!-- 下载按钮 -->
    <div v-if="showBtn" class="downApk" @click="downApk">
      <img src="@/assets/imgs/link.svg" alt="" />
      点击下载乱伦社区App
    </div>
  </div>
</template>

<script>
import { downApp, appsClick, advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  name: 'AppLayout',
  data() {
    return {
      showBtn: false,
      newSplashAD: [],
      msg: '',
      splashShow: false,
      // showPageArray:['/home','/comics','/community','/mine'],
      showActivityImg: false,
      tabbars: [
        {
          title: '首页',
          to: {
            name: 'Home'
          },
          icon: {
            active: require('assets/imgs/footer/solar_home-bold-a.png'),
            defaultActive: require('assets/imgs/footer/solar_home-bold.png')
          }
        },
        {
          title: '暗网',
          to: {
            name: 'DeepWeb'
          },
          icon: {
            active: require('assets/imgs/footer/solar_home-bold1-a.png'),
            defaultActive: require('assets/imgs/footer/solar_home-bold1.png')
          }
        },
        {
          title: '闲鱼',
          to: {
            name: 'Trade'
          },
          icon: {
            active: require('assets/imgs/footer/solar_home-bold2-a.png'),
            defaultActive: require('assets/imgs/footer/solar_home-bold2.png')
          }
        },
        {
          title: '广场',
          to: {
            name: 'Post'
          },
          icon: {
            active: require('assets/imgs/footer/solar_home-bold3-a.png'),
            defaultActive: require('assets/imgs/footer/solar_home-bold3.png')
          }
        },
        {
          title: '我的',
          to: {
            name: 'Mine'
          },
          icon: {
            active: require('assets/imgs/footer/solar_home-bold4-a.png'),
            defaultActive: require('assets/imgs/footer/solar_home-bold4.png')
          }
        }
      ],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      showAnnouncemnetPop: false, //公告弹窗开关
      getshowAppList: false,
      splashStaticShow: true
    }
  },
  components: {
    EFootBar: () => import('@/components/web_base/component_base/EFootBar/index.vue'),
    JavSwiper: () => import('@/components/JavSwiper.vue'),
    ActivityPopup: () => import('./activityPopup.vue')

    // Head: () => import('@/components/Home/header.vue'),
  },
  computed: {
    // 启动页面
    hasSplashStatus() {
      return this.$store.getters['hasSplashStatus']
      // return false
    },
    splashAD({ $store }) {
      return $store.getters['splashAD']
    },
    isZsMember({ $store }) {
      return $store.getters['isZsMember']
    },
    // 获取公告弹窗开关
    announcementPop({ $store }) {
      return $store.getters['announcementPop']
    },
    // 获取公告内容开关
    announcement({ $store }) {
      return $store.getters['announcement']
    },
    // 获取首页活动弹窗内容
    activityImg({ $store }) {
      return $store.getters['popAD']
    },
    // 获取首页活动内容弹窗开关
    activityPop({ $store }) {
      return $store.getters['activityPop']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取应用列表头部广告
    lotteryTopAd({ $store }) {
      return $store.getters['lotteryTopAd']
    },
    // 获取应用列表
    lotteryAd({ $store }) {
      return $store.getters['lotteryAd']
    },
    config({ $store }) {
      return $store.getters['config']
    },
    // 是否第一次登陆
    isFirstLogin({ $store }) {
      return $store.getters['getFirstLogin']
    }
  },
  mounted() {
    // this.getshowAppList = this.$store.state.shortVideo.showAppList
    let isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
    let isWebApp = navigator.standalone
    if (isIos) {
      if (this.isSafari()) {
        this.showBtn = true
        if (isWebApp) {
          this.showBtn = false
        }
      } else {
        this.showBtn = false
      }
    } else {
      this.showBtn = true
    }
    // 如果参数中含有dc则因此
    if (this.$route.query.dc) {
      this.showBtn = false
    }
    if (!this.isFirstLogin) {
      this.setAllDialog()
    }
  },
  methods: {
    // app.vue里面进行了ref调用！！！！
    setAllDialog() {
      this.getshowAppList = this.$store.state.shortVideo.showAppList
      if (this.hasSplashStatus && !this.isFirstLogin) {
        let newArr = JSON.parse(JSON.stringify(this.splashAD))
        this.newSplashAD = newArr
        this.splashShow = true
        this.$nextTick(() => {
          this.splashStaticShow = false
        })
      }
      this.$nextTick(() => {
        let i = 6
        this.timer = setInterval(() => {
          i--
          this.msg = i + 's'
          if (i <= 0) {
            this.msg = '跳过'
            clearInterval(this.timer)
          }
        }, 1000)
      })
    },
    // 关闭应用列表弹窗
    closeAppLisy() {
      this.getshowAppList = false
      this.$store.commit('SET_showAppList', false)
      // 公告弹窗
      if ((this.announcement && this.announcement.title) || (this.announcement && this.announcement.content)) {
        this.showAnnouncemnetPop = this.announcementPop
      }
      // 当公告弹窗没显示的时候就开启首页广告弹窗
      if (!this.showAnnouncemnetPop) {
        this.showActivityImg = this.activityPop
      }
    },
    // 关闭公告弹窗
    closeAccountImg() {
      this.$store.commit('SET_ANNOUNCEMENT_POP', false)
      this.showAnnouncemnetPop = this.announcementPop
      // 活动弹窗
      this.showActivityImg = this.activityPop
    },
    submit() {
      if (this.announcement && this.announcement.jumpUrl) {
        const code = handleURlParams(this.announcement.jumpUrl)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      }
      this.$store.commit('SET_ANNOUNCEMENT_POP', false)
      this.showAnnouncemnetPop = this.announcementPop
    },

    imgJub() {
      if (this.activityImg[0].href) {
        const code = handleURlParams(this.activityImg[0].href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      } else {
        return
      }
      this.$store.commit('SET_ACTIVITY_POP', false)
      this.showActivityImg = this.activityPop
    },
    handleChange(v) {
      console.log('tab value:', v)
    },
    // 顶部轮播图点击事件
    async clickImg(item) {
      let code = undefined

      if (item.href) {
        code = handleURlParams(item.href)
      } else {
        code = handleURlParams(item.download_url)
      }
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)

      const res = await advertiseClick({
        id: item.id
      })
      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    async clickItem(item) {
      let code = undefined
      if (item.href) {
        code = handleURlParams(item.href)
      } else {
        code = handleURlParams(item.download_url)
      }
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)

      const res = await appsClick({
        id: item.id
      })
      if (res.code !== 200) {
        return this.$toast(res.tip)
      }
    },
    closeSplashAD() {
      if (this.msg == '跳过') {
        this.$store.commit('SET_SPLASH_STATUS', false)
        this.splashShow = false
        this.splashStaticShow = false
        this.msg = ''
      }
    },

    async downApk() {
      let isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
      if (isIos) {
        let url = this.$store.getters['getUserInfo'].inviteUrl.split('?')[0] + 'iosBetaH'
        window.location.href = url
      } else {
        let params = {
          appId: 594,
          channel: '{}',
          domain: window.location.protocol + '//' + window.location.host, //落地页域名
          terminal: 'android'
        }
        try {
          const res = await downApp(params)
          if (res.code === 200) {
            window.location.href = res.data?.link[0]
          } else {
            return this.$toast(res.tip)
          }
        } catch (error) {
          return this.$toast('请求失败，请稍后再试')
        }
      }
    },
    isSafari() {
      var ua = navigator.userAgent.toLowerCase()
      if (
        ua.indexOf('applewebkit') > -1 &&
        ua.indexOf('mobile') > -1 &&
        ua.indexOf('safari') > -1 &&
        ua.indexOf('linux') === -1 &&
        ua.indexOf('android') === -1 &&
        ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 &&
        ua.indexOf('browser') === -1
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    splashShow: {
      handler(newval) {
        if (newval) {
          this.$nextTick(() => {
            this.splashStaticShow = false
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.account-img-pop {
  width: 5.5rem;
  height: 6.4rem;
  font-size: 0.26rem;
  background: #000;
  // background: url('../../assets/imgs/gc.png') center center no-repeat;
  background-size: 100% 100%;

  .topText {
    text-align: center;
    color: #fff;
    font-size: 0.36rem;
    line-height: 0.64rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  }

  .titlePack {
    margin: 0.2rem;
    margin-bottom: 0.5rem;
    padding: 0.24rem;
    height: 3.8rem;
    overflow-y: auto;
    background: #292929;

    .title {
      color: #fff;
    }

    .desc {
      color: #fff;
      white-space: pre-wrap;
    }
  }

  .btn {
    width: 4rem;
    height: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #fff;
    font-size: 0.28rem;
    margin: 0 auto;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  }
}

.desc {
}

.account-img {
}

:deep() {
  .van-tabbar {
    padding-top: 0.2rem;
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom)) !important;
    /* 兼容 iOS < 11.2 */
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom)) !important;
    box-sizing: border-box;
  }
}

.downApk {
  position: fixed;
  right: 0;
  bottom: 3.2rem;
  font-size: 0.26rem;
  z-index: 100;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  color: #fff;
  border-radius: 60px 0px 0px 60px;
  background: var(--icon, linear-gradient(95deg, #493afd 0%, #752dfc 100%));
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 0.49rem;
    height: 0.45rem;
    margin-right: 0.2rem;
  }
}

.layout-footer {
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: $pcMaxWidth;
  bottom: 0;
  left: 50%;
  padding: 0 0.25rem;
  @include transformCenter(-50%, 0);
  height: 1.04rem;

  :deep() {
    .van-tabbar,
    .van-tabbar-item--active {
      border-radius: 8px;
      // background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0f0f0f 60%);
      background: #111;

      &::after {
        display: none;
      }
    }

    .van-tabbar-item {
      .van-tabbar-item__icon {
        img {
          width: 0.7rem;
          height: 0.7rem;
        }
      }
    }

    .van-tabbar-item--active {
      .van-tabbar-item__icon {
        // img {
        //   width: 1.1rem;
        //   height: 1.1rem;
        // }
      }

      .van-tabbar-item__text {
        color: #fff;
      }
    }

    .van-tabbar-item__text {
      color: rgba($color: #fff, $alpha: 0.6);
    }
  }
}

.app-container {
  .splash-close {
    width: 1.2rem;
    text-align: center;
    position: fixed;
    right: 0.1rem;
    top: 0.1rem;
    height: auto;
    padding: 0.1rem 0.25rem;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999999999;
    border-radius: 0.35rem;
    font-size: 0.26rem;
    font-weight: 600;
    color: #fff;
    border: none;
  }
}

.splashAD {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999;
  font-size: 0;
  overflow: hidden;
  max-width: 15rem;

  .top-swiper {
    height: 100vh;

    :deep() {
      .default {
        height: 100%;

        .warp {
          height: 100vh;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}

.activity-img-pop {
  background-color: transparent !important;
  width: 6.7rem;
  overflow-y: visible;

  .activity-img {
    text-align: center;
    width: 100%;

    :deep() {
      .van-icon {
      }
    }
  }
}

// 应用列表弹窗
.app-list-pop {
  width: 100%;
  max-width: $pcMaxWidth;
  padding: 0.53rem 0.4rem;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  overflow-y: visible;
  z-index: 999999;
  @include flexbox($fd: column, $jc: center);

  .swiper {
    height: 1.8rem;

    :deep() {
      img {
        object-fit: cover !important;
      }
    }
  }

  .app-list {
    @include flexbox($fw: wrap, $jc: space-around);
    margin-top: 0.36rem;
    max-height: 8rem;
    overflow-y: auto;
    position: relative;

    li {
      width: 1.5rem;
      margin-bottom: 0.33rem;
      margin-right: 0.44rem;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .app_item {
        width: 1.5rem;
        height: 1.5rem;

        :deep() {
          img {
            border-radius: 0.12rem;
          }
        }
      }

      .btn {
        border-radius: 0.06rem;
        background: $btnBg;
        padding: 0.06rem 0;
        margin-top: 0.24rem;
        text-align: center;
        color: #333;
        white-space: nowrap;
      }
    }
  }

  .close_btn {
    text-align: center;
  }
}
</style>
