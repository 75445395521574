<template>
  <div class="comment" :class="{ active: list.length, black: bgColor !== '#fff' }">
    <div class="header"><span style="font-size: 0.34rem; font-weight: 500">全部评论</span> （{{ newComments }}条）</div>
    <van-pull-refresh v-if="list.length" :disabled="disabledPull" v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad" :immediate-check="false">
        <div class="content-main-wrap">
          <div class="content-main" v-for="item in list" :key="item.id">
            <EDecryptImg
              class="user-header"
              :imgCDN="imgCDN"
              @click.native="$router.push(`/otherUserInfo/${item.userId}`)"
              :imgURL="item.userAvatar"
            />
            <!-- 第一层用户 -->
            <div class="comment-user">
              <div class="first-comment">
                <div class="comment-user-name">
                  <div class="comment-user-name-line">
                    <div class="comment-user-name-text">
                      <span v-if="item.userId === publisher.userId" class="zuoze">作者</span>
                      <span v-else>{{ item.userName }}</span>
                      <img
                        style="width: 0.32rem; height: 0.32rem; margin-left: 0.14rem"
                        src="@/assets/imgs/renz.svg"
                        alt=""
                      />
                    </div>

                    <!-- 回复 -->
                    <div class="resTxt" @click.stop="clickRespondText(item)">
                      <img src="@/assets/imgs/comment_icon.png" alt="" />
                      回复
                    </div>
                  </div>
                  <!-- 回复按钮，时间 -->
                  <div class="comment-user-res">
                    <p class="card_name" v-if="item.cardName">{{ item.cardName }}</p>
                    <p class="comment-user-time">
                      {{ item.createdAt | timeDiff }}
                    </p>
                  </div>
                  <div class="text">
                    <!-- 图片 -->
                    <ul class="img-msg">
                      <li v-for="(imgItem, index) in item.imageUrls">
                        <EDecryptImg
                          @click.native="imagePreview(item.imageUrls,index)"
                          :imgCDN="imgCDN"
                          ref="refImg"
                          :imgRadius="'0.12rem'"
                          class="img-msg-item"
                          :imgURL="imgItem"
                        >
                        </EDecryptImg>
                      </li>
                    </ul>
                  </div>
                  <p class="comment-user-text">
                    {{ item.text }}
                  </p>
                </div>
                <!-- 第二层回复 -->
                <div class="second_comment_all" v-if="item.subList && item.subList.length">
                  <div class="second-comment" v-for="(sItem,sIndex) in item.subList" :key="sItem.id">
                    <!-- <div class="comment-user-name">
                      {{ sItem.userName }}：
                      <span v-if="sItem.userId === 10551" class="zuoze">作者</span>
                    </div> -->
                    <div class="text">
                      <div class="comment-user-text" style="display: block">
                        <span>
                          <span style="color: #00f6f8"> {{ sItem.userName }}： </span>
                          {{ sItem.text }}</span
                        >

                        <div
                          v-if="sItem.imageUrls && sItem.imageUrls.length > 0"
                          class="more-img"
                          @click.stop="imagePreview(sItem.imageUrls,sIndex)"
                        >
                          <img src="@/assets/imgs/community/show-comment-pic.svg" alt="" />
                          查看图片
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- <NoData class="noData" style="padding:0.5rem 0" v-else></NoData> -->
    <!-- 评论 -->
    <div class="submit-box">
      <div class="line-bot"></div>
      <!-- 是否为会员 -->

      <div class="submit-input">
        <!-- 图片评论组件 -->
        <img
          src="@/assets/imgs/upoad_img_pic2.svg"
          v-if="isShowUpload"
          @click="showLoadImgType = !showLoadImgType"
          class="add_img_box"
        />
        <van-field v-model="text" :placeholder="placeholder" />
        <span class="submit-txt" @click="addComment">发送</span>
      </div>
    </div>
    <!-- 上传图片弹窗组件 -->
    <van-popup
      class="img-load-pup"
      :overlay="false"
      round
      v-model="showLoadImgType"
      position="bottom"
      :style="{ height: '4.5rem' }"
    >
      <div class="top-box">
        <img v-if="!loadImgList.length" class="pic1" src="@/assets/imgs/upoad_img_pic1.png" alt="" />
        <ul v-else>
          <li v-for="(item, index) in loadImgList" :key="index">
            <EDecryptImg :imgRadius="'0.12rem'" :imgCDN="imgCDN" class="load-img-item" :imgURL="item">
              <img src="@/assets/imgs/xx.png" @click.stop="replaceImg(index)" class="close-btn" alt="" />
            </EDecryptImg>
          </li>
        </ul>
      </div>

      <UploadImgs :maxLength="3" :upLoadImgs="loadImgList" @onReadImg="onReadImg">
        <div class="load-btn">上传图片</div>
      </UploadImgs>
      <div class="tip-desc">
        <img src="@/assets/imgs/upoad_img_tip.png" alt="" />
        <span>小提示:每次只能发3张图片哦</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { comCommentAdd, comCommentList } from 'api/comics'
import { handleVerAutoImg } from '@/utils/utils_tools'
export default {
  props: {
    disabledPull: {
      // 评论对象id
      type: Boolean,
      default() {
        return false
      }
    },

    isShowUpload: {
      // 评论对象id
      type: Boolean,
      default() {
        return false
      }
    },

    objectId: {
      // 评论对象id
      type: Number,
      default() {
        return 0
      }
    },
    bgColor: {
      //评论类型
      type: String,
      default() {
        return '#fff'
      }
    },
    objectype: {
      //评论类型
      type: Number,
      default() {
        return 0
      }
    },
    publisher: {
      // 发布者id
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      text: '', //评论内容
      placeholder: '请说点您想说的话吧~',
      pageNum: 1,
      pageSize: 10,
      activeIndex: 0, // 展开的id
      newComments: 0, //评论数
      parentsId: undefined, // 父级回复id
      list: [], // 评论列表
      secondList: [], //二级评论列表
      //上拉加载
      loading: false,
      //上拉加载完成
      finished: false,
      //下拉刷新
      isLoading: false,
      //评论状态
      commentReady: true,
      // 上传图片弹窗控制
      showLoadImgType: false,

      // 已上传图片数组
      loadImgList: []
    }
  },
  mounted() {
    // 一级列表请求参数
    const data = {
      objectId: this.objectId,
      objectType: this.objectype,
      pageNum: 1,
      pageSize: this.pageSize,
      parentsId: undefined
    }
    this.getCommentList(data)
  },
  components: {
    // EDecryptImg: () => import('@/components/EDecryptImg/index.vue'),
    // NoData: () => import('@/components/noData.vue'),
    UploadImgs: () => import('@/components/UploadImgs/index.vue')
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    // 解密并获取base64图片地址
    // async getBlobUri(imgURL) {
    //   let imgArr = []
    //   let realUrl = ''
    //   imgURL.forEach(async item => {
    //     realUrl = await handleVerAutoImg(`${this.imgCDN}${item}`)
    //     imgArr.push(realUrl)
    //   })
    //   ImagePreview({
    //     images: imgArr, //需要预览的图片 URL 数组
    //     showIndex: true, //是否显示页码
    //     loop: true, //是否开启循环播放
    //     startPosition: 0, //图片预览起始位置索引
    //     getContainer: '.second_comment_all',
    //     closeable: true
    //   })
    // },
    // 图片预览
    imagePreview(imgArr,index) {
      let domArr = []
         let realUrl = ''
      imgArr.forEach(async (item, index) => {
          realUrl = await handleVerAutoImg(`${this.imgCDN}${item}`)
          domArr.push(realUrl)
      })
      ImagePreview({
        images: domArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: index, //图片预览起始位置索引
        
        closeable: true,
       
      })
    },
    // 替换上传图片
    replaceImg(index) {
      this.loadImgList.splice(index, 1)
    },
    //读取img
    onReadImg(list) {
      if (!list.length) return
      this.loadImgList = [...this.loadImgList, ...list]
    },

    // 关闭弹窗
    close() {
      this.showType = false
      this.$emit('close', this.showType)
    },
    // 点击回复按钮
    clickRespondText(item) {
      this.placeholder = `回复 ${item.userName}`
      // if (!this.isVipMember) {
      //   return this.$toast('会员才参与可以评论!')
      // }
      this.parentsId = item.id
    },
    // 获取评论列表
    async getCommentList(data, type) {
      this.loading = true
      const res = await comCommentList(data)
      if (res.code === 200) {
        // 有parentsId 为二级列表请求
        this.loading = false
        this.isLoading = false
        //防止出现两个刷新
        if (type == 'pull' || type == 'comment') (this.list = []), (this.secondList = [])

        if (data.parentsId) {
          if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
          if (res.data.list) this.secondList = [...this.secondList, ...res.data.list]
        } else {
          if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
          if (res.data.list) this.list = [...this.list, ...res.data.list]
        }
        //评论数算上子集
        this.newComments = this.list.length
        this.list.forEach(item => {
          this.newComments = this.newComments + item.commentCount
        })
      } else {
        this.loading = false
        this.isLoading = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    // 发布评论
    async addComment() {
      if (!this.commentReady) {
        return this.$toast('评论操作过快!')
      }
      if (!this.text && !this.loadImgList.length) {
        return this.$toast('请输入评论内容,或者上传图片')
      }
      this.commentReady = false
      const res = await comCommentAdd({
        objectId: this.objectId, // 对象id
        objectType: this.objectype, // 对象类型
        parentsId: this.parentsId, // 上级id
        replyId: this.replyId, // 回复id
        // score: this.score, // 评分 没有为0
        text: this.text,
        imageUrls: this.loadImgList
      })
      this.activeIndex = 0
      if (res.code === 200) {
        this.commentReady = true
        this.text = null
        this.newComments += 1
        this.parentsId = undefined // 每次发布做一次清除操作
        this.loadImgList = []
        const data = {
          objectId: this.objectId,
          objectType: this.objectype,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          parentsId: this.parentsId
        }
        this.getCommentList(data, 'comment')
        this.$emit('addComments')
        this.showLoadImgType = false
        this.placeholder = `喜欢就给个评论支持一下～`
        return this.$toast('评论成功')
      } else {
        this.commentReady = true
        this.loading = false
        this.isLoading = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    // 打开二级评论列表
    openSon(item) {
      this.activeIndex = item.id
      this.pageNum = 1
      this.finished = false

      this.parentsId = item.id
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: item.id
      }
      this.secondList = []
      this.getCommentList(data)
    },
    //上拉刷新
    onRefresh() {
      //表示处于可以下拉状态
      this.finished = false
      this.loading = true
      this.activeIndex = 0
      // 一级列表请求参数
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: 1,
        pageSize: this.pageSize,
        parentsId: undefined
      }
      this.getCommentList(data, 'pull')
    },

    //下拉加载
    onLoad() {
      this.pageNum += 1
      // 一级列表请求参数
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: this.parentsId
      }
      this.getCommentList(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.comment {
  position: relative;
  // background: #000;
  color: #fff;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;

  //没有评论数，三角+下面空白需要隐藏
  &.black {
    background: #333 !important;

    .comment-user-name {
      color: #fff !important;
    }

    .comment-user-text {
      color: #979797 !important;
    }
  }

  &.active {
    padding-bottom: 1.5rem;
  }

  .header {
    font-size: 0.26rem;
    text-align: left;
    padding: 0.3rem 0;
    margin: 0 0.3rem;
    // border-bottom: 1px solid #eee;
    color: #fff;
  }
}

//  评论主体内容
.content-main-wrap {
  // height: 4.4rem;
  overflow: auto;
  @include scrollbar-hide;

  :deep() {
    .van-image-preview__overlay {
      z-index: 99999 !important;
    }

    .van-image-preview {
      z-index: 100000 !important;
    }
  }

  .content-main {
    display: flex;
    padding: 0.3rem 0;
    margin: 0 0.3rem;
    box-sizing: border-box;
    max-width: 100%;
    // border-bottom: 0.02rem solid #eee;

    &:last-child {
      border-bottom: 0;
    }

    .user-header {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;

      :deep() {
        .warp {
          font-size: 0;
          border-radius: 50%;
        }

        .img {
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }

    .comment-user {
      flex-grow: 1;

      .first-comment,
      .second-comment {
        font-size: 0.22rem;
        color: #fff;
        margin-top: 0.1rem;

        .zuoze {
          display: inline-block;
          width: 0.65rem;
          height: 0.37rem;
          // background: #fff;
          background: #ff8b00;

          font-size: 0.24rem;
          color: #fff;
          text-align: center;
          line-height: 0.37rem;
          border-radius: 0.05rem;
        }

        .comment-user-name {
          font-size: 0.3rem;
          color: #fff;

          .comment-user-name-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .comment-user-time {
          font-size: 0.18rem;
          color: rgba($color: #fff, $alpha: 0.7);
          margin: 0;
          margin-right: 0.3rem;
        }

        .comment-user-text {
          max-width: 100%;
          font-size: 0.18rem;
          word-break: break-all;
          word-wrap: break-word;
          margin: 0;
          color: #fff;
          opacity: 0.8rem;
          margin: 0.1rem 0;
          font-size: 0.24rem;
          display: flex;
          align-items: center;
          img {
            height: 0.22rem;
            margin: 0 0.04rem;
          }
        }
      }
    }
  }
}

// 回复文本样式
.resTxt {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 0.22rem;
  @include flexbox($jc: flex-start);
  img {
    width: 0.26rem;
    margin-right: 0.1rem;
  }
}
// 第二层样式
.second_comment_all {
  background-color: #000;
  padding: 0.1rem;
  border-radius: 0.08rem;
  .second-comment {
    @include flexbox($jc: flex-start, $ai: flex-start);
    .comment-user-name {
      color: $btnBg !important;
      font-size: 0.24rem !important;
      flex: none;
    }
  }
}

.comment-user-res {
  @include flexbox($jc: flex-start);
  font-size: 0.2rem;
  .card_name {
    border-radius: 0.04rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
    padding: 0rem 0.12rem;
    margin-right: 0.08rem;
  }
}

// 展开回复
.comment-user-open {
  &-right {
    color: #ff8b00;
  }
}

// 提交按钮
.submit-box {
  position: fixed;
  width: 100%;
  max-width: $pcMaxWidth;
  bottom: 0;
  font-size: 0.3rem;
  // background-color: #000;
  border: 1px solid #535353;
  background: #292929;
  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;

  // 评论发送按钮
  .submit-input {
    position: relative;
    @include flex-align-center;
    justify-content: space-around;
    margin: 0.3rem auto;
    font-size: 0.26rem;

    .add_img_box {
      width: 0.45rem;
      height: 0.42003rem;
    }

    .submit-txt {
      width: 0.96rem;
      height: 0.72rem;
      border-radius: 0.16rem;
      background: #00f6f8;
      text-align: center;
      line-height: 0.72rem;
      color: #fff;
      font-size: 0.28rem;
    }

    :deep() {
      .van-cell {
        border-radius: 0.16rem;
        background: #fff;
        padding: 0.1rem 0.2rem;
        width: 4.67rem;
        height: 0.72rem;

        .van-field__control {
          text-indent: 0.2rem;
        }
      }
    }
  }

  // 开通会员提示按钮
  .submit-btn {
    color: #333;
    background-color: $btnBg;
    width: 6rem;
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    margin: 0.3rem auto;
    border-radius: 0.5rem;
  }
}

// .color {
//   color: #333;
// }

// 图片组件上传
.img-load-pup {
  @include flexbox($fd: column, $jc: flex-start);
  padding-top: 0.96rem;
  background-color: #292929;
  bottom: 1.35rem;
  .top-box {
    ul {
      @include flex-align-center;

      li {
        margin-right: 0.3rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .load-img-item {
        width: 1.9rem;
        height: 1.9rem;
        flex-shrink: 0;
        position: relative;

        :deep() {
          .warp {
            overflow: visible;
          }
        }

        .close-btn {
          position: absolute;
          right: 0;
          top: -0.14rem;
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }
  }

  .pic1 {
    width: 0.88rem;
    height: 0.80001rem;
  }

  .load-btn {
    display: flex;
    width: 2.8rem;
    height: 0.64rem;
    padding: 0.22rem 0.84rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.08rem;
    background: #f9c006;
    margin: 0.49rem 0;
    color: #fff;
  }

  .tip-desc {
    color: #999;
    font-family: PingFang HK;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    @include flex-align-center;

    img {
      width: 0.192rem;
      height: 0.24rem;
      margin-right: 0.06rem;
    }
  }
}

// 图片列表
.img-msg {
  @include flex-align-center;
  margin-top: 0.16rem;

  li {
    margin-right: 0.16rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &-item {
    width: 1.8rem;
    height: 1.8rem;
  }
}
</style>
