<template>
  <div class="recharge">
    <EBackTop title="金币钱包" background="#000" color="#fff" @click-right="$router.push('/mine/recharge')">
      <slot slot="right" name="right">
        <div class="right-txt">充值记录</div>
      </slot>
    </EBackTop>
    <!-- 用户 -->
    <div class="user-info">
      <EDecryptImg
        @click.native="$router.push('/mine/setting')"
        :imgCDN="imgCDN"
        class="md-mine-photo"
        :imgURL="userInfo.avatarUrl ? userInfo.avatarUrl : ''"
      >
        <p class="nickName">{{ userInfo.nickName }}</p>
      </EDecryptImg>
      <div class="trans-btn" @click="$router.push('/mine/transList')">明细流水</div>
    </div>
    <!-- 账户余额 -->
    <div class="my-money">
      <div class="my-money-left">
        <p>当前余额</p>
        <p>{{ userInfo.balance | changeGold }}</p>
        <p>金币</p>
      </div>
      <div class="my-money-line"></div>
      <div class="my-money-right">
        <p>赠送金币</p>
        <p>{{ userInfo.giftGold | changeGold }}</p>
        <p>金币</p>
      </div>
    </div>
    <!-- tips -->
    <div class="tips-box">
      <span>TIPS</span>
      <span>赠送的金币只能用于解锁付费视频和抽奖</span>
    </div>
    <!-- 金币列表 -->
    <ul class="check-type">
      <li v-for="(item, index) in priceList" @click="changeActive(index)" :key="item.id">
        <div class="check_item" :class="{ active: active === index }">
          <div class="top-text">
            {{ item.payAmount | changeGold }}金币
            <!-- <img  src="@/assets/imgs/mine/gold.png" alt="" /> -->
          </div>
          <p class="name" v-if="item.giftGold > 0">赠送{{ item.giftGold | changeGold }}金币</p>
        </div>

        <p class="price">¥ {{ item.payAmount | changeGold }}</p>
      </li>
    </ul>
    <div class="other-btn" @click="$router.push('/mine/setting/kf')">5000以上的大额充值 请点击这里</div>
    <!-- 支付小贴士 -->
    <div class="tips-list">
      <span>支付小帖士</span><br />
      1.跳转后请及时付款，超时支付无法到账，要重新发起<br />
      2.每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单<br />
      3.支付通道在夜间比较忙碌，为保证您的观影体验，您可以选择白天支付，晚上观看，谢谢您的理解
    </div>

    <!-- 支付按钮 -->
    <div class="buy-btn" @click="showPopFn">
      <span>充值金币</span>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="showPop" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="content">
        <div class="title">请确认订单</div>
        <div class="pice">
          已选择：<span style="color: #ffcf86">¥{{ activeData.payAmount | changeMoneyYuan }}</span>
        </div>
        <ul class="pay-list">
          <li
            :key="index"
            v-for="(item, index) in activeData.rchgType"
            @click="fn_sel(item.type)"
            class="item"
            :class="{ active: item.type === payMode }"
          >
            <div class="left" v-if="item.type === 'alipay'">
              <img src="@/assets/imgs/mine/zfb2.png" alt="" />
              {{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'wechat'">
              <img src="@/assets/imgs/mine/weChat2.png" alt="" />
              {{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'overage'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'union'">
              <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'daichong'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="right">
              <div class="active" v-if="item.type == payMode"></div>
            </div>
          </li>
        </ul>
        <div class="tip-wrap">
          付款提示：<br />
          1、支付前请先绑定手机号，避免重新安装时用户权益遗失！<br />
          2、支付前先选择支付方式，再点“确认支付”跳转后请及时付款，超时支付无法到账，需重新发起支付。<br />
          3、若支付时出现任何风险提示请不要担心，重新支付一次即可，并不会重复付款。<br />
          4、付款如遇到其他问题，可咨询在线客服处理
        </div>
        <div class="submit" @click="fn_submit">
          <div>确认支付</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { paytypeinfo, recharge_sumbit } from 'api/home'
export default {
  components: {
    EDecryptImg: () => import('@/components/web_base/component_base/EDecryptImg/index.vue')
  },
  data() {
    return {
      priceList: [],
      active: 0,
      activeData: {},
      showPop: false,
      payMode: ''
    }
  },
  async mounted() {
    await this.getPaytypeinfo()
    if (this.priceList && this.priceList.length > 0) {
      this.activeData = this.priceList[0]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    // 获取购买金币列表
    async getPaytypeinfo() {
      const res = await paytypeinfo({})
      if (res.code === 200) {
        this.priceList = res.data.payModes
      } else {
        this.$toast(res.tip)
      }
    },
    // 打开底部弹窗
    showPopFn() {
      this.showPop = true
    },
    // 切换卡片类型
    changeActive(index) {
      this.active = index
      this.activeData = this.priceList[index]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '跳转中请稍后'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeData.payAmount,
        payMode: this.payMode,
        productId: this.activeData.id,
        rchgUse: 2
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) return this.$toast('购买成功！！！')
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      const TagA = document.createElement('a')
      TagA.href = fileUrl
      // TagA.target = '__blank';
      TagA.className = 'oInput'
      TagA.style.display = 'none'
      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge {
  min-height: 100vh;
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  padding-bottom: 2rem;
}

.right-txt {
  height: 0.5rem;
  line-height: 0.5rem;
  background: $btnBg;
  color: #333;
  font-size: 0.24rem;
  padding: 0 0.16rem;
  border-radius: 0.12rem;
}

.user-info {
  @include flexbox();
  padding: 0.36rem 0.36rem;

  .md-mine-photo {
    width: 0.88rem;
    height: 0.88rem;
    border-radius: 50%;
    @include flexbox($ai: center);

    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }

  .nickName {
    margin-left: 0.32rem;
    white-space: nowrap;
  }

  .trans-btn {
    border-radius: 0.08rem;
    background: #be9155;
    font-size: 0.24rem;
    display: inline-flex;
    padding: 0.08rem 0.16rem;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    color: #333;
  }
}

// 用户余额
.my-money {
  width: 6.78rem;
  height: 3.2rem;
  margin: 0 auto;
  flex-shrink: 0;
  border-radius: 0.16rem;
  color: #f5ae42;
  font-size: 0.36rem;
  font-weight: 500;
  border-radius: 0.16rem;
  border: 1px solid #ffeaa1;
  background: url('../../../assets/imgs/mine/myWallet_bg.png') no-repeat;
  background-size: cover;
  @include flexbox($jc: space-around);

  &-right,
  &-left {
    @include flexbox($fd: column, $jc: center);

    p {
      &:nth-child(2) {
        margin: 0.2rem 0;
      }

      &:last-child {
        border-radius: 0.08rem;
        border: 1px solid #be9155;
        display: inline-flex;
        padding: 0.02rem 0.16rem;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &-line {
    width: 0.01rem;
    height: 1.6rem;
    background: #d6af26;
  }
}

.tips-box {
  margin: 0.4rem 0 0.46rem 0.37rem;

  span {
    &:first-child {
      border-radius: 0.08rem;
      background: rgba(190, 145, 85, 0.2);
      color: #be9155;
      font-family: PingFang HK;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 0.23rem;
      padding: 0.04rem 0.08rem;
    }
  }
}

// 金币列表

.check-type {
  @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
  padding: 0 0.36rem;

  li {
    width: 30%;

    margin-right: 5%;
    margin-bottom: 0.24rem;

    .check_item {
      @include flexbox($jc: center, $ai: center, $fd: column);
      border-radius: 0.24rem;
      height: 2.55rem;
      border: 2px solid #787979;
    }

    .active {
      border-radius: 0.24rem;
      border: 2px solid #be9155;
      background: rgba(190, 145, 85, 0.2);
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      margin-right: 0.15rem;
      width: 0.37rem;
      height: 0.37rem;
    }

    .top-text {
      color: #be9155;
      font-family: PingFang SC;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .name {
      color: #F00;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
      margin-top: 0.16rem;
    }
  }

  .price {
    text-align: center;
    margin-top: 0.16rem;
  }
}

.other-btn {
  border-radius: 0.39rem;
  background: $btnBg;
  color: #333;
  width: 4.6rem;
  height: 0.7rem;
  margin: 0.5rem auto 0.62rem auto;
  line-height: 0.7rem;
  text-align: center;
  font-size: 0.28rem;
}

.tips-list {
  margin-left: 0.4rem;
  line-height: 0.4rem;

  span {
    border-radius: 0.08rem;
    background: rgba(190, 145, 85, 0.2);
    color: #be9155;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.04rem 0.08rem;
    margin-bottom: 0.2rem;
    display: inline-block;
  }
}

.buy-btn {
  @include flexbox($jc: center);
  max-width: $pcMaxWidth;
  width: 100%;
  background: #212223;
  border-top: 0.01rem solid #575757;
  position: fixed;

  bottom: 0;
  left: 50%;
  height: 1rem;
  transform: translate(-50%, 0);
  padding: 0.34rem 0.5rem;
  box-sizing: content-box;

  span {
    border-radius: 0.46rem;
    background: #ffcf86;
    width: 6.5rem;
    height: 0.9rem;
    flex-shrink: 0;
    text-align: center;
    line-height: 0.9rem;
    color: #333;
  }
}

// 弹窗
.submit-pop {
  // background: url('/images/mine/pop-bg.png') no-repeat;
  // background-size: 100% 100%;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  padding: 0rem 0.3rem;
  background-color: #000;

  .right {
    width: 0.28rem;
    height: 0.28rem;
    border-radius: 50%;
    border: 0.02rem solid #ffcf86;
    position: relative;

    .active {
      width: 0.2rem;
      height: 0.2rem;
      background: #ffcf86;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    font-size: 0.38rem;
    margin-bottom: 0.48rem;
    margin-top: 0.4rem;
    text-align: center;
  }

  .tip-wrap {
    font-size: 0.24rem;
    margin-top: 0.5rem;
    margin-right: 0.7rem;
  }

  // 支付按钮
  .submit {
    width: 6.4rem;
    height: 0.9rem;
    text-align: center;
    line-height: 0.9rem;
    background: #ffcf86;
    color: #333;
    font-size: 0.32rem;
    margin: 0 auto;
    margin-top: 0.6rem;
  }

  .pay-list {
    .item {
      @include flexbox();
      padding: 0 0.46rem;
      margin-top: 0.36rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
        margin-right: 0.16rem;
      }

      .right {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 50%;
        border: 0.02rem solid #ffcf86;
        position: relative;

        .active {
          width: 0.2rem;
          height: 0.2rem;
          background: #ffcf86;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    // 选中状态
  }
}
</style>
