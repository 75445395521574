<template>
  <div class="income">
    <EBackTop :background="'transparent'" class="mine-income" color="#fff" title="收益明细" />
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <ul class="income-list">
        <li v-for="(item, index) in list" :key="index">
          <div>
            <div>
              <span>{{ item.contributor }}</span>
              <span>解锁</span>
              <span>{{ item.desc }}</span>
              <span>帖子视频</span>
            </div>
            <div>
              {{ item.createdAt.split('T')[0] }}
            </div>
          </div>
          <div>+{{ item.coinAmount }}</div>
        </li>
      </ul>
    </PullUp>
  </div>
</template>

<script>
import { income_detail } from '@/api/user'
export default {
  name: 'income',
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    PullUp: () => import('@/components/PullUp.vue')
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageParams: {
        pageNum: 1,
        pageSize: 10
      },
      income: 0,
      list: [],
      total: 0
    }
  },
  methods: {
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.pageNum = 1
      this.myPostList = []
      this.getList()
    },
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getList()
    },
    async getList() {
      this.loading = true
      try {
        const { data } = await income_detail(this.pageParams)
        this.income = data.income
        this.list = data.list
        this.total = data.total
      } finally {
        this.loading = false
        this.refreshing = false
        this.finished = true
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.income {
  &-list {
    padding: 0 0.36rem;
    li {
      padding: 0.24rem 0.36rem;
      border-radius: 0.08rem;
      border: 1.5px solid #535353;
      background: #292929;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.24rem;
      & > div {
        &:nth-child(1) {
          flex: 1;
          > div:nth-child(1) {
            margin-bottom: 0.06rem;
          }
          > div:nth-child(2) {
            color: rgba(255, 255, 255, 0.6);
            font-family: PingFang SC;
            font-size: 0.24rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          span:nth-child(odd) {
            color: $btnBg;
            margin-right: 0.1rem;
          }
          span:nth-child(3) {
            margin-left: 0.1rem;
          }
        }
        &:nth-child(2) {
          margin-left: 0.68rem;
          color: $btnBg;
          font-family: PingFang SC;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>