<template>
    <!-- 占位符，后续替换swiper组件 -->
    <ul class="theme-list">
        <li v-for="item in postCategory"  :key="item.id" @click="changeType(item.id)">
            <!-- 遮罩层 -->
            <div class="overlay" v-if="item.id!==postCategoryId">

            </div>
            <EDecryptImg :imgCDN="imgCDN" :imgURL="item.imageUrl ? item.imageUrl : ''" class="item-cover">
                <p>#{{item.name}}#</p>
            </EDecryptImg>
        </li>
        
    </ul>
    <!-- <EBswiper class="swiper_main" @changeSlide="changeSlide" :list="themeList" 
        :EADconfig="EADconfig" :activeIndex="activeIndex" :imgCDN="imgCDN"
        :keyName="'image'" :slidesPerView="3" :spaceBetween="40">
        <template v-slot:default="scope">
          <p>#话题#</p>
        </template>
      </EBswiper> -->
</template>

<script>
export default {
    data() {
        return {
            postCategoryId:0,
            EADconfig:{
                speed: 300,
            }
        }
    },
    name: 'TipList',
    mounted(){
  
    },
    computed: {
        imgCDN({ $store }) {
            return $store.getters['cdn']
        },
            // 获取顶部分类
      postCategory({ $store }) {
       return $store.getters['postCategory']
     },
    },
    mounted(){
        this.postCategoryId = this.postCategory[0].id
    },
    methods: {
        changeType(id){
            this.postCategoryId = id
            this.$emit('postCategoryId',id)
        }
    },
}
</script>

<style lang="scss" scoped>
.theme-list {
    @include flexbox($jc: flex-start);
    margin-left: 0.66rem; // 标题隔开距离
    @include scrollbar-hide();
    overflow-y: auto;
    li {
        margin-left: 0.16rem;
        position: relative;
        .item-cover {
            position: relative;
            width: 2.6rem;
            height: 1.47rem;
            flex-shrink: 0;
            p {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
            }
            :deep(){
                img{
                    border-radius: 0.08rem;
                }
            }
        }
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        &::after{
backdrop-filter: blur(3.5px);

        }
        background: rgba(0, 0, 0, 0.60);
z-index: 10;
    }
}</style>
