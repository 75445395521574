<template>
    <div class="E-tab" :style="styleVar">
        <van-tabs v-model="active" lazy-render v-bind="$attrs" :line-height="lineHeight" v-on="$listeners" @change="change"
            :class="addClass">
            <van-tab v-for="(item, index) in tabList" :key="item.id" :title="item.name" :name="item.id">
                <template #title>
                    <slot name="title" :data="item.showType" :item="item" :index="index"></slot>
                </template>
                <slot :data="item.showType" :item="item" :index="index"></slot>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'ETab',
    props: {
        tabList: {
            type: Array,
            default () {
                return [];
            },
        },
        lineSwitch: {
            type: Boolean,
            default () {
                return false;
            },
        },
        activeId: {
            type: Number,
            default () {
                return 0;
            },
        },
        lineBg: {
            type: String,
            default () {
                return '#333';
            },
        },
        lineHeight: {
            type: String,
            default () {
                return '.06rem';
            },
        },
        tabLineHeight: {
            type: String,
            default () {
                return '0rem';
            },
        },
        lineBott: {
            type: String,
            default () {
                return '15px';
            },
        },
        sizeTab: {
            type: String,
            default () {
                return '0.3rem';
            },
        },
        sizeActive: {
            type: String,
            default () {
                return '0.3rem';
            },
        },
        addClass: {
            type: String,
            default () {
                return '';
            },
        },
        // tabList tab数据列表 Array
        // lineSwitch 是否显示选中下划线 Boolean
        // lineBg 选中下划线背景色 String
        // lineBott 选中下划线距离底部距离 String
        // lineHeight 选中下划线高度 String
        // line-width 选中下划线宽度 String
        // sizeTab Tab字体大小 String
        // sizeActive 选中字体大小 String
        // 剩下的查看vant API文档
    },

    data () {
        return {
            active: 0
        };
    },
    watch: {
        activeId (n) {
            this.active = n
        },
    },

    computed: {
        styleVar () {
            return {
                '--lineSwitch_css': this.lineSwitch ? 'block' : 'none',
                '--lineBg_css': this.lineBg,
                '--lineBott_css': this.lineBott,
                '--lineHeight_css': this.lineHeight,
                '--tabLineHeight_css': this.tabLineHeight,
                '--sizeActive_css': this.sizeActive,
                '--sizeTab_css': this.sizeTab,
            };
        },
    },
    mounted () {
        this.active = this.activeId
    },
    methods: {
        change (id) {
            this.$emit('change', id)
        }
    },
};
</script>

<style lang="scss" scoped>
.E-tab {
    .van-tabs {
        width: 100%;

        ::v-deep {
            .van-tabs__nav--complete {
                padding-left: 0;
                padding-right: 0;
            }

            .van-tab {
                font-size: var(--sizeTab_css);
                padding: 0 0.2rem;
                flex-shrink: 0;
                border-bottom-width: var(--tabLineHeight_css);
                border-bottom-color: var(--lineBg_css);
                border-bottom-style: solid;

                span {
                    line-height: var(--sizeTab_css);
                }
            }

            .van-tab--active {
                font-size: var(--sizeActive_css);

                span {
                    line-height: var(--sizeActive_css);
                }
            }

            .van-tabs__line {
                background-color: #888;
                background-color: var(--lineBg_css);
                display: var(--lineSwitch_css);
                bottom: var(--lineBott_css);
                height: var(--lineHeight_css);
            }
        }
    }
}
</style>
