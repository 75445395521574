<!-- home -->
<template>
  <div class="index-container">
    <div class="top_search">
      <div class="searchPack" @click="$router.push('/subpage/search')">
        <img src="@/assets/imgs/community/findhoIcon.png" alt="" />
      </div>
      <div class="discoverPack" @click="$router.push('/subpage/discover')">
        <img src="@/assets/imgs/index/discoverIcon.png" alt="" />
        发现
      </div>
    </div>
    <!--切换面板 -->
    <ETab
      class="container-tab"
      @change="change"
      :sticky="true"
      :tabList="category"
      :v-model="type ? type : category && category[0] ? category[0].id : 0"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#00F6F8'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#fff'"
      :title-inactive-color="'#fff'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'"
      :placeholder="false"
      :ellipsis="false"
      background="#000"
    >
      <template v-slot:default="scope">
        <component
          :navItem="scope"
          :showType="scope.data"
          :avAD="avAD"
          :type="scope.item.id"
          :short_videoAD="short_videoAD"
          :is="compComponent(scope.data)"
        ></component>
      </template>
    </ETab>
    <!-- <DmPopup ref="popupMsg"></DmPopup>  -->
    <!-- <van-overlay z-index="2001" :show="userInfo.vipType!==8 && userInfo.vipType!==12"  @click="$router.push('/mine/vip')"
    :class="{'is-iphonex-height':isIphonex}"
    >
      <div class="overlay-center" >
        <p>因内含禁止流出缅北轮奸，韩国N号房，战争，恐怖 
          <p>全球封禁暗网解密，各类真实事件等等 </p>
          <p>累积百万资源揭秘</p>
          </p>
        <div class="active_txt"><span>严重警告：无承受能力勿入！！ </span>
          <div>仅对少量需求用户开放 
            <div>禁止传播分享</div>
          </div>
        </div>
        <div>开启无限观影</div>
        <div>
          <span v-for="(item,index) in cardList" :key="item.id">{{item.name}}{{index < cardList.length -1 ? '/':''}}</span>
        </div>
        <div>点击开通</div>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { vip_list } from 'api/home'
import { isIphonex } from '@/utils/utils_tools'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      // indexActive: 0, // 默认选中id
      type: 0, // 切换的id
      topTabLabelList: [],
      gerFirstCheckDeep: true,
      show: true,
      vipList: [],
      isIphonex: isIphonex(0)
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Index: () => import('@/components/Home/index.vue'),
    Actor: () => import('@/components/Home/actor.vue'),
    SwiperShow: () => import('@/components/Swiper/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    Default: () => import('@/components/Home/default.vue')
  },
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取顶部分类
    category({ $store }) {
      return $store.state.config.config.jMediaCategory
    },
    // 获取首页底部轮播广告
    short_videoAD({ $store }) {
      return $store.getters['short_videoAD']
    },
    cardList() {
      return this.vipList.filter(v => v.vipType == 8)
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  activated() {
    // this.gerFirstCheckDeep = true;
    // if (this.gerFirstCheckDeep) {
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       this.$refs['popupMsg'].popupMsg = {
    //         show: true,
    //         type: 'waring_post_02',
    //         cb: async (returnData) => {
    //           this.gerFirstCheckDeep = false
    //         },
    //         close: async returnData => {
    //           this.gerFirstCheckDeep = false;
    //           this.$router.go(-1);
    //         },
    //       };
    //     }, 200);
    //   });
    // }
  },
  mounted() {
    this.getVipList()
  },
  methods: {
    change(type) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      // 分类id存入vuex
      this.$store.dispatch('setAvIndex', type)
      this.type = type
    },

    //  1:主题  2:竖版 模版 3:横板 模版  4:女优 5:推荐
    //  Default（2,3切换列表：最新，最热，人气） Index（5+1主题+） Actor(4女优)
    compComponent(data) {
      switch (data) {
        case 1:
          return 'Default'

        default:
          return 'Index'
      }
    },
    async getVipList() {
      try {
        const res = await vip_list()
        if (res.code === 200) {
          this.vipList = res.data[0].cardInfoList
        } else {
          return this.$toast(res.tip || '请求错误，请稍后再试！')
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.van-overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: calc(100vh - 0.2rem - calc(0.2rem + env(safe-area-inset-bottom)) - 0.7rem - 4px - 12px);
  &.is-iphonex-height {
    height: calc(100vh - 0.2rem - calc(0.2rem + env(safe-area-inset-bottom)) - 0.7rem - 4px - 12px - 34px);
  }
  .overlay-center {
    // background-color: red;
    > p {
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    > div {
      &:nth-of-type(1) {
        background: url('../../assets/imgs/trade/warning.png') top center no-repeat;
        background-size: contain;
        height: 1.8rem;
        padding: 0.5rem 0.72rem 0.28rem;
        margin-top: 0.24rem;
        text-align: center;
        font-size: 0.24rem;
      }
      &:nth-of-type(2) {
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.24rem 0 0.08rem;
      }
      &:nth-of-type(3) {
        color: #00f6f8;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.08rem;
      }
      &:nth-of-type(4) {
        display: flex;
        padding: 0.04rem 0.16rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.04rem;
        background: #00f6f8;
        width: fit-content;
        margin: 0 auto;
        color: #333;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.index-container {
  // min-height: $minHeight;
  .searchPack {
    position: fixed;
    right: 50%;
    right: 0.3rem;
    // margin-right: -3.5rem;
    top: 0.2rem;
    z-index: 10;
    img {
      width: 0.36rem;
      height: 0.36rem;
    }
  }
  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0rem;
        left: 1.5rem;
        // transform: translate(-50%,0);
        width: 5rem;
        max-width: $pcMaxWidth;
        z-index: 99;
        .van-tab {
          flex: none;
        }
      }
      .van-pull-refresh__track {
        padding-bottom: 1rem;
      }
    }
  }
}
.account-img-pop {
  background-color: transparent;
  min-height: 7.78rem;
  overflow-y: visible;
}
.account-img {
  width: 6rem;
  height: 100%;
  // background-image: url('../../assets/imgs/index-activity-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1.5rem 0.4rem 0.4rem 0.4rem;
  text-align: center;
  :deep() {
    .van-icon {
      @include transformCenter(-50%, 0);
      position: absolute;
      right: 0rem;
      top: 0.8rem;
    }
  }
  .title-img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.26rem;
    margin-bottom: 0.4rem;
    span {
      margin: 0 0.2rem;
    }
    img {
      width: 1.4rem;
    }
  }
  p {
    margin: 0 0 0.2rem 0;
    font-size: 0.63rem;
    color: #eb3876;
  }
  .titlePack {
    min-height: 3.5rem;
    .desc {
      text-align: left;
      font-size: 0.26rem;
      white-space: pre-line;
      color: #666666;
      margin: 0.3rem 0 0.45rem 0;
    }
  }

  .btn {
    background: linear-gradient(to right, #52b5f7, #6ce6f3);
    color: #fff;
    font-size: 0.3rem;
    border-radius: 0.36rem;
    width: 3.12rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0 auto;
  }
}
.discoverPack {
  position: absolute;
  left: 0.24rem;
  top: 0.2rem;
  z-index: 100;
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #333;
  background: #00f6f8;
  padding: 0.06rem 0.12rem;
  border-radius: 0.08rem;

  img {
    width: 0.3rem;
    height: 0.3rem;
    margin-right: 0.06rem;
  }
}
.top_search {
  @include flexbox();
  position: fixed;
  width: 100%;
  height: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #000;
  z-index: 10;
}
.active_txt {
  color: #fff;
}
</style>
