<template>
  <div class="tiao_jiao_detail">
    <EBackTop :title="''" background="transparent" :openFallback="true" class="backtop">
      <slot slot="left" name="left">
        <img class="left_icon" src="@/assets/imgs/trade/yuan_back.png" alt="" />
      </slot>
      <slot slot="right" name="right">
        <div class="collent_btn share_btn" @click="clickShare">分享</div>
        <div class="collent_btn" @click="checkRight">{{ detailData.details.isCollect ? '已收藏' : '+ 收藏' }}</div>
      </slot>
    </EBackTop>
    <!-- 妹子图片轮播图 -->
    <JavSwiper
      ref="JavSwiper"
      @clickImg="clickImg"
      :autoplay="0"
      class="ww-swiper"
      :imgs="detailData.details.images"
      :shoudPlay="detailData.details.videoUrl"
      :videoCover="detailData.details.videoCover"
      :height="'8.65rem'"
    />

    <!-- 详细信息 -->
    <div class="tiao_jiao_detail_main">
      <!-- 名字 -->
      <div class="name_box">
        {{ detailData.details.title }}
      </div>

      <!-- 女优详细信息 -->
      <ul class="actor_detail_info">
        <li>
          <!-- 商家信息 -->
          <div class="store_box" @click="$router.push(`/mine/myInfo?id=${detailData.bossInfo.id}`)">
            <EDecryptImg
              :imgCDN="imgCDN"
              :imgURL="detailData.bossInfo.avatar ? detailData.bossInfo.avatar : ''"
              class="store_avatar"
            >
            </EDecryptImg>
            <div class="store_name">
              <p>{{ detailData.bossInfo.name }}</p>
              <p>信息平台认证,真实可靠,可放心约!</p>
            </div>
          </div>
        </li>
        <li class="body_info">
          <div>
            <span class="label">年龄</span>
            <span class="info">{{ detailData.details.girlAge }}</span>
          </div>
          <div>
            <span class="label">身高</span>
            <span class="info">{{ detailData.details.girlHeight }}</span>
          </div>
          <div>
            <span class="label">体重</span>
            <span class="info">{{ detailData.details.girlWeight }}</span>
          </div>
          <div>
            <span class="label">胸围</span>
            <span class="info">{{ detailData.details.cupType }}</span>
          </div>
        </li>
        <li>
          <div class="service_type_box">
            <span class="label">服务类型</span>
            <span class="info">{{ detailData.details.desc }} </span>
          </div>
        </li>
        <li class="tips_list">
          <span class="label label_title">服务项目</span>
          <div class="must_check">
            <p>*必选</p>
            <div class="check_item">
              <span
                @click="checkRequier(item)"
                :class="item.id === requiredActive ? 'active' : ''"
                v-for="item in detailData.details.requiredServices"
                >{{ item.name }}+ {{ item.price | changeGold }}</span
              >
            </div>
          </div>
          <div class="optional_check">
            <p>*可选</p>
            <div class="check_item">
              <span
                @click="checkOption(item)"
                :class="item.check ? 'active' : ''"
                v-for="item in detailData.details.optionalServices"
                >{{ item.name }}+ {{ item.price | changeGold }}</span
              >
            </div>
          </div>
        </li>
        <li class="stepeer_time">
          <span class="label">时间</span>
          <div class="stepeer_time_right">
            <van-stepper
              :min="3"
              :max="12"
              v-model.number="timeValue"
              disable-input
              integer
              input-width="40px"
              step="3"
            />
            <span>小时</span>
          </div>
        </li>
        <li>
          <span class="label label_title">预约流程</span>
          <p>1.支付预约金之后，联系在线客服</p>
          <p>2.与在线客服确定约妹时间、地点，在线选妹妹</p>
          <p>3.与妹妹见面满意，则付尾款(全款减去预约金)给妹妹</p>
        </li>
      </ul>
    </div>

    <!-- 底部按钮 -->
    <div class="footer-btn">
      <ul>
        <li class="default_btn" @click="$router.push('/mine/myOrder')">
          <img src="@/assets/imgs/trade/menu.png" alt="" />订单
        </li>
        <li class="pay_btn">
     
                            <div @click="payFn"  v-if="!detailData.details.isBuy">支付金额{{ (( optionsPrice + requirePrice) * (timeValue / 3)) |
                        changeGold
                    }},预约服务</div>
          <div @click="$router.push('/mine/setting/kf')" v-else>{{ detailData.details.contactInfo }}</div>
        </li>
        <li class="default_btn" @click="$router.push('/mine/myShouyi')">
          <img src="@/assets/imgs/trade/share1.png" alt="" />分享
        </li>
      </ul>
    </div>
    <!-- 购买弹窗 -->
    <DmPopup ref="popupMsg"></DmPopup>
    <SharePopup
      :sharePopShow="showShare"
      @close="
        e => {
          showShare = e
        }
      "
    />
  </div>
</template>
    
<script>
import { dating_collect, loufeng_detail, dating_pay } from 'api/dating'
import { collect } from 'api/home'
import { ImagePreview } from 'vant'
export default {
  components: {
    JavSwiper: () => import('@/components/JavSwiper.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    SharePopup: () => import('@/components/SharePopup.vue')
  },
  data() {
    33
    return {
      detailData: {
        details: {},
        bossInfo: {}
      },
      timeValue: 3,
      requiredActive: 0,
      optionsPrice: 0,
      requirePrice: 0,
      serviceIds: [],
      optionalList: [],
      showShare: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  mounted() {
    this.getLfdetail()
  },

  methods: {
    clickShare() {
      this.showShare = true
    },
    checkRequier(item) {
      this.requiredActive = item.id
      this.serviceIds = [...this.serviceIds, this.requiredActive]
      this.requirePrice = item.price
    },
    // 收藏
    async checkRight(item) {
      try {
        const res = await dating_collect({
          collect: !this.detailData.details.isCollect,
          objectId: this.detailData.details.id,
          type: 4
        })
        if (res.code === 200) {
          this.detailData.details.isCollect = !this.detailData.details.isCollect
          if (this.detailData.details.isCollect) {
            return this.$toast('收藏成功')
          } else {
            return this.$toast('取消收藏')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 获取妹子详情
    async getLfdetail() {
      try {
        const res = await loufeng_detail({
          id: +this.$route.query.id,
          datingType: 2
        })
        if (res.code === 200) {
          this.detailData = res.data
          this.requiredActive =
            this.detailData.details.requiredServices.length > 0
              ? this.detailData.details.requiredServices[0].id
              : undefined
          this.serviceIds = this.requiredActive ? [this.requiredActive] : []
          this.requirePrice =
            this.detailData.details.requiredServices.length > 0 ? this.detailData.details.requiredServices[0].price : 0
            console.log(res.data,'00');
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    // 图片预览
    clickImg(imgUrl) {
      const imgArr = []
      let index = 0
      const domArr = this.$refs['JavSwiper'].$refs['refImg']
      domArr.forEach((itemBlob, indexBlob) => {
        if (imgUrl === itemBlob.imgURL) {
          index = indexBlob
        }
        imgArr.push(itemBlob.realUrl)
      })
      ImagePreview({
        images: imgArr, // 需要预览的图片 URL 数组
        showIndex: true, // 是否显示页码
        loop: true, // 是否开启循环播放
        startPosition: index // 图片预览起始位置索引
      })
    },

    // 购买弹窗购买按钮
    async payFn() {
      try {
        if (this.userInfo.balance < (this.optionsPrice + this.requirePrice) * (this.timeValue / 3)) {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '注意',
            content: '当前余额不足',
            type: 'up_post',
            ok: '购买VIP',
            img: '',
            cancel: '充值金币',
            position: 'center',
            showBalance: false,
            cb: async returnData => {
              this.$router.push('/mine/vip')
            },
            close: async returnData => {
              this.$router.push('/mine/myWallet')
            }
          }
        } else {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '确认支付',
            showBalance: true,
            type: 'pay_post',
            ok: '前往客服',
            img: this.detailData.details.videoCover
              ? this.detailData.details.videoCover
              : this.detailData.details.images[0],
            price: (this.optionsPrice + this.requirePrice) * (this.timeValue / 3),
            cancel: '狠心离开',
            position: 'center',
            tip: this.detailData.details.title,
            cb: async returnData => {
              // const res = await dating_pay({
              //   objectId: this.detailData.details.id,
              //   type: 2,
              //   serviceIds: this.serviceIds,
              //   time: this.timeValue
              // })
              // if (res.code === 200) {
              //   this.detailData.details.isBuy = true
              //   return this.$toast(res.tip)
              // } else {
              //   return this.$toast(res.tip)
              // }
              this.$router.push('/mine/setting/kf')
            }
          }
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    checkOption(item) {
      item.check = !item.check
      this.optionalList = this.detailData.details.optionalServices.map(sItem => {
        if (sItem.id === item.id && item.check) {
          this.optionsPrice += item.price
          return item.id
        } else if (sItem.id === item.id && !item.check) {
          this.optionsPrice -= item.price
        }
        this.serviceIds = [...this.optionalList, ...this.serviceIds]
      })
      this.$forceUpdate()
    }
  },
  beforeDestroy() {}
}
</script>
    
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.backtop {
  position: fixed;
  width: 100%;
  height: 0.9rem;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 10;
}

.left_icon {
  width: 0.6rem;
  height: 0.6rem;
}

.collent_btn {
  display: inline-flex;
  padding: 0.08rem 0.24rem;
  align-items: center;
  gap: 0.04rem;
  border-radius: 0.32rem;
  background: $btnBg;
  color: #333;
}
.share_btn {
  margin-right: 0.2rem;
}

.tiao_jiao_detail_main {
  border-radius: 0.32rem 0.32rem 0rem 0rem;
  background: #212223;
  padding: 0.48rem 0.36rem 1.92rem 0.36rem;

  .name_box {
    font-size: 0.36rem;
  }
}

.actor_detail_info {
  li {
    padding: 0.36rem 0;
    border-top: 0.01rem solid rgba($color: #979797, $alpha: 0.3);
    color: #787979;
    font-size: 0.26rem;
    font-weight: 400;

    &:first-child {
      padding: 0.44rem 0 0.4rem 0;
      margin-top: 0.44rem;
    }
  }

  .label {
    border-radius: 0.08rem;
    background: rgba(0, 246, 248, 0.1);
    padding: 0.04rem 0.08rem;
    gap: 0.1rem;
    color: $btnBg;
    margin-right: 0.24rem;
  }

  .body_info {
    @include flexbox($fw: wrap);
    padding-top: 0.12rem;

    div {
      width: 50%;
      margin-top: 0.24rem;
    }
  }

  .tips_list {
    p {
      margin-bottom: 0.16rem;
    }
  }
}

.label_title {
  margin-right: 0;
  margin-bottom: 0.16rem;
  display: inline-block;
}

.store_box {
  border-radius: 0.12rem;
  background: #333;
  @include flexbox($jc: flex-start);
  padding: 0.24rem 0.32rem;

  .store_avatar {
    margin-right: 0.16rem;
    width: 0.72rem;
    height: 0.72rem;

    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }

  .store_name {
    color: $btnBg;
    font-size: 0.28rem;
    font-weight: 400;

    p:last-child {
      color: #bcbcbc;
      font-size: 0.22rem;
    }
  }
}

.service_type_box {
  padding: 0.24rem 0.21rem;
  border-radius: 0.12rem;
  background: #333;
}

.must_check {
  p {
    color: #f9c006;
    font-size: 0.26rem;
  }
}

.optional_check {
  margin-top: 0.28rem;
}

.check_item {
  @include flexbox($jc: flex-start, $fw: wrap);

  span {
    border-radius: 0.12rem;
    border: 2px solid #787979;
    padding: 0.16rem 0.08rem 0.16rem 0.1rem;
    margin-right: 0.16rem;
    margin-bottom: 0.24rem;
  }

  .active {
    border: 2px solid #00f6f8;
    background: rgba(0, 246, 248, 0.1);
    color: $btnBg;
  }
}

.stepeer_time {
  @include flexbox();

  .stepeer_time_right {
    @include flexbox($jc: flex-start);
    color: #fff;
    position: relative;

    :deep() {
      .van-stepper__minus,
      .van-stepper__plus {
        border-radius: 0.12rem;
        background: $btnBg;
        padding: 0.02rem 0.12rem;
        color: #212223;
      }

      .van-stepper__minus {
        margin-right: 0.1rem;
      }

      .van-stepper__input {
        border-radius: 0.12rem;
      }

      .van-stepper__plus {
        margin-left: 0.68rem;
      }

      span {
        position: absolute;
        top: 18%;
        left: 52%;
        margin-left: 0.1rem;
      }
    }
  }
}

.footer-btn {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: $pcMaxWidth;
  height: 1.92rem;
  padding: 0.23rem 0.36rem;
  background: #212223;
  border-top: 0.01rem solid #575757;

  // .top_tips {
  //     margin-bottom: 0.25rem;

  //     span:last-child {
  //         color: #F9C006
  //         font-size: 0.24rem;
  //         display: inline-block;
  //         margin-left: 0.31rem;
  //     }
  // }

  ul {
    @include flexbox();
    height: 100%;
  }

  .default_btn {
    @include flexbox($fd: column, $jc: center);

    &:first-child {
      margin-right: 0.34rem;
    }

    &:last-child {
      margin-left: 0.34rem;
    }

    img {
      width: 0.44rem;
      height: 0.4rem;
    }
  }

  .pay_btn {
    padding: 0.12rem 0.32rem;
    border-radius: 0.56rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  }
}
</style>
    