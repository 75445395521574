<template>
  <div class="findhoTop" @click="$router.push('/subpage/search')">
    <div class="iptbox">请输入你想要的文字</div>
    <div class="textbox">搜索</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.findhoTop {
  display: flex;
  width: 100%;
  height: 0.64rem;
  //   background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 0.36rem;
  // transform: translate(-50%, 0);
  z-index: 20;
  background: url('../assets/imgs/mine/mine_top_bg.png') no-repeat;
  // background: red;
  // background: red;
  background-size: cover;
  .iptbox {
    width: 5.7rem;
    border-radius: 12px 0px 0px 12px;
    background: rgba(0, 0, 0, 0.5);
    color: #999;
    font-size: 0.22rem;
    padding: 0 0.28rem;
    display: flex;
    align-items: center;
    height: 0.64rem;
  }
  .textbox {
    flex-grow: 1;
    border-radius: 0px 12px 12px 0px;
    background: #00f6f8;
    font-size: 0.28rem;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.64rem;
  }
}
</style>