<template>
  <div class="video_image">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="media.coverImg">
      <!-- <div class="video-type">
          <div class="video-type-vip" v-if="media.payType === 1">VIP</div>
          <div class="video-type-gold" v-if="media.payType === 2">
            <img src="@/assets/imgs/index/gold.png" alt="" />
            {{ media.price | changeGold }}
          </div>
          <div class="video-type-free" v-if="media.payType === 0">免费</div>
        </div> -->
      <div class="vide_detail">
        <div class="vide_detail_l">
          <img src="@/assets/imgs/index/card-play.svg" alt="" />
          {{ media.playTime | secondToDate }}
        </div>
        <div class="vide_detail_r">{{ media.watchTimes | numberFilter('w') }}次播放</div>
      </div>
    </EDecryptImg>
  </div>
</template>
  
  <script>
export default {
  components: {},
  props: ['media'],
  data() {
    return {}
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {},
  methods: {},
  mounted() {}
}
</script>
  
  <style lang="scss" scoped>
.video_image {
  width: 100%;
  // height: 100%;
  .video-type {
    position: absolute;
    top: -0.03rem;
    right: -0.06rem;
    color: #fff;
    font-size: 0.26rem;
    font-weight: 600;
    transform: scale(0.8);
    &-vip {
      width: 0.64rem;
      height: 0.3rem;
      border-radius: 0 0.12rem 0 0.12rem;
      text-align: center;
      line-height: 0.3rem;
      background: $btnBg;
    }
    &-gold {
      min-width: 0.7rem;
      height: 0.3rem;
      border-radius: 0 0.12rem 0 0.12rem;
      text-align: center;
      line-height: 0.3rem;
      background: linear-gradient(to right, #493afd, #752dfc);
      img {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.02rem;
      }
    }
    &-free {
      width: 0.64rem;
      height: 0.3rem;
      border-radius: 0 0.12rem 0 0.12rem;
      text-align: center;
      line-height: 0.3rem;
      background: linear-gradient(to right, #00cd01, #06ac04);
    }
  }
  .vide_detail {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    line-height: 0.4rem;
    background: rgba(0, 0, 0, 0.2);
    padding: 0 0.15rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.2rem;
    .vide_detail_l {
      display: flex;
      align-items: center;
      img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.09rem;
      }
    }
    .vide_detail_r {
    }
  }
}
</style>
  