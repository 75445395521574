<template>
    <div class="short-six" v-if="list && list.length > 0" @touchmove.stop>
      <div class="short-six-item" v-for="(item, index) in list" :key="item.id" @click="toPlay(item, index, list)">
        <shortVideoCard :item="item"/>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'ShortVideo',
  props: ['list', 'id', 'sort', 'typeTxt'],
  components: {
    shortVideoCard: () => import('@/components/VideoCard/shortVideoCard.vue')
  },
  methods: {
    toPlay(item, index, list) {
      if (this.isLike) {
        this.$router.replace(`/play/${item.id}`)
      } else {
      // 如果是小视频 则这个列表的数据和id index 都存入vuex
        if (item.videoType === 2) {
          this.$router.push({ path: `/subpage/play/shortVideo/${this.id}`, query: { id: this.id, sort: this.sort, detailId: item.id, typeTxt: this.typeTxt }})
        } else {
          this.$router.push(`/subpage/play/longVideo/${item.id}`)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .short-six {
    border-radius: 0.12rem;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    i {
      width: 2.04rem;
    }
    &-item {
      min-width: 3rem;
      max-width: 3rem;
      margin-right: .2rem;
      ::v-deep{
        .item-bg{
            height: 3.9rem;
        }
      }
    }
  }
  </style>
