<template>
  <lazy-component class="default" @show="handler" :style="[imgRadius?{'border-radius':imgRadius}:'']">
    <div
      :class="needPadding ? 'warp' : 'warpNoPadding'"
      @click="imgClick"
      :style="[{ paddingTop: needPadding ? paddingNum : 0 },]"
    >
      <img
        :style="[imgRadius?{'border-radius':imgRadius}:'']"
        :class="needPadding ? 'imgTag' : 'imgNoPadding'"
        :src="realUrl ? realUrl : require('@/assets/imgs/default.png')"
        @click="imgClick"
        :key="imgCDN + imgURL"
        draggable="false"
        ondragstart="return false;"
      />
      <slot></slot>
    </div>
  </lazy-component>
</template>

<script>
import { handleVerAutoImg } from '@/utils/utils_tools'
import { IMAGE_DB_CONFIG, START_IMAGE_DB_KEY } from '@/config/imgConfig.js'
import { TIME_OUT_NUMBER_KEY } from '@/config/imgConfig.js'
const { max_time_consuming, time_out_max_number } = IMAGE_DB_CONFIG
export default {
  name: 'AutoImage',
  props: {
    // 图片路径
    imgURL: {
      typeof: String,
      default() {
        return ''
      }
    },
    // 默认padding值
    paddingNum: {
      typeof: String,
      default() {
        return '100%'
      }
    },
    // 是否需要padding
    needPadding: {
      typeof: Boolean,
      default() {
        return true
      }
    },
    //圆角
    imgRadius:''    
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    if (!this.imgURL) this.status = 0
  },
  watch: {
    imgURL(n) {
      if (n) {
        this.status = 1
        this.getBlobUri()
      }
    }
  },
  data() {
    return {
      realUrl: false,
      status: 1,
      timeConsuming: 0,
      // 超时次数
      timeOutNumber: 0
    }
  },
  methods: {
    handler() {
      this.imgURL ? this.getBlobUri() : (this.realUrl = false)
    },
    // 解密并获取base64图片地址
    async getBlobUri() {
      if (!this.imgURL) return
      const startTime = new Date().getTime()
      const baseUri = await handleVerAutoImg(`${this.imgCDN}${this.imgURL}`)
      if (baseUri && baseUri.length && this.status === 1) {
        this.realUrl = baseUri
        this.status = 2
      } else this.status = 0
      // 计算执行耗时事件
      this.timeConsuming = new Date().getTime() - startTime
      // 每一次超时当前+1
      const timeOutN = IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY]
      const startDb = IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]

      if (this.timeConsuming >= max_time_consuming) {
        IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY] += 1
      }
      // 超过最大次数 关闭 IndexDB
      if (timeOutN > time_out_max_number && startDb) IMAGE_DB_CONFIG[START_IMAGE_DB_KEY] = false
    },
    // 方法传递
    imgClick() {
      this.$emit('clickImg', this.imgURL)
    }
  }
}
</script>

<style scoped lang='scss'>
.default {
  width: 100%;
}
.warp {
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 0;
}
.warpNoPadding {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0;
}
.imgTag {
  width: 100%;
  position: absolute;
  // object-fit: cover;
  // transition: all 0.6s;
  // -webkit-user-drag: none;
  top: 0;
  left: 0;
}
.imgNoPadding {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
  -webkit-user-drag: none;
}
.load-error {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
