<template>
  <div @click.stop="toDetail(item)">
    <div class="diWangTj-order-item" v-if="cardType === 'order'">
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images ? item.images[0] : ''" class="item-bg">
        <!-- 视频分类标签 -->
        <div class="diWangTj-type">
          <!--  -->
          <div class="diWangTj-type-free">入驻商家</div>
        </div>
        <!-- 标题 -->
        <div class="diWangTj-title">
          {{ item.title }}
        </div>
      </EDecryptImg>
      <div class="order_item_right">
        <div class="nums">
          <p>订单编号:{{ item.orderId }}</p>
          <p>创建时间:{{ item.orderAt | timeYmd }}</p>
        </div>
        <p class="line"></p>
        <div class="top_pay_type">
          <div>
            <img src="@/assets/imgs/mine/check_kf.png" alt="" />
            找客服
          </div>
          <span>支付定金</span>
        </div>
        <div class="btn_box">
          <div>复制</div>
          <span>{{ item.reservationPrice | changeGold }}</span>
        </div>
      </div>
    </div>
    <div class="diWangTj-item" v-else>
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images ? item.images[0] : ''" class="item-bg">
        <!-- 视频分类标签 -->
        <div class="diWangTj-type">
          <!--  -->
          <div class="diWangTj-type-free">入驻商家</div>
        </div>
        <!-- 标题 -->
        <div class="diWangTj-title">
          {{ item.title }}
        </div>
      </EDecryptImg>
      <!-- 影片描述 -->
      <div class="item-desc">
        <div class="desc">
          {{ item.girlHeight }} . {{ item.girlWeight }} . {{ item.girlAge }}岁 . {{ item.cupType }}
        </div>
        <div class="tagsPack">
          <div class="tags">已接单 {{ item.sells }}</div>
          <div class="tags">
            <img src="@/assets/imgs/mine/chart.png" alt="" />
            聊
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DiWangTj',
  data() {
    return {
      showType: false
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    cardType: {
      type: String,
      default() {
        return ''
      }
    }
  },

  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push(`/trade/DiWangTiaoJiaoDetail/?id=${item.id}`)
      this.$store.dispatch('setAHYlist', {
        type: 'add',
        item: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.diWangTj-order-item {
  border-radius: 0.12rem;
  border: 1px solid #535353;
  background: #292929;
  @include flexbox();
  padding: 0.24rem 0.2rem;
  margin-bottom: 0.32rem;

  .order_item_right {
    @include flexbox($fd: column, $ai: flex-end);

    .nums {
      p {
        font-size: 0.24rem;

        &:last-child {
          color: #787979;
          font-size: 0.22rem;
          margin-top: 0.11rem;
        }
      }
    }

    .line {
      background-color: rgba(151, 151, 151, 0.3);
      width: 3.75rem;
      height: 0.01rem;
      flex-shrink: 0;
      margin: 0.3rem 0 0.39rem 0;
    }

    .top_pay_type {
      @include flexbox($jc: flex-start);

      div {
        @include flexbox($jc: flex-start);
        color: #f77;
        font-size: 0.22rem;
        margin-right: 0.29rem;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.02rem;
        }
      }

      span {
        padding: 0.04rem 0.16rem;
        gap: 0.1rem;
        border-radius: 0.08rem;
        background: rgba(134, 36, 36, 0.3);
      }
    }

    .btn_box {
      @include flexbox($jc: flex-start);
      margin-top: 0.4rem;

      div {
        border-radius: 0.08rem;
        background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
        padding: 0.04rem 0.16rem;
        margin-right: 0.5rem;
      }

      span {
        color: #fff;
        font-size: 0.4rem;
        font-weight: 500;
      }
    }
  }
}

// 封面公告样式
.item-bg {
  position: relative;
  border-radius: 0.12rem;
  overflow: hidden;
  height: 4.2rem !important;

  :deep() {
    .warpNoPadding {
      border-radius: 0.12rem;
    }
  }

  .diWangTj-type {
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: #fff;
    font-size: 0.24rem;

    &-vip {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
    }

    &-free {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: #00f6f8;
    }
  }
}

// 我的订单封面单独样式
.diWangTj-order-item {
  .item-bg {
    width: 2.2rem;
    height: 2.9rem;
    flex-shrink: 0;
  }
}

// 记录样式
.diWangTj-item {
  width: 3.2rem;
  margin-bottom: 0.36rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;

  // 底部时间
  .diWangTj-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.64rem;
    padding: 0.155rem 0.14rem 0.145rem 0.24rem;
    line-height: 0.5rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.94%);
    @include textoverflow(1);
  }

  .item-desc {
    font-size: 0.24rem;

    .desc {
      margin: 0.16rem 0;
    }

    .tagsPack {
      height: 0.32rem;
      @include flexbox();

      .tags {
        &:first-child {
          color: #ffcf86;
        }

        &:last-child {
          @include flexbox($jc: center);
          border-radius: 0.35rem;
          background: $btnBg;
          color: #333;

          padding: 0.02rem 0.24rem;

          img {
            width: 0.22rem;
            height: 0.22rem;
            margin-right: 0.08rem;
          }
        }
      }
    }
  }
}
</style>
