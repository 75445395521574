<template>
    <div class="my-back">
        <EBackTop title="常见问题" background="#000" color="#fff">
            <slot slot="right" name="right">
                <router-link class="tag" to="/mine/setting/kf">
                    <div class="right_txt">都没解决?<span>问客服</span></div>
                </router-link>
            </slot>
        </EBackTop>
        <ul >
            <li v-for="(item,index) in textList" :key="index">
                    <div class="title" :class="showA===index?'padClass':''">
                        <div class="title_info">
                            <img src="@/assets/imgs/mine/Q.png" alt="">
                      <span :class="showA!==index?'textOver':''">  {{ item.question }}</span>
                        </div>
             
                      <p class="btn" @click="changeShow(index)">{{showA?'收起':'展开'}}</p>
                    </div>
                    <div class="desc" v-if="showA===index">
                        <img src="@/assets/imgs/mine/A.png" alt="">
                        <span>  {{  item.answer}}</span>
                    
                    </div>
            </li>
        </ul>
    </div>
</template>
  
<script>
import { faq_list } from '@/api/home'

export default {
    components: {
        EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    },
    data() {
        return {
            showA:undefined,
            textList: [

            ],
        }
    },
    methods: {
        changeShow(index){
            if(this.showA === index){
                this.showA = undefined
            }else{
                this.showA = index
            }
            
        },
        async getFaq(){
            const res = await faq_list({})
            if(res.code===200){
                this.textList = res.data.list
            }else{
                return this.$toast(res.tip)
            }
        }

    },
    mounted() {
        this.getFaq()
    }
}
</script>
  
<style lang="scss" scoped>
.my-back{
    background: url('../../../assets/imgs/mine/mine_top_bg.png') no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.right_txt{
    color: #fff;
    font-size: 0.2rem;
    span{
        color: #F9C006;
    }
}
ul{
    padding: 0.36rem;
}
li{
    border-radius: 0.08rem;
border: 1px solid #535353;
background: #111;
padding: 0.32rem;
margin-top:0.12rem;
.title{
    @include flexbox();
    color: rgba(255, 255, 255, 0.40);
font-size: 0.26rem;
font-weight: 400;
.title_info{
    @include flexbox($jc:flex-start);
}
    img{
        width: 0.24rem;
        margin-right: 0.12rem;
    }
    .btn{
        color: #F9C006;
    }
    .textOver{
        @include textoverflow();    

    }
}
.padClass{
    padding-bottom: 0.36rem;


}
.desc{
    @include flexbox($jc:flex-start,$ai:flex-start);
font-size: 0.26rem;
font-weight: 400;
padding-top: 0.36rem;
border-top: 0.01rem solid #535353;
span{
    white-space: pre-line;
}
img{
        width: 0.24rem;
        margin-right: 0.12rem;
    }
}
}
</style>
  