<template>
  <van-uploader
    :after-read="onReadImg"
    multiple
    accept="image/*"
  >
    <slot></slot>
  </van-uploader>
</template>
<script>
import {uploadImgs} from 'api/home'
import {imgsCompress} from '@/utils/utils_tools'
export default {
  //最大长度，回调，图片数组
  props: {
    maxLength: {
      type: Number,
      default() {
        return 8
      },
    },
    upLoadImgs: {
      type: Array,
      default() {
        return []
      },
    },    
  },  
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
    async onReadImg(file){
      let addLength=1;
      if(file.length) addLength=file.length;
      if(this.upLoadImgs.length+addLength>this.maxLength) return this.$toast(`最多传${this.maxLength}张`);
      let newList=[];
      let formData = new FormData();
      if (!file.length) {
        newList = await imgsCompress([file]);
      } else {
        newList = await imgsCompress(file);
      };
      for (let index = 0; index < newList.length; index++) {
        formData.append('upload[]', newList[index]);
      }          
      this.$toast.loading({duration: 0,message: '图片加载中',});
      const res = await uploadImgs(formData);
      if (res.code === 200) {
        this.$toast.clear();
        this.$toast("上传成功");
        this.$emit("onReadImg",res.data.paths);
      } else {
        this.$toast(res.tip||res.msg);
        this.$emit("onReadImg",[]);
      }        
    },  
  },
  mounted() {
  },
  computed: {
  },
}
</script>
<style lang="scss" scoped>
.com-publish-wrap {
  min-height: 100vh;
  box-sizing: border-box;
  font-size: 0.3rem;
  color: #333;
  .title-wrap{
    .h{
      position: relative;
      &::after{
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
        content: "20";
        display: block;
        color: #ddd;
        font-size: 0.3rem;
      }
    }
    .title{
      font-size: 0.3rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      padding-top: 0.3rem;
    }
  }
  .edit-contentWrap{
    padding-bottom: 3rem;
    .start-title{
      margin: 0 0.3rem;
      font-style:italic;
      border-top: 1px solid #eaeaea;
      padding-top: 0.22rem;
      padding-bottom: 0.3rem;
    }
    .edit-item{
      .portrait-img{
        position: relative;
        box-sizing: border-box;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        img{
          width: 100%;
        }
        .ico{
          position: absolute;
          top: 0.1rem;
          right: 0.1rem;
          z-index: 9;
          font-size: 0.4rem;
          padding: 0.05rem;
          transform: rotate(15deg);
          color: #fff;
          background: rgba($color: #000000, $alpha: 0.3);
          border-radius: 50%;
        }        
      }

    }
  }
  .uploadImg-wrap{
    position: fixed;
    z-index: 99;
    bottom: 1.5rem;
    left: 50%;
    margin-left: calc($pcMaxWidth/2 - 1.3rem);
    .uploadImg-btn{
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: #0082ff;
      box-shadow: 0.1rem 0.1rem 0.1rem #ddd;
      font-size: 0.2rem;
      @include flex-column-center;
      color: #fff;
      i{
        font-size: 0.32rem;
      }
      span{
        transform: scale(.7);
      }
    }
  }

  .btn-wrap {
    position: fixed;
    z-index: 99;
    bottom: 0;
    max-width: $pcMaxWidth;
    width: 100%;
    height: 1.2rem;
    line-height: 1.2rem;
    background: #303132;
    color: #fff;
    font-size: 0.36rem;
    text-align: center;
    @include flex-center;
    span{
      background-image: -webkit-linear-gradient(left, #fbdfd2, #ffb781);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;        
    }
  }
  :deep(){
    .van-cell{
      &::after{
        border-bottom: 0;
      }
    }
    .van-field__control{
      min-height: 0.6rem;
    }
  }
  .popupWrapper{
    min-width: 80%;
    margin: 0 auto;
    max-width: $pcMaxWidth;  
    left: auto;     
    box-sizing: border-box;
    .title{
      padding: 0.3rem 0;
    }
    .upload-wrap{
      padding:0.2rem 0.3rem 1rem 0.3rem;
      .btn{
        margin-top: 0.5rem;
        div{
          margin: 0 auto;
          width: 90%;
          height: 0.6rem;
          line-height: 0.6rem;
          text-align: center;
          color: #fff;
          background: #303132;
          border-radius: 0.5rem;
          span{
            background-image: -webkit-linear-gradient(left, #fbdfd2, #ffb781);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;                
          }
        }
      }
    }
    :deep(){
      .van-cell{
        padding: 0;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        background: #f2f5fa;
        border-radius: 0.05rem;
      }
    }
  }
  .van-popup-bottom{
    min-width: 80%;
    margin: 0 auto;
    max-width: $pcMaxWidth;  
    left: auto;    
    font-size: 0.30rem;
    border-top-right-radius: 0.15rem;
    border-top-left-radius: 0.15rem;
    .title{
      width: 100%;
      text-align: center;
      padding: 0.2rem 0;
      border-bottom: 1px solid #eee;
    }
    ul{
      height: 4.8rem;
      overflow-y: scroll;
      li{
        display: flex;
        justify-content: space-between;
        padding: 0.2rem 1rem;
        border-bottom: 1px solid #eee;
      }
    }
  }
}

</style>
