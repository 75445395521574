<template>
  <div class="long-six-item">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.coverImg" class="item-bg">
      <!-- 视频分类标签 -->
      <div class="video-type">
        <div class="video-type-vip" v-if="item.payType === 1">VIP</div>
        <div class="video-type-gold" v-if="item.payType === 2">
          <img src="@/assets/imgs/mine/gold.png" alt="" />
          {{ item.price | changeGold }}
        </div>
        <div class="video-type-free" v-if="item.payType === 0">免费</div>
      </div>
      <!-- 视频详情标签 -->
      <div class="vide-detail-type">
        <img v-if="item.showTags === '无码'" src="@/assets/imgs/index/video-ma.svg" alt="" />
        <img v-if="item.showTags === '中文'" src="@/assets/imgs/index/video-zhong.svg" alt="" />
      </div>
      <!-- 时间 -->
      <div class="video-time">
        <!-- <div>{{ item.collects | numberFilter('w') }}</div> -->
        <div>
          <img src="@/assets/imgs/index/eyes.svg" alt="" />
          {{ item.watchTimes | numberFilter('w') }}
        </div>
        <div>{{ item.playTime | secondToDate }}</div>
      </div>
    </EDecryptImg>
    <!-- 影片描述 -->
    <div class="item-desc">
      <div class="title">{{ item.title }}</div>
      <div class="tagsPack">
        <!-- <div class="tags" v-if="item.addedTime">
          {{ item.addedTime | timeDiff }}
        </div> -->
        <div class="tags" v-if="item.tags?.[0]">#{{ item.tags[0] }}</div>
        <!-- <div v-else></div> -->
        <div class="likePack">
          <img src="@/assets/imgs/mine/dianZan.png" alt="" />
          <p>{{ item.likes | numberFilter }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortVideo',
  props: ['item'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted(){
  },
  methods: {}
}
</script>

  <style lang="scss" scoped>
.long-six-item {
  width: 100%;
  margin-bottom: 0.1rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;
  .item-bg {
    position: relative;
    border-radius: 0.08rem;
    overflow: hidden;
    :deep() {
      .warpNoPadding {
        border-radius: 0.12rem;
      }
    }
    .video-type {
      position: absolute;
      top: 0.15rem;
      right: 0.18rem;
      height: 0.35rem;
      line-height: 0.35rem;
      color: #fff;
      font-size: 0.26rem;
      font-weight: 600;
      
      div {
        height: 100%;
        height: 0.35rem;
        line-height: 0.35rem;
        text-align: center;
        border-radius: 0.04rem;
      }
      &-vip {
        width: 0.64rem;
        background: #FFCF86;
        color: #333;
      }
      &-gold {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0.68rem;
        background: #3E287D;
        img {
          width: 0.22rem;
          height: 0.22rem;
          margin-right: 0.04rem;
        }
      }
      &-free {
        width: 0.64rem;
        background: #272727;
      }
    }
  }

  // 详情标签
  .vide-detail-type {
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 0.25rem;
      height: 0.25rem;
      margin-top: 0.06rem;
    }
    img:first-child {
      margin-top: 0;
    }
  }
  // 底部时间
  .video-time {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    color: #fff;
    line-height: 0.4rem;
    padding: 0 0.16rem;
    font-size: 0.24rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 0.32rem;
      height: 0.24rem;
      vertical-align: middle;
    }
  }
  .item-desc {
    font-size: 0.24rem;
    padding: 0.14rem;
    .title {
      @include textoverflow();
    }
    .tagsPack {
      height: 0.32rem;
      @include flexbox();
      margin-top: 0.1rem;
      .tags {
        background: $btnBg;
        font-size: 0.2rem;
        color: #333;
        border-radius: 0.08rem;
        height: 0.32rem;
        line-height: 0.32rem;
        padding: 0 0.1rem;
      }
      .likePack {
        display: flex;
        align-items: center;
        img {
          height: 0.22rem;
          margin-right: 0.08rem;
        }
      }
    }
  }
}
</style>
