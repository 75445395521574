<template>
  <div class="long-six-item" @click="toPlay(item)">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.coverImg ? item.coverImg : ''" class="item-bg">
      <!-- 视频分类标签 -->
      <!-- <div class="video-type">
        <div class="video-type-vip" v-if="item.payType === 1">VIP</div>
        <div class="video-type-gold" v-if="item.payType === 2">
          <img src="@/assets/imgs/mine/gold.png" alt="" />
          {{ item.price | changeGold }}
        </div>
        <div class="video-type-free" v-if="item.payType === 0">免费</div>
      </div> -->
      <!-- 观看数量 -->
      <div class="video-info">
        <div class="video-watchs">
          <!-- <img src="@/assets/imgs/index/card-play.svg" alt="" /> -->
          {{ item.watchs | numberFilter }}
        </div>
        <!-- 时间 -->
        <div class="video-time">
          <!-- <img src="@/assets/imgs/index/card-play.svg" alt="" /> -->
          {{ item.playTime | secondToDate }}
        </div>
      </div>
    </EDecryptImg>
    <!-- 影片描述 -->
    <div class="item-desc">
      <div class="title">
        {{ item.title }}
      </div>
      <div class="tagsPack">
        <!-- item.tags[0] -->
        <div class="tags">{{ item.showTags === 1 ? '无码' : '中文字幕' }}</div>
        <div class="dian_zan">
          <img src="@/assets/imgs/mine/dianZan.png" alt="" />
          <p>{{ item.likes }}</p>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="order_info" v-if="cardType === 'order'">
      <span>{{ item.orderAt | timeYmd }}</span>
      <span>-{{ item.price | changeGold }}</span>
    </div>
    <!-- 上次观看时间 -->
    <div class="watch-time" v-if="cardType === 'history'">• 上次观看至{{ item.currentTime | secondToDate }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ShortVideo',
  props: ['item', 'cardType'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
     toPlay(item) {
      this.$router.push(`/subpage/play/longVideo/${item.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.long-six-item {
  margin-bottom: 0.24rem;
  font-size: 0.24rem;
  width: 3.3rem;

  .item-bg {
    position: relative;
    border-radius: 0.08rem;
    overflow: hidden;
    height: 1.86rem;

    .video-type {
      position: absolute;
      top: 0.05rem;
      right: 0.1rem;
      color: #fff;
      font-size: 0.26rem;
      font-weight: 600;
      transform: scale(0.8);

      &-vip {
        border-radius: 0.04rem;
        background: #00f6f8;
        display: inline-flex;
        padding: 0.02rem 0.12rem;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
        box-sizing: content-box;
      }

      &-gold {
        border-radius: 0.04rem;
        background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
        display: inline-flex;
        padding: 0.02rem 0.06rem;
        justify-content: center;
        align-items: center;
        gap: 0.04rem;
        box-sizing: content-box;

        img {
          width: 0.22rem;
          height: 0.22rem;
        }
      }

      &-free {
        border-radius: 0.04rem;
        background: rgba(39, 39, 39, 0.8);
        display: inline-flex;
        padding: 0.02rem 0.12rem;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
      }
    }

    .video-info {
      height: 0.31rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.8;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);

      padding: 0.04rem 0.24rem;
      @include flexbox();
    }
  }

  .item-desc {
    .title {
      @include textoverflow(1);
      font-size: 0.26rem;
      margin: 0.15rem 0;
    }

    .tagsPack {
      @include flexbox();

      .tags {
        border-radius: 0.08rem;
        background: #00f6f8;
        display: flex;
        padding: 0rem 0.08rem;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
        color: #333;
      }

      img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.08rem;
      }
    }
  }
}

.dian_zan {
  @include flexbox($jc: center);
}

.watch-time {
  margin-top: 0.12rem;
  border-radius: 0.04rem;
  background: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  padding: 0.04rem 0.12rem;
  justify-content: center;
  align-items: center;
  gap: 0.08rem;
}

.order_info {
  @include flexbox();
  margin-top: 0.18rem;

  span {
    color: #787979;
    font-size: 0.22rem;
    font-weight: 400;

    &:last-child {
      color: #00f6f8;
      font-size: 0.28rem;
      font-weight: 500;
    }
  }
}
</style>
