<template>
    <div class="default-avatar">
      <EBackTop class="header" background="#000"  color="#fff" :title="'官方招募'">
       
      </EBackTop>
      <div class="bg">
       <div class="icon_list">
        <div class="item" @click="clickCanct(potatoUrl)">
            <img src="@/assets/imgs/mine/potato_icon.png" alt="">
            <p>官方土豆群</p>
        </div>
        <div class="item"  @click="clickCanct(tgUrl)">
            <img src="@/assets/imgs/mine/tg_icon.png" alt="">
            <p>官方飞机群</p>
        </div>
    
       </div>
      </div>
    </div>
  </template>
  
  <script>
  import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools';
  export default {
    name: 'Recruitment',
    components: {
        EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
      EDecryptImg: () => import('@/components/DecryptImg/index.vue'),
  
      },
    computed: {
    
    },
    data() {
      return {
   
      }
    },
    created() {
     
    },
    computed: {
      imgCDN({ $store }) {
        return $store.getters['cdn']
      },
      // 落地页
      tgUrl({ $store }) {
      return $store.getters['tgUrl']
    },
    // 落地页
    potatoUrl({ $store }) {
      return $store.getters['potatoUrl']
    },
    },
    methods: {
     clickCanct(url){
        if(!url) return this.$toast('亲，还没有准备好哦～')
        const code = handleURlParams(url);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
         
     }
     
    }
  }
  </script>
  
  <style lang="scss" scoped>
 .bg{
  background: url('../../../assets/imgs/mine/recruitment_bg.jpg') no-repeat;
  width: 100%;
  height: calc(100vh - 1rem);
background-size: cover;    
position: relative;
    .icon_list{
        position: absolute;
        top: 48%;
        left: 0.53rem;
        color: #333;
        @include flexbox($jc:flex-start);
        img{
            width: 0.8rem;
height: 0.8rem;
margin-bottom: 0.08rem;
        }
        .item{
            @include flexbox($fd:column,$jc:center);
            margin-right: 0.36rem;
        }
    }
 }
  </style>
  