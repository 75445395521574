<!-- home -->
<template>
    <div class="AnWangJiaoYi-container">
        <!-- 主题横排 -->
        <div class="theme_box">
            <div class="theme_box_title">话题分类</div>
            <ThemesList @postCategoryId="postCategoryId" />
        </div>
        <ETab class="AnWangJiaoYi-tab" @change="change" :sticky="true" :tabList="category" :v-model="activeSort"
            :animated="false" :lineSwitch="true" :lineBg="'#00F6F8'" :lineHeight="'0.08rem'" :line-width="'0.4rem'"
            :title-active-color="'#fff'" :title-inactive-color="'#fff'" :sizeTab="'0.32rem'" :sizeActive="'.38rem'">
            <template v-slot:default="scope">
                <!-- 列表 -->
                <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
                    :refreshing="refreshing">
                    <!-- 列表 -->
                    <ul class="ah_list">
                        <li v-for="item in mainList" :key="item.id">
                            <AnWangJiaoYiOneCard :item="item" :topicTitle="postCategory[0].name" />
                        </li>
                    </ul>
                </PullUp>

            </template>
        </ETab>

    </div>
</template>
  
<script>
import scrollMixin from '@/utils/scrollMixin'

import { post_list } from '@/api/dating'
export default {
    mixins: [scrollMixin],
    data() {
        return {
            themeList: [],
            activeSort: 0,
            pageNum: 1,
            pageSize: 10,
            loading: false,
            finished: false,
            refreshing: false,
            mainList: [],
            category: [
                { showType: 0, name: "最新" },
                { showType: 1, name: "求购" },
                { showType: 2, name: "售卖" },
                { showType: 3, name: "我的交易" },
            ],
            subBostCategoryId: 0
        }
    },
    components: {
        AnWangJiaoYiOneCard: () => import('./components/AnWangJiaoYiOneCard.vue'),
        ThemesList: () => import('./components/ThemesList.vue'),
        PullUp: () => import('@/components/PullUp.vue'),
    },
    computed: {

        imgCDN({ $store }) {
            return $store.getters['cdn']
        },
        postCategory({ $store }) {
            return $store.getters['postCategory']
        },
    },

    async mounted() {
        this.subBostCategoryId = this.postCategory[0].id
        await this.refreshData();

    },
    methods: {
        change(sort) {
            this.activeSort = sort
            this.refreshData()
        },
        postCategoryId(postCategoryId) {

            this.subBostCategoryId = postCategoryId
            this.refreshData()
        },
        async getDatingList() {
            const res = await post_list({
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
                "type": this.activeSort,
                id: this.subBostCategoryId
            })
            if (res.code === 200) {
                if (res.data.list && res.data.list.length) {
                    this.mainList = [...this.mainList, ...res.data.list]
                    this.loading = false
                    this.refreshing = false
                    if (res.data.list.length < this.pageSize) {
                        this.finished = true
                    }
                } else {
                    this.loading = false
                    this.refreshing = false
                    this.finished = true
                }
            }
        },
        refreshData(refreshing) {
            this.refreshing = refreshing
            this.loading = false
            this.pageNum = 1
            this.mainList = []
            this.getDatingList()
        },
        moreData(loading) {
            this.loading = loading
            this.pageNum += 1
            this.getDatingList()
        },
    },
    beforeDestroy() { }
}
</script>
<style lang="scss" scoped>
.AnWangJiaoYi-container {
    overflow: hidden; // bfc 解决外边距坍塌的问题

    .theme_box {
        position: fixed;
        z-index: 20;
        width: 100%;
        max-width: $pcMaxWidth;
        padding-bottom: 0.44rem;
        @include flexbox($jc: flex-start);
        background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
        background-size: cover;
        height: 2rem;
        top: 0.8rem;
        left: 50%;
        box-sizing: content-box;
        transform: translate(-50%, 0);

        .theme_box_title {
            position: absolute;
            width: 0.26rem;
            box-sizing: content-box;
            padding: 0.12rem 0.19rem;
            border-radius: 0.06rem;
            background: #5E1A1A;
        }
    }

    .AnWangJiaoYi-tab {
        margin-top: 3rem;

        :deep() {
            .van-sticky {
                position: fixed;
                top: 3rem;
                left: 50%;
                right: 0;
                z-index: 99;
                width: 100%;
                transform: translate(-50%, 0);
                max-width: $pcMaxWidth;
            }

            .van-tabs__nav {
                justify-content: flex-start;
                background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
                background-size: cover;
            }

            .van-tab {
                flex: none;
            }
        }
    }
}
</style>
  