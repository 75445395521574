<template>
  <div class="bind-phone">
    <EBackTop title="注册" background="#000" color="#fff"></EBackTop>
    <!-- 注册box -->
    <div class="login-box">
      <!-- 切换按钮 -->
      <van-tabs class="wallet_tabs" v-model="loginActive" @change="change">
        <van-tab title="手机注册"></van-tab>
        <van-tab title="账号注册"></van-tab>
      </van-tabs>
      <!-- 注册输入 -->
      <div class="login-input">
        <!-- 手机号注册 -->
        <div class="bind-phone-main" v-if="loginActive === 0">
          <p v-if="showCodeTip" class="err-desc">对不起,验证码错误,请重新输入</p>
          <div class="phone-number">
            <van-field class="country-input" v-model="country" placeholder="编码" />
            <div class="line"></div>
            <van-field
              class="phone-input"
              type="number"
              maxlength="11"
              v-model="mobile"
              placeholder="输入您的手机号码"
            />
          </div>
          <div class="code-number">
            <van-field
              maxlength="6"
              type="number"
              class="captcha-input"
              v-model="captcha"
              placeholder="输入短信验证码"
            />
            <div class="btn" :class="{ disabled: time }" @click="getCaptcha">
              {{ time ? num + 's' : '获取验证码' }}
            </div>
          </div>
        </div>
        <!-- 账号注册 -->
        <div v-else class="user-input">
          <p v-if="showPwdTip" class="err-desc">对不起,密码匹配错误,请重新输入</p>
          <div class="phone-number">
            <van-field class="phone-input" v-model="userName" placeholder="输入用户名" />
          </div>
          <div class="code-number">
            <van-field type="password" v-model="pwd" placeholder="请输入密码" />
          </div>
          <div class="phone-number">
            <van-field @change="focusFn" type="password" v-model="newPwd" placeholder="请再次输入密码" />
          </div>
        </div>
        <div class="tip_desc">
          <span @click.stop="$router.push(`/mine/setting/accoutCodeCamera`)">使用账号二维码凭证登录</span>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="submit" @click="bindPhoneFn">提交</div>
    </div>
    <!-- <div class="login-logo"><img src="/newAppMh/login-logo.png" alt=""></div>
      <div class="foot-desc">
        <img src="/newAppMh/mine/login/footer-left.png" alt="">
        <p> 真爱有颜色 • 尽在欲漫涩</p>
        <img src="/newAppMh/mine/login/footer-right.png" alt="">
  
      </div> -->
  </div>
</template>
    
  <script>
import { phone_login_api } from '@/api/login'
import { account_register_api } from '@/api/user'

export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue')
  },
  data() {
    return {
      country: '+86',
      mobile: '',
      captcha: undefined, //验证码
      time: false, //定时器开关
      num: 60,
      countryArr: [],
      timer: null,
      myCategoryShow: [
        { id: 0, name: '手机注册' },
        { id: 1, name: '账号注册' }
      ],
      loginActive: 0,
      userName: '',
      pwd: '',
      newPwd: '',
      showCodeTip: false,
      showPwdTip: false
    }
  },
  methods: {
    change(id) {
      this.loginActive = id
      this.showCodeTip = false
      this.showPwdTip = false
    },
    //游客登录
    async loginNewGuest() {
      const res = await this.$getYkInfo({
        affCode: this.store.state.user.channel ? this.store.state.user.channel : '{}',
        devID: this.store.state.user.uid
      })
      if (res && res.code === 200) {
          this.$store.dispatch('setUserInfo', res.data)
          this.$store.dispatch('setToken', res.data.token)
  
        this.$toast('登录成功！！！')
        setTimeout(() => {
          this.$router.push(`/mine`)
        }, 500)
      } else {
        this.$toast('登录失败！！！')
      }
    },
    // 获取验证码
    async getCaptcha() {
      this.countryArr = this.country.split('+')
      if (!this.country || !this.countryArr[1]) {
        return this.$toast('请输入国家编码')
      }
      if (!this.mobile) {
        return this.$toast('请输入手机号')
      }

      this.time = true
      let t
      if (this.time) {
        t = setInterval(() => {
          if (this.num > 0) {
            this.num--
          } else {
            this.time = false
            this.num = 60
            window.clearInterval(t)
          }
        }, 1000)
        const res = await login_captcha({
          country: this.countryArr[1],
          mobile: this.mobile
        })
        if (res.code === 200) {
          return this.$toast('验证码已发送，请输入验证码')
        } else {
          return this.$toast(res.tip)
        }
      }
    },
    // 二次密码确认
    focusFn() {
      if (this.pwd === this.newPwd) {
        this.showPwdTip = false
      }
    },
    // 清楚
    clearFn(key) {
      this[key] = undefined
    },
    // 注册提交
    async bindPhoneFn() {
      let res = ''
      if (this.loginActive === 0) {
        this.countryArr = this.country.split('+')
        if (!this.country || !this.countryArr[1]) {
          return this.$toast('请输入国家编码')
        }
        if (!this.mobile) {
          return this.$toast('请输入手机号')
        }
        if (!this.captcha) {
          return this.$toast('请输入验证码')
        }
        res = await phone_login_api({
          captcha: this.captcha, // 验证码
          country: this.countryArr[1], // 编码
          mobile: this.mobile // 手机号
        })
      } else {
        if (!this.userName) {
          return this.$toast('请输入用户名')
        }
        if (!this.pwd) {
          return this.$toast('请输入密码')
        }
        res = await account_register_api({
          account: this.userName,
          password: this.pwd
        })
      }

      if (res.code === 200) {
      this.$store.dispatch('setUserInfo', res.data)
          this.$store.dispatch('setToken', res.data.token)
        this.$router.push('/mine')
        return this.$toast('注册成功')
      } else {
        if (this.loginActive === 0 && res.code === 1007) {
          this.showCodeTip = true
        }
        this.time = false
        this.num = 60
        window.clearInterval(this.timer)
        return this.$toast(res.tip)
      }
    }
  },
  mounted() {
    // Dialog.confirm({
    //   title: '账号登录异常',
    //   message: '账号不能同时在两个客户端登录！',
    //   cancelButtonText: '游客进入',
    //   confirmButtonText: '重新登录',
    //   titleColro: '#000',
    //   className: 'Dialog',
    // })
    //   .then(() => {
    //     return this.$toast('您已选择手机号登录')
    //   })
    //   .catch(() => {
    //     this.loginNewGuest();
    //   })
  }
}
</script>
    
  <style lang="scss" scoped>
.bind-phone {
  min-height: 100vh;
  font-size: 0.28rem;
  background: url('../../../assets/imgs/mine/login_bg.png') no-repeat;
  background-size: cover;
}

:deep() {
  .van-cell {
    background: transparent;
    border: none;

    .van-field__body {
      width: 100%;
      height: 0.54rem;
    }

    .van-field__control {
      color: #fff;
    }
  }

  .txt {
    .van-field__control::-webkit-input-placeholder {
      color: #fff;
    }
  }

  .van-cell::after {
    display: none;
  }
}

// 注册页样式
.back_icon {
  width: 0.26rem;
  height: 0.34rem;
  margin-left: 0.5rem;
}

.login-box {
  width: 6.98rem;
  height: 8rem;
  flex-shrink: 0;
  border-radius: 0.24rem;
  margin: 1rem auto 0 auto;
  border-radius: 0.24rem;
  border: 2px solid #474645;
  background: url('../../../assets/imgs/mine/login_box_bg.png') no-repeat;
  background-size: cover;

  @include flex-column-center();
  text-align: center;
  padding: 0.56rem 0 0.5rem 0;

  &::after {
    backdrop-filter: blur(2.5px);
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .login-input {
    width: 6.32rem;
    flex-shrink: 0;
    border-radius: 0.2rem;
    border: 1px solid rgba(192, 0, 0, 0.5);
    background: rgba(36, 36, 36, 0.3);
    background-blend-mode: color-burn;
    margin: 0.51rem 0 0.58rem 0;
    padding: 0.4rem 0.3rem 0.62rem 0.4rem;
  }
}

.err-desc {
  color: #f77;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: PingFang SC;
  font-size: 0.22rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.36811rem;
  /* 167.321% */
}

// 切换tabs
.wallet_tabs {
  :deep() {
    .van-tabs__wrap {
      height: 0.63rem;
      @include flex-center;
    }

    .van-tabs__nav {
      width: 3.08rem;
      height: 0.63rem;
      border-radius: 0.48rem;
      background: #000 !important;
      background-blend-mode: color-burn;
      padding-bottom: 0;
      margin-left: 0.24rem;
      margin-top: 0.03rem;
      padding: 0 0.02rem;

      .van-tab {
        font-size: 0.24rem !important;
        z-index: 2;
        color: #fff;
      }
      .van-tab--active {
        color: #333;
      }

      .van-tabs__line {
        width: 1.36rem;
        height: 0.47rem;
        border-radius: 0.24rem;
        background: $btnBg;
      }

      .van-tabs__line {
        top: 0.06rem;
      }
    }
  }
}

.phone-number {
  @include flexbox($jc: flex-start);
  border: 0.01rem dashed #999;
  box-sizing: border-box;
  border-radius: 0.15776rem;
  margin: 0.3rem 0 0.4rem 0;

  .country-input {
    color: #999;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.28923rem;
    /* 111.241% */

    padding: 0;
    width: 1rem;

    :deep() {
      .van-field__control {
        text-align: center;
      }
    }
  }

  .line {
    height: 0.3rem;
    width: 0.02rem;
    background: #545560;
  }
}

.code-number {
  @include flexbox();
  border: 0.01rem dashed #999;
  box-sizing: border-box;
  border-radius: 0.15776rem;

  .btn {
    display: flex;
    padding: 0.04rem 0.08rem;
    align-items: center;
    gap: 0.2rem;
    border-radius: 0.04rem;
    background: $btnBg;
    margin-right: 0.24rem;
    text-align: center;
    font-size: 0.22rem;
    font-weight: 400;
    white-space: nowrap;
    color: #333;
  }
  .disabled {
    background: rgba($color: $btnBg, $alpha: 0.5);
  }
}

.tip_desc {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  @include flexbox();
  margin-top: 0.5rem;
}

.submit {
  display: inline-flex;
  padding: 0.24rem 2.56rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.08rem;
  background: $btnBg;
  color: #333;
}

.login-logo {
  @include flex-center;
  margin: 1rem 0 0.92rem 0;

  img {
    width: 1.6rem;
    height: 0.88rem;
    flex-shrink: 0;
  }
}

.foot-desc {
  @include flex-center;

  p {
    margin-left: 0.28rem;
    color: #666;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: PingFang SC;
    font-size: 0.22rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28rem;
  }

  img {
    width: 0.12rem;
    height: 0.12rem;
    flex-shrink: 0;
  }
}
</style>
    