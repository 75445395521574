<template>
  <div class="collect">
    <EBackTop :title="'我的收藏'" background="#000" color="#fff">
      <div class="right-btn" slot="right" @click="onClickRight">
        {{ btnTxt }}
      </div>
    </EBackTop>
    <ETab
      class="myHistory-tab"
      v-model="indexActive"
      @change="change"
      :sticky="true"
      :tabList="cartoonCategory"
      :animated="false"
      :title-active-color="'#FFF'"
      :title-inactive-color="'#FFF'"
      :sizeActive="'.3rem'"
      :lineSwitch="true"
      lineBg="#00F6F8"
      :ellipsis="false"
      lineBott="0.3rem"
    >
      <template v-slot:default="scope">
        <component cardType="collect" ref="childDom" :is="compComponent(scope.data)" :showMask="showMask"></component>
      </template>
    </ETab>
    <!-- 弹窗 -->
    <van-popup
      class="pay-pop"
      v-model="showType"
      @close="cancelConfirm"
      close-icon="cross"
      position="bottom"
      lazy-render
    >
      <div @click="confirm">删除</div>
      <div @click="cancelConfirm">取消</div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btnTxt: '编辑',
      //当前页
      pageNum: 1,
      // 当前个数
      pageSize: 40,
      cartoonCategory: [
        { showType: 1, name: '视频' },
        { showType: 6, name: '嫩模空降' },
        { showType: 4, name: '好茶约炮' },
        { showType: 5, name: '帖子' }
      ],

      indexActive: 0,
      showType: false,
      showMask: false
    }
  },
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    AnHeiYouItem: () => import('@/components/HistoryComponents/AnHeiYouItem.vue'),
    DiWangTJItem: () => import('@/components/HistoryComponents/DiWangTJItem.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue')
  },

  methods: {
    change(id) {
      this.indexActive = id
    },
    // 编辑按钮
    onClickRight() {
      if (this.btnTxt == '编辑') {
        this.showMask = true
        return (this.btnTxt = '完成')
      }
      this.showType = true
    },
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'

        case 4:
          return 'DiWangTJItem'
        case 5:
          return 'CommunityDefault'
        case 6:
          return 'AnHeiYouItem'
        default:
          return 'LongVideoItem'
      }
    },

    //取消
    cancelConfirm() {
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    //确定删除
    confirm() {
      // let tyoe = ''
      // if (this.indexActive === 0) {
      //   type = 'longVideo'
      // } else if (this.indexActive === 1) {
      //   type = 'shortVideo'
      // } else if (this.indexActive === 2){
      //   type = 'ahy'
      // }else if(this.indexActive === 3) {
      //   type = 'dwtj'
      // }else if(this.indexActive === 4) {
      //   type = 'ahjy'
      // }
      this.$refs.childDom.delCollect()
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>
<style lang="scss" scoped>
.collect {
  // margin-top: 2rem;
  padding-bottom: 1rem;
  // min-height: 100vh;
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
}

.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .van-tabs__nav {
      justify-content: flex-start;
      background: url('../../../assets/imgs/mine/mine_top_bg.png') no-repeat;
      background-size: cover;
    }
    .van-tab {
      flex: none;
    }
  }
}

.pay-pop {
  background-color: transparent;
  div {
    margin: 0 auto;
    width: 7rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    color: #00f6f8;
    background-color: #fff;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    font-weight: 600;
    font-size: 0.32rem;
  }
}
</style>
