<template>
  <transition name="van-fade">
    <div class="dplayer-wrap" v-show="dplayerShow" @click.stop="dplayerShow = false">
      <div class="video-wrap">
        <div class="content">
          <div class="back" @click.stop="dplayerShow = false">
            <van-icon name="arrow-left" size="30" color="#fff" />
          </div>
          <div @click.stop id="dplayerVideo" ref="dplayer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
const HlsJsPlayer = require('xgplayer-hls.js')

export default {
  data() {
    return {
      // 显示播放
      dplayerShow: false,
      hls: null,
      dp: null,
      timer: null,
      err: []
    }
  },
  methods: {
    // 视频播放
    fn_playVideo(videoUrl, videoCover) {
      this.dplayerShow = true
      // 设置播放器
      this.$nextTick(() => {
        this.dp = new HlsJsPlayer({
          id: `dplayerVideo`, //容器ID
          lang: 'zh-cn',
          // fluid: true, //流式布局
          autoplay: true, // 是否自动播放
          volume: 0.3, // 音量
          autoplayMuted: true,
          width: window.width,
          height: window.innerHeight,
          closeInactive: true, //使播放器控制栏常驻不隐藏
          loop: true, // 循环
          pic: false, // 画中画
          miniplayer: false, // 迷你播放
          miniplayerConfig: {
            bottom: 40,
            right: 0,
            width: 160,
            height: 90
          },
          // controls: true,
          controlsList: ['nofullscreen'],
          fullscreen: false,
          cssFullscreen: false,
          videoInit: false, // 初始化视频首帧
          lastPlayTime: 0, // 记忆播放，从多少多少秒开始
          lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
          rotateFullscreen: false, // 使得视频全屏幕时 横屏
          download: false, //设置download控件显示
          airplay: false,
          playsinline: true,
          preloadTime: 10,
          cors: true,
          url: '/api/app/media/m3u8/' + videoUrl,
          execBeforePluginsCall: [
            res => {
              console.log('Execute before plugins call')
            }
          ],

          'x5-video-player-type': 'h5', //微信同层播放：
          'x5-video-player-fullscreen': 'true', //微信全屏播放：
          'x5-video-orientation': 'landscape' //微信横竖屏控制
        })
        this.dp.once('error', error => {
          console.log(error)
        })
        this.dp.once('complete', () => {
          console.log('complete')
        })
      })
    },
    shutVideo() {
      if (this.dp) {
        if (this.dp.hls) {
          this.dp.hls.destroy()
        }
        this.dp.src = ''
        this.dp.destroy()
      }
    }
  },
  watch: {
    dplayerShow(nVal) {
      if (!nVal) {
        this.shutVideo()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dplayer-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  .video-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 0.32rem;
    .content {
      position: relative;
      .back {
        padding: 0.3rem 0.4rem 0.4rem 0.2rem;
        position: absolute;
        z-index: 9999;
      }
    }
    :deep() {
      .dplayer-video {
        max-height: 100vh;
      }
    }
  }
}
</style>
