<template>
  <div class="share">
    <div class="main">
      <div class="main_top">
        <img class="top_img" src="@/assets/imgs/mine/share_logo.png" alt="" />
        <p class="title">{{ proxyData.title }}</p>
        <div class="line"></div>
        <p class="invites_nums">
          累计邀请 <span>{{ userInfo.invites }}</span> 人
        </p>
        <!-- <img class="mm_img" src="@/assets/imgs/mine/mm.png" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" :imgURL="proxyData.imageUrl" class="mm_img" />
      </div>
      <div class="info-top">
        <div class="code">
          <EGenerateImg
            ref="EGenerateImg"
            :link="userInfo.inviteUrl"
            :name="`来自${userInfo.nickName}的分享地址.png`"
            :config="config"
          >
          </EGenerateImg>
        </div>

        <div class="info_right">
          <p>邀请好友看片赚钱</p>
          <p>好友也可直接填写邀请码</p>
          <p class="right_code">邀请码{{ userInfo.inviteCode }}</p>
        </div>
      </div>
      <!--  -->
      <div class="info_center">
        <div>
          <p>扫码或浏览器输入</p>
          <div>
            <span>
              {{ userInfo.inviteUrl }}
            </span>
     
          </div>
        </div>
        <div class="btn" @click="clickSaveImg">保存图片</div>
      </div>
      <!-- 分享链接 -->
      <div class="info_bot">
        <p>分享链接</p>
        <div class="tip">每邀请 <span>1</span> 人,送 <span>1</span> 天VIP</div>
        <div v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
          复制链接
        </div>
      </div>
    </div>

    <!-- <div class="btn">
      <div @click="clickSaveImg">保存图片分享</div>
      <div v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
        复制链接分享
      </div>
    </div>
    <div class="tip">
      <p>推广说明</p>
      <span>好友通过您的二维码或者推广链接下载APP,并启动后即算推广成功。</span>
    </div> -->
    <img
      @click="$router.push('/mine/myShouyi/promotion')"
      class="share_bot"
      src="@/assets/imgs/mine/share_bot.png"
      alt=""
      
    />
  </div>
</template>

<script>
import { get_proxy_config } from '@/api/user'
export default {
  name: 'MineShare',
  components: {},
  data() {
    return {
      config: {
        qrSize: 200
      },
      web: '',
      app: '',
      qrCode: '',
      downloadLink: '',
      proxyData: {}
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.getProxyConfig()
  },
  methods: {
    onCopy() {
      this.$toast('复制成功')
    },
    onError() {
      this.$toast('不支持复制')
    },
    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg()
    },
    async getProxyConfig() {
      const res = await get_proxy_config()
      this.proxyData = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.share {
  min-height: 100vh;
  // background: url('../../../assets/imgs/mine/share_bg.png') no-repeat;
  // background-size: cover;
  background-color: rgba($color: #000000, $alpha: 0.25);
}

.main {
  padding: 0.5rem 0.36rem;
  border-radius: 0.32rem;
  margin: 0.7rem 0.32rem 1.4rem 0.32rem;
  position: relative;
  border-radius: 0.32rem;
  border: 2px solid #535353;
  background: #070707;
}
.main_top {
  text-align: center;
  .title {
    margin: 0.28rem 0 0.25rem 0;
  }
  .line {
    width: 6.06rem;
    border-bottom: 0.05rem dashed #aaa;
    margin-bottom: 0.25rem;
  }
  .invites_nums {
    span {
      color: $btnBg;
    }
  }
  .top_img {
    width: 2.35rem;
  }
  .mm_img {
    width: 6.14rem;
    height: 3rem;
    flex-shrink: 0;
    margin: 0.24rem 0 0.36rem 0;
  }
}

.info-top {
  @include flexbox($jc: flex-start);
  padding-bottom: 0.46rem;
  border-bottom: 0.01rem solid #535353;
  .code {
    border-radius: 0.16rem;
    background: #fff;
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
    @include flexbox($jc: center);
    margin-right: 0.32rem;

    :deep() {
      .qrBg {
        position: static;
      }

      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .info_right {
    p {
      color: #999;
      font-size: 0.28rem;
    }

    .right_code {
      border-radius: 0.24rem;
      border: 1px solid $btnBg;
      background: rgba(134, 36, 36, 0.2);
      padding: 0.04rem 0.16rem;
      color: $btnBg;
      margin-top: 0.25rem;
      font-size: 0.3rem;
    }
  }
}
.info_center {
  @include flexbox();
  margin: 0.32rem 0 0.34rem 0;
  .btn {
    padding: 0.16rem 0.32rem;
    border-radius: 0.42rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  }
  span {
    color: $btnBg;
    font-family: PingFang SC;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.info_bot {
  border-radius: 0.16rem;
  border: 1px solid $btnBg;
  background: #0f0f0f;
  @include flexbox($jc: center, $fd: column);
  padding: 0.24rem 0;
  span {
    color: $btnBg;
  }
  .tip {
    margin: 0.24rem 0;
  }
  div:last-child {
    border-radius: 0.42rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
    padding: 0.08rem 0.28rem;
  }
}
.share_bot {
  width: 100%;
  padding: 0 0.46rem;
  margin-bottom: 0.84rem;
}
</style>
