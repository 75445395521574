<template>
  <div class="md-mine" ref="mine">
    <EBackTop :background="'transparent'" class="mine-head" color="#fff" title="我的">
      <slot slot="right" name="right">
        <router-link class="tag" to="/mine/adds">
          <van-icon :name="require('@/assets/imgs/mine/love.png')" />
        </router-link>
        <router-link class="tag" to="/mine/msg">
          <van-icon :dot="hasNewMsgTip" :name="require('@/assets/imgs/mine/msg.png')" />
        </router-link>
        <router-link class="tag" to="/mine/setting">
          <van-icon :name="require('@/assets/imgs/mine/setting.png')"
        /></router-link>
      </slot>
    </EBackTop>
    <div class="md-mine-main">
      <div class="main-top">
        <div class="md-mine-info">
          <div>
            <EDecryptImg
              :imgCDN="imgCDN"
              @click.native="$router.push('/mine/setting')"
              class="md-mine-photo"
              :imgURL="info.avatarUrl ? info.avatarUrl : ''"
            >
            </EDecryptImg>
            <div class="md-mine-account">
              <div class="name">
                <span>
                  {{ info.nickName }}
                </span>
                <span class="desc">ID:{{ info.id }}</span>
               
              </div>
            
             
              <span class="movieTickets_num"
                >观影卷：<span class="number">{{ info.movieTickets }}</span>
                <span class="line"></span>
                AI脱衣：<span class="number">{{ info.aiFreeRemoveClothCount }}</span>
                <span class="line"></span>
                AI换脸：<span class="number">{{ info.aiFreeChangeFaceCount }}</span>
                </span
              >
              <div @click.stop="goLogin" :class="info.loginType !== 0 ? 'isLogin_style' : 'login_btn'">
                  {{ `${info.loginType !== 0 ? '已登陆' : '登陆/注册'}` }}
                </div>
            </div>
          </div>
          <div class="sign-in" @click="$router.push('/mine/sigin')">
            <img src="@/assets/imgs/mine/sigin_icon.png" alt="" />
          </div>
        </div>

        <div class="md-mine-profile" v-if="!isMember">
          <div class="profile_bg bg-video">
            <!-- <img class="profile_bg" src="@/assets/imgs/mine/vip_default_bg.png" alt="" /> -->
            <video
              webkit-playsinline="true"
              playsinline="true"
              id="bg-video"
              src="../../assets/imgs/video/di7.mp4"
              muted
              autoplay
              loop
            ></video>
          </div>
          <div class="vip_info" @click="$router.push('/mine/vip')">
            <div class="left">
              <div class="title">VIP限时特惠 畅看全场 <img src="@/assets/imgs/mine/vip_icon.png" alt="" /></div>
              <div class="desc">开通 <span>VIP</span> 全场畅看 剩余可下载次数 0</div>
            </div>
            <div class="right">去开通</div>
          </div>
        </div>
        <div class="md-mine-profile vip_active" v-else @click="$router.push('/mine/vip')">
          <img
            class="profile_bg"
            :src="
              info.vipType == 1
                ? require('@/assets/imgs/mine/vip_default.png')
                : require('@/assets/imgs/mine/vip_bg_active.png')
            "
            alt=""
          />
          <div class="vip_info">
            <div class="left">
              <div class="title">{{ info.cardName }} <img src="@/assets/imgs/mine/vip_active_icon.png" alt="" /></div>
              <div class="desc">剩余可下载次数 {{ info.dDownLoadTotal }}</div>
            </div>
            <div class="right_active">
              <p>到期时间</p>
              <p>{{ info.vipExpireTime | timeYmd }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mine-card">
        <div class="item" @click="$router.push('/mine/vip')">
          <img class="bg" src="@/assets/imgs/mine/item_2.png" alt="" />
          <img src="@/assets/imgs/mine/vip_index_icon.png" alt="" />
          <p>会员中心</p>
          <div>享会员特权</div>
        </div>
        <div class="item" @click="$router.push('/mine/myWallet')">
          <img class="bg" src="@/assets/imgs/mine/item_1.png" alt="" />
          <img src="@/assets/imgs/mine/gold_bg.png" alt="" />
          <p>金币充值</p>
          <div class="my_balance">当前余额</div>
          {{ (info.balance + info.giftGold) | changeMoneyYuan }}
        </div>

        <div class="item" @click="$router.push('/mine/myShouyi')">
          <img class="bg" src="@/assets/imgs/mine/item_3.png" alt="" />
          <img src="@/assets/imgs/mine/tuiguang.png" alt="" />
          <p>推广福利</p>
          <div>最高<span>55%</span>分成</div>
        </div>
      </div>
      <!-- ai入口 -->
      <div class="ai_main" @click="$router.push('/ai/generate')">
        <img src="@/assets/imgs/mine/ai_main.png" alt="" />
        <img src="@/assets/imgs/aiLab/icon_ai_arrow.gif" alt="" />
      </div>

      <div class="md-mine-property-panel">
        <router-link to="/trade/myPost" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/myPublic.png" alt="" />
          <p>我的发布</p>
        </router-link>
        <router-link to="/mine/myCollect" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/myCollect.png" alt="" />
          <p>我的收藏</p>
        </router-link>
        <router-link to="/mine/myOrder" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/myOrder.png" alt="" />
          <p>我的订单</p>
        </router-link>
        <router-link to="/mine/recruitment" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/myShare.png" alt="" />
          <p>官方招募</p>
        </router-link>
      </div>
      <!-- 广告轮播 -->
      <AdAvatarList v-if="$store.state.config.config.mineAdsType === 2"  :list="iconMineAds" :wrap="true" key-name="avatar" />

      <div class="swiperPack" v-else="$store.state.config.config.mineAdsType !== 3 &&  mineAD.length">
        <EADSwiper
          :list="mineAD"
          :slidesPerView="1"
          keyName="cover"
          :EADconfig="EADconfig"
          :imgCDN="imgCDN"
          @clickImg="clickImg"
        >
        </EADSwiper>
      </div>
      <!-- 跳转列表 -->
      <div class="md-mroe-servic">
        <van-cell title="账号凭证" is-link @click="showAccountImgBtn = true">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/account_id.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="浏览记录" is-link to="/mine/myHistory">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/myHistory.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="兑换中心" is-link to="/mine/redemptionCode">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/code.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="应用中心" is-link to="/mine/cooperateApp">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/app.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="在线客服" is-link to="/mine/setting/kf">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/kf.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="官方社群" is-link to="/mine/myGroup">
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/groupIcon.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="常见问题" is-link to="/mine/myQuestion">
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/wenti.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="问题反馈" is-link to="/mine/myBack">
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/fankui.png')" class="md-mine-icon" />
          </template>
        </van-cell>
      </div>
    </div>
    <van-popup class="code_pop" v-model="showAccountImgBtn" @close="closeAccountImg">
      <div class="code_pop_bg">
        <div class="account-img">
          <div class="code">
            <EGenerateImg
              ref="EGenerateImg"
              :link="qrCode"
              :name="`来自${info.nickName}的个人凭证.png`"
              :config="config"
            >
            </EGenerateImg>
          </div>
          <div class="account_info">
            <img class="title" src="@/assets/imgs/mine/mine_code_title.png" alt="" />
            <span>乱伦的欲望 从释放天性开始</span>
            <div class="desc">永久域名 {{ ldyCdn }}</div>
          </div>
        </div>
        <div class="tip">如果账号丢失,请到 <span>设置-找回账号-账号凭证</span> 找回上传凭证或者扫描凭证</div>
        <div class="bottom-btn" @click="clickSaveImg">保存账号凭证到手机</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
// 处理参数类型
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
import { userQrcode, userInfo, userIsCert } from 'api/user'
import QrcodeVue from 'qrcode.vue'
import QRCode from 'qrcode'
import axios from 'axios'
export default {
  components: {
    QrcodeVue,
    JavSwiper: () => import('@/components/JavSwiper.vue'),
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    EDecryptImg: () => import('@/components/web_base/component_base/EDecryptImg/index.vue'),
  AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    // SaveCredentials: () => import('@/components/SaveCredentials/index.vue')
  },
  data() {
    return {
      config: {
        qrSize: 200
      },
      web: '',
      hasNewMsgTip: false,
      app: '',
      qrCode: '',
      backup: '',
      showAccountImgBtn: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 4000
      }
    }
  },
  computed: {
    hasNewMsg({ $store }) {
      return $store.getters['hasNewMsg']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    mineAD({ $store }) {
      return $store.getters['mineAD']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    showAccountImg({ $store }) {
      return $store.state.user.showAccountImg
    },
    // 是否保存过个人凭证
    isSaveImg({ $store }) {
      return $store.state.user.isSaveImg
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
         // 我的icon列表
    iconMineAds({ $store }) {
      return $store.getters["iconMineAds"];
    },
  },
  async mounted() {
    this.showAccountImgBtn = this.showAccountImg && !this.isSaveImg
    this.hasNewMsgTip = this.hasNewMsg
    console.log(this.info,'fff');
    await this.getUerQrcode() 
    await this.$store.dispatch('get_userInfo')
    if (document.getElementById('bg-video')) {
      document.getElementById('bg-video').play()
    }
  },
  methods: {
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    },
    // 登陆跳转
    goLogin() {
      if (this.info.loginType === 0) {
        this.$router.push('/mine/login')
      }
    },
    async clickImg(item) {
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    clickSaveImg() {
      userIsCert({
        isSave: true
      }).then(res => {
        if (res.code === 200) {
          this.$refs['EGenerateImg'].clickSaveImg()
          this.$store.commit('isSaveImg', true)
          this.$toast('保存成功')
        } else {
          this.$toast('保存失败，请联系在线客服！')
        }
      })

      this.closeAccountImg()
    },
    onCopy() {
      this.$refs['EGenerateImg'].doCopy()
    },

    goRouter(path = '/') {
      this.$router.push(path)
    },
    // 点击顶部轮播图广告
    async clickTopSwiperSlide(item) {
      if (item.href) {
        const type = handleURlParams(item.href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        const res = await advertiseClick({
          id: item.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
    },
    async getUerQrcode() {
      const res = await userQrcode()
      if (res && res.code === 200) {
        this.web = res.data.web
        this.app = res.data.app
        this.qrCode = res.data.value
        this.backup = res.data.backup
      }
    },

    download(href) {
      const eleLink = document.createElement('a')
      eleLink.href = href
      eleLink.setAttribute('download', `来自${this.info.nickName}的个人凭证.png`)
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$store.commit('isSaveImg', true)
      this.closeAccountImg()
    },
    closeAccountImg() {
      this.showAccountImgBtn = false
      this.$store.commit('SET_ACCOUNT_IMG', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: center;
  align-items: center;

  .md-mine-photo {
    width: 0.9rem !important;
    height: 0.9rem;
    margin-right: 0.15rem;
  }

  h2 {
    color: #f96491;
    font-size: 0.38rem;
  }
}
.md-mine-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
}
.md-mine-property-panel {
  > a {
    flex: 1;
  }
}
.mine-card {
  margin: 0.36rem 0 0.16rem;
  @include flexbox($jc: center);

  .item {
    // width: 1.97rem;
    flex: 1;
    height: 2.4rem;
    border-radius: 0.12rem;
    font-size: 0.32rem;
    @include flexbox($jc: center, $fd: column);
    position: relative;

    div {
      font-size: 0.22rem;
    }

    &:nth-child(2) {
      border: 1px solid #ffcf86;
      margin-right: 0.16rem;

      .my_balance {
        color: #ffcf86;
        font-family: PingFang SC;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 0.04rem;
        border: 1px solid #ffcf86;
        background: rgba(255, 207, 134, 0.1);
        padding: 0.02rem 0.08rem;
        margin: 0.08rem 0;
      }

      img {
        width: 0.58rem;
        height: 0.44rem;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:first-child {
      border: 1px solid #00f6f8;
      margin-right: 0.16rem;

      p {
        margin: 0.34rem 0 0.1rem 0;
      }

      img {
        width: 0.57rem;
        height: 0.47rem;
      }

      span {
        color: #00f6f8;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:last-child {
      border: 1px solid #989dff;

      p {
        margin: 0.28rem 0 0.1rem 0;
      }

      img {
        width: 0.44rem;
        height: 0.72rem;
      }

      span {
        color: #989dff;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.md-mine {
  min-height: 100vh;
  position: relative;
  padding: 0 0.25rem 1.5rem 0.25rem;

  .mine-head {
    :deep() {
      .van-nav-bar {
        border: none;

        .van-nav-bar__content {
          .van-nav-bar__left {
            display: none;
          }
        }
      }
    }
  }

  .main-top {
    border-radius: 0.14rem;
    // box-shadow: $shadow;
    margin-top: 0.2rem;
    .bg-video {
      border-radius: 0.12rem;
      overflow: hidden;
      video {
        width: 100%;
      }
      // -webkit-backface-visibility: hidden;
      // -webkit-transform: translate3d(0, 0, 0);
    }
  }

  &-info {
    padding: 0.27rem 0 0.2rem 0;
    display: flex;
    // margin: 0 0.4rem;
    flex-flow: row nowrap;
    align-items: center;
    // border-bottom: 0.02rem solid #5b5b6f;
  }

  &-photo {
    width: 1.5rem !important;
    border-radius: 50%;
    overflow: hidden;
  }

  &-account {
    margin-left: 0.4rem;
    height: 100%;
    line-height: 1.5;

    .name {
      font-size: 0.32rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      @include flexbox($jc: flex-start);

      span:first-child {
        margin-right: 0.12rem;
      }
    }

    .desc {
      color: rgba(255, 255, 255, 0.6);
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .line {
      width: 0.03rem;
      height: 0.2rem;
      background: rgba(255, 255, 255, 0.6);
      display: inline-block;
      margin: 0 0.1rem;
    }
    .movieTickets_num {
      color: rgba(255, 255, 255, 0.6);
    }
    .number {
      color: #f9c006;
    }
  }

  &-profile {
    position: relative;
    height: 1.28rem;

    .profile_bg {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .vip_info {
      position: absolute;
      height: 100%;
      width: 100%;
      @include flexbox();
      padding: 0 0.31rem;
      left: 0;

      .left {
        .title {
          font-size: 0.3rem;
        }

        .desc {
          color: rgba(255, 255, 255, 0.5);
          font-family: PingFang SC;
          font-size: 0.22rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #00f6f8;
          }
        }

        img {
          width: 0.3rem;
          height: 0.26rem;
          flex-shrink: 0;
        }
      }

      .right {
        display: inline-flex;
        padding: 0.08rem 0.24rem;
        justify-content: center;
        align-items: center;
        gap: 0.1rem;
        border-radius: 0.64rem;
        background: #00f6f8;
        color: #333;
        font-size: 0.26rem;
      }
    }
  }

  &-property-panel {
    margin: 0.2rem 0;

    @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);

    a {
      color: #fff !important;
    }
  }

  &-property-info {
    @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
    margin-right: 0.52rem;

    &:last-child {
      margin-right: 0;
    }

    img {
      margin-bottom: 0.25rem;
    }

    &:nth-child(1) {
      img {
        width: 0.6rem;
        height: 0.42rem;
      }
    }

    &:nth-child(2) {
      img {
        width: 0.64rem;
        height: 0.56rem;
      }
    }

    &:nth-child(3) {
      img {
        width: 0.64rem;
        height: 0.56rem;
      }
    }

    &:nth-child(4) {
      img {
        width: 0.52rem;
        height: 0.42rem;
      }
    }

    p {
      font-size: 0.22rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      margin: 0 0;
      padding: 0 0;
    }
  }

  .swiperPack {
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
    height: 1.6rem;
  }

  .md-mroe-servic {
    border-radius: 0.14rem;
    box-shadow: $shadow;
    margin-top: 0.3rem;

    .van-cell {
      background-color: transparent;
      font-size: 0.26rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: #fff;
      justify-content: space-between;
    }

    :deep() {
      .van-cell::after {
        display: none !important;
      }

      .van-cell__value {
        flex: none;
        color: #fff;
        border-radius: 0.08rem;
        border: 1px solid #00f6f8;
        background: rgba(134, 36, 36, 0.16);
        padding: 0 0.12rem;
      }
    }

    .md-mine-icon {
      font-size: 0.28rem;
      padding: 0.05rem 0;

      img {
        width: 0.36rem;
        height: 0.36rem;
        object-fit: contain;
        margin-right: 0.12rem;
      }
    }
  }

  .tag {
    font-size: 0.38rem;
    margin: 0 0.1rem;
    position: relative;
    top: 0.05rem;
  }
}

.code_pop {
  background-color: transparent;
}

.code_pop_bg {
  background-image: url('../../assets/imgs/mine/mine_code_bg.png');
  // background-image: url('../../assets/imgs/mine/myShare-bg.svg');
  // background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  width: 6rem;
  padding-bottom: 0.64rem;
  text-align: center;
  border-radius: 0.32rem;
  overflow: hidden;
  .title {
    width: 2rem;
  }
  .tip {
    margin: 0.52rem 0 0.36rem 0;
    font-size: 0.24rem;
    text-align: center;

    span {
      color: $btnBg;
    }
  }
}

.account-img {
  padding: 4.7rem 0.36rem 0 0.36rem;
  @include flexbox();
  flex-shrink: 0;
  border-radius: 0.2rem;

  .code {
    border-radius: 0.16rem;
    background: #fff;
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
    margin-bottom: 0.52rem;
    @include flexbox($jc: center);
    margin-right: 0.15rem;
    padding: 0.14rem 0.2rem;

    :deep() {
      .qrBg {
        position: static;
        width: 100%;
        height: 100%;
      }

      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.account_info {
  @include flexbox($fd: column);
  max-width: 3rem;

  span {
    margin: 0.15rem 0 0.03rem 0;
    color: rgba(255, 255, 255, 0.7);

    font-size: 0.26rem;
  }
  .desc {
  max-width: 3rem;

    color: $btnBg;
    word-wrap:break-word;
  }
}
.bottom-btn {
  border-radius: 0.42rem;
  background: $btnBg;
  padding: 0.16rem 0.48rem;
  color: #333;
  text-align: center;
  display: inline-block;
}
// 新增登陆注册
.login_btn {
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0.16rem;
  border-radius: 0.04rem;
  background: #00f6f8;
  display: inline-block;
  color: #333;
}

.isLogin_style {
  border-radius: 0.04rem;
  background: #00f6f8;
  margin-left: 0.16rem;
  color: #333;

  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.md-mine-sj {
  border-radius: 0.08rem;
  border: 1px solid #777;
  background: rgba(0, 0, 0, 0.6);
  width: 6.22rem;
  padding: 0.4rem 0.32rem;
  margin: 0 auto;
  text-align: center;

  p:first-child {
    font-size: 0.5rem;
  }

  p:last-child {
    border-radius: 0.18rem;
    background: rgba($color: #00f6f8, $alpha: 0.7);
    display: inline-flex;
    padding: 0.01rem 0.1rem;
    font-size: 0.18rem;
  }
}
.vip_active {
  .title {
    background: linear-gradient(265deg, #ffbd8d 4.92%, #ffd2b5 95.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .right_active {
    text-align: right;
    p:last-child {
      border-radius: 0.06rem;
      background: rgba(0, 0, 0, 0.5);
      padding: 0.02rem 0.12rem;
    }
  }
}
.ai_main {
  position: relative;
  img {
    height: 2.1rem;
    width: 100%;
    &:nth-of-type(2) {
      width: 0.71rem;
      height: 0.41rem;
      position: absolute;
      left: 2.38rem;
      bottom: 0.12rem;
    }
  }
}
.sign-in {
  position: absolute;
  right: 0;
  top: 1.6rem;

  img {
    width: 1.2rem;
    height: 0.8rem;
  }
}
</style>
  
  