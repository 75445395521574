<template>
  <div class="record-container">
    <EBackTop title="提现记录" background="#000" color="#fff"></EBackTop>
    <van-pull-refresh v-if="transListData && transListData.length > 0" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad">
        <ul class="record-listWrap">
          <li class="item" v-for="(item, index) in transListData" :key="index">
            <div class="top">
              <div class="top-right">
                <div class="orderNo">订单编号:{{ item.tradeNo }}</div>
                <div class="createdAt">{{ item.createdAt | formatDate }}</div>
              </div>
              <div
                class="copy-btn"
                v-clipboard:copy="item.orderNo"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <span>复制</span>
              </div>
            </div>
            <div class="bottom">
              <div class="bottom-left">
                <span :class="item.status === 1 ? 'success' : item.status === 4 ? 'error' : 'default'">{{
                  item.status === 1 ? '审核通过' : item.status === 4 ? '审核拒绝' : '待审核'
                }}</span>
                <span>{{ item.reason }}</span>
              </div>
              <div>
                {{ item.coinAmount | changeMoneyYuan }}
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>

    <Nodata :text="'您还没有记录哦～'" v-else />
  </div>
</template>
<script>
import { withdrawal_list } from 'api/user'
export default {
  data() {
    return {
      // 上拉加载（loading:false代表可继续上拉加载）
      loading: false,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 关注列表
      transListData: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10
    }
  },

  components: {
    Nodata: () => import('@/components/JavNodata.vue')
  },
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await withdrawal_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        console.log('res', res)
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.transListData = [...this.transListData, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          // 下拉消失（自动开启下拉）
          this.refreshing = false
          this.loading = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.transListData = []
      this.get_transList()
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  },
  mounted() {
    this.onRefresh()
  },
  created() {},
  computed: {}
}
</script>
<style lang="scss" scoped>
.record-container {
  padding-top: 1rem;
  min-height: 100vh;
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  .record-listWrap {
    margin: 0.3rem 0;
    padding: 0 0.34rem;

    .item {
      border-radius: 0.12rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.42rem;
      margin-top: 0.32rem;
      .top {
        @include flexbox();
        border-bottom: 0.01rem solid #979797;
        padding-bottom: 0.24rem;
        .orderNo {
          font-size: 0.26rem;
        }

        .createdAt {
          color: #787979;
          font-size: 0.24rem;
        }
      }
      .bottom {
        margin-top: 0.33rem;
        @include flexbox();
        .bottom-left {
          @include flexbox($jc: flex-start);
        }
        .bottom-type {
          border-radius: 0.08rem;
          background: rgba(0, 246, 248, 0.3);
          padding: 0.04rem 0.16rem;
        }
        span {
          padding: 0.04rem 0.16rem;
          gap: 0.1rem;
          border-radius: 0.04rem;
          background: rgba(190, 145, 85, 0.3);
        }
        .success {
          border-radius: 0.04rem;
          background: rgba(85, 190, 127, 0.3);
        }
        .error {
          border-radius: 0.04rem;
          background: #00f6f8;
        }
      }
    }
  }

  .copy-btn {
    border-radius: 0.08rem;
    background: $btnBg;
    padding: 0.04rem 0.16rem;
    width: 0.96rem;
    height: 0.53rem;
    text-align: center;
    font-size: 0.32rem;
    white-space: nowrap;
    color: #333;
  }
}
</style>
