<template>
  <div @click.stop="toDetail(item)">
    <div class="anHeiYou-order-item" v-if="cardType === 'order'">
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images ? item.images[0] : ''" class="item-bg">
        <!-- 视频分类标签 -->
        <div class="anHeiYou-type">
          <div class="anHeiYou-type-vip" v-if="item.sellerType === 2">官方自营</div>
          <div class="anHeiYou-type-free" v-if="item.sellerType === 1">入驻商家</div>
        </div>
        <!-- 标题 -->
        <div class="anHeiYou-title">
          {{ item.title }}
        </div>
      </EDecryptImg>
      <div class="order_item_right">
        <div class="nums">
          <p>订单编号:{{ item.orderId }}</p>
          <p>创建时间:{{ item.orderAt | timeYmd }}</p>
        </div>
        <p class="line"></p>
        <div class="top_pay_type">支付定金</div>
        <div class="btn_box">
          <div>复制</div>
          <span>{{ item.infoPrice | changeGold }}</span>
        </div>
      </div>
    </div>
    <div class="anHeiYou-item" v-else>
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images ? item.images[0] : ''" class="item-bg">
        <!-- 视频分类标签 -->
        <div class="anHeiYou-type">
          <div class="anHeiYou-type-vip" v-if="item.sellerType === 2">官方自营</div>
          <div class="anHeiYou-type-free" v-if="item.sellerType === 1">入驻商家</div>
        </div>
        <!-- 标题 -->
        <div class="anHeiYou-title">
          {{ item.title }}
        </div>
      </EDecryptImg>
      <!-- 影片描述 -->
      <div class="item-desc">
        <div class="desc">
          {{ item.girlHeight }} . {{ item.girlWeight }} . {{ item.girlAge }}岁 . {{ item.cupType }}
        </div>
        <div class="tagsPack">
          <div class="tags">{{ item.price | changeMoneyYuan }}</div>
          <div class="tags">约</div>
        </div>
      </div>
      <div class="options">
      <img src="@/assets/imgs/options_icon.png" alt="">
      <span>{{ item.cityName }}</span>
    </div>
    </div>
    <slot></slot>
    <van-popup
      class="pay-pop"
      v-model="showType"
      @close="cancelConfirm"
      close-icon="cross"
      position="bottom"
      lazy-render
    >
      <div class="content">
        <span>确认删除？</span>
        <div class="btn-wrap">
          <div @click="confirm">删除</div>
          <div @click="cancelConfirm">取消</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'anHeiYou',
  data() {
    return {
      showType: false
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    cardType: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    cancelConfirm() {},
    confirm() {},
    toDetail(item) {
      this.$router.push(`/trade/AnHeiYouDetail/?id=${item.id}`)
      this.$store.dispatch('setAHYlist', {
        type: 'add',
        item: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.anHeiYou-order-item {
  border-radius: 0.12rem;
  border: 1px solid #535353;
  background: #292929;
  @include flexbox();
  padding: 0.24rem 0.2rem;
  margin-bottom: 0.32rem;

  .order_item_right {
    @include flexbox($fd: column, $ai: flex-end);

    .nums {
      p {
        font-size: 0.24rem;

        &:last-child {
          color: #787979;
          font-size: 0.22rem;
          margin-top: 0.11rem;
        }
      }
    }

    .line {
      background-color: rgba(151, 151, 151, 0.3);
      width: 3.75rem;
      height: 0.01rem;
      flex-shrink: 0;
      margin: 0.3rem 0 0.39rem 0;
    }

    .top_pay_type {
      padding: 0.04rem 0.16rem;
      gap: 0.1rem;
      border-radius: 0.08rem;
      background: rgba(190, 145, 85, 0.3);
    }

    .btn_box {
      @include flexbox($jc: flex-start);
      margin-top: 0.4rem;

      div {
        border-radius: 0.08rem;
        background: #00f6f8;
        padding: 0.04rem 0.16rem;
        margin-right: 0.5rem;
      }

      span {
        color: #fff;
        font-size: 0.4rem;
        font-weight: 500;
      }
    }
  }
}

// 封面公告样式
.item-bg {
  position: relative;
  border-radius: 0.12rem;
  overflow: hidden;
  height: 4.2rem !important;

  :deep() {
    .warpNoPadding {
      border-radius: 0.12rem;
    }
  }

  .anHeiYou-type {
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: #fff;
    font-size: 0.24rem;

    &-vip {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: $btnBg;
      color: #333;
    }

    &-free {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: #5f4a30;
    }
  }
}

// 记录样式
.anHeiYou-item {
  width: 3.2rem;
  margin-bottom: 0.36rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;

  .item-desc {
    font-size: 0.24rem;

    .desc {
      margin: 0.16rem 0;
    }

    .tagsPack {
      height: 0.32rem;
      @include flexbox();
margin-bottom: 0.1rem;
        .tags {
        &:first-child {
          color: #00f6f8;
          font-size: 0.34rem;
          font-weight: 500;
        }

        &:last-child {
          border-radius: 0.08rem;
          background: linear-gradient(110deg, #59411e 3.16%, #774b17 103.35%);
          padding: 0.02rem 0.24rem;
          color: #333;
        }
      }
    }
  }
}
// 我的订单封面单独样式
.anHeiYou-order-item {
  .item-bg {
    width: 2.2rem;
    height: 2.9rem;
    flex-shrink: 0;
  }
}
.anHeiYou-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.64rem;
  padding: 0.155rem 0.14rem 0.145rem 0.24rem;
  line-height: 0.5rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.94%);
  @include textoverflow(1);
}
.options {
  border-radius: 0.06rem;
  background: #373737;
  @include flexbox($jc: flex-start);
  padding: 0.04rem 0.08rem;
  flex: none;
  display: inline;


  img {
    width: 0.18rem;
    height: 0.22rem;
    margin-right: 0.08rem;
  }
}
</style>
