<template>
    <div class="release">
      <EBackTop title="意见反馈" background="#000" color="#fff"></EBackTop>
      <div class="release-main">
      <!-- 描述信息 -->
      <van-field
        maxlength="2000"
        v-model="text"
        autosize
        required
        type="textarea"
        placeholder="请详细描述您的意见问题，非常感谢您对我们的支持！"
      />
      <div class="tip">不能超过2000字符</div>
      <ul class="img-show">
        <DecryptImg 
          ref="refImgParent"
          v-for="(item, index) in upLoadImgs" 
          :key="index" 
          :upLoadImgs="upLoadImgs"
          :rowNum="3"
          :item="item"
          :showClose="true" 
        ></DecryptImg>             
      </ul>     
      <div class="btn-wrap">
        <div class="btn1" @click="loadImgFn">
            <img src="@/assets/imgs/mine/upload.png" alt="">
            插入图片
          </div>
        <UploadImgs v-show="false" ref="loadImg" :maxLength="9" :upLoadImgs="upLoadImgs" @result="onReadImg">
          
        </UploadImgs>
        <div class="btn2" @click="submit">
          提交
        </div>
      </div>

    </div>
    </div>
  </template>
  
  <script>
import {feedback_add} from '@/api/user'

  export default {
    components: {
      EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
      ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
      DecryptImg: () => import('@/components/DecryptImg/index.vue'),
      UploadImgs: () => import('@/components/UploadImg/index.vue'),
    },
    data() {
        return {
      upLoadImgs:[],
      text: '',
      textList: [
        '1.每一条楼凤只能举报一次不能重复举报',
        '2.楼凤举报有截图证明的合适以后可享受楼凤退款',
        '3.购买楼凤后超过7天再进行举报则视为常规举报，不享受退款处理',

      ],
    }
    },
    methods: {
      //读取img
    onReadImg(list){
      if(!list.length) return;
      this.upLoadImgs=[...this.upLoadImgs,...list];
    },
    loadImgFn(){
        this.$refs.loadImg.$refs.Uploader.chooseFile()
    },
    async submit() {
      if (!this.text || this.text.length<8) {
        return this.$toast('意见反馈字数不可少于8个字')
      }
      // if (this.upLoadImgs.length<4) {
      //   return this.$toast('图片不能少于4张')
      // }
      const res = await feedback_add({
        content: this.text,
        pics: this.upLoadImgs,
        type: 0
      })
      if (res.code === 200) {
        setTimeout(()=>{
          this.$router.go("-1");
        },2000);        
        return this.$toast('已提交成功！');
      } else {
        return this.$toast(res.tip)
      }
    },
    },
    mounted() {
    
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .release {
  min-height: 100vh;
  .release-main {
    padding: 0.3rem;
    .van-cell {
        border-radius: 0.16rem;
      width: 6.78rem;
height: 3.2rem;
      margin-top: 0.2rem;
border: 1px solid #535353;
background: #292929;
     :deep(){
        .van-field__control{
     color: #fff;

        }
     }
    }
    .van-cell::after {
      display: none;
    }
    .tip{
      padding-top: 0.1rem;
      color: #ff7777;
    }
  }
  .img-show{
    display: flex;
    flex-wrap: wrap;    
    margin-top: 0.2rem;
    :deep(){
      li{
        &.rowNum3{
          height: 2.12rem;
        }
      }
    }    
  }
  .btn-wrap{
    display: flex;
    padding-top: 0.2rem;
    @include flexbox();
    .btn1{
        border-radius: 0.06rem;
border: 1px solid #535353;
background: #333;
display: flex;
padding: 0.16rem 0.64rem;
justify-content: center;
align-items: center;
gap: 0.24rem;
      img{
        margin-right: 0.1rem;
        width: 0.33rem;
        height: 0.28rem;
      }
    }
    .btn2{
        width: 3rem;
        height: 0.74rem;
        text-align: center;
        line-height: 0.74rem;
        border-radius: 0.06rem;
background:$btnBg;
color: #333;
    }
  }
}
  </style>
  