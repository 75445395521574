<template>
    <div class="long-four" v-if="list && list.length > 0">
        <div class="long-four-item2" @click="toPlay(list[0])">
            <LongVideoCards :item="list[0]" @click="toPlay(list[0])"/>
        </div>
        <div class="long-four-item" v-for="(item,index) in list" v-show="index!==0" :key="item.id" @click="toPlay(item)">
            <LongVideoCards :item="item" @click="toPlay(item)"/>
        </div>
    <i></i><i></i>
    </div>
  </template>

<script>
export default {
  name: 'ShortVideo',
  props: ['list'],
  components: {
    LongVideoCards: () => import('@/components/VideoCard/LongVideoCards.vue')
  },

  methods: {
    toPlay(item) {
      this.$router.push(`/subpage/play/longVideo/${item.id}`)
    }
  }
}
</script>

  <style lang="scss" scoped>
  .long-four {
    border-radius: 0.05rem;
    @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
    i{
      width: 3.1rem;
    }
    .long-four-item2{
        width: 100%;
        ::v-deep{
            .item-bg{
                max-height: 8rem;
            }
        }
    }
    .long-four-item{
        width: 3.42rem;
        ::v-deep{
            .item-bg {
                height: 1.9rem;
            }
        }
    }
    ::v-deep{
      .short-six-item{
          margin-bottom: 0.2rem;
        }
    }
  }
  </style>
