<template>
  <div class="kf">
    <EBackTop class="header" background="transparent" color="#fff" :title="'在线客服'" />
    <div class="iframe-box" frameborder="0">
      <iframe class="iframe" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import { kf } from 'api/home'

export default {
  components: {},
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.getKfUrl()
  },
  computed: {
    apiUrl({ $store }) {
      return $store.getters['apiUrl']
    }
  },
  methods: {
    toBack() {
      return this.$router.go('-1')
    },
    async getKfUrl() {
      const res = await kf()
      if (res.code === 200) {
        let url = res.data.endpoint.replace('kefu/customer/im?', '?')
        this.url = 'https://kefuh5.6lc53h.com/' + url
      } else {
        return this.$toast('获取在线客服地址失败，请稍后再试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kf {
  min-height: 100vh;

  font-size: 0.28rem;
  padding-top: 1rem;
  .iframe-box {
    height: calc(100vh - 1rem);
    box-sizing: border-box;
    .iframe {
      width: 100%;
      max-width: $pcMaxWidth;
      height: 100%;
    }
  }
}
</style>
