<!-- home -->
<template>
  <div class="index-container">
    <!--切换面板 -->
    <ETab
      class="subPage-tab"
      @change="change"
      :sticky="true"
      :offsetTop="'0.1rem'"
      :tabList="findCategory"
      :activeId="indexActive"
      :title-inactive-color="'#fff'"
      :swipe-threshold="4"
    >
      <template v-slot:default="scope">
        <component :navItem="scope" :findhotList="findhotList" @checkTag="checkTag" :is="compComponent(scope.data)">
        </component>
        <!-- 发布按钮 -->
        <div class="ub_btn" @click="toPublic">
          <ul v-show="showUpBtn" class="btn_detail">
            <li @click.stop="$router.push(`/community/public/?postType=${'video'}&from=2&cid=${activeCId}`)">
              <img src="@/assets/imgs/trade/up_video.png" alt="" />
            </li>
            <li @click.stop="$router.push(`/community/public/?postType=${'img'}&from=2&cid=${activeCId}`)">
              <img src="@/assets/imgs/trade/up_photo.png" alt="" />
            </li>
          </ul>
          <img src="@/assets/imgs/trade/up_btn.png" alt="" />
        </div>
      </template>
    </ETab>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { community_findhot } from 'api/community'
export default {
  name: 'Community',
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      list: [],
      showUpBtn: false, // 发布弹窗控制
      hotLits: [], // 热门推荐列表
      newHomeAD: [], // 活动广告
      findhotList: [], //热搜榜列表
      indexActive: 0
    }
  },
  components: {
    CommunityIndex: () => import('@/components/Community/index.vue')
  },
  computed: {
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['postCategory'].filter(item => item.type === 2)
      // return $store.getters['postSectionFilterCommunity']
    },
    childCategory() {
      const titles = ['求片', '分享', '黄文', '写真']
      return this.findCategory.filter(item => titles.includes(item.name))
    },
    // 获取轮播广告
    commityAD({ $store }) {
      return $store.getters['commityAD']
    },

    isMember({ $store }) {
      return $store.getters['isMember']
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    activeCId() {
      if (!this.findCategory.length) return 0
      return this.indexActive == 0 ? this.findCategory[0].id : this.indexActive
    }
  },
  mounted() {
    // this.getFindhotLit()
  },

  methods: {
    checkTag(item) {
      this.indexActive = item
    },
    change(type) {
      console.log('this.findCategory', this.findCategory)
      console.log('change', type)
      this.indexActive = type
    },

    compComponent(data) {
      switch (data) {
        case 1:
          return 'CommunityIndex'
        default:
          return 'CommunityIndex'
      }
    },
    // 热搜榜获取
    async getFindhotLit() {
      try {
        const res = await community_findhot({
          pageNum: 1,
          pageSize: 9
        })
        if (res.code === 200) {
          this.findhotList = [...res.data.findHotList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    // 发布
    toPublic() {
      this.showUpBtn = !this.showUpBtn
    },
    toPublish(name) {
      const id = this.childCategory.find(item => item.name === name)?.id
      if (id) {
        this.showUpBtn = false
        this.$nextTick(() => {
          this.$router.push({
            path: `/community/public/${id}/${name}`
          })
        })
      } else {
        this.$toast('没有该类型')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.index-container {
  background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
  background-size: cover;
  min-height: 100vh;

  .subPage-tab {
    :deep() {
      .van-tab__text {
        color: rgba($color: #fff, $alpha: 0.7);
      }

      .van-tab--active {
        .van-tab__text {
          padding: 0.04rem 0.12rem;
          border-radius: 0.06rem;
          border: 1px solid #00f6f8;
          background: rgba(0, 246, 248, 0.12);
          font-size: 0.26rem;
          color: #fff;
        }
      }
      .van-tabs__nav {
        // background: #000;
      }

      .van-sticky {
        top: 0.77rem !important;
        // background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
      }
    }
  }
}

// 轮播图
.dating-swiper {
  width: 100%;

  :deep() {
    .van-swipe__track {
      width: 100% !important;

      .van-swipe-item {
        width: 100% !important;
      }
    }
  }
}

.ub_btn {
  position: fixed;
  right: 0.36rem;
  top: 60%;
  z-index: 10;

  img {
    width: 0.9rem;
    height: 0.9rem;
    margin-bottom: 0.36rem;
  }
}

.btn_detail {
  position: absolute;
  top: -2.5rem;
}
</style>
