<template>
  <div class="diWangTj-item" @click="toDetail(item)">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images && item.images.length ? item.images[0] : ''" class="item-bg">
      <!-- 视频分类标签 -->
      <div class="diWangTj-type">
        <div class="diWangTj-type-free" v-if="item.sellerType === 0">官方自营</div>
        <div class="diWangTj-type-vip" v-if="item.sellerType === 1">入驻商家</div>
      </div>
      <!-- 标题 -->
      <div class="diWangTj-title">
        {{ item.title }}
      </div>
    </EDecryptImg>
    <!-- 影片描述 -->
    <div class="item-desc">
      <div class="desc">{{ item.girlHeight }} . {{ item.girlWeight }} . {{ item.girlAge }}岁 . {{ item.cupType }}</div>
      <div class="tagsPack">
        <div class="tags">已接单 {{ item.sells }}</div>
        <div class="tags">
          <img src="@/assets/imgs/mine/chat1.png" alt="" />
          聊
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiWangTj',
  props: ['item', 'toAnhei'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    toDetail(item) {
      if (this.toAnhei) {
        this.$router.push(`/trade/AnHeiYouDetail/?id=${item.id}`)
        this.$store.dispatch('setAHYlist', {
          type: 'add',
          item: item
        })
        return
      }
      this.$router.push(`/trade/DiWangTiaoJiaoDetail/?id=${item.id}`)
      this.$store.dispatch('setDWTJlist', {
        type: 'add',
        item: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 封面公告样式
.item-bg {
  position: relative;
  border-radius: 0.12rem;
  overflow: hidden;
  height: 4.2rem;

  :deep() {
    .warpNoPadding {
      border-radius: 0.12rem;
    }
  }

  .diWangTj-type {
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: #fff;
    font-size: 0.24rem;

    &-vip {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: #ffcf86;
      color: #333;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
    }

    &-free {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: #00f6f8;
      color: #333;
    }
  }
}

// 记录样式
.diWangTj-item {
  width: 3.2rem;
  margin-bottom: 0.36rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;

  // 底部时间
  .diWangTj-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.64rem;
    padding: 0.155rem 1.04rem 0.145rem 0.24rem;
    line-height: 0.5rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.94%);
  }

  .item-desc {
    font-size: 0.24rem;

    .desc {
      margin: 0.16rem 0;
    }

    .tagsPack {
      height: 0.32rem;
      @include flexbox();

      .tags {
        &:first-child {
          // color: #00f6f8;
          color: #ffcf86;
        }

        &:last-child {
          @include flexbox($jc: center);
          border-radius: 0.35rem;
          background: $btnBg;
          color: #333;
          padding: 0.02rem 0.24rem;
          img {
            width: 0.22rem;
            height: 0.22rem;
            margin-right: 0.08rem;
          }
        }
      }
    }
  }
}
</style>
