<template>
    <div class="short-four" v-if="newList && newList.length > 0">
  
      <van-checkbox-group v-model="result" @change="resultChange">
      <div class="short-six-item" v-for="(item, index) in newList" :key="index" @click="toPlay(item, index, list)">
        <shortVideoCard :item="item"/>
        <div class="shadow" v-show="isShowShadow">
          <van-checkbox :name="item.id" class="shadowchild">
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
          </van-checkbox>
        </div>
      </div>
  
    </van-checkbox-group>
      <i></i><i></i>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ShortVideo',
    props: ['list', 'isLike', 'showMask', 'id', 'sort', 'typeTxt','tag','coded','coverType'],
    components: {
      shortVideoCard: () => import('@/components/VideoCard/shortVideoCard.vue')
    },
    data() {
      return {
        spliceList: [],
        newList: [],
        result:[],
        activeIcon: require('@/assets/imgs/mine/sel-active.png'),
        inactiveIcon: require('@/assets/imgs/mine/sel-default.png'),
      }
    },
    computed:{
      isShowShadow(){
        return this.showMask
      }
    },
    
    watch: {
      list: {
        handler(n) {
          this.newList = JSON.parse(JSON.stringify(n))
          this.result=[]
        },
        immediate: true,
        deep: true
      }
  
    },
  
    methods: {
  
      resultChange(item){
        this.$emit('clickItem', this.result)
      },
      toPlay(item, index, list) {
        if(this.isShowShadow) return
        if (this.isLike) {
          return this.$router.replace(`/subpage/play/longVideo/${item.id}`)
        }
        // 如果是小视频 则这个列表的数据和id index 都存入vuex
        if (item.videoType === 2) {
          if (this.typeTxt === '观看记录') {
            this.$store.commit('SET_VIDEOLIST', JSON.stringify(list))
            this.$router.push({ path: `/subpage/play/shortVideo/${item.id}`, query: { detailId: item.id, typeTxt: this.typeTxt }})
          } else {
            this.$router.push({
              path: `/subpage/play/shortVideo/${this.id}`,
              query: { id: this.id, sort: this.sort, detailId: item.id, typeTxt: this.typeTxt, tag: this.tag, coded: this.coded, coverType: this.coverType }
            })
          }
        } else {
          this.$router.push(`/subpage/play/longVideo/${item.id}`)
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .img-icon{
    width: 0.4rem;
    height: 0.4rem;
  }
  :deep(){
    .van-checkbox-group{
      @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
      .van-checkbox{
        flex-direction: row-reverse;
        height: .44rem;
      }
    }
  }
  .short-four {
    padding-top: 0;
    border-radius: 0.05rem;
    margin-bottom: 0.3rem;
  
    .short-six-item{
      position: relative;
      width: 3.42rem;
  
        .shadow{
          position: absolute;
          left: 0;
          top: 0;
          width: 3.42rem;
          height: 4.7rem;
          background: rgba(0, 0, 0, 0.6);
        }
  
      ::v-deep{
        .short-six-item{
          .item-bg{
            height: 4.7rem;
          }
        }
      }
    }
    i {
      width: 3.42rem;
    }
  }
  .short-video-time {
    padding-right: 0.16rem;
    @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
    .right-time {
      @include flexbox($jc: flex-end, $ai: center, $fd: row, $fw: nowrap);
      img {
        margin-right: 0.1rem;
      }
    }
  }
  // 选择视频
  .sel-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-end;
    border-radius: 0.12rem;
    img {
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  </style>
  