<template>
    <div class="allType-wrap">
        <div class="scopePack">
            <div class="topTabPack">
                <div class="topTab">
                    <van-icon name="arrow-left" size="25" @click="$router.push('/home')" />
                    <ul>
                        <li @click="$router.push('/subpage/search')">搜一搜</li>
                        <li class="active">发现</li>
                        <li @click="$router.push('/subpage/tagType')">片库</li>
                    </ul>
                    <i></i>
                </div>
            </div>
            <div class="all_list">
                <!-- 竖--父级 -->
                <ul class="fater_list">
                    <li @click="changeItem(item)" :class="active===item.id?'activeItem':''" v-for="item in category" :key="item.id">{{ item.name }}</li>
                </ul>
                <div class="right_info">
                    <div class="top_banner" v-if="discoverAD && discoverAD.length">
                        <EADSwiper :imgCDN="imgCDN" :list="discoverAD" keyName="cover" @clickImg="clickImg" />
                    </div>
                    <ul class="soon_list">
                        <li v-for="tagItem in  tagList"    @click="
            $router.push({
              path: `/subpage/labelPage`,
              query: {
                name: tagItem.name,
                tagId: tagItem.id,
              },
            })
          " :key="tagItem.id">
                            {{ tagItem.name }}
                        </li>
                        <li class="no_bg"></li>
                      
                    </ul>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import { handleParamsRouteJump, handleURlParams, isIphonex } from '@/utils/utils_tools';
import { index_home, advertiseClick } from 'api/home';
export default {
    name: 'TagType3',
    components: {

        EADSwiper: () => import('@/components/web_base/component_base/EADSwiper/index.vue'),
    },
    data() {
        return {
            active:0,
            tagList:[]
        };
    },
    computed: {
        // 获取顶部分类
        category({ $store }) {
            return $store.state.config.config.mediaCategory;
        },
        // 获取首页轮播广告
        discoverAD({ $store }) {
            return $store.getters['discoverAD'];
        },
        imgCDN({ $store }) {
            return $store.getters['cdn'];
        },
    },
    mounted() {
            if(this.category.length){
                this.active = this.category[0].id
            }
            this.getCartoonPage()
         
        },
    methods: {
        changeItem(item){
            this.active = item.id
                this.getCartoonPage()
        },
        // 活动广告跳转
      async clickImg(item) {
            const code = handleURlParams(item.href);
            handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
               const res = await advertiseClick({
                id: item.id
            })
            if (res !== 200) {
                return this.$toast(res.tip)
            }
        },
        // 获取首页数据
        async getCartoonPage() {

            const res = await index_home({
                id: this.active,
            });
            if (res.code === 200) {
                this.tagList = res.data.tagList
            }

        },

       
    },
};
</script>
  
<style lang="scss" scoped>
.allType-wrap {
  

    .topTab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.36rem;
        height: 1rem;
        width: 7.5rem;
        z-index: 9999;

        ul {
            margin: 0 auto;
            display: flex;
            align-items: center;

            li {
                font-size: 0.32rem;
                margin: 0 0.27rem;
                color: #fff;
            }

            .active {
                font-size: 0.36rem;
            }
        }

        i {
            width: 0.4rem;
        }
    }

}

.all_list {
    @include flexbox($ai: flex-start);
    padding: 0.5rem 0.36rem;
}

.fater_list {
    margin-right: 0.22rem;
    li {
        background: #2C2C2C;
        color: #FFF;
        font-size: 0.26rem;
        font-weight: 400;
        width: 1.68rem;
        height: 0.84rem;
        text-align: center;
        line-height: 0.84rem;
    }
    .activeItem{
    background: rgba(0, 246, 248, 0.30);
}
}

.top_banner {
    width: 4.88rem;
    height: 2.2rem;

}
.soon_list{
    @include flexbox($fw:wrap);
    margin-top: 0.22rem;
    li{
        background: #2C2C2C;
        font-size: 0.26rem;
        font-weight: 400;
        width: 1.5rem;
        height: 0.66rem;
        text-align: center;
        line-height: 0.66rem;   
        margin-bottom: 0.1rem;
            @include textoverflow();
    }
    .no_bg{
        background-color: transparent;
    }

        .activeItem{
    background: rgba(0, 246, 248, 0.30);
}
}
</style>
  