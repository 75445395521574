<template>
  <div class="ad-warp" v-if="swiperReset" :style="{ height: height }" @touchmove.stop>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in newList" :key="item.id">
          <img @click.stop="clickImg(item)" :ref="`img${index}`" :id="`img${index}`" class="bg-img" :src="item.image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleVerAutoImg } from '@/utils/utils_tools'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
import {  advertiseClick } from 'api/home'
export default {
  name: 'SwiperShow',
  components: {
  },
  props: {
    imgList: {
      type: Array,
      default() {
        return []
      }
    },
    height: {
      type: String,
      default() {
        return '3.44rem'
      }
    }
  },
  data() {
    return {
      newList: [],
      swiperReset: false,
      swiper: null
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.newList = this.imgList
    this.swiperReset = true
    this.imgList.forEach(async (element, index) => {
      const res = await handleVerAutoImg(this.imgCDN + element.cover)
      this.newList[index].image = res
      if (res) this.swiperReset = true
      this.$nextTick(() => {
        this.initSwiper()
        this.$forceUpdate()
      })
    })
  },
  methods: {
    // 初始化轮播
    async initSwiper() {
      let that = this
      this.swiper = new Swiper({
        el: '.swiper-container',
        loop: false,
        initialSlide: 0,
        // spaceBetween: 10,
        // slidesPerView: 1.12,
        centeredSlides: true,
        grabCursor: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 1500 //1秒切换一次
        },
        on: {
          slideChangeTransitionStart: function () {
            if (this.realIndex === 0) {
              that.$refs.img0 = that.newList[0].image
            } else if (this.realIndex === that.newList.length - 1) {
              that.$refs[`img${that.newList.length - 1}`] = that.newList[this.realIndex].image
            }
          }
        }
      })
    },

    // 活动跳转
  async  clickImg(item) {
      if (item.href) {
        const code = handleURlParams(item.href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
            const res = await advertiseClick({
          id: item.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      } else {
        return
      }
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper = null
    }
  }
}
</script>
<style lang="scss" scoped>
.ad-warp {
  margin: 0.2rem 0;
  overflow: hidden;
}
:deep() {
  // vip
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        // border-radius: 0.14rem;
      }
    }
  }
}
</style>
