
<template>
    <div class="awjy-index">

        <!-- 切换按钮 -->
        <van-tabs v-if="cardType === 'order'" class="wallet_tabs" v-model="orderTypeActive" @change="change">
            <van-tab title="我的求购"></van-tab>
            <van-tab title="我的出售"></van-tab>
        </van-tabs>
        <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
            :refreshing="refreshing">
            <div class="list-box">
                <AWJiaoYiOneCard class="AWJiaoYiOneCard" :cardType="cardType" v-for="(item, index) in listData" :key="index" :item="item" >
           
                </AWJiaoYiOneCard>
            </div>
        </PullUp>
    </div>
</template>
  
<script>
import { pay_history } from 'api/user'
export default {
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            loading: false,
            refreshing: false,
            orderTypeActive: 0,
            isSeller: false,
            listData: [],
            finished: false // 每次请求到的数据长度
        }
    },
    props: ['cardType'],
    components: {
        PullUp: () => import('@/components/PullUp.vue'),
        AWJiaoYiOneCard: () => import('./components/AWJiaoYiOneCard.vue'),
    },
    computed: {
        imgCDN({ $store }) {
            return $store.getters['cdn']
        }
    },
    mounted() {
        this.refreshData()
    },
    methods: {
        change(id) {
            this.orderTypeActive = id

        },
        // 收藏列表
        async getList() {
            const res = await pay_history({
                isSeller: this.orderTypeActive == 0 ? false : true,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: 5 // 收藏
            })
            if (res.code === 200) {
                if (res.data.postList && res.data.postList.length && res.data.postList.length <= this.pageSize) {
                    this.listData = [...this.listData, ...res.data.postList]
                    this.refreshing = false
                    this.loading = false
                } else {
                    this.refreshing = false
                    this.loading = false
                    this.finished = true
                }
            }

        },
      
        // 上拉加载更多
        moreData(loading) {
            this.loading = loading
            this.pageNum += 1
            this.getList()
        },
        // 下啦刷新
        refreshData(refreshing) {
            this.refreshing = refreshing
            this.loading = true
            this.finished = false
            this.jYlist = []
            this.pageNum = 1
            this.getList()
        },
     
    }
}
</script>
  
<style lang="scss" scoped>
.list-box {
    padding: 0.36rem;

}

// .awjy-index{

// }
.wallet_tabs {
    margin-top: 1rem;

    :deep() {
        .van-tabs__wrap {
            @include flex-center;
        }

        .van-tabs__nav {
            height: 0.63rem;

            background: transparent !important;
            padding-bottom: 0;
            border-radius: 0.6rem;
            border: 0.01rem solid #00F6F8;

            //border: 1px solid #F9C006;
            .van-tab {
                font-size: 0.24rem !important;
                z-index: 2;
                color: #787979;
                ;
            }

            .van-tab--active {
                color: #fff;

            }

            .van-tabs__line {
                width: 1.36rem;
                height: 0.47rem;
                border-radius: 0.24rem;
                background: $btnBg;
                top: 0.06rem;
            }

        }


    }
}

</style>
  