<template>
  <div class="no-data">
       <img v-if="imgType==1" src="@/assets/imgs/search/noData.png" alt="" />
    
       {{text}}
  </div>
</template>

<script>
export default {
  name:"Nodata",
  props:{
    text: {
      type: String,
      default() {
        return '没有更多了～'
      }
    },
    imgType: {
      type:  Number,
      default() {
        return 1   //1发布 2 收藏 3关注  4消息
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
  font-size: 0.28rem;
  color: #939496;
  min-height: 50vh;
  img {
    width: 3.62rem;
    height: 3.42rem;
    margin-bottom: 1.14rem;
  }
}
</style>
