<!-- home -->
<template>
  <div class="DiWangTiaoJiao-container">
    <!-- 公告 -->
    <div class="announcement">
      <div class="title">公告</div>
      <div class="scroll-text">
        <div class="text" ref="text">{{ $store.state.config.config.darkWebRunningLight.content }}</div>
      </div>
    </div>

    <!-- 列表 -->
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <!-- 列表 -->
      <ul class="dw_list">
        <li v-for="item in mainList" :key="item.id">
          <DiWangTiaoJiaoDefaultOneCard :item="item" />
        </li>
      </ul>
    </PullUp>
  </div>
</template>
  
<script>
import scrollMixin from '@/utils/scrollMixin'
import { dating_category } from '@/api/dating'
export default {
  mixins: [scrollMixin],
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      refreshing: false,
      mainList: []
    }
  },
  components: {
    DiWangTiaoJiaoDefaultOneCard: () => import('./components/DiWangTiaoJiaoDefaultOneCard.vue'),
    PullUp: () => import('@/components/PullUp.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  mounted() {
    this.startScrolling()
    this.getDatingList()
  },
  methods: {
    startScrolling() {
      const textWidth = this.$refs.text.offsetWidth
      const containerWidth = this.$el.offsetWidth
      const animationDuration = (textWidth / containerWidth) * 10
      this.$refs.text.style.animationDuration = animationDuration + 's'
    },
    // 列表请求 type 2 上门/嫩模 (帝王调教) 帖子（暗网交易）1楼风 (暗黑游)
    async getDatingList() {
      const res = await dating_category({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: 2
      })
      console.log('res', res)
      if (res.code === 200) {
        if (res.data.modelList && res.data.modelList.length && res.data.modelList.length <= this.pageSize) {
          this.mainList = [...this.mainList, ...res.data.modelList]
          this.loading = false
          this.refreshing = false
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
        }
      }
    },
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = false
      this.pageNum = 1
      this.mainList = []
      this.getDatingList()
    },
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getDatingList()
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.DiWangTiaoJiao-container {
  padding-top: 0.32rem;
}
.announcement {
  font-size: 0.26rem;
  @include flexbox($jc: flex-start);
  margin: 0.8rem 0 0.48rem 0;

  .title {
    padding: 0.02rem 0.12rem;
    border-radius: 0.08rem;
    background: #00f6f8;
    white-space: nowrap;
    color: #333;
  }

  .scroll-text {
    overflow: hidden;
    margin-left: 0.12rem;

    .text {
      white-space: nowrap;
      animation: scroll-text 10s linear infinite;
    }
  }

  @keyframes scroll-text {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
}

.dw_list {
  @include flexbox($fw: wrap);
}
</style>
  