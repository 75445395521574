<!--
使用方法
    <EADSwiper  :list="activityAds" :slidesPerView="2" keyName='img' :spaceBetween="20" :EADconfig="EADconfig"   @clickImg="clickImg"> </EADSwiper>

    imgCDN:图片CDN域名
    list:广告数组（需要遍历数据格式，图片需为img）
    slidesPerView:横排展示数量
    spaceBetween:横排展示间隔
    keyName:item里的图片索引
    EADconfig: { 轮播其他设置
        speed: 300,切换动画速度
        autoplay: false,自动
        loop: true,无限循环
        delay: 5000,切换速度
      },
    @clickImg:点击相对应图片钩子
    @changeSlide:滑动后获取相对应图片的索引的钩子

-->
<template>
    <div class="ad-list">
        <div ref="swiper-container" class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in list" :key="index" :data-index="index">
                    <EDecryptImgNolazy ref="decryptImg" :imgCDN="imgCDN" :imgURL="item[keyName] || item" objectFit="cover"
                        @loadedImg="renderSwiper"> </EDecryptImgNolazy>
                    <slot :item="item"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../Depend_modules/swiper/swiper-bundle.min.css';
import Swiper from '../Depend_modules/swiper/swiper-bundle.min.js';
export default {
    name: 'EADSwiper',
    components: {
        EDecryptImgNolazy: () => import('../EDecryptImgNolazy'),
    },
    props: {
        list: {
            type: Array,
            default () {
                return [];
            },
        },
        slidesPerView: {
            type: Number,
            default () {
                return 1;
            },
        },
        spaceBetween: {
            type: Number,
            default () {
                return 0;
            },
        },
        imgCDN: {
            typeof: String,
            default () {
                return '';
            },
        },
        keyName: {
            typeof: String,
            default () {
                return 'img';
            },
        },
        activeIndex: {
            type: Number,
            default () {
                return 0;
            },
        },
        centeredSlides: {
            type: Boolean,
            default () {
                return false;
            },
        },
        EADconfig: {
            typeof: Object,
            default () {
                return {
                    speed: 300,
                    autoplay: true,
                    loop: false,
                    delay: 5000,
                    allowTouchMove: true,
                };
            },
        },
        EADspeed: {
            typeof: Number,
            default () {
                return undefined
            }
        },
        EADautoplay: {
            typeof: Boolean,
            default () {
                return undefined
            }
        },
        EADloop: {
            typeof: Boolean,
            default () {
                return undefined
            }
        },
        EADdelay: {
            typeof: Number,
            default () {
                return undefined
            }
        }
    },

    data () {
        return {
            timeOut: null,
            swp: null,
            count: 0,
            loop: false,
            speed: 300,
            autoplay: true,
            delay: 5000,
            allowTouchMove: true,
        };
    },
    async mounted () {
        this.allowTouchMove = this.EADconfig.allowTouchMove ?? true
        if (this.EADloop === undefined) {
            if (this.EADconfig.loop) {
                this.loop = true
            }
        } else {
            if (this.EADloop) {
                this.loop = true
            }
        }
        if (this.EADspeed) {
            this.speed = this.EADspeed
        } else {
            this.speed = this.EADconfig.speed
        }

        if (this.EADautoplay === undefined) {
            if (this.EADconfig.autoplay) {
                this.autoplay = {
                    delay: this.EADdelay ? this.EADdelay : this.EADconfig.delay
                }
            } else {
                this.autoplay = false
            }
        } else if (this.EADautoplay === true) {
            this.autoplay = {
                delay: this.EADdelay ? this.EADdelay : this.EADconfig.delay
            }
        } else {
            this.autoplay = false
        }

        //轮播配置
        let that = this;
        this.$nextTick(() => {
            this.swp = new Swiper(this.$refs['swiper-container'], {
                init: !this.loop,
                speed: this.speed,
                autoplay: this.autoplay,  //可选选项，自动滑动
                direction: 'horizontal',
                loop: this.loop,
                on: {
                    click: e => {
                        let num;
                        if (that.slidesPerView > 1 && !this.loop) {
                            if (e.clickedIndex >= this.list.length) {
                                num = e.clickedIndex % this.list.length;
                            } else {
                                num = e.clickedIndex;
                            }
                        } else {
                            num = e.realIndex;
                        }
                        that.clickImg(this.list[num]);
                        this.$emit('clickImgBetter', this.list[e.clickedSlide.dataset.index]);
                    },
                    slideChangeTransitionStart: function (swiper) {
                        that.$emit('changeSlide', this.realIndex)
                    }
                },
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
                centeredSlides: this.centeredSlides,
                touchMoveStopPropagation: true,
                autoHeight: false,
                initialSlide:this.activeIndex,
                allowTouchMove: this.allowTouchMove

            });
        });
    },
    methods: {
        renderSwiper () {
            if (this.loop) {
                this.count++;
                //计数等+
                if (this.count >= this.list.length) {
                    if (this.swp) {
                        this.swp.init();
                    }
                }
            }
        },
        clickImg (item) {
            this.$emit('clickImg', item);
        },

    },
};
</script>
  
<style lang="scss" scoped>
.ad-list {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-container {
        height: 100%;

        .resetImg {
            ::v-deep {
                .img {
                    object-fit: fill !important;
                }
            }
        }
    }
}
</style>
  