<template>
  <div>
    <van-swipe
      class="my-swipe"
      ref="cardSwiper"
      :stop-propagation="true"
      indicator-color="white"
      :autoplay="autoplay"
      v-if="shoudPlay"
    >
      <van-swipe-item class="item" :class="{ radius: !radius }" :style="{ height: height }">
        <EDecryptImg
          :imgCDN="imgCDN"
          class="resetImg"
          :imgURL="videoCover"
          @click.native="clickImg(videoCover)"
          ref="refImg"
        >
          <div class="sm-video" v-if="shoudPlay">
            <van-icon name="play" color="#fff" size="60" />
          </div>
        </EDecryptImg>
      </van-swipe-item>
    </van-swipe>
    <van-swipe
      class="my-swipe"
      ref="cardSwiper"
      :stop-propagation="true"
      indicator-color="white"
      :autoplay="autoplay"
      v-if="!shoudPlay"
    >
      <van-swipe-item
        class="item"
        :class="{ radius: !radius }"
        :style="{ height: height }"
        v-for="item in imgs"
        :key="item.id"
      >
        <EDecryptImg
          :imgCDN="imgCDN"
          class="resetImg"
          :imgURL="item.cover ? item.cover : item"
          @click.native="clickImg(item)"
          ref="refImg"
        >
        </EDecryptImg>
      </van-swipe-item>
    </van-swipe>
    <ComPlayVideo ref="playVideo" />
  </div>
</template>

<script>
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools';
import { advertiseClick } from 'api/home';

export default {
  components: {
    ComPlayVideo: () => import('@/components/Community/video.vue'),
  },
  props: {
    imgs: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: String,
      default() {
        return '3.8rem';
        // return "3.16rem"
        // return "auto"
      },
    },
    autoplay: {
      type: Number,
      default() {
        return 3000;
        // return 100000
      },
    },
    shoudPlay: {
      type: String,
      default() {
        return '';
      },
    },
    videoCover: {
      type: String,
      default() {
        return '';
      },
    },
    radius: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn'];
    },
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
  async  clickImg(item) {
      if (this.shoudPlay) {
        const code = handleURlParams(this.shoudPlay);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
      } else if (item.href) {
        const code = handleURlParams(item.href);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
        const res = await advertiseClick({
          id:item.id
        })
        if(res!==200){
          return this.$toast(res.tip)
        }
      } else {
        this.$emit('clickImg', item);
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.my-swipe {
  .item {
    width: 100%;
    .resetImg {
      height: 100%;
      position: relative;
      .warp {
        width: auto;
      }
    }
  }
  .radius {
    :deep() {
      .warp {
        border-radius: 0;
        img {
          border-radius: 0;
        }
      }
    }
  }
}
.sm-video {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
}
</style>
