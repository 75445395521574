var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-index",class:{ isIphonex: _vm.isIphonex }},[_c('PullUp',{attrs:{"finished":_vm.finished,"loading":_vm.loading,"refreshing":_vm.refreshing},on:{"refreshData":_vm.refreshData,"moreData":_vm.moreData}},[(_vm.navItem.item.adsType === 2 && _vm.iconHomeAds.length)?_c('div',{staticClass:"top_banner"},[_c('AdAvatarList',{attrs:{"list":_vm.iconHomeAds,"wrap":true,"key-name":"avatar"}})],1):(_vm.navItem.item.adsType !== 3 && _vm.avAD.length)?_c('div',{staticClass:"top_banner"},[_c('EADSwiper',{attrs:{"EADconfig":_vm.EADconfig,"imgCDN":_vm.imgCDN,"list":_vm.avAD,"keyName":"cover"},on:{"clickImg":_vm.activityBtn}})],1):_vm._e(),_c('div',{staticClass:"tab-main"},[_c('div',{staticClass:"home-index-main"},_vm._l((_vm.topicList),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"topPack"},[_c('div',{staticClass:"topL"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"topR",on:{"click":function($event){return _vm.$router.push({
                  path: `/subpage/morePage`,
                  query: {
                    id: item.id,
                    name: item.name,
                    type: _vm.type
                  }
                })}}},[_vm._v(" 更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c(_vm.compComponent(item.showType),{tag:"component",attrs:{"list":item.mediaList,"showType":item.showType,"name":item.name,"id":item.id}})],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }