<template>
  <!--
使用方法
  <ERefreshLoad
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
      :skeleton="skeleton"
    >

    <div slot="top">内容</div> //附加扩展 在上拉加载控件外的slot （一般情况不与要）
    <div>内容</div> //（默认）
  </ERefreshLoad>

  loading: false, //上拉加载
  finished: false, /上拉加载完成
  refreshing: false, //下拉刷新
  skeleton: false, //上拉加载功能开关  默认是true

  //上拉刷新
  refreshData() {
    this.finished = false;
    this.loading = true;
    this.refreshing = true;
    this.pageNum = 1;
  },
  //下拉加载
  moreData() {
    this.loading = true
    this.pageNum += 1;
  },
-->
  <div class="pull-container">
    <van-pull-refresh ref="backup" v-model="childRefreshing" @refresh="onRefresh" :disabled="$route.meta.isCloseRefresh || disabled">
      <slot name="top"></slot>
      <van-list v-model="childLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false" v-if="skeleton">
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: 'ERefreshLoad',
  components: {},
  props: {
    loading: {
      Type: Boolean,
      default() {
        return false;
      },
    },
    refreshing: {
      Type: Boolean,
      default() {
        return false;
      },
    },
    finished: {
      Type: Boolean,
      default() {
        return false;
      },
    },
    skeleton: {
      Type: Boolean,
      default() {
        return true;
      },
    },
    disabled: {
      Type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      isShowGoback: false,
      scrollTop: 0,
      childLoading: false,
      childRefreshing: false
    };
  },
  methods: {
    onLoad() {
      this.$emit('moreData', this.childLoading);
    },
    onRefresh() {
      // 重新加载数据
      this.$emit('refreshData', this.childRefreshing);
    },
  },
  watch: {
    loading: {
      handler(n, o) {
        this.childLoading = n;
      },
    },
    refreshing: {
      handler(n, o) {
        this.childRefreshing = n;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.pull-container {
  position: relative;
}
</style>
