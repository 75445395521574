<!--
使用方法
    <EDecryptImg  :imgURL="item.cover" objectFit='inherit'  :defaultUrl="require('~/static/images/footer/home.png')"  >
        <img  slot="defaultImg" class='defaultImg' src="/images/circle/circle-check.png" alt="" />
        <img  slot="mask" class='maskClass' src="/images/circle/circle-check.png" alt="" />
    </EDecryptImg>

    imgCDN:图片CDN域名
    imgURL:图片地址
    objectFit:图片铺展方式
    defaultUrl:默认图片地址

    defaultImg插槽：默认图片
    mask插槽：图片上层遮盖物（比如logo，角标等）

    注：插槽优先级高于 组件内定义属性(圆角属性在最外层包裹，不要传到组件里)

-->
<template>
  <lazy-component class="default" @show="getBlobUri">
    <img class="realImg" v-if="realUrl" :src="realUrl" :style="styleVar" draggable="false" ondragstart="return false;" />
    <slot name="defaultImg" v-else>
      <img class="defaultImg" :src="defaultUrl" :style="styleVar" draggable="false" ondragstart="return false;" />
    </slot>
    <slot></slot>
  </lazy-component>
</template>

<script>
import { handleVerAutoImg } from '../../utils_base/EUtils/utils_tools';
import { IMAGE_DB_CONFIG, START_IMAGE_DB_KEY, TIME_OUT_NUMBER_KEY } from '../../utils_base/EConfig/imgConfig.js';
const { max_time_consuming, time_out_max_number } = IMAGE_DB_CONFIG;
export default {
  name: 'EDecryptImg',
  props: {
    //默认图片路径
    defaultUrl: {
      typeof: String,
      default() {
        return require('~/static/loading.png');
      },
    },
    // 图片路径
    imgURL: {
      typeof: String,
      default() {
        return '';
      },
    },
    imgCDN: {
      typeof: String,
      default() {
        return '';
      },
    },
    objectFit: {
      typeof: String,
      default() {
        return 'cover';
      },
    },
  },
  watch: {
    imgURL(newVal) {
      this.getBlobUri();
    },
  },
  computed: {
    url() {
      if (this.imgURL) {
        return this.imgCDN + this.imgURL;
      } else {
        return '';
      }
    },
    styleVar() {
      return {
        'object-fit': this.objectFit,
      };
    },
  },
  mounted() {
    // this.getBlobUri();
  },
  data() {
    return {
      realUrl: undefined,
      timeConsuming: 0, //执行耗时
    };
  },
  methods: {
    // 解密缓存并获取base64图片地址
    async getBlobUri() {
      if (!this.url) {
        this.realUrl = '';
        return;
      }
      const startTime = new Date().getTime();
      const baseUri = await handleVerAutoImg(this.url);
      if (baseUri && baseUri.length) {
        this.realUrl = baseUri;
      }
      // 计算执行耗时事件
      this.timeConsuming = new Date().getTime() - startTime;
      // 每一次超时当前+1
      const timeOutN = IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY];
      const startDb = IMAGE_DB_CONFIG[START_IMAGE_DB_KEY];

      if (this.timeConsuming >= max_time_consuming) {
        IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY] += 1;
      }
      // 超过最大次数 关闭 IndexDB
      if (timeOutN > time_out_max_number && startDb) IMAGE_DB_CONFIG[START_IMAGE_DB_KEY] = false;
    },
  },
};
</script>

<style scoped lang="scss">
.default {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  .realImg {
    width: 100%;
    height: 100%;
  }
  .defaultImg {
    width: 100%;
    height: 100%;
    background-size: 100%;
    margin: 0 auto;
  }
}
</style>
