<template>
  <van-popup
    v-model="childShowBuy"
    round
    :position="position"
    :style="{ width: width }"
    get-container="#app"
    @closed="closed"
  >
    <div class="title">{{ title }}</div>
    <div class="price">
      {{ price }}
      <img src="@/assets/imgs/index/gold.png" alt="" />
    </div>
    <template v-if="price > myBalance">
      <div class="btn" @click="shoudBuy">前往充值</div>
    </template>
    <template v-else>
      <div class="btn-group">
        <div class="btn" @click="shoudBuy">确认购买</div>
        <div class="btn cancel" @click="childShowBuy = false">忍着不看</div>
      </div>
    </template>
  </van-popup>
</template>

<script>
export default {
  name: 'ShowBuy',
  props: {
    position: {
      type: String,
      default() {
        return 'bottom'
      }
    },
    title: {
      type: String,
      default() {
        return '购买此楼凤信息'
      }
    },
    position: {
      type: String,
      default() {
        return 'bottom'
      }
    },
    price: {
      type: Number | String,
      default() {
        return 0
      }
    },
    showBuy: {
      type: Boolean,
      default() {
        return false
      }
    },

    width: {
      type: String,
      default() {
        return '100%'
      }
    }
  },
  data() {
    return {
      childShowBuy: false
    }
  },
  mounted() {},
  computed: {
    // 获取个人余额
    myBalance({ $store }) {
      return $store.getters['getUserInfo'].balance / 100
    }
  },
  watch: {
    showBuy: {
      handler(n, y) {
        this.childShowBuy = n
      }
    }
  },
  methods: {
    // 购买按钮
    shoudBuy() {
      if (this.price > this.myBalance) {
        this.$router.push('/mine/my-wallet')
      } else {
        this.$emit('shoudBuy')
      }
    },
    // 关闭弹窗按钮
    closed() {
      this.$emit('closed', false)
    }
  }
}
</script>

<style lang='scss' scoped>
.van-popup {
  // background: linear-gradient(to bottom, #e7e3fe, #d6e7fc, #ffffff);
  font-size: 0.32rem;
  text-align: center;
  padding: 0.3rem 0;
}

.price {
  font-size: 0.52rem;
  margin-top: 0.43rem;

  img {
    width: 0.35rem;
    height: 0.35rem;
  }
}

.btn {
  width: 2rem;
  height: 0.6rem;
  background: $btnBg;
  box-shadow: $shadow;
  margin: 0.3rem auto 0 auto;
  line-height: 0.6rem;
  color: #fff;
  border-radius: 0.3rem;
  &.cancel {
    color: #2e2e2e;
    background: #fff;
    border: 1px solid #000;
  }
}

.btn-group {
  display: flex;
}
</style>
