<template>
  <div class="default-avatar">
    <EBackTop class="header" background="tranparent" @click-right="checkRight" color="#fff" :title="'修改头像'">
      <slot slot="right" name="right" > 完成 </slot>
    </EBackTop>

    <div class="top">
      <EDecryptImg :imgCDN="imgCDN" class="item" :imgURL="avatarUrl" />
    </div>
    <div class="avatar-list">
      <EDecryptImg :imgCDN="imgCDN" @clickImg="changeImg" v-for="item in list" :key="item" :imgURL="item" class="item" />
    </div>
  </div>
</template>

<script>
import { user_avatar, update_info } from 'api/user'
export default {
  name: 'Avatar',
  components: {
      EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    EDecryptImg: () => import('@/components/DecryptImg/index.vue'),

    },

  data() {
    return {
      list: [],
      avatarUrl: '',
      curUrl: ''
    }
  },
  created() {
    this.avatarUrl = this.$route.query.userAvatarUrl
    this.getAvatar()
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
      isMember({ $store }) {
      return $store.getters['isMember']
    }
  },
  methods: {
    // 获取头像列表
    async getAvatar() {
      
      try {
        const res = await user_avatar()
        if (res.code === 200) {
          this.list = res.data
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 完成按钮
    async checkRight() {
      if (this.isMember) {
        const res = await update_info({
          avatar: this.curUrl
        })
        if (res.code === 200) {
          this.$toast('修改成功')

        } else {
          this.$toast(res.tip)
        }
      } else {
        this.$toast('亲，您还不是会员，不能修改噢')
      }
    },
    // 选择头像
    changeImg(item) {
      // 现在的头像路径 未解密
      this.curUrl = item
      // 已经解密的
      this.avatarUrl = item
    }
  }
}
</script>

<style lang="scss" scoped>
    :deep() {
.default{
  background-color: #fff;
   .realImg{
    border-radius: 50%;
   }
}
    }
.default-avatar {
  min-height: 100vh;
}
.top {
  padding: 0.3rem 0;
  .item {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 auto;
    background-color: transparent;
    :deep() {
 img {
        font-size: 0;
        border-radius: 50%;
      }
    }
  }
}
.avatar-list {
  padding: 0.54rem;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  
  
 .item {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 auto 0.2rem auto;
    border-radius: 50%;
    font-size: 0;
    :deep() {
 img {
        font-size: 0;
        border-radius: 50%;
      }
    }
  }

  .item:last-child {
    margin-left: 0.35rem;
  }
}
.avatar-list::after {
  content: '';
  flex: auto;
  margin-left: 0.1rem;
}
</style>
