<template>
  <div class="sign-rule">
    <EBackTop title="兑换记录" :placeholder="false" color="#fff" :background="'transparent'"></EBackTop>
    <div class="rule-wrap">
      <ul v-if="checkinDatas && checkinDatas.length>0">
        <li v-for="(item, index) in checkinDatas" :key="index">
          <div class="left-wrap">
            <div class="t1">{{ item.prizeName }}</div>
            <div>
              <span class="day">{{ item.createdAt | timeYmdhm }}</span>
            </div>
          </div>
          <div class="right-wrap">
            <span>-{{ item.usePoint }}</span>
          </div>
        </li>
      </ul>
      <Nodata v-else />
    </div>
  </div>
</template>

<script>
import { prize_redeemhistory } from '@/api/user'
export default {
  components: {
      Nodata: () => import('@/components/JavNodata.vue')
  },
  data() {
    return {
      checkinDatas: [],
    }
  },
  methods: {
    async checkinSetup() {
      const res = await prize_redeemhistory({})
      if (res.code === 200) {
        this.checkinDatas = res.data.list
      } else {
        this.$toast(res.tip)
      }
    },
  },
  mounted() {
    this.checkinSetup()
  },
}
</script>

<style lang="scss" scoped>
.sign-rule {
  padding-top: 1rem;
  //margin-top: 0.2rem;
  font-size: 0.24rem;

  .rule-wrap {
    padding: 0.61rem 0.64rem 0;
    border-top: .01rem solid rgba(255, 255, 255, 0.30);
    ul {
      li {
        padding: 0.3rem 0;
        padding-right: 0.05rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.28rem;
        //border-bottom: 1px solid#eee;
        &:last-child {
          border-bottom: 0;
          box-shadow: none;
        }
        .left-wrap {
          .t1 {
          }
          .t2 {
            font-size: 0.22rem;
            opacity: 0.6;
          }
        }
        .right-wrap {
          @include flex-center;
          width: 0.95rem;
          height: 0.55rem;
          border-radius: 0.12rem;

       border-radius: 0.12rem;
border: 1px solid  #00F6F8;
color: #fff;

        }
      }
    }
  }
}
</style>
