<template>
  <div>
    <template v-if="isAppleMobilePlatform">
      <template-apple
          :name="name"
          :description="description"
          :install-description="installDescription"
          :disable-description="disableDescription"
          :icon="icon"
          :install-available="isInstallAvailable && !isDialogHidden"
          :hideDialog="hideDialogObjUser"
          :how-to-for-apple="howToForApple"
          :how-to-requested="howToRequested"
          :toggle-gallery="toggleGallery"
          :gallery-requested="galleryRequested"
      />
    </template>
  </div>
</template>

<script>
// import '@khmyznikov/pwa-install';
import {
  eventInstallAvailable, eventInstalledFail,
  eventInstalledSuccess,
  eventUserChoiceResult, getInstalledRelatedApps,
  isAppleMobile,
  isRelatedAppsInstalled,
  isStandalone
} from './utils'
import TemplateApple from ".//templates/apple/template-apple.vue";

export default {
  name: 'EAddPwa',
  components: {TemplateApple},
  props: {
    installDescription: '',
    disableDescription: {
      type: Boolean,
      default: false
    },
    manualApple: {
      type: Boolean,
      default: false
    },
    manualChrome: {
      type: Boolean,
      default: false
    },
    disableChrome: {
      type: Boolean,
      default: false
    },
    disablePreCache: {
      type: Boolean,
      default: true
    }, //workbox设置了关闭preCache 开启此属性
    forceUnregister: {
      type: Boolean,
      default: false
    }  //删除sw 以及缓存

  },
  data() {
    return {
      manifestUrl: '',
      manifest: {},
      icon: '',
      name: '',
      description: '',
      platforms: [],
      userChoiceResult: '',
      isDialogHidden: JSON.parse(window.sessionStorage.getItem('pwa-hide-install') || 'false'),
      isInstallAvailable: false,
      isAppleMobilePlatform: isAppleMobile(),
      isUnderStandaloneMode: isStandalone(),
      isRelatedAppsInstalled: isRelatedAppsInstalled(),
      howToRequested: false,
      galleryRequested: false,
    }
  },
  created() {
    if (this.forceUnregister){
      this.unregister()
    }else {
      this.register()

    }
    this.init()
  },
  async mounted() {
    // 测试弹框代码
    // setTimeout(() => {
    //   this.showDialog(true)
    //   // this.forceApple(true)
    // }, 2000)
  },
  methods: {
    register(){
      if ('serviceWorker' in navigator) {
        const SW = navigator.serviceWorker
        SW.register(
          '/sw.js'
        ).then((registration) => {
            ////https://blog.csdn.net/zmx_FZ/article/details/106206890
            ////解决每次发布上线，用户首次拿到的都是旧的，需要再次刷新 在precache开始下需要使用
          if (!this.disablePreCache){
            // 捕获到registration.installing触发 似乎ios下面该事件触发时机费解 待改进
            registration.onupdatefound = function(){
              console.log('ServiceWorker：', 'onupdatefound')
              const installingWorker = registration.installing;
              installingWorker.onstatechange = function(){
                console.log('ServiceWorker：', 'onstatechange')
                // 发布时每次更新了新的内容，用户首次看到的是旧的内容，要二次刷新的问题
                if(installingWorker.state === 'activated'){
                  if(SW.controller){
                    // 这个是后台你配置的开关，是否暴力刷新页面。以防万一发布上线了错误的代码，第一时间关闭按钮
                    const forceReload = true;
                    this.notifyUpdate(forceReload)
                  }
                }
              }
            }
            // 在开启preCache情况下 存储本地；
            window.localStorage.setItem('hasPreCache', JSON.stringify(true))
          }else {
            //在关闭preCache情况下，检查是否已经缓存precache数据并且清除缓存
            const hasPreCache =  JSON.parse(window.localStorage.getItem('hasPreCache') || 'false')
            if (hasPreCache){
              this.removeCache();
              this.notifyUpdate(true);
            }
            window.localStorage.setItem('hasPreCache', JSON.stringify(false))
          }

            console.log('ServiceWorker注册成功！注册作用域是：', registration.scope);
          },
          (err) => {
            console.log('ServiceWorker registration failed: ', err)
          }
        )
      }
    },
    unregister(){
      if(window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function(registrations) {
            for(let registration of registrations) {
              registration.unregister();
            }
          });
      }
      console.log('ServiceWorker注销成功！');
      this.removeCache()
    },
    notifyUpdate(force = true){
      if(force){
        console.log('暴力刷新页面');
        window.location.reload();
      }else{
        console.log('有新的内容更新，请刷新页面');
        window.location.reload();
      }
    },
    removeCache(){
      if ('caches' in window) {
        caches.keys()
          .then(function(keyList) {
            return Promise.all(keyList.map(function(key) {
              return caches.delete(key);
            }));
          })
        console.log('ServiceWorker缓存清除成功！');
      }
    },
    forceApple(apple = true) {
      this.isAppleMobilePlatform = apple;
      this.hideDialog();
      this.showDialog();
    },
    howToForApple() {
      this.howToRequested = !this.howToRequested;
      if (this.howToRequested && this.galleryRequested)
        this.galleryRequested = false;
    },
    toggleGallery() {
      this.galleryRequested = !this.galleryRequested;
      if (this.howToRequested && this.galleryRequested)
        this.howToRequested = false;
    },
    install() {
      if (this.isAppleMobilePlatform) {
        this.howToRequested = true;
      } else
      if (window.deferredEvent) {
        this.hideDialog();
        window.deferredEvent.prompt();
        window.deferredEvent.userChoice
            .then((choiceResult) => {
              this.userChoiceResult = choiceResult.outcome;
              eventUserChoiceResult(this, this.userChoiceResult);
            })
            .catch((error) => {
              eventInstalledFail(this);
            });
        window.deferredEvent = null;
      }
    },
    hideDialogObjUser() {
      eventUserChoiceResult(this, 'dismissed');
      this.hideDialog();
    },
    hideDialog() {
      this.isDialogHidden = true;
      window.sessionStorage.setItem('pwa-hide-install', 'true');
    },
    showDialog(forced = false) {
      this.isDialogHidden = false;
      if (forced)
        this.isInstallAvailable = true;
      window.sessionStorage.setItem('pwa-hide-install', 'false');
    },

    async getInstalledRelatedApps() {
      return await getInstalledRelatedApps();
    },
    async checkInstalled() {
      this.isUnderStandaloneMode = isStandalone();
      this.isRelatedAppsInstalled = await isRelatedAppsInstalled();
      this.isAppleMobilePlatform = isAppleMobile();
      if (this.isAppleMobilePlatform) {
        if (!this.isUnderStandaloneMode) {
          this.manualApple && this.hideDialog();
          setTimeout(
              () => {
                this.isInstallAvailable = true;
                eventInstallAvailable(this);
              },
              300
          );
        }
      } else {
        this.manualChrome && this.hideDialog();
      }
    },
    init() {
      this.manifestUrl = document.querySelector('link[rel="manifest"]').href
      window.deferredEvent = null;

      this.checkInstalled();

      if (!this.disableChrome)
        window.addEventListener('beforeinstallprompt', (e) => {
          window.deferredEvent = e;
          e.preventDefault();

          this.platforms = e.platforms;

          if (this.isRelatedAppsInstalled || this.isUnderStandaloneMode) {
            this.isInstallAvailable = false;
          } else {
            this.isInstallAvailable = true;
            eventInstallAvailable(this);
          }

          if (this.userChoiceResult === 'accepted') {
            this.isDialogHidden = true;
            eventInstalledSuccess(this);
          }

        });

      window.addEventListener('appinstalled', (e) => {
        window.deferredEvent = null;
        this.isInstallAvailable = false;
        eventInstalledSuccess(this);
      });


      fetch(this.manifestUrl).then((response) => {
        if (response.ok)
          response.json().then((_json) => {
            this.icon = this.icon || _json.icons[0].src;
            this.name = this.name || _json['short_name'] || _json.name;
            this.description = this.description || _json.description;
            this.manifest = _json;
          });
        else {
          this.icon = this.icon || this.manifest.icons?.[0].src || '';
          this.name = this.name || this.manifest['short_name'] || '';
          this.description = this.description || this.manifest.description || '';
        }
      });
    },
  }
}
</script>

<style lang="scss">
//chrome
#pwa-install-element {
  user-select: none;

  .install-dialog {
    --text-color-normal: #212121;
    --background-color: #fff;
    --border-bottom-color: #DBDCDE;
    --scrollbar-background-color: #fff;

    font-family: "Roboto", "Segoe UI", system-ui;

    position: fixed;
    display: inline-block;

    opacity: 0;
    visibility: hidden;

    z-index: 2147483001;
  }

  .install-dialog.chrome {
    max-width: 380px;
    width: 90%;
    min-height: 90px;
    margin: 0 auto;

    right: 150px;
    left: auto;

    background-color: var(--background-color);
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, .3));

    font-size: 15px;
    color: var(--text-color-normal);
    border-radius: 8px;

    top: -100px;

    transition: top .5s ease-in-out, opacity .8s ease-in-out, max-height 0.35s ease-in-out;


    &.available {
      opacity: 1;
      top: 28px;
      visibility: visible;
    }

    .dialog-body {
      display: grid;

      grid-template-columns: 70px 1fr;

      .icon {
        display: flex;
        align-items: flex-start;

        .icon-image {
          width: 48px;
          height: 48px;

          margin: 0 auto;
          margin-top: 10px;

          border-radius: 6px;
        }
      }

      .about {
        display: grid;
        grid-template-rows: 30px auto;

        .name, .hostname {
          padding: 0px 5px;
          // padding-left: 5px;
          overflow: hidden;
        }

        .name {
          font-weight: 500;
          // padding-top: 10px;
          font-size: 18px;

          line-height: 24px;
          // padding: 5px 0px;
          margin-top: 5px;


          align-items: flex-start;
          display: flex;

          label {
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .hostname {
          font-size: 13px;
          font-weight: 400;
          opacity: 0.7;

          line-height: 14px;

          overflow-x: hidden;
          text-overflow: ellipsis;
          text-align: left;
          word-break: break-all;
          white-space: nowrap;
        }
      }

      .description {
        font-weight: 400;
        line-height: 20px;

        grid-column: 1 / 3;
        padding: 0px 6px;
        margin: 10px 5px 5px;

        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        max-height: 115px;
        overflow: hidden;
        overflow-y: auto;
        text-overflow: ellipsis;
        text-align: left;
      }

      .app-description {
        font-size: 13px;
        margin-bottom: 10px;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgb(146, 146, 146);
          border-radius: 4px;

          &:hover {
            background: rgb(100, 100, 100);
          }
        }

        &::-webkit-scrollbar-track {
          background: var(--scrollbar-background-color);
          border-radius: 4px;
        }
      }

      .install-description {
        font-size: 14px;
      }

      hr {
        height: 1px;
        width: 100%;
        grid-column: 1 / 3;

        background-color: var(--border-bottom-color);
        border: none;

        margin: 0;
        // & + .description{

        // }
      }

      .action-buttons {
        display: grid;
        grid-template-columns: 50% auto;

        grid-column-start: 1;
        grid-column-end: 3;

        gap: 10px;
        padding: 10px 10px;

        .primary {
          grid-column-start: 2;
        }
      }

      pwa-gallery {
        grid-column: 1 / 3;

        max-height: 0;
        opacity: 0;

        transition: opacity ease-in-out .5s, max-height .5s ease-in-out;
        overflow: hidden;
      }
    }

    &.gallery {
      pwa-gallery {
        transition: opacity .3s ease-in-out .2s, max-height .5s ease-in-out;

        max-height: 70vh;
        opacity: 1;
      }
    }
  }

  .install-dialog.mobile {
    --touch-header-color: #DBDCE0;
    top: 0px;

    max-width: 414px;
    width: 100%;

    bottom: auto;
    left: 0px;
    right: 0px;

    // transition: bottom .5s ease-in-out 1s, opacity 1.3s ease-in-out .8s, max-height 0.35s ease-in-out 0s;

    transition: none;
    transform: translateY(100vh);

    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    padding-bottom: 35px;

    .dialog-body {
      grid-template-columns: 1fr;

      .touch-header {
        height: 30px;
        width: 100%;
        padding-top: 10px;
        box-sizing: border-box;

        cursor: grab;

        &:active {
          cursor: grabbing;
        }

        &::before {
          content: '';
          width: 40px;
          height: 5px;
          border-radius: 3px;
          background: var(--touch-header-color);
          margin: 0 auto;
          display: block;
        }
      }

      .body-header {
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        grid-gap: 15px;

        padding: 18px 20px;
        padding-top: 0;

        border-bottom: 1px solid var(--border-bottom-color);

        .install {
          text-transform: capitalize;
          height: 40px;
          padding: 0 23px;
        }

        .about {
          grid-template-rows: auto auto;
          grid-gap: 6px;

          .name {
            padding: 0;
            line-height: 20px;
            font-size: 16px;
          }

          .hostname {
            font-size: 14px;
            font-weight: 400;
            opacity: 0.7;

            line-height: 14px;

            overflow-x: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;

            padding: 0;
          }
        }

        .icon .icon-image {
          margin: 0;
          width: 45px;
          height: 45px;
        }
      }

      .description {
        // font-size: 14px;
        // font-weight: normal;
        padding: 15px 20px 0px;
        margin: 0;
        margin-bottom: 15px;

        // display: -webkit-box;
        -webkit-line-clamp: 6;
        // -webkit-box-orient: vertical;

        max-height: 150px;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      pwa-gallery {
        max-height: 100%;
        padding-bottom: 15px;
        opacity: 1;
      }
    }

    .material-button {
      border-radius: 20px;
    }

    &.available {
      --translateY: translateY(calc(100vh - 85px));

      opacity: 1;
      top: 0px;

      visibility: visible;

      transform: var(--translateY);
    }
  }

  .material-button {
    --text-color-primary: #fff;
    --text-color-secondary: #374fc6;
    --background-color-primary: #374fc6;
    --background-color-ripple: #fff;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    padding: 0 16px;
    min-width: 64px;
    height: 36px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;

    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
    font-size: 14px;
    font-weight: 500;
    line-height: 37px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &.primary {
      color: var(--text-color-primary);
      background-color: var(--background-color-primary);
    }

    &.secondary {
      background-color: transparent;
      color: var(--text-color-secondary);
      // border: 1px solid #4285F4;

      box-shadow: none;

      &::before, &::after {
        background-color: var(--background-color-primary);
      }

      &:hover, &:active {
        box-shadow: none;
      }

      &.close {
        height: 26px;
        min-width: 26px;
        line-height: 0;

        margin-right: 0;
        margin-left: auto;
        padding: 0;

        border-radius: 50%;

        svg {
          width: 16px;
          fill: var(--text-color-secondary);
        }

        &::after {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        &::before {
          border-radius: 50%;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--background-color-ripple);
      opacity: 0;
      transition: opacity 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      padding: 50%;
      width: 32px;
      height: 32px;
      background-color: var(--background-color-ripple);
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
      transition: opacity 1s, transform 0.5s;
    }

    &:hover {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

      &::before {
        opacity: 0.08;
      }

      &:focus {
        &::before {
          opacity: 0.3;
        }
      }
    }

    &:active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

      &::after {
        opacity: 0.32;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0s;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  #pwa-install-element .install-dialog {
    --text-color-normal: #fff;
    --background-color: #1e1e1e;
    --border-bottom-color: #404140;
    --scrollbar-background-color: #424242;

    &.mobile {
      --touch-header-color: #3f403f;
    }

    .material-button {
      --text-color-primary: #1B222C;
      --text-color-secondary: #a7c7fa;
      --background-color-primary: #a7c7fa;
    }
  }
}

@media (max-width: (768px - 1px)) and (min-height: 495px) {
  #pwa-install-element {
    .install-dialog.chrome.mobile {
      display: inline-block;
    }

    .install-dialog.chrome {
      display: none;
    }
  }
}

@media (min-width: 768px), (max-height: 495px) {
  #pwa-install-element {
    .install-dialog.chrome.mobile {
      display: none;
    }

    .install-dialog.chrome {
      display: inline-block;
    }
  }
}

@media (max-height: 420px) {
  #pwa-install-element {
    .install-dialog.chrome .dialog-body .description {
      -webkit-line-clamp: 3;
      max-height: 70px;
    }
  }
}

@media(hover: none) and (pointer: coarse) {
  #pwa-install-element {
    .install-dialog.chrome .dialog-body .description::-webkit-scrollbar {
      display: none;
    }
  }
}

//apple
#pwa-install-element {
  .install-dialog.apple {
    --text-color-normal: #333;
    --text-color-description: #333;
    --background-color: rgba(255, 255, 255, 0.75);
    --text-color-button: #fff;
    --background-color-button: #fff;
    --background-color-button-active: #DBDAE0;
    --border-bottom-color: rgba(0, 0, 0, 0.1);

    font-family: system-ui, -apple-system, 'HelveticaNeue', BlinkMacSystemFont, "Roboto", "Segoe UI";

    // display: inline-grid;
    // grid-template-rows: 1fr auto;
    // grid-gap: 15px;

    max-width: 380px;
    width: auto;

    margin: 0 auto;
    // padding: 15px;

    right: 0;
    left: 0;

    font-size: 15px;
    color: var(--text-color-normal);

    bottom: 0;

    transition: transform .4s cubic-bezier(.33, 1, .66, 1), opacity .5s cubic-bezier(1, 0, 1, -2), border .35s ease-in-out;

    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    visibility: visible;
    transform: translateY(100%);
    will-change: opacity, transform;

    &.dialog-body {
      display: grid;

      grid-template-columns: 95px auto 1fr;
      grid-template-rows: 1fr auto;

      grid-template-areas:
              "icon description"
              "welcome welcome"
              "how-to how-to"
              "gallery gallery"
              "button button";

      border-radius: 10px;

      border-bottom: 1px solid transparent;
      // transition: border .35s ease-in-out;

      background-color: var(--background-color);
      filter: drop-shadow(0 5px 15px rgba(0, 0, 0, .2));
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);

      // header{
      //     grid-area: header;

      //     display: flex;
      //     flex-flow: row nowrap;
      //     justify-content: space-between;

      //     h2{
      //         font-size: 18px;
      //         font-weight: 500;
      //         line-height: 1.125;
      //         margin: 0;
      //     }
      // }

      .icon {
        display: flex;
        align-items: center;

        grid-area: icon;

        border-bottom: 1px solid var(--border-bottom-color);

        .icon-image {
          width: 64px;
          height: 64px;

          margin: 0 auto;
          border-radius: 6px;
        }
      }

      .about {
        display: grid;
        grid-template-rows: 35px 60px;

        border-bottom: 1px solid var(--border-bottom-color);

        grid-area: description;

        .name, .description {
          padding-right: 15px;
          overflow: hidden;
        }

        .name {
          font-weight: 600;
          padding-top: 10px;
          padding-right: 10px;
          line-height: 22px;

          align-items: flex-start;
          display: flex;
        }

        .description {
          font-size: 12px;
          line-height: 15px;

          margin-bottom: 15px;
          overflow-y: auto;

          color: var(--text-color-description);
        }

        .close {
          height: 26px;
          min-width: 26px;
          line-height: 0;

          margin-right: -3px;
          margin-top: -3px;
          margin-left: auto;

          padding: 0px;
          border-radius: 50%;

          opacity: .5;
          outline: none;

          color: var(--text-color-normal);

          border: none;
          background-color: transparent;

          cursor: pointer;

          transition: opacity .2s ease-in-out, background-color .2s ease-in-out;

          svg {
            fill: var(--text-color-normal);
            width: 18px;
          }

          &:hover, &:active {
            opacity: 1;
            background-color: var(--background-color-button);
          }
        }
      }

      .welcome-to-install {
        font-size: 15px;
        // grid-area: title;
        grid-area: welcome;

        text-align: left;
        font-weight: 400;
        padding: 15px;
        width: auto;

        border-bottom: 1px solid var(--border-bottom-color);
      }
    }

    .action-buttons {
      grid-area: button;

      grid-column-start: 1;
      grid-column-end: 3;

      // gap: 15px;
      padding: 15px;
    }

    .dialog-button {
      display: grid;

      height: 50px;
      width: 100%;
      line-height: 50px;

      border-radius: 8px;

      color: var(--text-color-normal);

      &.button {
        border: none;
        outline: none;

        font-family: system-ui, -apple-system, 'HelveticaNeue', BlinkMacSystemFont;

        cursor: pointer;

        user-select: none;
        font-weight: 400;
        font-size: 17px;
        padding: 0;
        margin: 0;

        background-color: var(--background-color-button);

        &.install {
          padding: 0 15px;
          text-align: left;

          // display: inline-flex;

          .button-text {
            opacity: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;

            transition: opacity .4s ease-in-out 0.1s;

            overflow: hidden;

            > span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            > svg {
              flex-shrink: 0;
              transform: scale(.8);
            }

            &.show {
              opacity: 1;

              visibility: visible;
              width: auto;
              height: auto;
            }

            &.hide {
              opacity: 0;
              position: absolute;

              width: 0px;
              height: 0px;
              visibility: hidden;
            }
          }
        }

        &.gallery {
          display: flex;
          align-items: center;

          min-width: 50px;
          width: auto;

          margin-right: 15px;

          #pwa-gallery {
            margin: 0 auto;
            width: 23px;
            fill: var(--text-color-normal);
          }
        }

        &:active {
          background-color: var(--background-color-button-active);
        }
      }
    }

    .how-to-body {
      opacity: 0;
      max-height: 0px;

      overflow: hidden;

      transition: opacity ease-in-out .5s, max-height .5s ease-in-out;

      grid-area: how-to;

      .how-to-description {
        display: grid;
        user-select: none;

        grid-auto-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        // grid-template-columns: 1fr 1fr 1fr;
        // grid-template-rows: auto 1fr;
        gap: 10px;
        // grid-template-areas:
        //     "title title title"
        //     ". . .";

        padding: 15px;
        padding-bottom: 0;


        .description-step {
          display: inline-flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;

          font-size: 14px;

          .svg-wrap {
            position: relative;
            display: inline-flex;
            flex-shrink: 0;
            // background-color: var(--background-color-button);

            width: 50px;
            height: 50px;
            align-items: center;

            border-radius: 6px;

            #pwa-share, #pwa-add, #pwa-safari {
              margin: 0 auto;
            }

            #pwa-safari, #pwa-share {
              fill: #527AFB;
            }

            #pwa-share {
              margin-bottom: 4px;
              transform: scale(0.75);
            }

            #pwa-add {
              transform: scale(0.8);
              fill: var(--text-color-normal);
            }

            #pwa-safari {
              transform: scale(.92);
            }

            .step-count {
              position: absolute;
              line-height: 12px;
              top: 3px;
              left: 4px;
            }
          }

          .step-text {
            // margin-top: 5px;
            font-weight: 600;
            text-align: left;
          }
        }
      }
    }

    pwa-gallery {
      grid-column: 1 / 3;

      max-height: 0;
      opacity: 0;

      transition: opacity ease-in-out .5s, max-height .5s ease-in-out;
      overflow: hidden;

      & + .action-buttons {
        display: flex;
        // grid-template-columns: 64px auto;
      }
    }

    &.available {
      opacity: 1;
      pointer-events: unset;
      transform: translateY(0);
      transition: transform .5s cubic-bezier(.33, 1, .66, 1), border .35s ease-in-out;
    }

    &.gallery {
      pwa-gallery {
        transition: opacity .3s ease-in-out .2s, max-height .5s ease-in-out;

        max-height: 70vh;
        overflow-y: auto;
        opacity: 1;
      }
    }

    &.how-to {
      .dialog-body {
        border-bottom: 1px solid #e0e0e0;
      }

      .how-to-body {
        opacity: 1;
        max-height: 400px;
        overflow-y: auto;

        transition: opacity .3s ease-in-out .2s, max-height .5s ease-in-out;
      }
    }
  }
}

$phone-small-landscape: 667px;

@media (min-width: $phone-small-landscape) {
  #pwa-install-element {
    .install-dialog.apple {
      top: 0px;
      bottom: unset;
      transform: translateY(calc(-100% - 40px));

      right: 150px;
      left: auto;

      &.available {
        top: 28px;
        bottom: auto;
        max-height: calc(95vh - 28px);
        // transition: top .5s ease-in-out, opacity .8s ease-in-out, max-height .35s ease-in-out;
      }
    }
  }
}

@media (min-width: $phone-small-landscape) and (max-width: 1366px) {
  #pwa-install-element {
    .install-dialog.apple {
      right: max(28px, env(safe-area-inset-right, 28px));
      left: auto;
    }
  }
}

@media (max-width: ($phone-small-landscape - 1px)) {
  #pwa-install-element {
    .install-dialog.apple {
      .description-step .step-text {
        font-size: 13px;
      }

      &.available {
        bottom: 20px;
        max-height: calc(85vh - 20px);
      }
    }
  }
}

@media (max-width: 428px) {
  #pwa-install-element {
    .install-dialog.apple.dialog-body {
      max-width: 100vw;

      .action-buttons {
        padding-bottom: max(15px, env(safe-area-inset-bottom))
      }

      &.available {
        bottom: 0px;
        max-height: 85vh;
      }

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

    }
  }
}

@media (max-height: 548px) {
  #pwa-install-element {
    .install-dialog.apple.dialog-body {
      &.available {
        overflow-y: auto;
        max-height: calc(90vh - 28px - env(safe-area-inset-bottom, 0px));
      }
    }
  }
}

// @media (max-width: 370px) {
//     #pwa-install-element{
//         .install-dialog.apple{
//             max-width: 300px;
//         }
//     }
// }

@media (prefers-color-scheme: dark) {
  #pwa-install-element {
    .install-dialog.apple {
      --text-color-normal: #fff;
      --text-color-description: #9E9AA1;
      --background-color: rgb(25 25 25 / 90%);
      --background-color-button: #353535;
      --background-color-button-active: #454545;
      --border-bottom-color: #353535;
    }
  }
}

</style>
