<!--
 * @Author: 
 * @Mail: 
 * @Date: 2023-07-03 22:54:45
 * @LastEditTime: 2023-07-04 16:49:08
 * @LastEditors: 
 * @FilePath: /bliblih5/web_base/component_base/EJumpLinkPage/index.vue
-->
<!--
使用方法
     <EJumpLinkPage :linkUrl="url" />

    linkUrl:需复制链接

    注：把bgimg.png放在静态资源根目录下

-->
<template>
  <div class="mask">
    <img :src="imgUrl" />
    <div class="copbBtn" @click="doCopy">点击复制链接</div>
  </div>
</template>
<script>
export default {
  name: 'EJumpLinkPage',
  props: {
    linkUrl: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  data() {
    return {
      imgUrl: require('~/static/bgimg.png'),
    };
  },

  mounted() {},
  methods: {
    doCopy() {
      this.$copyText(this.linkUrl)
        .then(() => {
          this.$toast('复制剪贴板成功');
        })
        .catch(err => {
          this.$toast('复制剪贴板失败');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: #333333;
  > img {
    width: 100%;
  }
  .copbBtn {
    width: 5rem;
    height: 1rem;
    margin: 0 auto;
    background: #fff;
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.4rem;
  }
}
</style>
