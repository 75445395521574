<template>
    <div class="big-list" v-if="list && list.length > 0">
      <div class="big-list-item" v-for="(item, index) in list" :key="index" @click="toPlay(item)">
        <LongVideoCards :item="item"/>
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'BigList',
    props: ['list'],
    components: {
      LongVideoCards: () => import('@/components/VideoCard/LongVideoCards.vue')
    },
    computed: {
      imgCDN({ $store }) {
        return $store.getters['cdn']
      }
    },
    methods: {
      toPlay(item) {
        this.$router.push(`/subpage/play/longVideo/${item.id}`)
      }
    },
    mounted() {
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .big-list {
    // padding: 0.3rem 0.27rem;
    padding-bottom: 0;
    border-radius: 0.05rem;
    @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
    &-item {
      margin-bottom: 0.3rem;
      width: 100%;
    }
  }
  </style>
  