<!-- home -->
<template>
  <div class="post-container">
    <div class="myPubilc" @click="$router.push('/trade/myPost')">我的发布</div>
    <ETab
      class="post-tab"
     
      @change="change"
      :sticky="true"
      :tabList="topTabLabelList"
      :v-model="type"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#00F6F8'"
      :line-width="'.5rem'"
      :lineHeight="'.08rem'"
      :lineBott="'18px'"
      :sizeActive="'.36rem'"
      :title-active-color="'#fff'"
      :title-inactive-color="'#fff'"
      :sizeTab="'0.32rem'"
    >
      <template v-slot:default="scope">
        <component
          :navItem="scope"
          :findhotList="findhotList"
          @checkTag="checkTag"
          :is="compComponent(scope.data)"
        ></component>
      </template>
    </ETab>
  </div>
</template>
  
  <script>
import scrollMixin from '@/utils/scrollMixin'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      topTabLabelList: [
        { showType: 0, name: '社区' },
        { showType: 1, name: '娱乐' }
      ],
      findhotList: [], //热搜榜列表
      indexActive: 0
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Community: () => import('@/views/community/index.vue'),
    Entertainment: () => import('./components/entertainment.vue')
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    postSection({ $store }) {
      return $store.getters['postSection']
    }
  },

  activated() {},
  methods: {
    checkTag(item) {
      this.indexActive = item
    },

    change(type) {
      this.type = type
    },

    //  0:暗黑游  1:帝王调教 模版 2:暗网交易
    compComponent(data) {
      switch (data) {
        case 0:
          return 'Community'
        case 1:
          return 'Entertainment'
        default:
          return 'Community'
      }
    }
  },
  beforeDestroy() {}
}
</script>
  <style lang="scss" scoped>
.post-container {
  min-height: $minHeight;
  background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .paddingAll {
  }
  .post-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: 50%;
        right: 0;
        z-index: 99;
        width: 100%;
        transform: translate(-50%, 0);
        max-width: $pcMaxWidth;
        height: 1rem;
      }
      .van-tabs__nav {
        justify-content: flex-start;
        background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
        background-size: cover;
      }
      .van-tab {
        flex: none;
      }
    }
  }
}
.myPubilc {
  border-radius: 0.12rem;
  background: #00f6f8;
  padding: 0.08rem 0.16rem;
  position: fixed;
  top: 0.2rem;
  right: 0.1rem;
  z-index: 100;
  color: #333;
}
</style>
  