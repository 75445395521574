<template>
    <div class="message-container">
        <EBackTop title="系统公告" background="#000" color="#fff"></EBackTop>
        <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
            :refreshing="refreshing" :skeleton="skeleton">
            <ul class="message-listWrap">
                <li class="item" :key="index" v-for="(item, index) in tipList" @click="toDetail(item)">
                    <div class="top">
                        <div class="title">
                            <img src="@/assets/imgs/mine/message.png" alt="" />
                            <div class="title-txt">
                                <p>系统公告</p>
                                {{ item.createdAt.split('T')[0] }}
                            </div>
                           

                        </div>

                    </div>
                    <div class="bottom">
                        <div class="title">{{ item.title }}</div>
                        <div>{{ item.content }}</div>
                    </div>
                </li>
            </ul>
        </PullUp>
    </div>
</template>
<script>
import { message_list } from 'api/user'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools';
import { advertiseClick } from 'api/home'
export default {
    data() {
        return {
            tipList: [],
            // 当前页
            pageNum: 1,
            // 当前个数
            pageSize: 5,
            // 上拉加载
            loading: false,
            // 上拉加载完成
            finished: false,
            // 下拉刷新
            refreshing: false,
            skeleton: false
        }
    },

    components: {
        PullUp: () => import('@/components/PullUp.vue')
    },
    methods: {
        // 获取消息列表
        async get_tipList() {
            try {
                const res = await message_list({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    type:2
                })
                if (res.code === 200) {
                    this.refreshing = false
                    this.loading = false
                    this.tipList = [...this.tipList, ...res.data.list]
                    if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
                        this.finished = true
                    }
                } else {
                    this.refreshing = false
                    this.loading = false
                    this.finished = true
                    this.$toast(res.tip)
                }
            } catch (error) {
                this.refreshing = false
                this.loading = false
                this.finished = true
                console.log(error)
            }
        },
        // 下拉加载
        moreData(loading) {
            this.loading = loading
            this.pageNum++
            this.get_tipList()
        },
        // 上拉刷新
        refreshData(refreshing) {
            this.refreshing = refreshing
            this.loading = true
            this.finished = false
            this.pageNum = 1
            this.tipList = []
            this.skeleton = true
            this.get_tipList()
        },
       async toDetail(item){
            if (!item.link) return
            const code = handleURlParams(item.link)
            handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
                const res = await advertiseClick({
                id: item.id
            })
            if (res !== 200) {
                return this.$toast(res.tip)
            }
        },
    },

    created() {
        this.refreshData()
        this.$store.commit('setHasNewMsg', false)
    },
    computed: {}
}
</script>
<style lang="scss" scoped>
.message-container {
    padding: 1rem 0;

    min-height: 100vh;
    box-sizing: border-box;
    :deep(){
        .van-nav-bar__placeholder{
            height: 0px !important;
        }
    }
    .message-listWrap {
        margin: 0.3rem 0;

        .item {
            font-size: 0.24rem;
            align-items: center;
            padding: 0.16rem 0.25rem;
            margin: 0.3rem 0.3rem;
            border-radius: 0.12rem;
            border: 1px solid #535353;
            background: #292929;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

            .top {
                width: 100%;
              
                .title {
                    color: #999;
                    font-size: 0.22rem;
                    font-style: normal;
                    font-weight: 400;
                    padding-bottom: .24rem;
                    border-bottom: 0.01rem solid #535353;
                    /* 145.455% */
                    p {
                        color: #FFF;
                        font-family: PingFang SC;
                        font-size: 0.3rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 0.32rem;
                        /* 106.667% */
                        letter-spacing: 0.009rem;
                    }
                }

                img {
                    width: 0.64rem;
                
                }

                div {
                    &:first-child {
                        @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);

                        // padding-bottom: 0.22rem;
                        img {
                            margin-right: 0.1rem;
                        }
                    }

                }
            }

            .bottom {
                padding: 0.24rem 0;
                box-sizing: content-box;
                .title {
                    font-weight: 600;

                }

                div {
                    &:first-child {
                        font-size: 0.28rem;
                        text-align: center;
                        padding-bottom: 0.2rem;
                    }

                    &:last-child {
                        font-size: 0.24rem;
                        white-space: pre-line;
                        color: #787979;
                    }
                }
            }
        }

        .item-title {
            font-size: 0.32rem;
            padding-left: 0.3rem;
            padding-top: 0.15rem;
        }
    }
}</style>
  