<template>
  <div class="release">
    <EBackTop background="#000" title="发布帖子" color="#fff"> </EBackTop>
    <div class="release_main">
      <div>
        <!-- 选择 -->
        <div class="check_tag">
          <p class="check_txt">选择板块</p>
          <div class="check_right" @click="toTypeList">
            <span>{{ itemName.name ? itemName.name : '请选择帖子板块' }}</span>
            <van-icon name="arrow" color="#BCBCBC" class="right_icon" />
          </div>
        </div>
        <!-- 内容填写 -->
        <div class="content_box">
          <p class="check_txt">填写内容</p>
          <!-- 描述信息 -->
          <van-field maxlength="50" v-model="title" class="title_box" required placeholder="请填写标题" />
          <van-field
            class="text_box"
            maxlength="1000"
            v-model="text"
            autosize
            type="textarea"
            placeholder="请填写正文 (选填)"
          />
        </div>
        <div class="set_video" v-if="isFromTrade">
          <span class="check_txt">设置联系方式</span>
          <van-field v-model="contact" class="title_box" placeholder="请输入微信、手机号、QQ等联系方式" />
        </div>
        <div class="set_video" v-if="isFromTrade">
          <span class="check_txt">设置解锁价格</span>
          <van-field v-model.number="price" class="title_box" placeholder="请设置价格，100金币起步" />
          <p class="tip_txt">根据业务商家需要缴纳30%的保证金。</p>
        </div>

        <!-- 视频上传 -->
        <div class="video_box" v-if="$route.query.postType === 'video'">
          <div class="video_box_title">
            <span class="check_txt video_title">添加视频</span>
            <span class="video_title"> 只能添加一个视频,没有封面显示视频第一帧 </span>
          </div>
          <div>
            <div class="video_bot">
              <UploadVideo @result="resultVideo" ref="uploadVideo" />
              <!-- <img class="cover_bg" v-if="!cover[0]" @click="loadCover" src="'@/assets/imgs/community/load_img_bg.png'" alt="">
        <EDecryptImg class="cover_bg" v-else :imgCDN="imgCDN" :imgURL="cover[0]" /> -->
              <div class="cover_load">
                <UploadImgSpecial class="cover_bg" ref="coverLoad" :maxLength="1" @result="resultCover" />
              </div>
              <div class="info-text">*视频大小不超过100M</div>
            </div>
          </div>
        </div>
        <!-- 设置价格 -->
        <div class="set_video" v-if="!isFromTrade && $route.query.postType === 'video'">
          <span class="check_txt">设置视频金币</span>
          <van-field v-model.number="price" class="title_box" placeholder="请填写金币数值,若不填则为免费" />
        </div>
        <!-- 图片上传 -->
        <div class="pohoto_box cover_bg">
          <div class="video_title">
            <span class="check_txt">添加图片</span>
            最多只能添加8张图片,最大每张1M以内
          </div>
          <UploadImg ref="uploadImgClear" :maxLength="8" @result="resultImgs" />
        </div>
      </div>
      <div>
        <div class="btn" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import { community_publish } from '@/api/dating'

export default {
  components: {
    UploadImg: () => import('@/components/UploadImg/index.vue'),
    UploadImgSpecial: () => import('@/components/UploadImgSpecial/index.vue'),
    UploadVideo: () => import('@/components/UploadVideo/index.vue')
  },
  data() {
    return {
      text: '',
      price: undefined,
      taskID: '',
      video: '',
      title: '',
      imgs: [],
      cover: [],
      itemName: {},
      contact: ''
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    postSection({ $store }) {
      return $store.getters['postSection']
    },
    isFromTrade() {
      return this.$route.query.from === '1'
    }
  },
  activated() {
    if (this.$store.state.history.postSelect) {
      this.itemName = this.$store.state.history.postSelect
    }
  },
  deactivated() {
    this.$store.commit('setPostSelect', {})
  },

  methods: {
    // 视频上传回调
    resultVideo({ taskID, videoUri }) {
      this.taskID = taskID
      this.video = videoUri
    },
    resultCover(imgs) {
      this.cover = imgs
    },
    // 图片上传回调
    resultImgs(imgs) {
      this.imgs = imgs
    },
    loadCover() {
      this.$refs.coverLoad.$refs.Uploader.chooseFile()
    },
    async submit() {
      if (!this.text || this.text.length < 8) {
        return this.$toast('描述信息字数不可少于8个字')
      }
      if (this.$route.query.postType !== 'video') {
        if (!this.imgs || this.imgs.length === 0) {
          return this.$toast('请上传图片!')
        }
      }

      if (this.imgs.length > 8) {
        return this.$toast('图片不能大于8张')
      }
      if (!this.isFromTrade) {
        if (this.$route.query.postType === 'video') {
          if (!this.video) return this.$toast('请上传视频!')
        }
      }
      if (this.isFromTrade) {
        if (!this.price) {
          return this.$toast('请填写解锁价格!')
        }
        if (this.price < 100) return this.$toast('解锁价格最低100金币起!')
      }
      try {
        const res = await community_publish({
          title: this.title,
          text: this.text,
          cover: this.cover[0],
          imgs: this.imgs,
          price: this.price * 100,
          video: this.video,
          taskID: this.taskID,
          type: this.isFromTrade ? 1 : 2,
          contact: this.contact,
          sectionId: this.itemName.id,
          categoryId: Number(this.$route.query.cid)
        })
        if (res.code === 200) {
          this.$toast('提交成功，请等待审核')
          this.$store.commit('setPostSelect', {})
          this.title = ''
          this.text = ''
          this.taskID = ''
          this.video = ''
          this.price = undefined
          this.contact = ''
          this.imgs = []
          this.cover = []
          this.$refs.uploadImgClear.upLoadImgs = []
          this.itemName = {}
          if (this.$route.query.postType == 'video') {
            this.$refs.uploadVideo.clearInfo()
          }
          this.$router.replace(this.isFromTrade ? '/trade' : '/post')
        } else {
          return this.$toast(res.tip || res.data.msg || '上传失败')
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    toTypeList() {
      this.$router.push({ path: '/community/typeList', query: { ...this.$route.query } })
    }
  }
}
</script>

<style lang='scss' scoped>
.release_main {
  background: #212223;
  min-height: calc(100vh - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:nth-child(2) {
    border-top: 0.01rem solid #575757;
  }
  > div:nth-child(1) {
    padding: 0.36rem;
  }
}

.van-cell {
  border-radius: 0.16rem;

  margin-top: 0.2rem;
  border: 1px solid #535353;
  background: #292929;

  :deep() {
    .van-field__control {
      color: #fff;
      &::placeholder {
        color: #787979;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.video_title {
  color: #999;
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.van-cell::after {
  display: none;
}

.text_box {
  height: 1.8rem;
  border-radius: 0.08rem;
}

.check_tag {
  @include flexbox($jc: flex-start);

  .check_right {
    border-radius: 0rem 0.08rem 0.08rem 0rem;
    border: 1px solid #535353;
    background: #292929;
    display: flex;
    width: 5.26rem;
    height: 0.64rem;
    padding: 0.11rem 0.28rem 0.11rem 0.18rem;
    align-items: center;
    gap: 2.54rem;
    flex-shrink: 0;
    justify-content: space-between;
  }
}

.right_icon {
  width: 0.16rem;
  height: 0.26rem;
  flex-shrink: 0;
  font-weight: 600;
}

.check_txt {
  border-radius: 0.08rem;
  background: rgba(0, 246, 248, 0.12);
  padding: 0.11rem 0.16rem;
  color: #00f6f8;
  display: inline-block;
}

.content_box {
  margin-top: 0.48rem;
}

.set_video {
  margin-top: 0.52rem;
}

.video_box {
  margin: 0.52rem 0;

  .video_box_title {
    margin-bottom: 0.2rem;
  }
}

.pohoto_box {
  margin-top: 0.52rem;

  :deep() .upload-img-box .img-item {
    margin-right: 0.08rem;
  }
}

.cover_load {
  @include flexbox($fd: column, $jc: center);
  :deep() .img-item {
    height: 1.6rem !important;
    width: 1.6rem !important;
    margin-right: 0.32rem !important;
    margin-top: 0 !important;
    border-radius: 0.08rem !important;
    // margin-right: 0.32rem;
    img {
      border-radius: 0.08rem !important;
    }
  }
}

.cover_bg {
  // margin-bottom: 0.2rem;
  // margin-left: 0.3rem;
  .video_title {
    margin-bottom: 0.38rem;
  }
  :deep() {
    .uploadImg-wrap {
      width: 1.6rem !important;
      height: 1.6rem !important;
    }
    .img-item {
      width: 1.6rem !important;
      height: 1.6rem !important;
      margin-top: 0 !important;
    }
    .upload-img-item {
      margin: 0 !important;

      img {
        width: 1.6rem !important;
        height: 1.6rem !important;
      }
    }
  }
}

.video_bot {
  @include flexbox($jc: flex-start, $ai: flex-start);
  > .global-uploader {
    margin-right: 0.32rem;
  }
  > .info-text {
    color: #ff5454;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 1.6rem;
    margin-left: 0.39rem;
  }
}

.btn {
  border-radius: 0.46rem;
  background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  width: 6.5rem;
  height: 0.9rem;
  flex-shrink: 0;
  text-align: center;
  line-height: 0.9rem;
  margin: 0.3rem auto;
  color: #fff;

  text-align: center;
  font-size: 0.32rem;
}
.tip_txt {
  color: #f9c006;
  margin-top: 0.12rem;
  font-size: 0.2rem;
}
</style>
