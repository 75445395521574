<template>
  <div class="history">
    <EBackTop :title="'浏览记录'" background="#000" color="#fff">
      <div class="right-btn" slot="right" @click="onClickRight">
        {{ btnTxt }}
      </div>
    </EBackTop>
    <ETab
      class="myHistory-tab"
      @change="change"
      :sticky="true"
      :tabList="cartoonCategory"
      :v-model="indexActive"
      :animated="false"
      :title-active-color="'#FFF'"
      :title-inactive-color="'#FFF'"
      :sizeActive="'.3rem'"
      :lineSwitch="true"
      lineBg="#00F6F8"
      :ellipsis="false"
      lineBott="0.4rem"
    >
      <template v-slot:default="scope">
        <component
          ref="childDom"
          cardType="history"
          :is="compComponent(scope.data)"
          :showMask="showMask"
          @checkList="checkList"
        ></component>
      </template>
    </ETab>

    <!-- 弹窗 -->
    <van-popup
      class="pay-pop"
      v-model="showType"
      @close="cancelConfirm"
      close-icon="cross"
      position="bottom"
      lazy-render
    >
      <div @click="confirm">删除</div>
      <div @click="cancelConfirm">取消</div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //文章列表
      historyList: [],
      spliceList: [],
      indexActive: 0,
      showType: false,
      btnTxt: '编辑',
      showMask: false,
      cartoonCategory: [
        { showType: 1, name: '视频' },
        { showType: 6, name: '嫩模空降' },
        { showType: 4, name: '好茶约炮' },
        { showType: 5, name: '帖子' }
      ]
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    AnHeiYouItem: () => import('@/components/HistoryComponents/AnHeiYouItem.vue'),
    AWJiaoYiItem: () => import('@/components/HistoryComponents/AWJiaoYiItem.vue'),
    DiWangTJItem: () => import('@/components/HistoryComponents/DiWangTJItem.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    ShortVideoItem: () => import('@/components/HistoryComponents/ShortVideoItem.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue')
  },

  created() {},
  computed: {
    // 获取长视频观看记录列表
    avList({ $store }) {
      return $store.getters['avList']
    },
    // 获取动漫视频观看记录列表
    cartoonList({ $store }) {
      return $store.getters['cartoonList']
    },

    postList({ $store }) {
      return $store.getters['postList']
    }
  },
  methods: {
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'

        case 4:
          return 'DiWangTJItem'
        case 5:
          return 'CommunityDefault'
        case 6:
          return 'AnHeiYouItem'
        default:
          return 'LongVideoItem'
      }
    },

    //切换
    change(id) {
      this.indexActive = +id
    },
    // 编辑按钮
    onClickRight() {
      if (this.btnTxt == '编辑') {
        this.showMask = true
        return (this.btnTxt = '完成')
      }
      this.showType = true
    },
    //取消
    cancelConfirm() {
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    //确定删除
    confirm() {
      if (this.indexActive === 0) {
        this.$store.dispatch('setLongVideoList', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 1) {
        this.$store.dispatch('setAHYlist', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 2) {
        this.$store.dispatch('setDWTJlist', { type: 'del', arr: this.spliceList })
      } else {
        this.$store.dispatch('setPostList', { type: 'del', arr: this.spliceList })
      }
      this.$refs.childDom.refreshData()
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    checkList(arr) {
      this.spliceList = arr
    }
  }
}
</script>
<style lang="scss" scoped>
.history {
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  min-height: 100vh;
}
.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }
    .van-tabs__nav {
      justify-content: flex-start;
      background: url('../../../assets/imgs/mine/mine_top_bg.png') no-repeat;
      background-size: cover;
    }
    .van-tab {
      flex: none;
    }
  }
}
.right-btn {
  display: inline-flex;
  padding: 0.04rem 0.16rem;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
  border-radius: 0.24rem;
  border: 1px solid #00f6f8;
  background: rgba(134, 36, 36, 0.2);
  font-size: 0.24rem;
}
.pay-pop {
  background-color: transparent;
  div {
    margin: 0 auto;
    width: 7rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    color: #00f6f8;
    background-color: #fff;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    font-weight: 600;
    font-size: 0.32rem;
  }
}
.nodata {
  line-height: 60vh;
  font-size: 0.26rem;
  color: #ccc;
  text-align: center;
}
</style>
