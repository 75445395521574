<template>
  <div class="bind-phone">
    <EBackTop class="header" background="transparent" color="#fff" :title="'绑定手机号码'" />
    <div class="bind-phone-main">
      <!-- 手机号 -->
      <div class="phone-number">
        <van-field class="country-input" v-model="country" placeholder="编码" />
        <p class="line"></p>
        <van-field
          class="phone-input"
          maxlength="11"
          v-model="mobile"
          placeholder="输入您的11位手机号码"
        />
      </div>
      <!-- 验证码 -->
      <div class="captcha-number">
        <p class="txt">验证码</p>
        <p class="line"></p>
        <van-field
          maxlength="6"
          type="number"
          class="captcha-input"
          v-model="captcha"
          placeholder="输入短信验证码"
        />
        <div class="btn" :class="{ disabled: time }" @click="getCaptcha">
          {{ time ? num + 's' : '获取验证码' }}
        </div>
      </div>
      <!-- 提示语 -->
      <p class="waring">禁止未满18周岁的用户注册使用</p>
      <!-- 按钮 -->
      <div class="submit" @click="bindPhoneFn">绑定手机号码</div>
    </div>
  </div>
</template>

<script>
import { login_captcha, bind_Phone } from 'api/home'
export default {

  components: {
  },
  data() {
    return {
      country: '+86',
      mobile: '',
      timer: null,
      captcha: undefined, // 验证码
      time: false, // 定时器开关
      num: 60,
      countryArr: []
    }
  },
  methods: {
    toBack() {
      return this.$router.go('-1')
    },
    // 获取验证码
    async getCaptcha() {
      this.countryArr = this.country.split('+')
      if (!this.country || !this.countryArr[1]) {
        return this.$toast('请输入国家编码')
      }
      if (!this.mobile) {
        return this.$toast('请输入手机号')
      }

      this.time = true
      let t
      if (this.time) {
        t = setInterval(() => {
          if (this.num > 0) {
            this.num--
          } else {
            this.time = false
            this.num = 60
            window.clearInterval(t)
          }
        }, 1000)
        const res = await login_captcha({
          country: this.countryArr[1],
          mobile: this.mobile
        })
        if (res.code === 200) {
          return this.$toast('验证码已发送，请输入验证码')
        } else {
          return this.$toast(res.tip)
        }
      }
    },
    // 绑定手机号
    async bindPhoneFn() {
      this.countryArr = this.country.split('+')
      if (!this.country || !this.countryArr[1]) {
        return this.$toast('请输入国家编码')
      }
      if (!this.mobile) {
        return this.$toast('请输入手机号')
      }
      if (!this.captcha) {
        return this.$toast('请输入验证码')
      }
      const res = await bind_Phone({
        captcha: this.captcha, // 验证码
        country: this.countryArr[1], // 编码
        mobile: this.mobile // 手机号
      })
      if (res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
        this.$store.dispatch('setToken', res.data.token)
        this.$toast('登陆成功')
        this.timer = setTimeout(() => {
          this.$router.push('/mine')
        }, 500)
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  beforeDestroy() {
    this.timer = null
  }
}
</script>

<style lang="scss" scoped>
.bind-phone {
  font-size: 0.28rem;
  padding-top: 1rem;
  background: url("../../../assets/imgs/mine/mine_top_bg.png") center center no-repeat;
  min-height: 100vh;
  &-main {
    padding: 0 0.6rem;
    box-sizing: border-box;
    p{
      margin: .2rem 0;
      font-weight: 500;
    }
    .line {
      height: 0.3rem;
      width: 0.02rem;
      background: #545560;
      margin: 0.2rem;
    }
    .phone-number {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-top: 0.3rem;
      height: .8rem;
      border-radius: .1rem;
      overflow: hidden;
      .phone-input {
        padding: 0;
        padding-left: .24rem;
        background-color: transparent;
      }
      .country-input {
        border-radius: 0.4rem;
        color: #333;
        min-width: 1.27rem;
        max-width: 1.27rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      background-color: transparent;

        :deep(){
          .van-field__control{
            text-align: center;
            color: #fff;
          }
        }
      }
    }
    .captcha-number {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: .8rem;
      margin-top: 0.3rem;
      border-radius: .1rem;
      overflow: hidden;

      .txt {
        padding: 0;
        min-width: 1.27rem;
        height: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .captcha-input {
        width: 100%;
        padding: 0 .24rem;
        background-color: transparent;
      }
      .btn {
        width: 2.24rem;
        padding: 0.1rem 0.2rem;
        border-radius: 0.4rem;
        background:$btnBg;
        text-align: center;
        white-space: nowrap;
        color: #333;
      }
      .disabled {
        background: rgba($color:  $btnBg, $alpha: 0.5);

      }
    }
    .waring {
      text-align: right;
      color: #848494;
      font-size: 0.24rem;
      margin-top: 0.6rem;
    }
  }
}
.submit {
  border-radius: 0.4rem;
background: $btnBg;
  padding: 0.2rem 1.8rem;
  text-align: center;
  margin-top: 1rem;
  color: #333;

}

:deep() {
  .van-cell {
    box-sizing: border-box;
    padding: 0.09rem 0.388rem;

    .van-field__body {
      width: 100%;
      height: 0.54rem;
    }
    .van-field__control {
      color: #333;
    }
  }
  .txt {
    .van-field__control::-webkit-input-placeholder {
      color: #333;
    }
  }
  .van-cell::after {
    display: none;
  }
}
</style>
