<template>
  <div class="long-four" v-if="list && list.length > 0">
    <div
      class="short-six-item"
      v-for="(item, index) in videoList.length ? videoList : list"
      :key="index"
      @click="toPlay(item)"
    >
      <div v-if="item.cover">
        <div class="advertise-item">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item.cover" />
        </div>
        <div class="advertise-desc">视频广告</div>
      </div>
      <template v-else>
        <LongVideoCards :item="item" />
      </template>
    </div>
    <i></i><i></i>
    <div class="btn" v-if="showType == 3 || showType == 4 || showType == 2">
      <div @click="fn_videoTopicChange"><img src="@/assets/imgs/index/exchangeIcon.png" alt="" />换一批</div>
      <div
        @click="
          $router.push({
            path: `/subpage/morePage`,
            query: {
              id: id,
              name: name
            }
          })
        "
      >
        查看更多
      </div>
    </div>
  </div>
</template>
  
  <script>
import { videoTopicChange, advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  name: 'ShortVideo',
  props: ['list', 'showType', 'name', 'id'],
  components: {
    LongVideoCards: () => import('@/components/VideoCard/LongVideoCards.vue')
  },
  data() {
    return {
      videoList: []
    }
  },
 
  mounted() {
    // this.videoList = this.list;
  
  },

  methods: {
    async toPlay(item) {
      if (item.cover) {
        if (!item.href) return
        const code = handleURlParams(item.href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
          const res = await advertiseClick({
            id: item.id
          })
          if (res !== 200) {
            return this.$toast(res.tip)
          }
        return
      }
      this.$router.push(`/subpage/play/longVideo/${item.id}`)
    },
    async fn_videoTopicChange() {
      const res = await videoTopicChange({
        id: this.id
      })
      if (res.code === 200 && res.data) {
        this.videoList = res.data.mediaList
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.long-four {
  border-radius: 0.05rem;
  @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
  i {
    width: 3.3rem;
  }
  .short-six-item {
    width: 3.38rem;
    ::v-deep {
      .item-bg {
        height: 1.9rem;
      }
    }
  }
  .btn {
    display: flex;
    width: 6.78rem;
    background: #000;
    margin: 0 auto;
    margin-top: 0.1rem;
    border-radius: 1rem;
    div {
      width: 50%;
      height: 0.84rem;
      line-height: 0.84rem;
      font-size: 0.28rem;
      margin: 0 auto;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 0.26rem;
        height: 0.26rem;
        margin-right: 0.05rem;
      }
    }
  }
  .advertise-item {
    position: relative;

    :deep() img {
      height: 1.9rem;
    }
    &::before {
      content: '广告';
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0.02rem 0.12rem;
      border-radius: 0.12rem 0rem;
      background: linear-gradient(111deg, #ff3981 9.07%, #cd0025 96.67%);
      z-index: 1;
    }
  }
  .advertise-desc {
    font-size: 0.24rem;
    padding: 0.14rem;
  }
}
</style>
  