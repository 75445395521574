<template>
    <div class="share">
        <div class="share_top">
            <div class="share_top_info">
                <div class="left_info">
                    <EDecryptImg @click.native="$router.push('/mine/setting')" :imgCDN="imgCDN" class="md-mine-photo"
                        :imgURL="userInfo.avatarUrl ? userInfo.avatarUrl : ''">
                    </EDecryptImg>
                    <div class="right">
                        <!-- <p class="nickName">代理等级：{{ userInfo.level ? userInfo.level : 0 }}</p> -->
                        <img :src="getLvImg" alt="">
                        <div class="title">邀请好友快快升级</div>
                    </div>
                </div>
                <div class="right_info">
                    <p>可提现金额</p>
                    <p>{{ userIncomeInfo.balance | changeGold }}</p>
                </div>
            </div>
            <div class="share_bot_info">
                <span @click="$router.push(`/mine/myShouyi/withdraw?title=代理提现`)">立即提现</span>
                <span @click="$router.push('/mine/myShouyi/promotion')">推广数据</span>
            </div>
        </div>
        <!-- 规格说明 -->
        <div class="tips_txt">
            <span class="tips_title">规则说明</span>
           <div>
            <p>1.邀请好友 <span>注册成功</span> 在 <span>福利任务</span> 中领取 <span>会员</span> 和高额 <span>积分</span></p>
            <p>2. <span>积分</span> 可兑换<span>会员</span>,观看视频,下载视频等等</p>
            <p>3.邀请好友产生充值可获充值最高 <span>55%返利收益</span>如:邀请好友A,A充值<span>100元年卡VIP</span>,即可获得<span>55元收益,可提现!</span></p>
            <p>4.邀请说明:点击[保存二维码]或[复制推广链接],获取专属推广链接,推荐分享给其他人下载即可</p>
           </div>
        </div>
        <div class="main">
            <div class="main_top">
                <img class="top_img" src="@/assets/imgs/mine/share_logo.png" alt="">
                <p class="title">乱伦的欲望 从释放天性开始</p>
                <div class="line"></div>
                <p class="invites_nums">累计邀请 <span>{{ userInfo.invites }}</span> 人</p>
                <img class="mm_img" src="@/assets/imgs/mine/mm.png" alt="">
            </div>
            <div class="info-top">
                <div class="code">
                    <EGenerateImg ref="EGenerateImg" :link="userInfo.inviteUrl" :name='`来自${userInfo.nickName}的分享地址.png`'
                        :config="config">
                    </EGenerateImg>
                </div>

                <div class="info_right">
                    <p>邀请好友看片赚钱</p>
                    <p>好友也可直接填写邀请码</p>
                    <p class="right_code">邀请码{{ userInfo.inviteCode }}</p>
                </div>
            </div>
            <!--  -->
            <div class="info_center">
                <div>
                    <p>扫码或浏览器输入</p>
                    <div><span>
                            {{ ldyCdn }}
                        </span> 进行下载</div>
                </div>
                <div class="btn" @click="clickSaveImg">保存图片</div>
            </div>
            <!-- 分享链接 -->
            <div class="info_bot">
                <p>分享链接</p>
                <div class="tip">每邀请 <span>1</span> 人,送 <span>1</span> 天VIP</div>
                <div v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
                    复制链接
                </div>
            </div>
        </div>


        <!-- <div class="btn">
        <div @click="clickSaveImg">保存图片分享</div>
        <div v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
          复制链接分享
        </div>
      </div>
      <div class="tip">
        <p>推广说明</p>
        <span>好友通过您的二维码或者推广链接下载APP,并启动后即算推广成功。</span>
      </div> -->
        <img class="share_bot" @click="$router.push('/mine/myShouyi/promotion')" src="@/assets/imgs/mine/share_bot.png" alt="">
    </div>
</template>
  
<script>
import { user_income } from '@/api/user'
export default {
    name: 'MineShare',
    components: {
    },
    data() {
        return {
            config: {
                qrSize: 200,
            },
            web: '',
            app: '',
            qrCode: '',
            userIncomeInfo: {}
        }
    },
    computed: {
        userInfo({ $store }) {
            return $store.getters['getUserInfo']
        },
        // 落地页
        ldyCdn({ $store }) {
            return $store.getters['ldyCdn']
        },
        imgCDN({ $store }) {
            return $store.getters['cdn']
        },
        getLvImg(){
            switch (this.userInfo.level) {
                case 1:
                    return require('@/assets/imgs/mine/vip_1.png')
                    case 2:
                    return require('@/assets/imgs/mine/vip_2.png')
                    case 3:
                    return require('@/assets/imgs/mine/vip_3.png')
                    case 4:
                    return require('@/assets/imgs/mine/vip_4.png')
                default:
                return require('@/assets/imgs/mine/vip_1.png')
               
            }
        }
    },
    mounted() {
        this.getUserIncome()
    },
    methods: {
        onCopy() {
            this.$toast('复制成功')
        },
        onError() {
            this.$toast('不支持复制')
        },
        clickSaveImg() {
            this.$refs['EGenerateImg'].clickSaveImg();
        },
        // 获取用户代理信息
        async getUserIncome() {
            const res = await user_income({})
            if (res.code === 200) {
                this.userIncomeInfo = res.data
            } else {
                return this.$toast('请求错误，请联系后台管理人员！')
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
.share {
    min-height: 100vh;
    // background: url('../../../assets/imgs/mine/share_bg.png') no-repeat;
    // background-size: cover;
    background-color: rgba($color: #000000, $alpha: 0.25);
}

.share_top {
    border-radius: 0.12rem;
    border: 1px solid #00F6F8;
    background: #222;
    padding: 0.38rem 0 0.28rem 0.38rem;
    margin: 0.6rem 0.32rem 0.48rem 0.32rem;

    .share_top_info {
        @include flexbox($ai: flex-start);

    }

    .left_info {
        @include flexbox();
        .right{
           img{ height: 0.39rem;
            margin-bottom: 0.08rem;
        }
        }
        .md-mine-photo {
            width: 0.98rem;
            height: 0.98rem;
            margin-right: 0.18rem;
        }
    }

    .right_info {
        text-align: right;

        p:first-child {
            border-radius: 0.24rem 0rem 0rem 0.24rem;
            border: 1px solid #FFCF86;
            background: rgba(255, 207, 134, 0.10);
            padding: 0.04rem 0.16rem;
        }

        p:last-child {
            color: #FFCF86;
            font-size: 0.4rem;
            font-weight: 700;
            margin-right: 0.32rem;
            margin-top: .1rem;
        }
    }


    .share_bot_info {
        @include flexbox($jc: space-around);

        span {
            border-radius: 0.08rem;
            padding: 0.12rem 0.8rem;
            color: #333;

            &:first-child {
                background: #00F6F8;
            }

            &:last-child {
                background: #FFCF86;
            }
        }
    }
}

.tips_txt{
    margin-top: 0.48rem;
    padding: 0 0.36rem;
    span{
        color: $btnBg;
    }
    .tips_title{
        border-radius: 0.08rem;
border: 1px solid #00F6F8;
background: rgba(0, 246, 248, 0.10);
padding: 0.06rem 0.12rem;   
margin-bottom: 0.3rem;
display: inline-block;
color: #fff;
    }
   
}
.main {
    padding: 0.5rem 0.36rem;
    border-radius: 0.32rem;
    margin: 0.7rem 0.32rem 1.4rem 0.32rem;
    position: relative;
    border-radius: 0.32rem;
    border: 2px solid #535353;
    background: #070707;
}

.main_top {
    text-align: center;

    .title {
        margin: 0.28rem 0 0.25rem 0;
    }

    .line {
        width: 6.06rem;
        border-bottom: 0.05rem dashed #AAA;
        margin-bottom: 0.25rem;
    }

    .invites_nums {
        span {
            color: $btnBg;
        }
    }

    .top_img {
        width: 2.35rem;
    }

    .mm_img {
        width: 6.14rem;
        height: 3rem;
        flex-shrink: 0;
        margin: 0.24rem 0 0.36rem 0;
    }
}

.info-top {
    @include flexbox($jc: flex-start);
    padding-bottom: 0.46rem;
    border-bottom: 0.01rem solid #535353;

    .code {
        border-radius: 0.16rem;
        background: #FFF;
        width: 1.8rem;
        height: 1.8rem;
        flex-shrink: 0;
        @include flexbox($jc: center);
        margin-right: 0.32rem;

        :deep() {
            .qrBg {
                position: static;
            }

            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .info_right {


        p {
            color: #999;
            font-size: 0.28rem;
        }

        .right_code {
            border-radius: 0.24rem;
            border: 1px solid $btnBg;
            background: rgba(134, 36, 36, 0.20);
            padding: 0.04rem 0.16rem;
            color: $btnBg;
            margin-top: 0.25rem;
            font-size: 0.3rem;


        }
    }
}

.info_center {
    @include flexbox();
    margin: 0.32rem 0 0.34rem 0;

    .btn {
        padding: 0.16rem 0.32rem;
        border-radius: 0.42rem;
        background: linear-gradient(90deg, #00BBBC 0%, #0070C0 100%);
    }

    span {
        color: $btnBg;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.info_bot {
    border-radius: 0.16rem;
    border: 1px solid $btnBg;
    background: #0F0F0F;
    @include flexbox($jc: center, $fd: column);
    padding: 0.24rem 0;

    span {
        color: $btnBg;

    }

    .tip {
        margin: 0.24rem 0;
    }

    div:last-child {
        border-radius: 0.42rem;
        background: linear-gradient(90deg, #00BBBC 0%, #0070C0 100%);
        padding: 0.08rem 0.28rem;
    }
}

.share_bot {
    width: 100%;
    padding: 0 0.46rem;
    margin-bottom: 0.84rem;
}</style>
  