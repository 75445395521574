<!-- home -->
<template>
  <div class="index-container">
    <div class="top_search">
      <div class="searchPack" @click="$router.push('/subpage/search')">
        <img src="@/assets/imgs/community/findhoIcon.png" alt="" />
      </div>
      <div class="discoverPack" @click="$router.push('/subpage/discover')">
        <img src="@/assets/imgs/index/discoverIcon.png" alt="" />
        发现
      </div>
    </div>

    <!--切换面板 -->
    <ETab
      class="container-tab"
      @change="change"
      :sticky="true"
      :tabList="category"
      :v-model="type ? type : category && category[0] ? category[0].id : 0"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#00F6F8'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#fff'"
      :title-inactive-color="'#fff'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'"
      :placeholder="false"
      :swipe-threshold="4"
      background="#000"
    >
      <template v-slot:default="scope">
        <component
          :navItem="scope"
          :showType="scope.data"
          :avAD="avAD"
          :short_videoAD="short_videoAD"
          :type="scope.item.id"
          :is="compComponent(scope.data)"
        ></component>
      </template>
    </ETab>
    <div class="lottery_icon" v-if="this.$store.state.config.config.lotteryEnable" @click="$router.push('/mine/luckyDraw')">
      <img src="@/assets/imgs/lottery_icon.webp" alt="">
    </div>
    <div class="ai-inside-box" :class="{ close: !isShowAi }">
      <div class="show-inside" v-if="isShowAi">
        <img src="@/assets/imgs/aiLab/close_inside_icon.svg" alt="" @click="isShowAi = !isShowAi" />
        <img src="@/assets/imgs/aiLab/ai_inside.png" alt="" @click="$router.push('/ai/generate')" />
        <img src="@/assets/imgs/aiLab/icon_ai_arrow.gif" alt="" />
      </div>
      <div v-else class="inside-close">
        <img src="@/assets/imgs/aiLab/inside_close.png" alt="" @click="isShowAi = !isShowAi" />
      </div>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      // indexActive: 0, // 默认选中id
      type: 0, // 切换的id
      topTabLabelList: [],
      isShowAi: true
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Index: () => import('@/components/Home/index.vue'),
    Default: () => import('@/components/Home/default.vue')
  },
  computed: {
    // 获取首页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取首页底部轮播广告
    short_videoAD({ $store }) {
      return $store.getters['short_videoAD']
    },

    // 获取顶部分类
    category({ $store }) {
      console.log($store.state.config.config.mediaCategory);
      return $store.state.config.config.mediaCategory
    }
  },

  mounted() {
    this.type = this.indexActive
  },
  methods: {
    change(type) {
      this.type = type
    },

    //  Default（2,3切换列表：最新，最热，人气） Index（5+1主题+）
    compComponent(data) {
      switch (data) {
        case 1:
          return 'Default'

        default:
          return 'Index'
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.index-container {
  padding: 0rem 0 0 0;
  // min-height: $minHeight;
  position: relative;
  .lottery_icon{
      position: fixed;
    right: 0;
     bottom: 7.3rem;
     img{
      width: 1.5rem;
     }
  }
  .ai-inside-box {
    height: 2.7rem;
    width: 2.1rem;
    position: fixed;
    right: 0;
    bottom: 4rem;
    transition: all 0.3s;
    &.close {
      height: 0.95rem;
      width: 0.7rem;
    }
    .show-inside {
      position: relative;
      height: 100%;
      img {
        &:nth-child(1) {
          width: 0.32rem;
          height: 0.32rem;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        &:nth-child(2) {
          width: 2rem;
          height: 2.5rem;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:nth-child(3) {
          width: 0.47rem;
          height: 0.27rem;
          position: absolute;
          right: 0.3rem;
          bottom: 0.3rem;
        }
      }
    }
    .inside-close {
      img {
        width: 0.7rem;
        height: 0.95rem;
      }
    }
  }
  .top_search {
    @include flexbox();
    position: fixed;
    width: 100%;
    height: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #000;
    z-index: 10;
  }
  .discoverPack {
    position: absolute;
    left: 0.24rem;
    top: 0.2rem;
    z-index: 100;
    display: flex;
    align-items: center;
    font-size: 0.28rem;
    color: #333;
    background: #00f6f8;
    padding: 0.06rem 0.12rem;
    border-radius: 0.08rem;

    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.06rem;
    }
  }
  .searchPack {
    position: fixed;
    right: 50%;
    right: 0.3rem;
    // margin-right: -3.5rem;
    top: 0.2rem;
    z-index: 100;
    img {
      width: 0.36rem;
      height: 0.36rem;
    }
  }
  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0rem;
        left: 1.5rem;
        // transform: translate(-50%,0);
        width: 5rem;
        max-width: $pcMaxWidth;
        z-index: 99;
        .van-tab {
          flex: none;
        }
      }

      .van-pull-refresh__track {
        padding-bottom: 1rem;
      }
    }
  }
}

.account-img-pop {
  background-color: transparent;
  min-height: 7.78rem;
  overflow-y: visible;
}

.account-img {
  width: 6rem;
  height: 100%;
  // background-image: url('../../assets/imgs/index-activity-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1.5rem 0.4rem 0.4rem 0.4rem;
  text-align: center;

  :deep() {
    .van-icon {
      @include transformCenter(-50%, 0);
      position: absolute;
      right: 0rem;
      top: 0.8rem;
    }
  }

  .title-img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.26rem;
    margin-bottom: 0.4rem;

    span {
      margin: 0 0.2rem;
    }

    img {
      width: 1.4rem;
    }
  }

  p {
    margin: 0 0 0.2rem 0;
    font-size: 0.63rem;
    color: #eb3876;
  }

  .titlePack {
    min-height: 3.5rem;

    .desc {
      text-align: left;
      font-size: 0.26rem;
      white-space: pre-line;
      color: #666666;
      margin: 0.3rem 0 0.45rem 0;
    }
  }

  .btn {
    background: linear-gradient(to right, #52b5f7, #6ce6f3);
    color: #fff;
    font-size: 0.3rem;
    border-radius: 0.36rem;
    width: 3.12rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
html {
  .desktop {
    .discoverPack {
      left: 5rem !important;
    }
  }
}
</style>