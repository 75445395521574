<!--
使用方法
    <ETabBar :list="myCategoryShow" :tabbarConfig="tabbarConfig"  @change="change" :activeNum='firstActiveId'>
      <template v-slot:default="scope">
        <component :is="compComponent(scope.data)" :data="scope.data" ></component>
      </template>
    </ETabBar>

    tabbarConfig:  {
        barWidth: '7.5rem',//宽度
        barHeight: '1rem',//高度
        fontSize: '0.3rem', //默认字体大小
        fontSizeActive: '0.5rem', //选中字体大小
        fontColor: '#d4d4d4', //默认字体颜色
        fontColorActive: '#ff3f7c', //选中字体颜色
        bg: '#1a1a1a', //默认背景色
        bgActive: '#1a1a1a', //选中背景色
        lineColorActive: '#1a1a1a', //选中下划线颜色
        lineBott: '15px', //下划线距离底部距离 String
        lineWidth: '1rem', //下划线宽度 String
        lineHeight: '.06rem', //下划线高度 String
        sticky: false, //粘性布局
        offsetTop: '1rem', //距顶部距离
        shrink: false,
        animated: true, //是否开启切换动画
        tabFlex:true,//左侧收缩布局
    },
    activeNum//首个渲染的tab
    change()//切换钩子
    scope:返回子数据item


-->
<template>
  <div class="ETabBar">
    <van-tabs v-model="newActive" :style="styleVar" type="line" lazy-render :background="config.bg"
      :line-height="config.lineHeight" :line-width="config.lineWidth" :title-inactive-color="config.bg"
      :title-active-color="config.bgActive" :sticky="config.sticky" :offset-top="config.offsetTop" :shrink="config.shrink"
      :animated="config.animated" @change="change" swipe-threshold="2">
      <van-tab v-for="(item, index) in list" :key="item.id" :title="item.name" :name="item.id">
        <template #title>
          <slot name="title" :data="item.showType" :item="item" :index="index"></slot>
        </template>
        <slot :data="item" :index="index"></slot>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'ETabBar',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    activeNum: {
      type: Number,
      default() {
        return 0
      }
    },
    tabbarConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      newActive: this.activeNum
    }
  },
  computed: {
    config() {
      return Object.assign(
        {
          barWidth: '7.5rem',
          barHeight: '1rem',
          fontSize: '0.3rem', //默认字体大小
          fontSizeActive: '0.5rem', //选中字体大小
          fontColor: '#d4d4d4', //默认字体颜色
          fontColorActive: '#ff3f7c', //选中字体颜色
          bg: '#1a1a1a', //默认背景色
          bgActive: '#1a1a1a', //选中背景色
          lineColorActive: '#1a1a1a', //选中下划线颜色
          lineBott: '15px', //下划线距离底部距离 String
          lineWidth: '1rem', //下划线宽度 String
          lineHeight: '.06rem', //下划线高度 String
          sticky: false, //粘性布局
          offsetTop: '1rem', //距顶部距离
          shrink: false,
          animated: true, //是否开启切换动画
          tabFlex: '' //左侧收缩布局
        },
        this.tabbarConfig
      )
    },
    styleVar() {
      return {
        '--tabFlex': this.config.tabFlex ? 'inherit' : 'auto',
        '--barWidth': this.config.barWidth,
        '--barHeight': this.config.barHeight,
        '--fontSize': this.config.fontSize,
        '--fontSizeActive': this.config.fontSizeActive, //选中字体大小
        '--fontColor': this.config.fontColor, //默认字体颜色
        '--fontColorActive': this.config.fontColorActive, //选中字体颜色
        '--bg': this.config.bg, //默认背景色
        '--bgActive': this.config.bgActive, //选中背景色
        '--lineColorActive': this.config.lineColorActive, //选中下划线颜色
        '--lineBott': this.config.lineBott //下划线距离底部距离 String
      }
    }
  },
  watch: {
    activeNum() {
      this.newActive = this.activeNum
    }
  },
  mounted() { },

  methods: {
    change(id) {
      if(this.config.sticky){
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.ETabBar {
  overflow: hidden;

  .van-tabs {
    max-width: 100%;

    ::v-deep{
      .van-tabs__content {
        padding: 0.1rem 0rem 0;
        box-sizing: border-box;
      }

      .van-sticky {
        max-width: 7.5rem;
        width: var(--barWidth);
        height: var(--barHeight) !important;
        position: relative;
        z-index: 99;
      }

      .van-sticky--fixed {
        // margin: 0 auto;
        position: fixed !important;
        left: 50%;
       transform: translateX(-50%);
      }

      .van-tabs__wrap {
        height: var(--barHeight);
      }

      .van-tabs__nav--complete {
        padding-left: 0;
        padding-right: 0;
      }

      .van-tab {
        flex: var(--tabFlex);
        font-size: var(--fontSize);
        color: var(--fontColor) !important;
        background-color: var(--bg);

        padding: 0 0.2rem;
        flex-shrink: 0;
      }

      .van-tab--active {
        font-size: var(--fontSizeActive);
        color: var(--fontColorActive) !important;
        background-color: var(--bgActive);
        line-height: var(--fontSizeActive);
      }

      .van-tabs__line {
        background-color: var(--lineColorActive);
        bottom: var(--lineBott);
      }

      .van-tabs__nav--line {
        padding-bottom: 0px;
      }
    }
  }
}
</style>
