<!-- home -->
<template>
  <div class="trade-container" :class="!isTrade ? 'mt-pad' : ''">
    <!-- && type === homecategoryV2[0].id -->
    <!-- <SwiperShow :imgList="avAD" v-if="avAD && avAD.length"/> -->
    <!-- :class="{ 'index-container': !isTrade, 'trade-container': isTrade, 'mt-top': type == 10172 }" -->
    <!-- 我的发布 -->

    <SeachIpt v-if="type == 10171" />
    <!--切换面板 -->
    <ETab
      class="container-tab"
      @change="change"
      :sticky="true"
      :tabList="findCategory"
      :v-model="type"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#00F6F8'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#fff'"
      :title-inactive-color="'#fff'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'"
      :swipe-threshold="4"
    >
      <template v-slot:default="scope">
        <div class="index-container">
          <component :navItem="scope" :is="compComponent(scope.item)"></component>
        </div>
      </template>
    </ETab>
    <!-- 发布按钮 -->
    <div class="ub_btn" @click="toPost()">
      <!-- <ul v-show="showUpBtn" class="btn_detail">
        <li @click.stop="$router.push(`/community/public/?postType=${'video'}&from=1`)">
          <img src="@/assets/imgs/trade/up_video.png" alt="" />
        </li>
        <li @click.stop="$router.push(`/community/public/?postType=${'img'}&from=1`)">
          <img src="@/assets/imgs/trade/up_photo.png" alt="" />
        </li>
      </ul> -->
      <img src="@/assets/imgs/trade/up_btn.png" alt="" />
    </div>
    <!-- 提示弹窗 -->
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>
  
<script>
import scrollMixin from '@/utils/scrollMixin'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      tabLabelList: [
        { showType: 0, name: '好茶约炮' },
        { showType: 1, name: '嫩模空降' },
        { showType: 2, name: '猛男禁地' }
      ]
      // showUpBtn: false
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    SwiperShow: () => import('@/components/Swiper/index.vue'),
    AnHeiYou: () => import('@/components/AnHeiYou/home.vue'),
    AnWangJiaoYi: () => import('@/components/AnWangJiaoYi/home.vue'),
    DiWangTiaoJiao: () => import('@/components/DiWangTiaoJiao/home.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    CommunityIndex: () => import('@/components/Community/index.vue'),
    SeachIpt: () => import('@/components/SearchIpt.vue')
  },
  created() {
    this.type = this.findCategory[0].id
  },
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取顶部分类
    category({ $store }) {
      return $store.state.config.config.mediaCategory
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    findCategory({ $store }) {
      return $store.getters['postCategory'].filter(item => item.type === 1)
    },
    isTrade() {
      return [10171, 10172].includes(this.type)
    }
  },

  activated() {},
  methods: {
    change(type) {
      this.type = type
    },
    toPost(postType) {
      // '/trade/postTransaction/jiaoYiPost' //this.userInfo.vipType !== 11
      if (false) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          type: 'vip_waring',
          cb: async returnData => {
            this.$router.push('/mine/vip')
          }
        }
      } else {
        if (this.isTrade) {
          if (this.type == 10171) {
            this.$router.push('/trade/postTransaction?indexType=1')
          }
          if (this.type == 10172) {
            this.$router.push('/trade/postTransaction?indexType=2')
          }
        } else {
          this.$router.push(`/community/public/?postType=${'video'}&from=1&cid=${this.type}`)
        }
      }
    },
    //  0:暗黑游  1:帝王调教 模版 2:暗网交易
    compComponent(data) {
      switch (data.id) {
        case 10171:
          return 'AnHeiYou'
        case 10172:
          return 'DiWangTiaoJiao'
        default:
          return 'CommunityIndex'
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.trade-container {
  padding: 0 0.36rem 0 0.36rem;
  // padding-top: 2.1rem;
  // min-height: $minHeight;
  background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
  background-size: cover;

  // &.mt-top {
  //   padding-top: 1rem;
  // }
  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: 50%;
        right: 0;
        z-index: 99;
        width: 100%;
        transform: translate(-50%, 0);
        max-width: $pcMaxWidth;
      }

      .van-tabs__nav {
        justify-content: flex-start;
        background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
        background-size: cover;
      }

      .van-tab {
        flex: none;
      }
    }
  }
}

.mt-pad {
  padding: 0;
}

.ub_btn {
  position: fixed;
  right: 0.36rem;
  top: 60%;
  z-index: 10;

  img {
    width: 0.9rem;
    height: 0.9rem;

    margin-bottom: 0.36rem;
  }
}

.btn_detail {
  position: absolute;
  top: -2.5rem;
}

.index-container {
  min-height: $minHeight;
  position: relative;
  background: url('../../assets/imgs/mine/mine_top_bg.png') no-repeat;
  background-size: cover;

  :deep() {
    .van-tabs__nav {
      background: #000;
    }

    .van-sticky {
      top: 0rem !important;
    }

    .van-tab {
      flex: initial;
    }
  }
}

// 轮播图
.dating-swiper {
  width: 100%;

  :deep() {
    .van-swipe__track {
      width: 100% !important;

      .van-swipe-item {
        width: 100% !important;
      }
    }
  }
}
</style>
  