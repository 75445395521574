<template>
  <van-share-sheet
    class="md-share-popup"
    :value="isShow"
    :options="options"
    :title="title"
    :description="description"
    :close-on-click-overlay="false"
    @select="select"
    @cancel="cancel"
    @click-overlay="cancel"
  >
  </van-share-sheet>
</template>
<script>
export default {
  name: 'MdShare',
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      },
    },
    options: {
      type: Array,
      default() {
        return [
          { name: '本片鏈接', icon: 'link' },
          { name: 'APP下載鏈接', icon: require('@/assets/imgs/short-video/down.png') },
        ]
      },
    },
    title: {
      type: String,
      default() {
        return '立即分享給好友'
      },
    },
    description: {
      type: String,
      default() {
        return '分享面板'
      },
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel', false)
    },
    select(option, index) {
      this.$emit('select', option, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.md-share-popup {
  max-width: $pcMaxWidth;
  left: 0;
  margin: 0 auto;
}

:deep() {
  .van-share-sheet__options {
    padding: .1rem;
    justify-content: space-evenly;

  }

}
</style>
