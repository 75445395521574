<template>
    <div class="lucky_draw">
        <EBackTop title="" background="transparent" color="#fff">
            <slot slot="right" name="right">
                <div class="num" v-if="lotteryCountGet">
                    剩余次数
                    <span>{{ lotteryFreeLeftCount }}</span>
                    <!-- <img @click.stop="addNum" src="@/assets/imgs/index/luckyDraw/add_icon.png" alt=""> -->
                </div>
                <div class="my" @click.stop="showMyFn">
                    <img src="@/assets/imgs/index/luckyDraw/top_icon.png" alt="">
                    我的奖品
                </div>
            </slot>
        </EBackTop>
        <div class="mian">
            <div class="title">
                <img class="lantern" src="@/assets/imgs/index/luckyDraw/lantern.png" alt="">
                <img class="title_img" src="@/assets/imgs/index/luckyDraw/title.png" alt="">
                <img class="lantern" src="@/assets/imgs/index/luckyDraw/lantern.png" alt="">
            </div>
            <lottery @lotteryClick="lotteryClick" @lotteryDone="lotteryDone" :lottery-start="lotteryStart"
                :lottery-prizenum="8" :lottery-prizeno="lotteryPrizeno"
                :lottery-bg="require('@/assets/imgs/index/luckyDraw/lucky_bg.png')"
                :content-bg="require('@/assets/imgs/index/luckyDraw/content_bg.png')"
                :pointer-bg="require('@/assets/imgs/index/luckyDraw/btn_bg.png')" :lottery-width="['90%', '30%']" />
            <div class="desc">
                <img src="@/assets/imgs/index/luckyDraw/laba.png" alt="">
                <van-notice-bar class="desc_swiper" :scrollable="false">

                    <van-swipe vertical class="notice-swiper" :autoplay="2000" :show-indicators="false">
                        <van-swipe-item v-for="item in runningLights" :key="item.id">
                            <div class="item">

                                恭喜<span>{{ item.name }}</span>抽中<span>{{ item.prize }}</span>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </van-notice-bar>
            </div>
            <div class="tip">
                <img src="@/assets/imgs/index/luckyDraw/tip_bg.png" alt="">
            </div>
            <!-- tabs切换 -->
            <ul class="tips_main">
                <li v-if="checkActive == 1" class="left_item">
                    <div class="btn" @click="checkActive = 2"></div>
                    <div class="main_content" v-html="lotteryRuleDesc">
                    </div>
                </li>
                <li v-if="checkActive == 2" class="right_item">
                    <div class="btn" @click="checkActive = 1"></div>
                    <div class="main_content" v-html="lotteryPrizeGetDesc">

                    </div>
                </li>
            </ul>
        </div>
        <DmPopup ref="popupMsg" :img="activeImg" />
        <!-- 我的奖品弹窗 -->
        <van-popup class="my_pop" v-model="showMy" get-container="body" position="center">
            <img class="closeBtn" @click="showMy = false" src="@/assets/imgs/close.png" alt="">
            <ul class="my_pop_item">
                <li v-for="item in lotteryHistoryList" :key="item.id">
                    <EDecryptImg :imgCDN="imgCDN" class="lootery_img" :imgURL="item.prizeCover ? item.prizeCover : ''">
                    </EDecryptImg>
                    <p>{{ item.prizeName }}</p>
                </li>
                <li></li>
                <li></li>
            </ul>
        </van-popup>
    </div>
</template>

<script>
import { checkLottery, getLotteryListAll, lotteryHistory } from 'api/user'
export default {
    components: {
        EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
        DecryptImg: () => import('@/components/DecryptImg/index.vue'),
        DmPopup: () => import('@/components/Popup/index.vue')
    },
    data() {
        return {
            lotteryFreeLeftCount: 0,
            lotteryStart: 0,
            lotteryPrizeno: 0,
            checkActive: 1,
            activeImg: "",
            showMy: false,
            lotteryRuleDesc: '',
            lotteryPrizeGetDesc: '',
            activeLotteryItem: undefined, // 中奖信息
            lotteryHistoryList: [],
            runningLights: [],
            type: 1,
            isLottery: false,
            lotteryGoldPrice: 0, // 价格
            lotteryCountGet: true,// 是否显示次数
            dataList: [

            ]
        }
    },
    computed: {
        // 获取个人余额
        myRecharge({ $store }) {
            return ($store.getters['getUserInfo'].balance + $store.getters['getUserInfo'].giftGold) / 100
        },
        imgCDN({ $store }) {
            return $store.getters['cdn']
        }
    },
    methods: {
        // 转盘点击
        async lotteryClick() {
            if (!this.isLottery) {
                this.$refs['popupMsg'].popupMsg = {
                    show: true,
                    type: 'lotteryType',
                    ok: '开通VIP',
                    img: require('@/assets/imgs/index/luckyDraw/pop_bg.png'),
                    cancel: '离开',
                    content: '此抽奖为专属活动，请前往开通活动会员！',
                    position: 'center',
                    cb: (type) => {
                        this.$router.push('/mine/vip')
                    },
                    close: () => {
                        this.$router.push('/')
                    }
                }
                return
            }
            if (this.lotteryFreeLeftCount > 0) {
                await this.checkLotteryFn(1)
                return
            }

            if ((this.lotteryFreeLeftCount <= 0) && (this.myRecharge >= this.lotteryGoldPrice)) {
                this.$refs['popupMsg'].popupMsg = {
                    show: true,
                    type: 'lotteryType',
                    ok: '是的,我确定',
                    img: require('@/assets/imgs/index/luckyDraw/pop_bg.png'),
                    cancel: '算了吧,我怂了',
                    content: `确认花费${this.lotteryGoldPrice}金币获得一次抽奖机会吗`,
                    position: 'center',
                    cb: (type) => {
                        this.checkLotteryFn(2)
                    },
                }
                return
            }
            if (this.myRecharge < this.lotteryGoldPrice) {
                this.$refs['popupMsg'].popupMsg = {
                    show: true,
                    type: 'lotteryType',
                    ok: '前往充值',
                    img: require('@/assets/imgs/index/luckyDraw/pop_bg.png'),
                    cancel: '算了吧,我怂了',
                    content: '很遗憾,您的金币余额不足',
                    position: 'center',
                    cb: (type) => {
                        this.$router.push('/mine/myWallet')
                    },
                }
                return
            }
        },
        // 转盘完成回调
        async lotteryDone(inx) {
            this.lotteryStart = 0
            this.$refs['popupMsg'].popupMsg = {
                show: true,
                type: 'lottery_ok',
                ok: '再抽一次',
                img: this.activeLotteryItem.cover,
                cancel: '算了,放弃了',
                content: "恭喜您获得",
                desc: this.activeLotteryItem.name,
                position: 'center',
                cb: (type) => {
                    this.$refs['popupMsg'].popupMsg.show = false
                    this.lotteryClick()
                },
            }

        },
        // 奖品列表
        async getLotteryListAllFn() {
            const res = await getLotteryListAll({
            })
            if (res.code === 200) {
                this.lotteryFreeLeftCount = res.data.lotteryFreeLeftCount //抽奖次数
                this.list = res.data.list
                this.lotteryRuleDesc = res.data.lotteryRuleDesc // 注意事项
                this.lotteryPrizeGetDesc = res.data.lotteryPrizeGetDesc // 兑奖方式
                this.runningLights = res.data.runningLights // 跑马灯
                this.lotteryGoldPrice = res.data.lotteryGoldPrice / 100 // 价格
                this.lotteryCountGet = res.data.lotteryCountGet
                this.isLottery = res.data.isLottery
                this.dataList = res.data.list
            }
        },
        // 抽奖接口
        async checkLotteryFn(type) {
            const res = await checkLottery({
                type: type
            })
            if (res.code == 200) {
                // 抽奖完刷新下抽奖次数
                this.activeLotteryItem = res.data.prize
                this.dataList.filter(async (item, index) => {
                    if (item.id === res.data.prize.id) {
                        this.lotteryStart = 1
                        switch (index) {
                            case 0:
                                this.lotteryPrizeno = 1;
                                break;
                            case 1:
                                this.lotteryPrizeno = 8;
                                break;
                            case 2:
                                this.lotteryPrizeno = 7;
                                break;
                            case 3:
                                this.lotteryPrizeno = 6;
                                break;
                            case 4:
                                this.lotteryPrizeno = 5;
                                break;
                            case 5:
                                this.lotteryPrizeno = 4;
                                break;
                            case 6:
                                this.lotteryPrizeno = 3;
                                break;
                            case 7:
                                this.lotteryPrizeno = 2;
                                break;

                            default:
                                break;
                        }

                    }
                })
                await this.getLotteryListAllFn()
            } else {
                this.$toast(res.tip)
            }

        },

        // 打开抽奖记录弹窗
        showMyFn() {
            this.showMy = true
            lotteryHistory({}).then(res => {
                if (res.code === 200) {
                    this.lotteryHistoryList = res.data.list
                } else {
                    return this.$toast(res.tip)
                }

            })

        }

    },
    async mounted() {
        await this.getLotteryListAllFn()
        // setInterval(()=>{
        //    this.runningLights.push(this.runningLights.shift()) 
        // },1000)
    }
}
</script>

<style lang="scss" scoped>
.lucky_draw {
    background: url('../../../assets/imgs/index/luckyDraw/main_bg.jpeg') no-repeat;
    background-size: cover;
    min-height: 100vh;
}

:deep() {
    .lottery_wraper {
        width: 4.8rem;
        margin: 0.4rem auto;
    }
}

.num {
    @include flexbox($jc: flex-start);
    border-radius: 0.47rem;
    background: rgba(0, 0, 0, 0.30);
    padding: 0.12rem 0.24rem;
    margin-right: 0.24rem;
    font-size: 0.3rem;

    span {
        color: #FFC700;
        margin: 0 0.14rem;
    }

    img {
        width: 0.2rem;
        height: 0.2rem;
    }

}

.my {
    border-radius: 0.47rem;
    background: rgba(0, 0, 0, 0.30);
    padding: 0 0.24rem;
    font-size: 0.3rem;

    img {
        width: 0.62rem;
        height: 0.62rem;
    }
}

.title {
    @include flexbox($jc: space-around);
    margin-top: 0.45rem;

    .lantern {
        width: 0.78rem;
        height: 1.13rem;
    }

    .title_img {
        height: 1rem;
    }
}

.desc {
    background: url('../../../assets/imgs/index/luckyDraw/gdbb.png') no-repeat;
    background-size: 100% 100%;
    width: 6.32rem;
    height: 1.05rem;
    @include flexbox($jc: flex-start);
    padding: 0.3rem 0.32rem 0 0.5rem;
    margin: 0 auto 0.47rem auto;

    .desc_swiper {
        width: 100%;
        height: 0.6rem;
        padding: 0;
        background: transparent;

        :deep() {
            .van-notice-bar__content {
                width: 100%;
                height: 0.6rem;
            }
        }

        .item {
            @include flexbox($jc: flex-start);
            height: 0.6rem;

        }
    }

    img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.38rem;
    }

    span {
        color: #FFE500;
        margin: 0 0.1rem;
        @include textoverflow();
    }
}

.tip {
    text-align: center;
    position: relative;
    z-index: 2;

    img {
        width: 100%;
    }
}

.tips_main {
    @include flexbox($jc: center);
    margin-top: -1.2rem;

    li {
        width: 6.78rem;
        height: 11.68rem;
        position: relative;

        .btn {
            position: absolute;
            width: 4.16rem;
            height: 1.04rem;
            top: 0.3rem;
            z-index: 10;
        }
    }

    .main_content {
        width: 6.46rem;
        height: 10rem;
        position: absolute;
        left: 50%;
        top: 1.3rem;
        transform: translate(-50%, 0);
        z-index: 2;
        padding: 0.43rem 0.36rem;
        color: #333;
        overflow: auto;
        white-space: pre-wrap;
    }
}

.left_item {
    background: url('../../../assets/imgs/index/luckyDraw/left_item_bg.png') no-repeat;
    background-size: cover;

    .btn {
        right: 0;

    }
}

.right_item {
    background: url('../../../assets/imgs/index/luckyDraw/right_item_bg.png') no-repeat;
    background-size: cover;

    .btn {
        left: 0;

    }
}

// 我的奖品弹窗
.my_pop {
    width: 5.6rem;
    height: 11.2rem;
    background: url('../../../assets/imgs/index/luckyDraw/my_bg.png') no-repeat;
    background-size: cover;
    padding-top: 2.5rem;

    .closeBtn {
        width: .4rem;
        height: .4rem;
        position: absolute;
        right: 0;
        top: 0;
    }

    .my_pop_item {
        @include flexbox($jc: space-between, $fw: wrap, $ai: flex-start);
        padding: 0 0.56rem;
        max-height: 8rem;
        overflow: auto;

        li {
            color: #900000;
            @include flexbox($jc: center, $fd: column);
            font-size: 0.28rem;
            flex: none;
            width: 1.28rem;
            margin-top: 0.24rem;
        }

    }

    .lootery_img {
        width: 100%;
        height: 1.6rem;
        margin-bottom: 0.16rem;
    }
}
</style>
