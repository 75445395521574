<!-- home -->
<template>
  <div class="AnHeiYou-container">
    <!-- 女优列表 -->
    <ul class="actor_list">
      <li v-for="(item, index) in typeList" v-if="index <= 8" :key="item.id" @click="changeType(item)">
        <div>
          <EDecryptImg :imgCDN="imgCDN" class="actor_item" :imgURL="item.imageURL"> </EDecryptImg>
          <p>{{ item.title }}</p>
        </div>
      </li>
      <i></i>
      <i></i>
    </ul>
    <!-- 公告 -->
    <div class="announcement">
      <div>
        <div class="title">公告</div>
        <div class="scroll-text">
          <!-- <div class="text" ref="text">{{ $store.state.config.config.darkWebRunningLight.content }}</div> -->
          <van-notice-bar
            class="text-scroll-bar"
            scrollable
            :text="$store.state.config.config.darkWebRunningLight.content"
            background="transparent"
            color="#fff"
          />
          <!-- <div class="text" ref="text">
            {{
              '$store.state.config.config.darkWebRunningLight.content$store.state.config.config.darkWebRunningLight.content$store.state.config.config.darkWebRunningLight.content$store.state.config.config.darkWebRunningLight.content$store.state.config.config.darkWebRunningLight.content'
            }}
          </div> -->
        </div>
      </div>
      <!-- 城市筛选 -->
      <div class="city_list" @click="showCityList = !showCityList">
        <div>
          <img src="@/assets/imgs/options_icon.png" alt="" />
          <span>{{ cityName }}</span>
          <img src="@/assets/imgs/trade/select_icon.png" alt="" />
        </div>
        <!-- 列表 -->
        <ul class="city_list_show" v-if="showCityList">
          <li @click="checkCity(item)" v-for="item in cityList">{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <!-- 列表 -->
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <ul class="ah_list">
        <li v-for="item in mainList" :key="item.id">
          <AnHeiYouDefaultOneCard :item="item" />
        </li>
      </ul>
    </PullUp>
  </div>
</template>
  
<script>
import scrollMixin from '@/utils/scrollMixin'
import { dating_category, loufeng_type } from '@/api/dating'
import { city_list } from 'api/dating'
export default {
  mixins: [scrollMixin],
  data() {
    return {
      typeList: [],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      refreshing: false,
      mainList: [],
      showCityList: false,
      cityName: '全国',
      cityId: 0
    }
  },
  components: {
    AnHeiYouDefaultOneCard: () => import('./components/AnHeiYouDefaultOneCard.vue'),
    PullUp: () => import('@/components/PullUp.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  async mounted() {
    // this.startScrolling()
    await this.getCityList()
    await this.loufengType()
    await this.refreshData()
  },
  methods: {
    checkCity(city) {
      this.cityName = city.name
      this.cityId = city.id
      this.refreshData()
    },
    // 公告动画
    // startScrolling() {
    //   const textWidth = this.$refs.text.offsetWidth
    //   const containerWidth = this.$el.offsetWidth
    //   const animationDuration = (textWidth / containerWidth) * 10
    //   this.$refs.text.style.animationDuration = animationDuration + 's'
    // },
    // 列表请求 type 2 上门/嫩模 (帝王调教) 帖子（暗网交易）1楼风 (暗黑游)
    async getDatingList() {
      const res = await dating_category({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: 1,
        cityId: this.cityId
      })
      if (res.code === 200) {
        console.log('res.data', res.data)
        if (res.data.modelList && res.data.modelList.length && res.data.modelList.length <= this.pageSize) {
          this.mainList = [...this.mainList, ...res.data.modelList]
          this.loading = false
          this.refreshing = false
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
        }
      }
    },
    async getCityList() {
      const res = await city_list({})
      if (res.code === 200) {
        this.cityList = [{ id: 0, name: '全国' }, ...res.data.list]
      } else {
        return this.$toast(res.tip)
      }
    },
    // 顶部type列表请求
    async loufengType() {
      const res = await loufeng_type({})
      this.typeList = res.data.list
    },
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = false
      this.pageNum = 1
      this.mainList = []
      this.getDatingList()
    },
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getDatingList()
    },
    // 切换type
    changeType(item) {
      this.$router.push(`/trade/typeDteail/?louFengTypeID=${item.id}&title=${item.title}`)
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.AnHeiYou-container {
  padding-top: 2rem;

  .actor_list {
    @include flexbox($fw: wrap);

    li {
      margin-bottom: 0.24rem;
      width: 1.28rem;
      div {
        @include flexbox($fd: column);
      }

      p {
        margin-top: 0.24rem;
      }

      .actor_item {
        width: 1.28rem;
        height: 1.28rem;

        :deep() {
          img {
            border-radius: 50%;
          }
        }
      }
    }

    i {
      width: 1.28rem;
    }
  }
}

.announcement {
  font-size: 0.26rem;
  // @include flexbox($jc: space-between);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.36rem 0 0.3rem 0;
  .text-scroll-bar {
    flex: 1;
  }
  > div:nth-child(1) {
    display: flex;
    flex: 1;
  }
  .title {
    padding: 0.02rem 0.12rem;
    border-radius: 0.08rem;
    background: #00f6f8;
    white-space: nowrap;
    color: #333;
    flex: none;
  }
  :deep() .van-notice-bar {
    height: 0.44rem;
  }

  .scroll-text {
    overflow: hidden;
    margin-left: 0.12rem;
    flex: 1;
    // max-width: 4rem;
    .text {
      white-space: nowrap;
      animation: scroll-text 12s linear infinite;
    }
  }

  @keyframes scroll-text {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
}

.ah_list {
  @include flexbox($fw: wrap);
}

// 城市选择
.city_list {
  position: relative;
  // margin-left: 40%;
  flex: none;
  div {
    @include flexbox();
    width: 1rem;
  }

  border-radius: 0.06rem;
  border: 1px solid #535353;
  background: #292929;
  padding: 0.02rem 0.08rem;

  img:first-child {
    width: 0.2rem;
    height: 0.24rem;
    margin-right: 0.08rem;
  }

  img:last-child {
    width: 0.22rem;
    height: 0.2rem;
    margin-left: 0.08rem;
  }

  span {
    @include textoverflow();
    max-width: 100%;
  }

  .city_list_show {
    position: absolute;
    top: 0.4rem;
    left: 0.2rem;
    z-index: 10;
    border: 1px solid #535353;
    background: #292929;
    max-height: 4rem;
    overflow: auto;
    li {
      @include textoverflow();
    }
  }
}
</style>
  