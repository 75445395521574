<template>
  <div class="record-container">
    <EBackTop title="充值记录" background="transparent" color="#fff"></EBackTop>
    <van-pull-refresh  v-if="transList && transList.length>0" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad">
        <ul class="record-listWrap">
      <li class="item" v-for="(item, index) in transList" :key="index">
        <div class="top">
          <div class="top-right">
            <div class="orderNo">订单编号:{{ item.orderNo }}</div>
            <div class="createdAt">{{ item.createdAt | formatDate }}</div>
          </div>
          <div class="copy-btn" v-clipboard:copy="item.orderNo" v-clipboard:success="onCopy" v-clipboard:error="onError">
            <span>复制</span>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <div class="bottom-type">{{ item.currencyType===1?'金币充值':'VIP充值' }}</div>
          <div class="bottom-desc">{{ item.desc }}</div>
          </div>
          <div>
            -{{ item.coinAmount | changeMoneyYuan }}
          </div>
        </div>
      </li>
    </ul>
      </van-list>
    </van-pull-refresh>
  
    <Nodata :text="'您还没有记录哦～'" v-else/>
  </div>
</template>
<script>
import { recharge_list } from 'api/user'
export default {
  data() {
    return {
      // 上拉加载（loading:false代表可继续上拉加载）
      loading: false,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10
    }
  },

  components: {
    Nodata: () => import('@/components/JavNodata.vue'),
  },
  methods: {
    // 获取流水记录
    async get_transList() {
      try {
        const res = await recharge_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          if (res.data && res.data.list.length <= this.pageSize) {
            this.transList = [...this.transList, ...res.data.list]
            this.refreshing = false
          this.loading = false
          }
        } else {
          // 下拉消失（自动开启下拉）
          this.refreshing = false
          this.loading = false
          this.finished = true
         return this.$toast(res.tip)
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.transList = []
      this.get_transList()
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  },
  mounted() {
    this.get_transList()
  },

}
</script>
<style lang="scss" scoped>
.record-container {
  padding-top: 1rem;
  min-height: 100vh;
  background: url("../../../assets/imgs/mine/mine_top_bg.png") center center no-repeat;
  .record-listWrap {
    margin: 0.3rem 0;
    padding: 0 0.34rem;

    .item {
      border-radius: 0.12rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.42rem;
      margin-top: 0.32rem;
      .top {
        @include flexbox();
        border-bottom: 0.01rem solid #979797;
        padding-bottom: 0.24rem;
        .orderNo {
          font-size: 0.26rem;
        }

        .createdAt {
          color: #787979;
          font-size: 0.24rem;

        }
      }
      .bottom{
        margin-top: .33rem;
        @include flexbox();
        .bottom-left{
          @include flexbox($jc:flex-start);
        }
        .bottom-type{
          border-radius: 0.08rem;
background: rgba(190, 145, 85, 0.30);
padding: 0.04rem 0.16rem;
gap: 0.1rem;
        }
        .bottom-desc{
          margin:0 0.32rem 0 0.16rem;
          @include textoverflow();
        }
      }
    }
  }

  .copy-btn {
    border-radius: 0.08rem;
background: $btnBg;
    padding: 0.04rem 0.16rem;
    width: 0.96rem;
    height: 0.53rem;
    text-align: center;
    font-size: 0.32rem;
    white-space: nowrap;
  }
}</style>
