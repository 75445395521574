<template>
  <!--
使用方法
    <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="beforeRefresh" :moreData="onLoad" :finished="finished">
      <TopicShowType3 :list="mediaList" />
    </ERefreshLoadV3>

    this.$refs['ERefreshLoadV3'].onRefresh(); //手动调用刷新方法

      // 下拉刷新
    async beforeRefresh() {
      this.finished = false;
      this.pageNum = 0;
      this.mediaList = [];
    },

    // 上拉加载 (一定要异步请求数据 async await)
    async onLoad() {
      this.pageNum += 1;
      await this.getResultList();
    },

    finished 列表是否加载完成

    刷新时：只重置参数，后调用load请求数据，
    加载时：调用load请求数据
    注意：刷新列表板块最好设置最低高度，否则可能出现多次请求列表数据

    <template #loaddingLogo> 加载logo插槽 </template>
    <template #loading> 头部加载文字描述 </template>
    <template #loosing> 头部释放文字描述 </template>


-->
  <div class="pull-container">
    <van-pull-refresh ref="backup" v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh" :head-height="50" :disabled="disabledPull">
      <slot name="top"></slot>
      <!-- 加载提示 -->
      <template #loading>
        <slot name="loading">
          <div></div>
        </slot>
      </template>
      <template #loosing>
        <slot name="loosing"> 释放刷新... </slot>
      </template>
      <template #default>
        <slot name="loaddingLogo" v-if="refreshing">
          <ERefreshingPage />
        </slot>
        <van-list v-if="!refreshing" ref="list" v-model="loading" :finished="finished" :finished-text="finishedText" :immediate-check="false" @load="onLoad">
          <div>
            <slot></slot>
          </div>
        </van-list>
      </template>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: 'ERefreshLoadV3',
  props: {
    disabledPull: {
      type: Boolean,
      default() {
        return false;
      },
    },
    finished: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    moreData: {
      type: Function,
      required: true,
      default() {
        return async () => {};
      },
    },

    beforeRefresh: {
      type: Function,
      required: true,
      default() {
        return async () => {};
      },
    },
    autoOnload: {
      type: Boolean,
      default() {
        return true;
      },
    },
    finishedText:{
      type:String,
       default() {
        return '别划啦！我也是有底线的';
      },
    }
  },
  data() {
    return {
      loading: false,
      refreshing: false,
    };
  },
  mounted() {
    if (this.autoOnload) {
      this.onRefresh();
    }
  },

  methods: {
    async onRefresh() {
      await this.beforeRefresh();
      window.scrollTo(0, 0);
      this.refreshing = true;
      this.loading = true;
      await this.onLoad();
    },
    async onLoad() {
      await this.moreData();
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.pull-container {
  position: relative;
}
</style>
