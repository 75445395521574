<template>
    <div class="contact-container">
      <EBackTop title="官方群组" background="#000" color="#fff"></EBackTop>
      <ul >
        <li @click="openPage(item)" class="item" v-for="(item,index) in contactList" :key="index">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item.cover" />
        </li>
      </ul>
    </div>
  </template>
  <script>
  import {config_contact} from 'api/user'
  export default {
    data() {
      return {
        contactList:[]
      }
    },
  
    components: {
    },
    methods: {
      //获取联系方式
      async getConfigContact(){
        const res = await config_contact({
        })
        if (res.code === 200) {
          this.contactList=res.data;
        } else {
         this.$toast(res.tip);
        }
  
      },
      //打开页面
      openPage(item){
        if(item.jumpUrl) window.open(item.jumpUrl, '_blank');
      }
    },
    mounted() {
      this.getConfigContact();
    },
    computed: {
      imgCDN({ $store }) {
        return $store.getters['cdn']
      }
    },
  }
  </script>
  <style lang="scss" scoped>
  .contact-container{
  background: url("../../../assets/imgs/mine/mine_top_bg.png") center center no-repeat;
  min-height: 100vh;
    ul{
      display: flex;
      flex-flow: wrap;
      padding-top: .6rem;
      .item{
        width: 2.28rem;
        height: 2.28rem;
        margin: 0 .57rem .8rem .9rem;
        :deep() {
          .warp{
            font-size: 0;
  
            background-color: #fff;
          }
        }
      }
    }
  
  }
  </style>
  