<template>
  <div class="an_hei_you_detail">
    <EBackTop :title="''" background="transparent" :openFallback="true" class="backtop">
      <slot slot="left" name="left">
        <img class="left_icon" src="@/assets/imgs/trade/yuan_back.png" alt="" />
      </slot>
      <slot slot="right" name="right">
        <div class="collent_btn share_btn" @click="clickShare">分享</div>
        <div class="collent_btn" @click="checkRight">{{ detailData.details.isCollect ? '已收藏' : '+ 收藏' }}</div>
      </slot>
    </EBackTop>
    <!-- 妹子图片轮播图 -->
    <JavSwiper
      ref="JavSwiper"
      :shoudPlay="detailData.details.videoUrl"
      :videoCover="detailData.details.videoCover"
      @clickImg="clickImg"
      :autoplay="0"
      class="ww-swiper"
      :imgs="detailData.details.images"
      :height="'8.65rem'"
    />

    <!-- 详细信息 -->
    <div class="anheijiaoyi_detail_main">
      <!-- 名字 -->
      <div class="name_box">
        <div>{{ detailData.details.title }}</div>
        <div class="options_name">
          <span v-if="findCategory[0]">{{ findCategory ? findCategory[0]?.title : '女优' }}</span>
          <div class="options">
            <img src="@/assets/imgs/options_icon.png" alt="" />
            <p>{{ detailData.details.cityName }}</p>
          </div>
        </div>
      </div>

      <!-- 女优详细信息 -->
      <ul class="actor_detail_info">
        <li>
          <!-- 商家信息 -->
          <div class="store_box" @click="$router.push(`/mine/myInfo?id=${detailData.bossInfo.id}`)">
            <EDecryptImg
              :imgCDN="imgCDN"
              :imgURL="detailData?.bossInfo?.avatar ? detailData?.bossInfo?.avatar : ''"
              class="store_avatar"
            >
            </EDecryptImg>
            <div class="store_name">
              <p>{{ detailData.bossInfo.name }}</p>
              <p>信息平台认证,真实可靠,可放心约!</p>
            </div>
          </div>
        </li>
        <li>
          <div>
            <span class="label">联系方式</span>
            <span @click="payFn" class="info" :class="!detailData.details.isBuy ? 'showBg' : ''">{{
              detailData.details.isBuy ? detailData.details.contactInfo : '购买后可查看联系方式'
            }}</span>
            <span
              class="label label-copy"
              v-if="detailData.details.isBuy"
              @click="doCopy(detailData.details.contactInfo)"
              >复制</span
            >
          </div>
        </li>
        <li class="body_info">
          <div>
            <span class="label">年龄</span>
            <span class="info">{{ detailData.details.girlAge }}</span>
          </div>
          <div>
            <span class="label">身高</span>
            <span class="info">{{ detailData.details.girlHeight }}</span>
          </div>
          <div>
            <span class="label">体重</span>
            <span class="info">{{ detailData.details.girlWeight }}</span>
          </div>
          <div>
            <span class="label">胸围</span>
            <span class="info">{{ detailData.details.cupType }}</span>
          </div>
          <div>
            <span class="label">服务价格</span>
            <span class="info">{{ detailData.details.price }}</span>
          </div>
        </li>
        <li>
          <div>
            <span class="label">项目</span>
            <span class="info">{{ detailData.details.serviceItems }}</span>
          </div>
        </li>

        <li class="tips_list">
          <span class="label label_title">预约流程</span>
          <p>1.支付预约金之后，获得经纪人的联系方式</p>
          <p>2.联系经纪人确定约妹时间、地点，在线选妹妹</p>
          <p>3.与妹妹见面满意，则付尾款(全款减去预约金)给妹妹</p>
        </li>
      </ul>
    </div>

    <!-- 底部按钮 -->
    <div class="footer-btn">
      <div class="top_tips">
        <span>账户余额:{{ userInfo.balance | changeGold }}金币</span>
        <span @click.stop="$router.push('/mine/myWallet')">去充值>></span>
      </div>
      <ul>
        <li class="default_btn" @click="$router.push('/mine/myOrder')">
          <img src="@/assets/imgs/trade/menu.png" alt="" />订单
        </li>
        <li class="pay_btn">
          <div @click="payFn" v-if="!detailData.details.isBuy">
            <img src="@/assets/imgs/trade/lock.png" alt="" />支付{{ detailData.details.infoPrice | changeGold }}金币解锁
          </div>
          <div v-else>已解锁</div>
        </li>
        <li class="default_btn" @click="$router.push('/mine/myShouyi')">
          <img src="@/assets/imgs/trade/share1.png" alt="" />分享
        </li>
      </ul>
    </div>
    <!-- 购买弹窗 -->
    <DmPopup ref="popupMsg"></DmPopup>
    <SharePopup
      :sharePopShow="showShare"
      @close="
        e => {
          showShare = e
        }
      "
    ></SharePopup>
  </div>
</template>
  
<script>
import { dating_collect, loufeng_detail, dating_pay, loufeng_type } from 'api/dating'
import { ImagePreview } from 'vant'
export default {
  components: {
    JavSwiper: () => import('@/components/JavSwiper.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    SharePopup: () => import('@/components/SharePopup.vue')
  },
  data() {
    return {
      detailData: {
        details: {},
        bossInfo: {}
      },
      typeList: [],
      findCategory: [],
      showShare: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  async mounted() {
    await this.getLfdetail()
    await this.loufengType()
  },

  methods: {
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    clickShare() {
      this.showShare = true
    },
    // 顶部type列表请求
    async loufengType() {
      const res = await loufeng_type({})
      this.typeList = res.data.list
      this.findCategory = this.typeList.filter(item => item.id == this.detailData.details.datingUserTypeID)
    },
    // 收藏信息
    async checkRight() {
      try {
        const res = await dating_collect({
          collect: !this.detailData.details.isCollect,
          objectId: this.detailData.details.id,
          type: 6
        })
        if (res.code === 200) {
          this.detailData.details.isCollect = !this.detailData.details.isCollect
          if (this.detailData.details.isCollect) {
            return this.$toast('收藏成功')
          } else {
            return this.$toast('取消收藏')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 获取妹子详情
    async getLfdetail() {
      try {
        const res = await loufeng_detail({
          id: +this.$route.query.id,
          datingType: 1
        })
        if (res.code === 200) {
          this.detailData = res.data
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },

    // 图片预览
    clickImg(imgUrl) {
      const imgArr = []
      let index = 0
      const domArr = this.$refs['JavSwiper'].$refs['refImg']
      domArr.forEach((itemBlob, indexBlob) => {
        if (imgUrl === itemBlob.imgURL) {
          index = indexBlob
        }
        imgArr.push(itemBlob.realUrl)
      })
      ImagePreview({
        images: imgArr, // 需要预览的图片 URL 数组
        showIndex: true, // 是否显示页码
        loop: true, // 是否开启循环播放
        startPosition: index // 图片预览起始位置索引
      })
    },

    // 购买弹窗购买按钮
    async payFn() {
      try {
        if (this.userInfo.balance < this.detailData.details.infoPrice) {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '注意',
            content: '当前余额不足',
            type: 'up_post',
            ok: '购买VIP',
            img: '',
            cancel: '充值金币',
            position: 'center',
            showBalance: false,
            cb: async returnData => {
              this.$router.push('/mine/vip')
            },
            close: async returnData => {
              this.$router.push('/mine/myWallet')
            }
          }
        } else {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '确认支付',
            showBalance: true,
            type: 'pay_post',
            ok: '立即支付',
            img: this.detailData.details.videoCover
              ? this.detailData.details.videoCover
              : this.detailData.details.images[0],
            price: this.detailData.details.infoPrice,
            cancel: '狠心离开',
            position: 'center',
            tip: this.detailData.details.title,
            cb: async returnData => {
              const res = await dating_pay({
                objectId: this.detailData.details.id,
                type: 1
              })
              if (res.code === 200) {
                this.detailData.details.isBuy = true
              } else {
                return this.$toast(res.tip)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    }
  },
  beforeDestroy() {}
}
</script>
  
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.an_hei_you_detail {
  padding-bottom: 2rem;

  .backtop {
    position: fixed;
    width: 100%;
    height: 0.9rem;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 10;
  }
}

.left_icon {
  width: 0.6rem;
  height: 0.6rem;
}

.collent_btn {
  display: inline-flex;
  padding: 0.08rem 0.24rem;
  align-items: center;
  gap: 0.04rem;
  border-radius: 0.32rem;
  background: $btnBg;
  color: #333;
}
.share_btn {
  margin-right: 0.2rem;
}
.label-copy {
  margin-left: 0.1rem;
}

.anheijiaoyi_detail_main {
  border-radius: 0.32rem 0.32rem 0rem 0rem;
  background: #212223;
  padding: 0.48rem 0.36rem;

  .name_box {
    @include flexbox();
    font-size: 0.36rem;
    .options_name {
      @include flexbox($jc: flex-start);
      flex: none;
    }
    span {
      border-radius: 0.08rem;
      background: $btnBg;
      color: #333;
      padding: 0.04rem 0.12rem;
      margin-left: 0.24rem;
      font-size: 0.24rem;
      white-space: nowrap;
      margin-right: 0.2rem;
    }
    .options {
      @include flexbox($jc: flex-start);
      font-size: 0.26rem;
      img {
        width: 0.18rem;
        height: 0.22rem;
        margin-right: 0.08rem;
      }
    }
  }
}

.actor_detail_info {
  li {
    padding: 0.36rem 0;
    border-top: 0.01rem solid rgba($color: #979797, $alpha: 0.3);
    color: #787979;
    font-size: 0.26rem;
    font-weight: 400;

    &:first-child {
      padding: 0.44rem 0 0.4rem 0;
      margin-top: 0.44rem;
    }
  }

  .label {
    border-radius: 0.08rem;
    background: rgba(0, 246, 248, 0.1);
    padding: 0.04rem 0.08rem;
    gap: 0.1rem;
    color: $btnBg;
    margin-right: 0.24rem;
  }

  .body_info {
    @include flexbox($fw: wrap);
    padding-top: 0.12rem;

    div {
      width: 50%;
      margin-top: 0.24rem;
    }
  }

  .tips_list {
    .label_title {
      margin-right: 0;
      margin-bottom: 0.16rem;
      display: inline-block;
    }

    p {
      margin-bottom: 0.16rem;
    }
  }
}

.store_box {
  border-radius: 0.12rem;
  background: #333;
  @include flexbox($jc: flex-start);
  padding: 0.24rem 0.32rem;

  .store_avatar {
    margin-right: 0.16rem;
    width: 0.72rem;
    height: 0.72rem;

    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }

  .store_name {
    color: $btnBg;
    font-size: 0.28rem;
    font-weight: 400;

    p:last-child {
      color: #bcbcbc;
      font-size: 0.22rem;
    }
  }
}

.footer-btn {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: $pcMaxWidth;
  height: 1.92rem;
  padding: 0.23rem 0.36rem;
  background: #212223;
  border-top: 0.01rem solid #575757;

  .top_tips {
    span:last-child {
      color: #f9c006;
      font-size: 0.24rem;
      display: inline-block;
      margin-left: 0.31rem;
    }
  }

  ul {
    @include flexbox();
    margin-top: 0.25rem;
  }

  .default_btn {
    @include flexbox($fd: column, $jc: center);

    img {
      height: 0.4rem;
    }
  }

  .pay_btn {
    padding: 0.12rem 0.64rem;
    border-radius: 0.56rem;
    background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);

    div {
      @include flexbox($jc: center);
    }

    img {
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.12rem;
    }
  }
}

.showBg {
  border-radius: 0.08rem;
  border: 1px solid #00f6f8;
  background: rgba(0, 246, 248, 0.1);

  color: #fff;
  padding: 0.04rem 0.16rem;
}
</style>
  