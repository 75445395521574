<template>
  <div class="changeFace">
    <!-- <EBackTop :background="'transparent'" class="mine-head" color="#fff" title="AI换脸">
      <template #right>
        <van-icon name="wap-nav" color="#ffffff" size="25" @click="isShowPop = !isShowPop" />
      </template>
    </EBackTop> -->
    <AiHeader />
    <div class="changeFace-box">
      <div class="white-slik" ref="whiteSlik">
        <!-- <img src="@/assets/imgs/aiLab/changeFace.png" alt="" /> -->
        <img
          v-if="$route.query.type == 'video'"
          @click="fn_play_video(changeFaceItem)"
          class="play_icon"
          src="@/assets/imgs/trade/play.png"
          alt=""
        />
        <EDecryptImg :imgCDN="imgCDN" class="resetImg" :imgURL="changeFaceItem.picture" ref="refImg" />
      </div>
      <div class="bottom-round" ref="bottomRound">
        <div class="up-material">
          <div>上传您的素材</div>
          <div @click="showModal = !showModal">
            <img src="@/assets/imgs/aiLab/section3/svg3.svg" alt="" />
            <span>素材示范</span>
          </div>
        </div>
        <div class="insert-photo">
          <div class="cover_load">
            <UploadImgs
              @deleteImgs="handleDeleteImg"
              :isShowAdd="false"
              class="cover_bg"
              ref="coverLoad"
              :maxLength="1"
              @result="resultCover"
            />
          </div>
          <div class="button-group">
            <!-- <div @click="handle_choose">
            </div> -->
            <div>
              <van-button type="default" @click="handle_choose" :disabled="uploadLoading">+ &nbsp; 插入图片</van-button>
              <van-button type="default" @click="checkBalance" :disabled="loading">生成</van-button>
            </div>
            <div>
              <div>
                <img src="@/assets/imgs/coin2.png" alt="" />
                <span>扣除金币</span>
              </div>
              <span>-{{ changeFaceItem.price / 100 }}</span>
            </div>
          </div>
        </div>
        <div class="tips">
          <div>注意事项</div>
          <div>
            <div>
              1.选择一张人脸清晰，
              <span> 不得有任何遮挡的照片 </span>
              上传（注意：只含有一个人物和脸部，图片不能过暗）
            </div>
            <div>
              2.选择一个心仪的视频/图片模版，点击生成，生成时间需要{{
                $route.query.type == 'video' ? '3-10' : '3-5'
              }}分钟，耐心等待。
            </div>
            <div>3.在右上角记录查看生成进度，生成成功后可以点击进行下载，也可以在线观看</div>
            <div>
              4.
              <span> 不支持多人图片，禁止未成年人图片</span>
              <i>(后台检测封号)</i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showModal" position="bottom" class="error-tips-pop1">
      <div class="up-material">
        <div>素材规范</div>
        <div>
          <img src="@/assets/imgs/aiLab/error_tips.svg" alt="" />
          <span style="color: #fff">
            上传素材需要为正脸,且无遮挡,无表情最好, <span style="color: red">严禁使用未成年素材</span>
          </span>
        </div>
        <ul class="errorTipsList">
          <li v-for="(item, index) in errorTipsList" :key="index">
            <img :src="item.img" alt="" />
            <img :src="item.icon" alt="" />
            <div>{{ item.text }}</div>
            <!-- <div v-if="index === 0" style="color: #f9c006">(比基尼最佳)</div> -->
          </li>
        </ul>
      </div>
    </van-popup>
    <ComPlayVideo ref="ComPlayVideo" />
    <ChangeFace :isShow="isShowPop" />
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { aiorder_facechange_submit } from '@/api/ai'
export default {
  name: 'changeFace',
  components: {
    UploadImgs: () => import('@/components/UploadImgSpecial/index.vue'),
    ComPlayVideo: () => import('@/components/aiLab/video.vue'),
    ChangeFace: () => import('../components/leftPopup.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    AiHeader: () => import('../components/header.vue')
  },
  data() {
    return {
      cover: [],
      showModal: false,
      errorTipsList: [
        {
          img: require('@/assets/imgs/aiLab/error1.png'),
          icon: require('@/assets/imgs/aiLab/corret_icon.svg'),
          text: '正朝向,素材清晰,无遮挡'
        },
        {
          img: require('@/assets/imgs/aiLab/error2.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '素材清晰,但是不是正脸'
        },
        {
          img: require('@/assets/imgs/aiLab/error3.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '像素太过模糊'
        },
        {
          img: require('@/assets/imgs/aiLab/error4.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '脸部被遮挡'
        },
        {
          img: require('@/assets/imgs/aiLab/error5.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '表情过于浮夸'
        },
        {
          img: require('@/assets/imgs/aiLab/error6.png'),
          icon: require('@/assets/imgs/aiLab/error_icon.svg'),
          text: '多人'
        }
      ],
      loading: false,
      uploadLoading: false,
      isShowPop: false
    }
  },
  methods: {
    handleDeleteImg() {
      this.uploadLoading = false
    },
    fn_play_video(item) {
      this.$refs.ComPlayVideo.fn_playVideo(item.videoUrl, item.videoCover)
    },
    resultCover(imgs) {
      this.cover = imgs
      this.uploadLoading = true
    },
    handle_choose() {
      this.$refs.coverLoad.chooseFile()
    },

    checkBalance() {
      if (!this.cover.length) {
        this.$toast('请先插入图片')
        return
      }
      if (this.userInfo.balance < this.changeFaceItem.price && this.userInfo.aiFreeChangeFaceCount <= 0) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '对不起,您的余额不足',
          type: 'change-face-post',
          ok: '前往充值',
          cancel: '做任务得VIP',
          position: 'center',
          showBalance: true,
          balance: this.userInfo.balance,
          price: this.changeFaceItem.price,
          cb: async returnData => {
            this.$router.push('/mine/myWallet')
          },
          close: async returnData => {
            this.$router.push({
              path: '/mine/sigin',
              query: {
                type: 1
              }
            })
          }
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '确认支付',
          showBalance: true,
          type: 'change-face-enough',
          ok: '使用金币',
          price: this.changeFaceItem.price,
          cancel: this.$route.query.type == 'video' ? '' : `使用兑换卷:${this.userInfo.aiFreeChangeFaceCount}`,
          position: 'center',
          balance: this.userInfo.balance,
          cb: async returnData => {
            // this.shoudBuy(item)
            this.submitOrder(0)
          },
          close: async returnData => {
            if (this.userInfo.aiFreeChangeFaceCount) {
              this.submitOrder(1)
            } else {
              this.$router.push({
                path: '/mine/sigin',
                query: {
                  type: 1
                }
              })
            }
          }
        }
      }
    },
    async submitOrder(action) {
      try {
        const { type, id } = this.$route.query
        const item = {
          aoContentType: type == 'photo' ? 1 : 2,
          sourceImgs: this.cover,
          tplId: id,
          payType: action
        }
        const res = await aiorder_facechange_submit(item)
        if (res.code == 200) {
          this.$toast('上传成功！请到我的生成查看进度')
          this.loading = true
          this.$store.dispatch('get_userInfo')
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    changeFaceItem({ $store }) {
      return $store.getters['getChangeFaceItem']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    // this.getWhiteSlikHeight()
  }
}
</script>

<style lang="scss" scoped>
.changeFace-box {
  // padding: 0.6rem 0.36rem 0;
  position: relative;
  .bottom-round {
    padding: 0.6rem 0.36rem 0;
    // border-radius: 0.42rem 0.42rem 0rem 0rem;
    // background: red;
    position: absolute;
    left: 0;
  }
  .white-slik {
    width: 100%;
    // height: 9rem;
    // background: url('../../../assets/imgs/aiLab/changeFace.png') center center no-repeat;
    // background-size: contain;
    // border-radius: 0.12rem;
    position: relative;
    > img {
      width: 100%;
    }
    > .play_icon {
      width: 1.28rem;
      height: 1.28rem;
      position: absolute;
      left: 50%;
      top: 50%;
      @include transformCenter;
      z-index: 1;
    }
  }
  .up-material {
    margin-top: 0.5rem;
    @include flexbox;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;
        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #00f6f8;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }
      &:nth-child(2) {
        color: #f9c006;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .insert-photo {
    margin-top: 0.5rem;
    display: flex;

    justify-content: space-between;
  }
  .cover_load {
    width: 3rem;
    height: 3rem;
    border-radius: 0.12rem;
    border: 1px dashed #535353;
    background: #111;
    :deep() .img-item {
      width: 3rem;
      height: 3rem;
      margin-right: 0rem !important;
      margin-top: 0 !important;
      border-radius: 0.08rem !important;
      // margin-right: 0.32rem;
      img {
        border-radius: 0.08rem !important;
      }
    }
    .cover_bg {
      // margin-bottom: 0.2rem;
      // margin-left: 0.3rem;
      .video_title {
        margin-bottom: 0.38rem;
      }
      :deep() {
        .uploadImg-wrap {
          width: 3rem !important;
          height: 3rem !important;
        }
        .img-item {
          width: 3rem !important;
          height: 3rem !important;
          margin-top: 0 !important;
        }
        .upload-img-item {
          margin: 0 !important;

          img {
            width: 0.84rem !important;
            height: 0.84rem !important;
            @include transformCenter;
            position: absolute;
            left: 50%;
            top: 50%;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      &:nth-child(1) {
        padding-top: 0.12rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
      }
      &:nth-child(2) {
        display: flex;
        flex: none;
        align-items: center;
        color: #ffcf86;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.2rem;
        > div {
          border-radius: 0.35rem;
          border: 1.2px dashed #777;
          padding: 0.06rem 0.16rem;
          margin-right: 0.27rem;
          display: flex;
          align-items: center;
        }
        > span {
          font-size: 0.4rem;
        }
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.1rem;
        }
      }
    }
    button {
      width: 3.19rem;
      height: 0.9rem;
      border-radius: 0.06rem;
      border: 1px solid #535353;
      background: #333;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:nth-of-type(2) {
        background: #00f6f8;
        color: #333;
        font-family: PingFang SC;
        font-size: 0.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.03rem;
      }
    }
  }
  .tips {
    margin-top: 0.7rem;
    padding-bottom: 0.7rem;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;
        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #00f6f8;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }
      &:nth-child(2) {
        color: rgba(255, 255, 255, 0.6);
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.31rem;
      }
      > div > span {
        color: #00f6f8;
      }
      i {
        color: #d20000;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.1rem;
      }
    }
  }
}

.error-tips-pop1 {
  padding: 0.6rem 0.36rem;
  border-radius: 0.42rem 0.42rem 0rem 0rem;
  border: 1px solid #535353;
  background: #292929;
  .up-material {
    display: block;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include flex-align-center;
        &::before {
          content: '';
          width: 0.12rem;
          height: 0.25rem;
          background-color: #00f6f8;
          display: block;
          border-radius: 0.12rem;
          margin-right: 0.16rem;
        }
      }
      &:nth-child(2) {
        margin: 0.34rem 0 0.47rem;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        > img {
          width: 0.28rem;
          margin-right: 0.09rem;
        }
      }
    }
  }

  .errorTipsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.15rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1.8rem;
      margin-right: 0.51rem;
      text-align: center;
      color: #fff;
      text-align: center;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.34rem;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    img {
      &:nth-of-type(1) {
        height: 1.9rem;
        border-radius: 0.12rem;
      }
      &:nth-of-type(2) {
        width: 0.32rem;
        height: 0.32rem;
        margin: 0.15rem 0 0.06rem;
      }
    }
  }
}
</style>