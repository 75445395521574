<template>
  <div class="upload-img-box">
    <EDecryptImg
      :imgCDN="imgCDN"
      class="img-item"
      v-for="(item, index) in upLoadImgs"
      :key="index"
      :imgURL="item"
      ref="refImg"
      @click.native="imagePreview(index)"
    >
      <div class="close-icon">
        <img src="@/assets/imgs/trade/close.png" class="colse" @click.stop="colse(index)" alt="" />
      </div>
    </EDecryptImg>
    <van-uploader
      v-if="!upLoadImgs.length"
      class="uploadImg-wrap"
      :after-read="onReadImg"
      ref="Uploader"
      multiple
      accept="image/*"
    >
      <div class="upload-img-item img-item">
        <img class="add-img" src="@/assets/imgs/trade/video-add.png" alt="" />
        <span class="add-text" v-if="isShowAdd">添加封面</span>
      </div>
    </van-uploader>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { imgsCompress } from '@/utils/utils_tools'
import { uploadImgs } from 'api/home'
export default {
  components: {},
  props: {
    imgs: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 8
    },
    isShowAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      upLoadImgs: [] // 已上传图片
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  watch: {
    imgs(n) {
      this.upLoadImgs = this.imgs
    }
  },

  methods: {
    chooseFile() {
      this.$refs.Uploader.chooseFile()
    },
    // 图片预览
    imagePreview(indexImg) {
      const imgArr = []
      const domArr = this.$refs['refImg']
      domArr.forEach(item => {
        imgArr.push(item.realUrl)
      })
      ImagePreview({
        images: imgArr, // 需要预览的图片 URL 数组
        showIndex: true, // 是否显示页码
        loop: true, // 是否开启循环播放
        startPosition: indexImg // 图片预览起始位置索引
      })
    },
    // 上传多图
    async onReadImg(file) {
      if (this.upLoadImgs && this.upLoadImgs.length >= this.maxLength) return this.$toast('最多传1张')
      // 组合
      const formData = new FormData()
      let newBlobList = []
      if (!file.length) {
        newBlobList = await imgsCompress([file])
      } else {
        newBlobList = await imgsCompress(file)
      }
      for (let index = 0; index < newBlobList.length; index++) {
        formData.append('upload[]', newBlobList[index])
      }
      // 上传
      this.$toast.loading({ duration: 0, message: '图片上传中请稍后' })
      const res = await uploadImgs(formData)
      if (res.code === 200) {
        this.$toast.clear()
        this.$toast('上传成功')
        this.upLoadImgs.push(...res.data.paths)
        this.$emit('result', this.upLoadImgs)
      } else {
        this.$toast(res.tip || res.msg)
      }
    },
    // 获取上传文件信息，并调上传接口
    async tirggerFile(event) {
      // console.log(event)
      // return
      // 获取当前选中的文件
      if (event.target.files[0]) {
        const file = event.target.files[0]
        const imgMasSize = 1024 * 1024 * 4 // 10MB
        // 检查文件类型
        if (['jpeg', 'png', 'gif', 'jpg'].indexOf(file.type.split('/')[1]) < 0) {
          // 自定义报错方式
          this.$toast('文件类型仅支持 jpeg/png/gif！')
          return
        }
        // 文件大小限制
        if (file.size > imgMasSize) {
          // 文件大小自定义限制
          this.$toast('文件大小不能超过4MB！')
          return
        }
        const formData = new FormData()
        // 自定义formData中的内容
        // type
        formData.append('type', file.type)
        // size
        formData.append('size', file.size || 'image/jpeg')
        // name
        formData.append('name', file.name)
        // lastModifiedDate
        formData.append('lastModifiedDate', file.lastModifiedDate)
        // append 文件
        formData.append('upload', file)
        // 已上传图片
        const res = await uploadImgs(formData)
        if (res.code === 200) {
          if (this.upLoadImgs) {
            this.upLoadImgs.map((item, index) => {
              if (item === res.data.path) {
                this.upLoadImgs.splice(index, 1)
              }
            })
          }
          this.upLoadImgs.push(res.data.path)
          this.$emit('result', this.upLoadImgs)
          this.$refs.filElem.value = null
        } else {
          this.$toast('上传失败')
        }
      }
    },
    // 取消上传
    colse(index) {
      this.upLoadImgs.splice(index, 1)
      this.$emit('deleteImgs')
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.upload-img-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .img-item {
    width: 2.18rem;
    height: 2.18rem;
    position: relative;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
    .close-icon {
      position: absolute;
      top: -0.2rem;
      right: 0rem;

      > img {
        width: 0.24rem;
        height: 0.24rem;
      }
    }
  }
  .upload-img-item {
    width: 2.18rem;
    height: 2.18rem;
    border-radius: 0.08rem;
    border: 1px solid #535353;
    background: #292929;
    .add-img {
      width: 0.42rem !important;
      height: 0.42rem !important;
      position: absolute;
      left: 0.62rem;
      top: 0.28rem;
    }
    .add-text {
      position: absolute;
      left: 50%;
      bottom: 0.24rem;
      border-radius: 0.04rem;
      background: #f9c006;
      transform: translate(-50%, 0);
      padding: 0.02rem 0.12rem;
      height: 0.35rem;
      white-space: nowrap;
      color: #333;
      font-family: PingFang SC;
      font-size: 0.22rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
