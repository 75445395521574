<!-- home -->
<template>
  <div class="trade-container">
    <EBackTop :background="'#000'" class="mine-head" color="#fff"> </EBackTop>
    <!--切换面板 -->
    <ETab
      class="container-tab"
      @change="change"
      :sticky="true"
      :tabList="topTabLabelList"
      :v-model="type"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#00F6F8'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#fff'"
      :title-inactive-color="'#fff'"
      :sizeTab="'0.3rem'"
      :sizeActive="'.3rem'"
      :activeId="type"
    >
      <template v-slot:default="scope">
        <component :is="compComponent(scope.data)"></component>
      </template>
    </ETab>
  </div>
</template>
    
    <script>
import scrollMixin from '@/utils/scrollMixin'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      topTabLabelList: [
        { showType: 0, name: '分享邀请' },

        { showType: 1, name: '代理赚钱' },
  
      ],
      showUpBtn: false
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Default: () => import('./components/default.vue'),
    Share: () => import('./components/share.vue')
  },
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取顶部分类
    category({ $store }) {
      return $store.state.config.config.mediaCategory
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  created() {
    this.showUpBtn = false
    if (this.$route.query.type) {
      console.log('123', 123)
      this.type = Number(this.$route.query.type)
    }
  },
  methods: {
    change(type) {
      this.type = type
      this.showUpBtn = false
    },

    //  0:暗黑游  1:帝王调教 模版 2:暗网交易
    compComponent(data) {
      switch (data) {
        case 1:
          return 'Default'
    
        default:
          return 'Share'
      }
    }
  },
  beforeDestroy() {}
}
</script>
    <style lang="scss" scoped>
.trade-container {
  min-height: $minHeight;
  .mine-head {
    :deep() {
      .van-nav-bar {
        z-index: 98;
      }
    }
  }
  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: 1rem;
        right: 0;
        z-index: 99;
        width: 100%;

        max-width: $pcMaxWidth;
      }
      .van-tabs__nav {
        justify-content: flex-start;
        background-color: #000;
      }
      .van-tab {
        flex: none;
      }
    }
  }
}
</style>
    