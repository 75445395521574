<template>
  <div class="pull-container">
    <van-pull-refresh
      ref="backup"
      v-model="childRefreshing"
      @refresh="onRefresh"
      :disabled="$route.meta.isCloseRefresh || disabled"
    >
      <slot name="top"></slot>
      <van-list
        v-model="childLoading"
        :finished="childFinished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
        v-if="skeleton"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: 'PullUp',
  components: {
  },
  props: {
    loading: {
      Type: Boolean,
      default() {
        return false
      }
    },
    refreshing: {
      Type: Boolean,
      default() {
        return false
      }
    },
    finished: {
      Type: Boolean,
      default() {
        return false
      }
    },
    skeleton: {
      Type: Boolean,
      default() {
        return true
      }
    },
  
  },
  data() {
    return {
      isShowGoback: false,
      scrollTop: 0,
      childLoading: false,
      childRefreshing: false,
      disabled: false,
      childFinished:false
    }
  },
  methods: {
    onLoad() {
      this.$emit('moreData', this.childLoading)
    },
    onRefresh() {
      console.log(this.childRefreshing,'this.childRefreshing');
      // 重新加载数据
      this.$emit('refreshData', this.childRefreshing)
    }
  },
  watch: {
    loading: {
      handler(n, o) {
        this.childLoading = n
      }
    },
    refreshing: {
      handler(n, o) {
        this.childRefreshing = n
      }
    },
    finished:{
      handler(n,o){
        this.childFinished = n
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pull-container {
  position: relative;
  
}
</style>
