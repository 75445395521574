<template>
  <div class="my_post">
    <EBackTop :title="'我的发布'" background="#000" color="#fff"> </EBackTop>
    <ETab class="myPost-tab" @rendered="rendered" @change="change" :sticky="true" :tabList="category"
      :activeIndex="activeSort" :animated="false" :lineSwitch="true" :lineBg="'#00F6F8'" :lineHeight="'0.08rem'"
      :line-width="'0.4rem'" :title-active-color="'#fff'" :title-inactive-color="'#fff'" :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'" :activeId="activeId">
      <template v-slot:default="scope">
        <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
          :refreshing="refreshing">
          <div v-if="activeSort == 2">
            <div class="cash-gold">
              <div>
                <span>可提现收益:</span>
                <span>{{ income }}金币</span>
              </div>
              <div class="bottom-btn">
                <van-button @click="toPage(`/mine/myShouyi/withdraw?title=收益提现`)">立即提现</van-button>
                <van-button @click="toPage('/mine/myShouyi/income')">收益明细</van-button>
              </div>
            </div>
            <div>
              <CommunityDefault v-for="(item, index) in myPostList" :key="index" :itemData="item" :isShowAudit="true" />
            </div>
          </div>
          <!-- 列表 -->
          <ul class="ah_list" v-else>
            <li v-for="item in myPostList" :key="item.id" class="diWangTj-order-item">
              <EDecryptImg :imgCDN="imgCDN" :imgURL="item.imageUrls ? item.imageUrls[0] : ''" class="item-bg">
              </EDecryptImg>
              <div class="order_item_right">
                <p>
                  {{ item.title }}
                </p>
                <p>创建时间:{{ item.createdAt | dateFormat08 }}</p>
                <p class="line"></p>
                <div class="top_pay_type">
                  <div v-if="item.reviewStatus === 0 || item.reviewStatus === 3" @click="toUpdate">
                    <img src="@/assets/imgs/trade/fix_icon.png" alt="" />
                    修改
                  </div>
                  <div class="active_txt" v-if="item.reviewStatus === 4">{{ item.remark }}</div>
                  <span :class="item.reviewStatus === 1 ? 'success' : item.reviewStatus === 4 ? 'error' : 'default'">{{
                    item.reviewStatus === 1 ? '审核通过' : item.reviewStatus === 4 ? '审核拒绝' : '待审核'
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </PullUp>
      </template>
    </ETab>
  </div>
</template>
    
<script>
import { user_publish, user_post_publish } from '@/api/dating'
import { income_detail } from '@/api/user'

export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    CommunityDefault: () => import('@/components/Community/default.vue'),
    PullUp: () => import('@/components/PullUp.vue')
  },
  data() {
    return {
      activeSort: 6,
      refreshing: false,
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      category: [
        { id: 6, name: '嫩模空降' },
        { id: 4, name: '好茶约炮' },
        { id: 2, name: '帖子' }
      ],
      status: 1,
      myPostList: [],
      income: 0,
      activeId: 0
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    getUserInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    // this.refreshData()
  },
  methods: {
    refreshData(refreshing) {
      this.refreshing = true
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.myPostList = []
      console.log('this.', this.activeSort)
      if (this.activeSort == 2) {
        this.getMyPostPublic()
      } else {
        this.getMyPost()
      }
    },
    moreData(loading) {
      this.loading = false
      this.pageNum += 1
      if (this.activeSort == 2) {
        this.getMyPostPublic()
      } else {
        this.getMyPost()
      }
    },
    rendered() {
      this.refreshData()
    },
    change(type) {
      this.activeSort = type
    },
    toUpdate() {
      this.$router.push({
        path: '/trade/postTransaction',
        query: {
          indexType: this.activeSort,
          id: 0,
          postType: 'video'
        }
      })
    },
    async getMyPost() {
      // this.getIncome()
      const res = await user_publish({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        modelType: this.activeSort
      })
      if (res.code == 200) {
        if (res.data.list && res.data.list.length) {
          this.myPostList = [...this.myPostList, ...res.data.list]
          this.loading = false
          this.refreshing = false
          if (this.pageSize < res.data.length) {
            this.finished = true
          }
        } else {

          this.finished = true
        }
      }
    },
    async getMyPostPublic() {
      const res = await user_post_publish({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.getUserInfo.id
      })
      if (res.code == 200) {
        if (res.data.list && res.data.list.length ) {
          this.myPostList = [...this.myPostList, ...res.data.list]
          this.loading = false
          this.refreshing = false
          if(res.data.list.length < this.pageSize){
          
             this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
        }
      }
    },
    toPage(path) {
      this.$router.push(path)
    },
    async getIncome() {
      const res = await income_detail(this.pageParams)
      this.income = res.data.income
    }
  },
  beforeRouteEnter(to, from, next) {
    if (['MineMyShouyiIncome', 'MineMyShouyiWithdraw'].includes(from.name)) {
      next(vm => {
        vm.activeId = 2
        vm.activeSort = 2
      })
    } else {
      next()
    }

    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
  }
}
</script>
    
<style lang="scss" scoped>
.myPost-tab {
  background: #212223;
  min-height: 100vh;
  padding: 0.4rem 0.36rem 2rem 0.36rem;

  :deep() {
    .van-sticky {
      position: fixed;
      top: 1rem;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .van-tabs__nav {
      justify-content: flex-start;
      background: #212223;
    }

    .van-tab {
      flex: none;
    }

    .van-tabs__content {
      margin-top: 1rem;
    }
  }
}

.cash-gold {
  width: 100%;
  height: 2.4rem;
  padding: 0.49rem 1.71rem 0.44rem 1.71rem;
  border-radius: 0.12rem;
  border: 1px solid #535353;
  background: linear-gradient(263deg, rgba(19, 255, 227, 0.04) -1.84%, rgba(92, 255, 235, 0) 101.84%), #111;
  text-align: center;
  margin-bottom: 0.36rem;
}

.my_post {
  .bottom-btn {
    display: flex;
    margin-top: 0.51rem;

    :deep() .van-button {
      padding: 0.06rem 0.16rem;
      border-radius: 0.08rem;
      background: #00f6f8;
      border: none;
      color: #333;
      font-family: PingFang SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 0.48rem;
      margin-right: 0.64rem;
      white-space: nowrap;
    }
  }

  .ah_list {
    li {
      border-radius: 0.12rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.32rem 0.36rem 0.56rem;

      >div {
        &:nth-child(1) {
          padding-bottom: 0.22rem;
          color: #999;
          font-family: PingFang SC;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-bottom: 0.01rem solid #4e4e4e;
        }

        &:nth-child(2) {
          margin: 0.26rem 0;
          color: #fff;
          font-family: PingFang SC;
          font-size: 0.34rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &:nth-child(3) {
          display: flex;

          >img {
            flex: 1;

            &:nth-child(1) {
              margin-right: 0.14rem;
            }
          }
        }

        &:nth-child(4) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.36rem 0;
        }

        &:nth-child(5) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.18rem;
          border-top: 0.01rem solid #4e4e4e;

          >div {
            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              padding: 0.04rem 0.16rem;
              border-radius: 0.04rem;
              background: #862424;
              margin-left: 0.45rem;
            }
          }
        }
      }
    }
  }

  .action-group {
    display: flex;

    img {
      height: 0.2rem;
      margin-right: 0.08rem;
      vertical-align: middle;
    }

    color: #fff;
    font-family: PingFang HK;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0;

    >div {
      margin-right: 0.64rem;
    }
  }

  .profile {
    border-radius: 0.04rem;
    border: 1px solid #00f6f8;
    background: rgba(0, 246, 248, 0.15);
    padding: 0.02rem 0.08rem;
  }
}

.diWangTj-order-item {
  border-radius: 0.12rem;
  border: 1px solid #535353;
  background: #292929;
  @include flexbox();
  padding: 0.2rem 0.36rem;
  margin-bottom: 0.32rem;

  .item-bg {
    width: 1.43rem;
    height: 1.92rem;

    :deep() {
      img {
        border-radius: 0.12rem;
      }
    }
  }

  .order_item_right {
    @include flexbox($fd: column, $ai: flex-end);

    .line {
      background-color: rgba(151, 151, 151, 0.3);
      width: 3.75rem;
      height: 0.01rem;
      flex-shrink: 0;
      margin: 0.3rem 0 0.39rem 0;
    }

    .top_pay_type {
      @include flexbox($jc: flex-start);

      div {
        @include flexbox($jc: flex-start);
        color: #f77;
        font-size: 0.22rem;
        margin-right: 0.29rem;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.02rem;
        }
      }

      span {
        padding: 0.04rem 0.16rem;
        gap: 0.1rem;
        border-radius: 0.04rem;
        background: rgba(190, 145, 85, 0.3);
      }

      .success {
        border-radius: 0.04rem;
        background: rgba(85, 190, 127, 0.3);
      }

      .error {
        border-radius: 0.04rem;
        background: #00f6f8;
      }
    }
  }
}
</style>
  
