<template>
  <div class="accout">
    <EBackTop class="header" background="transparent" color="#fff" :title="'找回账号'" />
    <div class="accout-main">
      <div class="top">
        <p>如果您的账号丢失，可以使用以下方法找回</p>
        <p>1.使用您已经保存的账号凭证</p>
        <p>2.输入您已经绑定的手机号，接收验证码找回</p>
        <p>3.联系 <span class="top-kf" @click="toDetail('kf')">在线客服</span></p>
      </div>
      <ul class="bot">
        <li>
          <van-cell title="使用账号凭证找回" is-link to="/mine/setting/accoutCodeCamera">
        </van-cell>
        </li>
        <li >
          <van-cell title="使用手机号找回" is-link to="/mine/setting/phone">
        </van-cell>
        </li>
        <li>
          <van-cell title="联系客服找回" is-link to="/mine/setting/kf">
         
        </van-cell>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  methods: {

    toDetail(type) {
      this.$router.push(`/mine/setting/${type}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.accout {
  min-height: 100vh;
  font-size: 0.28rem;
  .top {
    padding: 0.47rem 0.64rem 0 0.64rem;
    font-size: 0.28rem;
    color: #787979;
    p{
      margin: .11rem 0;
    }
    p:first-child {
      font-weight: 600;
      font-size: .3rem;
      margin-bottom: .3rem;
      color: #fff;
    }
  }

  .bot {
    padding: 0.47rem 0.64rem 0 0.64rem;

    li {
      .van-cell {
      background-color: transparent;
      font-size: 0.3rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      padding: 0;
      color: #fff;
 justify-content: space-between;
 :deep() {
      .van-icon::before {
        font-size: 0.5rem;
        color: #fff;
      }

   
    }
    }
    margin-bottom: 0.6rem;
    }
  }
}
.top-kf {
  color: #fff;
}
</style>
