<template>
  <EBackTop :background="'transparent'" class="mine-head" color="#fff">
    <template #title>
      <span>AI换脸</span>
      <span class="blue-title">{{ $route.query.type == 'photo' ? '（图片）' : '（视频）' }}</span>
    </template>
    <template #right>
      <div class="right-my-generate" @click="$router.push('/ai/myGenerate')">
        <img src="@/assets/imgs/aiLab/icon/Magicpencil.svg" alt="" />
        <span> 我的生成 </span>
      </div>
    </template>
  </EBackTop>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
:deep() .mine-head {
  .blue-title {
    color: #00f6f8 !important;
    font-family: PingFang SC;
    font-size: 0.34rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.right-my-generate {
  padding: 0.08rem 0.16rem;
  border-radius: 0.64rem;
  background: #00f6f8;
  display: flex;
  align-items: center;
  color: #333;
  font-family: PingFang SC;
  font-size: 0.26rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    width: 0.26rem;
    margin-right: 0.06rem;
  }
}
</style>