<template>
  <div class="index-label">
    <!-- @click="$router.push({
                path: `/subpage/labelPage`,
                query: {
                  name: item.name,
                  tagId: item.tagId
                },
              })" -->
    <EBackTop :background="'transparent'" :color="'#fff'" :title="$route.query.name" :placeholder="false" />
    <div class="labelBg">
      <div class="labelData">
        <div class="labelAvatar">
          <!-- <EDecryptImg :imgCDN="imgCDN" :imgURL="cover" class="item-bg"></EDecryptImg> -->
          <img src="@/assets/imgs/topic_bg.png" alt="" />
        </div>
        <!-- <div class="labelText">
          <div>{{ $route.query.name }}</div>
          <div class="mediavNum">视频{{ longMediaNum }}</div>
        </div> -->
      </div>
    </div>
    <!--切换面板 -->
    <div class="label-pack">
      <ETab
        class="label-tab"
        @change="change"
        :sticky="true"
        :tabList="category"
        :v-model="type ? type : category && category[0] ? category[0].id : 0"
        :animated="false"
        :lineSwitch="true"
        :lineBg="'#00F6F8'"
        :lineHeight="'0.08rem'"
        :line-width="'0.4rem'"
        :title-active-color="'#fff'"
        :title-inactive-color="'#fff'"
        :sizeTab="'0.3rem'"
        :sizeActive="'.3rem'"
      >
        <template>
          <div class="label-scroll">
            <PullUp
              @refreshData="refreshData"
              @moreData="moreData"
              :finished="finished"
              :loading="loading"
              :refreshing="refreshing"
            >
              <component :list="mediaList" :is="compComponent(type)"></component>
            </PullUp>
          </div>
        </template>
      </ETab>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { labelTopicList } from 'api/comics' //api列表
export default {
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      category: [
        { name: '最近更新', id: 0 },
        {
          name: '本周最热',
          id: 1
        },
        {
          name: '最多观看',
          id: 2
        },
        {
          name: '十分钟以上',
          id: 3
        }
      ],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      finished: false,
      mediaList: [],
      type: 0,
      cover: '',
      longMediaNum: undefined,
      shortMediaNum: undefined
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'), //长视频
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    async fn_labelTopicList() {
      const res = await labelTopicList({
        location: 1,
        tag: this.$route.query.name,
        tagId: +this.$route.query.tagId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.type
      })
      if (res.code === 200 && res.data) {
        this.loading = false
        this.refreshing = false
        this.mediaList = [...this.mediaList, ...res.data.mediaList]
        if (this.mediaList.length) {
          this.cover = res.data.cover
        }
        this.longMediaNum = res.data.longMediaNum
        this.shortMediaNum = res.data.shortMediaNum
        if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
          this.finished = true
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    change(type) {
      this.type = type
      this.refreshData()
    },

    compComponent(showType) {
      return 'JavFourCard'
      // switch (showType) {
      //   case 1: // 长视频*
      //     return 'JavFourCard'
      //   case 2: // 短视频
      //     return 'JavShortSix'
      //   default:
      //     return 'JavFourCard'
      // }
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.fn_labelTopicList()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = true
      this.finished = false
      this.loading = false
      this.mediaList = []
      this.pageNum = 1

      this.fn_labelTopicList()
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.index-label {
  .label-pack {
    background: #212223;
    border-radius: 0.48rem 0.48rem 0 0;
    border-top: 0.01rem solid #585858;
    position: relative;
    margin-top: -2.5rem;

    .label-tab {
      :deep() {
        .van-sticky {
          z-index: 99;
          padding: 0 0.16rem;

          .van-tab {
            flex: inherit;
          }
        }

        .van-tabs__nav {
          background: transparent !important;
        }
      }
    }
  }

  .labelBg {
    position: relative;
    opacity: 0.6;

    img {
      width: 100%;
    }
  }

  .label-scroll {
    height: calc(100vh - 3.5rem);
    padding: 0.2rem 0.26rem;
    overflow-x: auto;
  }
}

.labelAvatar {
  height: 5.02rem;
}
</style>
