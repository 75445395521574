<!--
使用方法
    <EBackTop :title="'收藏列表'"  @goBack="goBack()" :leftText="'这是一个返回说明'" :color="'#ffffff'" :background="'#123123'" :placeholder='false' >
      <slot slot="left">&nbsp; </slot>
      <slot slot="title"> </slot>
      <slot slot="right"> <div @click='doSomething'></div> </slot>
    </EBackTop>

    goBack：左侧按钮调用goBack,如不穿入则调用默认
    leftText:左侧返回按钮后字体
    color：中间字体颜色
    background：EBackTop背景色
    fixed:是否浮动在窗口顶部
    placeholder：如果浮动在顶部，是否预留占位符

    注：插槽优先级高于 组件内定义属性

-->
<template>
  <div class="BackTop">
    <van-nav-bar :style="styleVar" @click-left="onClickLeft" :left-text="leftText" left-arrow v-bind="$attrs"
      v-on="$listeners" :fixed="fixed" :placeholder="placeholder">
      <slot slot="left" name="left"></slot>
      <slot slot="title" name="title"></slot>
      <slot slot="right" name="right"></slot>
    </van-nav-bar>
  </div>
</template>
<script>
import { fallback } from '../../utils_base/EUtils';

export default {
  name: 'EBackTop',
  props: {

    openFallback: {
      type: Boolean,
      default() {
        return false;
      },
    },
    color: {
      type: String,
      default() {
        return '#333';
      },
    },
    background: {
      type: String,
      default() {
        return '#fff';
      },
    },
    leftText: {
      type: String,
      default() {
        return '';
      },
    },
    height: {
      type: String,
      default() {
        return '1rem';
      },
    },
    fixed: {
      type: Boolean,
      default() {
        return true;
      },
    },
    placeholder: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fontSize: {
      type: String,
      default() {
        return '16px';
      },
    },
  },
  computed: {
    styleVar() {
      return {
        '--color_css': this.color,
        '--background_css': this.background,
        '--fontSize_css': this.fontSize,
        '--height_css': this.height,
      };
    },
  },
  data() {
    return {};
  },
  mounted() {
  },

  methods: {
    onClickLeft() {
      if (this.$listeners['goBack']) {
        this.$emit('goBack');
      } else {
        if (this.openFallback) {
          fallback(this);
        } else {
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.BackTop {
  width: 100%;

  ::v-deep {
    .van-nav-bar {
      max-width: 7.5rem;
      font-weight: 500;
      height: var(--height_css);
      z-index: 1000;
      background: var(--background_css);

      .van-nav-bar__content {
        border-bottom: 0.5px solid transparent;
        height: 100%;

        .van-nav-bar__title {
          font-size: var(--fontSize_css);
        }
      }

      .van-nav-bar__left {
        .van-icon {
          font-size: 25px;
          color: var(--color_css);
        }

        .van-nav-bar__text {
          color: #0f0f0f;
        }
      }

      .van-nav-bar__title {
        font-weight: 600;
        line-height: 1;
        color: var(--color_css);
      }
    }

    .van-hairline--bottom::after {
      display: none;
    }

    .van-nav-bar--fixed {
      left: auto;
      top: 0;
    }
  }
}
</style>
