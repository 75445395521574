<template>
  <div class="buy-history">
    <EBackTop :title="'我的订单'" background="#000" color="#fff" />
    <ETab
      class="myHistory-tab"
      :sticky="true"
      :tabList="cartoonCategory"
      :v-model="indexActive"
      :animated="false"
      @change="change"
      :title-active-color="'#FFF'"
      :title-inactive-color="'#FFF'"
      :sizeActive="'.3rem'"
      :lineSwitch="true"
      lineBg="#00F6F8"
      :ellipsis="false"
      lineBott="0.4rem"
    >
      <template v-slot:default="scope">
        <component :cardType="'order'" :is="compComponent(scope.data)"></component>
      </template>
    </ETab>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //上拉加载
      loading: false,
      //上拉加载完成
      finished: false,
      //下拉刷新
      refreshing: false,
      //文章列表
      payList: [],
      indexActive: 0,
      skeleton: false,
      //当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10,
      cartoonCategory: [
        { showType: 1, name: '视频' },
        { showType: 6, name: '嫩模空降' },
        { showType: 4, name: '好茶约炮' },
        { showType: 5, name: '帖子' }
      ]
    }
  },
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    AnHeiYouItem: () => import('@/components/HistoryComponents/AnHeiYouItem.vue'),
    AWJiaoYiItem: () => import('@/components/HistoryComponents/AWJiaoYiItem.vue'),
    DiWangTJItem: () => import('@/components/HistoryComponents/DiWangTJItem.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue')
  },
  created() {},
  methods: {
    change(id) {
      this.indexActive = id
    },
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'
        case 4:
          return 'DiWangTJItem'
        case 5:
          return 'CommunityDefault'
        case 6:
          return 'AnHeiYouItem'
        default:
          return 'LongVideoItem'
      }
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>
<style lang="scss" scoped>
.buy-history {
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  min-height: 100vh;
}
.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }
    .van-tabs__nav {
      justify-content: flex-start;
      background: url('../../../assets/imgs/mine/mine_top_bg.png') no-repeat;
      background-size: cover;
    }
    .van-tab {
      flex: none;
    }
  }
}
</style>
