<!--
使用方法
    <EDecryptImg  :imgURL="item.cover" objectFit='inherit'  :defaultUrl="require('~/static/images/footer/home.png')"  >
        <img  slot="defaultImg" class='defaultImg' src="/images/circle/circle-check.png" alt="" />
        <img  slot="mask" class='maskClass' src="/images/circle/circle-check.png" alt="" />
    </EDecryptImg>

    imgCDN:图片CDN域名
    imgURL:图片地址
    objectFit:图片铺展方式
    defaultUrl:默认图片地址

    defaultImg插槽：默认图片
    mask插槽：图片上层遮盖物（比如logo，角标等）

    注：插槽优先级高于 组件内定义属性(圆角属性在最外层包裹，不要传到组件里)

-->
<template>
  <div class="default">
    <img class="realImg" v-if="realUrl" :src="realUrl" :style="styleVar" draggable="false" ondragstart="return false;"
      @load="loadImg" />
    <slot name="defaultImg" v-else>
      <img class="defaultImg" :src="defaultUrl" :style="styleVar" draggable="false" ondragstart="return false;"
        @load="loadImgD(imgURL)" />
    </slot>
    <slot></slot>
  </div>
</template>

<script>
import { handleVerAutoImg } from '../../utils_base/EUtils/utils_tools'
import { IMAGE_DB_CONFIG, START_IMAGE_DB_KEY, TIME_OUT_NUMBER_KEY } from '../../utils_base/EConfig/imgConfig.js'
const { max_time_consuming, time_out_max_number } = IMAGE_DB_CONFIG
export default {
  name: 'EDecryptImgNolazy',
  props: {
    //默认图片路径
    defaultUrl: {
      typeof: String,
      default() {
        return require('~/static/loading.png')
      }
    },
    // 图片路径
    imgURL: {
      typeof: String,
      default() {
        return ''
      }
    },
    imgCDN: {
      typeof: String,
      default() {
        return ''
      }
    },
    objectFit: {
      typeof: String,
      default() {
        return 'cover'
      }
    }
  },
  watch: {
    imgURL(newVal) {
      this.getBlobUri()
    }
  },
  computed: {
    url() {
      if (this.imgURL) {
        return this.imgCDN + this.imgURL
      } else {
        return ''
      }
    },
    styleVar() {
      return {
        'object-fit': this.objectFit
      }
    }
    // showImg() {
    //   if (this.realUrl) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },

  mounted() {
    this.getBlobUri()
  },
  data() {
    return {
      realUrl: undefined,
      timeConsuming: 0, //执行耗时
      img: new Image(), //用来检测图片是否有效的
    }
  },
  methods: {
    loadImgD(url) {
      if (!url) {
        this.$emit('loadedImg')
      }
    },
    loadImg(e) {
      this.$emit('loadedImg')
    },
    // 解密缓存并获取base64图片地址
    async getBlobUri() {
      if (!this.url) return
      const startTime = new Date().getTime()
      // 如何解密失败直接重新还能触发loadedImg事件
      const baseUri = await handleVerAutoImg(this.url).catch(() => this.$emit('loadedImg'))
      // console.log(baseUri, 'baseUribaseUri')
      if (this.isBase64ImageValid(baseUri)) {
          // 图片有效会自动加载
          // console.log('Base64图片有效');
      } else {
          // 图片如果无效还是需要加载loadedImg事件
          this.$emit('loadedImg')
          console.log('Base64图片无效');
      }
      if (baseUri && baseUri.length) {
        this.realUrl = baseUri
      }
      // 计算执行耗时事件
      this.timeConsuming = new Date().getTime() - startTime
      // 每一次超时当前+1
      const timeOutN = IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY]
      const startDb = IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]

      if (this.timeConsuming >= max_time_consuming) {
        IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY] += 1
      }
      // 超过最大次数 关闭 IndexDB
      if (timeOutN > time_out_max_number && startDb) IMAGE_DB_CONFIG[START_IMAGE_DB_KEY] = false
    },
    // 判断base64的图片是否有效 同步检测 
    isBase64ImageValid(base64String) {
      try {
        // 创建一个新的Image对象并设置src为Base64字符串
        this.img.src = base64String;

        // 如果没有抛出异常，则表示Base64字符串有效
        return true;
      } catch (error) {
        // 如果抛出异常，则表示Base64字符串无效
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.default {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  .realImg {
    width: 100%;
    height: 100%;
  }

  .defaultImg {
    max-width: 100%;
    max-height: 100%;
    background-size: 100%;
    margin: 0 auto;
  }
}
</style>
