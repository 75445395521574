<!-- home -->
<template>
  <div class="trade-container">
    <EBackTop :background="'#000'" class="mine-head" color="#fff" :title="title"> </EBackTop>
    <div class="top_bg">
      <p>可提现余额</p>
      <p>¥ {{ userIncomeInfo.balance | changeGold }}</p>
      <p @click="$router.push('/mine/myWithdraw')">提现记录</p>
    </div>
    <div v-if="hasInfo">
      <div class="wihtdraw-form">
      <!-- 提现金额输入框 -->
      <div class="money-input">
        <div class="input-content">
          ¥
          <van-field v-model="form.money" type="number" placeholder="请输入您要提现的金额" />
          <!-- <div class="all-withdrawal" @click="allMoney">全部提现</div> -->
        </div>
        <div class="input-bot" style="color: #848494">
          <div>提现收取⼿续费</div>
        </div>
      </div>

      <!-- 银行卡提现表单 -->
      <div class="alipay-form">
        <van-field v-model.trim="form.accountName" placeholder="请输入开户姓名" />
        <van-field v-model.trim="form.bankName" placeholder="请输入银行名称" />
        <van-field v-model.trim="form.bankSite" placeholder="请输入开户行" />
        <van-field v-model.trim="form.accountNo" type="number" placeholder="请输入银行卡号" />
      </div>
      <!-- 提现提示 -->
      <div class="tip">
        提现将在1-2⼯作⽇内到账，如未收到，请联系<span class="kf" @click="$router.push('/mine/setting/kf')"
          >在线客服</span
        >
        <!-- <div>(请正确填写账号，账号内请不要出现空格，如果账号填写不规范，可能会导致无法提现)</div> -->

        <p>(请正确填写账号，账号内请不要出现空格，如果账号填写不规范，可能会导致无法提现)</p>
      </div>
    </div>
    <!-- 提现按钮 -->
    <div class="withdraw-btn" @click="withdrawBtn">立即提现</div>
    </div>
 
    <div v-else class="wihtdraw-none">暂无提现渠道，请稍后再来查看</div>
  </div>
</template>
    
<script>
import { user_income, withdrawal_submit, withdrawal_info } from '@/api/user'
export default {
  data() {
    return {
      title: '代理提现',
      userIncomeInfo: {},
      form: {
        accountName: undefined, // 账户持有人
        accountNo: undefined, // 交易账户
        bankName: undefined, // 银行名
        bankSite: undefined, //开户行
        money: undefined // 金额
      },
      withdrawInfo:{},
      itemInfo: {},
      hasInfo:false
    }
  },
 async mounted() {
    this.title = this.$route.query.title
    await this.mineWithdrawalInfo()
    this.getUserIncome()
  },
  components: {},
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取顶部分类
    category({ $store }) {
      return $store.state.config.config.mediaCategory
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  methods: {
    // 提现按钮
    withdrawBtn() {
      if (this.userIncomeInfo.balance <= 0) return this.$toast('您暂无可提现额度')
      if (!this.form.money) return this.$toast('请输入提现金额')
      if (this.form.money > this.userIncomeInfo.balance / 100) return this.$toast('提现数额大于可提现余额')
      if (!this.form.accountName) return this.$toast('请输入提现人姓名')
      if (!this.form.bankName) return this.$toast('请输入银行卡名称')
      if (!this.form.accountNo) return this.$toast('请输入提现账户')
      // 提现金额限制
      if (this.form.money > this.userIncomeInfo.maximum / 100 || this.form.money < this.userIncomeInfo.minimum / 100)
        return this.$toast(
          `提现金额超出提现额度限制,当前提现方式最提现额度为${this.userIncomeInfo.minimum / 100}~${
            this.userIncomeInfo.maximum / 100
          }`
        )
      this.mineWithdrawalSubmit()
    },
    // 申请提现请求
    async mineWithdrawalSubmit() {
      const res = await withdrawal_submit({
        accountName: this.form.accountName,
        accountNo: this.form.accountNo,
        bankName: this.form.bankName,
        mode: 'app',
        money: this.form.money * 100
      })
      if (res.code === 200) {
        return this.$toast(res.tip)
      } else {
        return this.$toast(res.tip)
      }
    },
    // 获取用户代理信息
    async getUserIncome() {
      const res = await user_income({})
      if (res.code === 200) {
        this.userIncomeInfo = res.data
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
     
    },
     // 获取提现信息
     async mineWithdrawalInfo() {
      const res = await withdrawal_info()
      if (res.code === 200) {
       console.log(res.data,'sss');
        this.withdrawInfo = res.data.info
        if(this.withdrawInfo.list && this.withdrawInfo.list.length>0){
          this.withdrawInfo.list.map(item=>{
            if(item.name==='银联'){
              this.hasInfo = true
            }
          })
        }else{
          this.hasInfo = false
        }
      } else {
        return this.$toast(res.tip)
      }
    },
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.wihtdraw-none {
  text-align: center;
}
.top_bg {
  background: url('../../../assets/imgs/mine/withdraw_top_bg.png') no-repeat;
  background-size: cover;
  width: 6.78rem;
  height: 3.2rem;
  flex-shrink: 0;
  margin: 0.79rem auto;
  text-align: center;

  p:first-child {
    padding-top: 0.75rem;
    font-size: 0.36rem;
    font-weight: 500;
  }

  p:nth-child(2) {
    border-radius: 0.08rem;
    background: rgba(0, 0, 0, 0.15);
    padding: 0.04rem 0.32rem;
    display: inline-block;
    margin-top: 0.12rem;
  }

  p:last-child {
    margin-top: 0.9rem;
    text-decoration: underline;
  }
}
// 提现表单
.wihtdraw-form {
  font-size: 0.3rem;
  margin: 0.3rem 0;
  // border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0.26rem;
  // 提现金额输入框
  .money-input {
    box-shadow: $shadow;
    padding: 0.3rem;
    .input-content {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #848494;
      img {
        width: 0.23rem;
        height: 0.27rem;
      }
      :deep() {
        .van-cell {
          width: 100%;
          background-color: transparent;
        }
        .van-field__control {
          color: #fff;
        }
      }
      .all-withdrawal {
        color: #848494;
        font-size: 0.32rem;
        white-space: nowrap;
        font-weight: 600;
      }
    }
    .input-bot {
      @include flexbox();
      margin-top: 0.15rem;
      font-size: 0.22rem;
      div:first-child {
        span {
          margin-right: 0.1rem;
        }
      }
    }
  }
}
// 表单数据
.alipay-form {
  margin-top: 0.5rem;
  :deep() {
    .van-cell {
      border-radius: 0.08rem;
      border: 1px solid #535353;
      background: #292929;
      box-shadow: $shadow;
      margin-bottom: 0.2rem;
    }
    .van-cell::after {
      display: none;
    }
    .van-field__control {
      color: #fff;
    }
  }
}
// tip
.tip {
  font-size: 0.2rem;
  color: #848494;

  p {
    margin-top: 0.05rem;
  }

  .kf {
    color: #f9c006;
  }
}
// 提现按钮
.withdraw-btn {
  width: 6rem;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  background: $btnBg;
  border-radius: 0.06rem;
  margin: 0 auto;
  font-size: 0.3rem;
  margin-top: 0.2rem;
  color: #333;
}
</style>
    