<template>
  <div class="short-six-item">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.coverImg" class="item-bg">
      <!-- 视频分类标签 -->
      <div class="video-type">
        <div class="video-type-vip" v-if="item.payType === 1">VIP</div>
        <div class="video-type-gold" v-if="item.payType === 2">
          <img src="@/assets/imgs/mine/gold.png" alt="" />
          {{ item.price | changeGold }}
        </div>
        <div class="video-type-free" v-if="item.payType === 0">免费</div>
      </div>
      <!-- 视频详情标签 -->
      <div class="vide-detail-type">
        <img v-if="item.showTags === '无码'" src="@/assets/imgs/index/video-ma.svg" alt="" />
        <img v-if="item.showTags === '中文'" src="@/assets/imgs/index/video-zhong.svg" alt="" />
      </div>
      <div class="titlePack">
        <div class="title">
          {{ item.title }}
        </div>
        <!-- 时间 -->
        <div class="video-time">
          <div class="collectPack">
            <img src="@/assets/imgs/mine/star_white.png" alt="" />
            {{ item.collects | numberFilter('w') }}
          </div>
          <div>{{ item.playTime | secondToDate }}</div>
        </div>
      </div>
    </EDecryptImg>
  </div>
</template>

<script>
import { onMounted } from 'vue'

export default {
  name: 'ShortVideo',
  props: ['item'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.short-six-item {
  margin-bottom: 0.2rem;
  width: 100%;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;
  .item-bg {
    // height: 2.7rem;
    position: relative;
    border-radius: 0.08rem;
    overflow: hidden;

    :deep() {
      .warpNoPadding {
        border-radius: 0.12rem;
      }
    }

      .video-type {
      position: absolute;
      top: 0.15rem;
      right: 0.18rem;
      height: 0.35rem;
      line-height: 0.35rem;
      color: #fff;
      font-size: 0.26rem;
      font-weight: 600;
      
      div {
        height: 100%;
        height: 0.35rem;
        line-height: 0.35rem;
        text-align: center;
        border-radius: 0.04rem;
      }
      &-vip {
        width: 0.64rem;
        background: #FFCF86;
        color: #333;
      }
      &-gold {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0.68rem;
        background: #3E287D;
        img {
          width: 0.22rem;
          height: 0.22rem;
          margin-right: 0.04rem;
        }
      }
      &-free {
        width: 0.64rem;
        background: #272727;
      }
    }
  }

  // 详情标签
  .vide-detail-type {
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 0.25rem;
      height: 0.25rem;
      margin-top: 0.06rem;
    }

    img:first-child {
      margin-top: 0;
    }
  }
  // 底部时间
  .titlePack {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    .title {
      padding: 0 0.1rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
    }
    .video-time {
      padding: 0 0.1rem;
      height: 0.36rem;
      color: #fff;
      line-height: 0.36rem;
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .collectPack {
        display: flex;
        align-items: center;
      }
      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.02rem;
      }
    }
  }
}
</style>
