<template>
  <div class="community-default">
    <div class="item-data" @click.target="toDetail(newData)">
      <!-- 头部头像 -->
      <div
        class="top-avatar"
        :class="!showType ? 'hasBorder' : ''"
        @click.stop="$router.push(`/mine/myInfo?id=${newData.base.userId}`)"
      >
        <div class="up_info_l">
          <div class="avatarPack">
            <EDecryptImg :imgCDN="imgCDN" class="avatar" :imgURL="newData.base.userAvatar" />
          </div>

          <div class="avatar_name">
            <p style="display: flex; align-items: center">
              {{ newData.base.userName }}
              <!-- 2原创作者 3up主 4创作者 -->
              <img
                v-if="newData.base.userType === 2 || newData.base.userType === 3 || newData.base.userType === 4"
                style="width: 0.32rem; height: 0.32rem; margin-left: 0.14rem"
                src="@/assets/imgs/renz.svg"
                alt=""
              />
            </p>
            <div class="watches_num">{{ newData.base.addedTime | timeDiff }}</div>

            <div style="display: flex; align-items: center">
              <div class="cardc">{{ newData.base.cardName }}</div>
            </div>
          </div>
        </div>

        <div class="up_info_r">
          <span @click.stop="addCare(newData)" :class="{ avatar: newData.base.isCare }">
            <img src="@/assets/imgs/jiah.svg" alt="" v-if="!newData.base.isCare" />
            <!-- <img src="@/assets/imgs/community/line.png" alt="" v-if="!newData.base.isLike"> -->
            {{ newData.base.isCare ? '已关注' : '关注' }}
          </span>
        </div>
      </div>
      <!-- 发布时间 -->
      <!-- <div class="watches_num">
        <p>{{ newData.base.watches }}次浏览</p>
        <p>{{ newData.base.createdAt | timeDiff }}</p>
      </div> -->
      <template>
        <Stencil
          :itemData="newData"
          :imgCDN="imgCDN"
          :isDetail="isDetail"
          @fn_playVideo="fn_playVideo"
          :showType="showType"
          @checkTag="checkTag"
        ></Stencil>
      </template>
      <!-- 底部信息展示 -->
      <div class="footer" v-if="!showType">
        <div class="foot-l">
          <div class="item">
            <img src="@/assets/imgs/picon1.svg" alt="" />
            {{ newData.base.watches }}
          </div>
          <div class="item">
            <img src="@/assets/imgs/picon2.svg" alt="" />
            {{ newData.base.likes }}
          </div>
          <div class="item">
            <img src="@/assets/imgs/picon3.svg" alt="" />
            {{ newData.base.comments }}
          </div>
        </div>
        <div
          class="foot-r"
          v-if="newData.base.postSection"
          @click.stop="$router.push(`/community/tagDteail?id=${newData.base.id}&title=${newData.base.postSection}`)"
        >
          {{ newData.base.postSection }}
        </div>
      </div>
      <div
        v-if="isShowAudit"
        class="audit-status"
        :class="{ 'is-success': newData.base.status == 1, 'is-inprogress': ![1, 4].includes(newData.base.status) }"
      >
        <span> {{ newData.base.remark }} </span>
        <span>审核{{ newData.base.status == 1 ? '通过' : newData.base.status == 4 ? '拒绝' : '中' }}</span>
      </div>
    </div>
    <!-- 详情图片跳转 -->
    <EDecryptImg
      :imgCDN="imgCDN"
      class="img-link"
      @click.native="txtLink(newData.pictureLink)"
      v-if="isDetail && newData.pictureLink"
      :needPadding="false"
      :imgURL="newData.jumpPicture"
    >
    </EDecryptImg>
    <!-- 详情文字跳转 -->
    <div class="txt-link" v-if="isDetail && newData.titleLink" @click.stop="txtLink(newData.titleLink)">
      {{ newData.jumpTitle }}
    </div>
    <div class="contact-info" v-if="isShowContact">
      <div @click="showContact(newData)" v-if="!newData.base.isBought">
        <img src="@/assets/imgs/coin2.png" alt="" />
        <span>{{ newData.base.price / 100 }}金币解锁联系方式</span>
      </div>
      <div v-else class="real-contact">
        <span>{{ newData.base.contact }}</span>
        <span @click="doCopy(newData.base.contact)">复制</span>
      </div>
    </div>
    <!-- 详情页底部 -->
    <div class="detail-foot" v-if="isDetail">
      <div class="foot-l">
        <div
          class="item"
          :style="{ color: newData.base.isLike ? '#00F6F8' : '#fff' }"
          @click="addLike(newData.base, 2)"
        >
          <van-icon style="margin-right: 0.1rem" name="like-o" />
          {{ newData.base.isLike ? '取消点赞' : '点赞' }}
        </div>
        <div
          class="item"
          @click="addLike(newData.base, 1)"
          :style="{ color: newData.base.isCollect ? '#00F6F8' : '#fff' }"
        >
          <van-icon name="star-o" style="margin-right: 0.1rem" />
          {{ newData.base.isCollect ? '取消收藏' : '收藏' }}
        </div>
        <div class="item" @click="clickShare">
          <img src="@/assets/imgs/fx.svg" alt="" />
          分享
        </div>
      </div>
    </div>

    <ComPlayVideo ref="ComPlayVideo" />
    <JavShowBuy
      :title="'购买此帖子需支付'"
      :showBuy="showBuy"
      :width="'80%'"
      :position="'center'"
      @shoudBuy="shoudBuy(newData)"
      @closed="closed"
      :price="newData.price | changeGold"
    />
    <div @click.stop="checkItem(newData)" v-if="showMask" class="sel-wrap">
      <img v-if="!newData.clickMask" src="@/assets/imgs/mine/sel-default.png" alt="" />
      <img v-else src="@/assets/imgs/mine/sel-active.png" alt="" />
    </div>

    <!-- 分享弹窗 -->
    <BlShare
      :isShow.sync="showShare"
      @select="shareBtn"
      @cancel="
        e => {
          showShare = e
        }
      "
    />
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { care_add, collect } from 'api/home'
import { community_pay } from 'api/community'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home';
export default {
  name: 'Default',
  props: ['itemData', 'isDetail', 'status', 'showMask', 'showType', 'isShowAudit'],
  components: {
    JavShowBuy: () => import('@/components/JavShowBuy.vue'),
    ComPlayVideo: () => import('@/components/Community/video.vue'),
    Stencil: () => import('@/components/Community/stencil.vue'),
    BlShare: () => import('@/components/BlShare/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  data() {
    return {
      newData: {},
      showBuy: false,
      showPopover: false,
      spliceList: [],
      showShare: false
    }
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    // 获取首页求片id
    wantToId({ $store }) {
      return $store.getters['wantToId']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isShowContact() {
      return this.$route.path.includes('community/detail') && this.newData.base.contact
    }
  },
  filters: {
    nickNamef(v, tabList) {
      for (let index = 0; index < tabList.length; index++) {
        const element = tabList[index]
        if (v == element.id) {
          return element.name
        }
      }
    }
  },
  watch: {
    itemData: {
      handler(n, o) {
        this.newData = n
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    showContact(item) {
      if (this.userInfo.balance < item.base.price) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '对不起,您的余额不足',
          type: 'community-post',
          ok: '前往充值',
          cancel: '做任务得VIP',
          position: 'center',
          showBalance: false,
          balance: this.userInfo.balance,
          price: item.base.price,
          isShowTips: true,
          cb: async returnData => {
            this.$router.push('/mine/myWallet')
          },
          close: async returnData => {
            this.$router.push('/mine/myShouyi')
          }
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '确认支付',
          showBalance: true,
          type: 'community-post-enough',
          ok: '确认支付',
          price: item.base.price,
          cancel: '狠心离开',
          position: 'center',
          isShowTips: true,
          balance: this.userInfo.balance,
          cb: async returnData => {
            this.shoudBuy(item)
          }
        }
      }
    },
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    checkTag(item) {
      this.$emit('checkTag', item)
    },
    closed(showBuy) {
      this.showBuy = showBuy
    },
    // 删除记录点击事件
    checkItem(item) {
      item.clickMask = !item.clickMask
      if (item.clickMask) {
        if (this.spliceList.length > 0) {
          this.spliceList.map((sItem, index, arr) => {
            if (item.id === sItem.id) {
              arr.splice(index, 1, item.id)
            }
          })
          this.spliceList.push(item.id)
        } else {
          this.spliceList.push(item.id)
        }
      } else {
        this.spliceList.map((sItem, index) => {
          if (item.id === sItem.id) {
            this.spliceList.splice(index, 1)
          }
        })
      }
      this.$emit('clickItem', this.spliceList)
      this.$forceUpdate()
    },
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}?type=${this.$route.path.includes('/trade') ? '1' : '2'}`)
      // if (!item.price || (item.price && item.isBuy)) {
      //   this.$router.push(`/community/detail/${item.id}`)
      // } else {
      //   return this.$toast('购买后才可查看详情！')
      // }
    },
    /**
     * 点击私信
     */
    clickPrivateLetter(data) {
      if (this.isMember) {
        this.$router.push({
          path: `/oneSession/${data.userId}`,
          query: {
            nickName: data.userName,
            avatar: data.userAvatar
          }
        })
      } else {
        this.$toast('开通会员才能发起私聊！')
      }
    },
    async addCare(item) {
      if (this.userInfo.loginType == 0) {
        return this.$toast('仅注册的用户才能关注')
      }
      // 关注用户
      try {
        const res = await care_add({
          id: item.base.userId,
          add: !item.base.isCare
        })
        if (res.code === 200) {
          item.base.isCare = !item.base.isCare
          if (item.base.isCare) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 播放视频
    fn_playVideo(item) {
   
        if (!item.base.price || (item.base.price && item.base.isBought)) {
          // /subpage/play/longVideo/12680
          this.$refs['ComPlayVideo'].fn_playVideo(item.base.videoUrl, item.base.videoCover)
          // this.$router.push(`/community/detail/${item.id}`)
        } else {
          if (this.userInfo.balance < item.base.price) {
            this.$refs['popupMsg'].popupMsg = {
              show: true,
              title: '注意',
              content: '对不起,您的余额不足',
              type: 'community-post',
              ok: '前往充值',
              cancel: '做任务得VIP',
              position: 'center',
              showBalance: false,
              balance: this.userInfo.balance,
              price: item.base.price,
              cb: async returnData => {
                this.$router.push('/mine/myWallet')
              },
              close: async returnData => {
                this.$router.push('/mine/myShouyi')
              }
            }
          } else {
            this.$refs['popupMsg'].popupMsg = {
              show: true,
              title: '确认支付',
              showBalance: true,
              type: 'pay_post',
              ok: '立即支付',
              price: item.base.price,
              cancel: '狠心离开',
              position: 'center',
              cb: async returnData => {
                this.shoudBuy(item)
              }
            }
          }
        }
      
    },
    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    // 点击分享按钮
    shareBtn(options, index) {
      switch (index) {
        case 0:
          let url = this.$store.getters['shareUrl'] + '/'
          if (url[url.length - 1] == '/') {
            url = url.slice(0, url.length - 1)
          }
          let str = url + '/#' + '/community/detail/' + this.newData.id
          this.doCopy(str)
          break
        default:
          this.$router.push({ path: '/mine/share' })
          break
      }
    },
    async clickShare(item) {
      this.showShare = true
    },

    // 添加喜欢
    async addLike(item, type) {
      // 收藏信息
      try {
        const res = await collect({
          // flag: true,
          flag: type === 1 ? !item.isCollect : !item.isLike,
          object_id: item.id,
          collectType: 5,
          type: type
        })
        if (res.code === 200) {
          this.$emit('getNewDetail')
          this.$bus.emit('getNewCommunityList')
          this.$toast(res.tip)
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },

    // 帖子购买
    async goBuyPost(data) {
      this.showBuy = true
    },
    // 购买弹窗购买按钮
    async shoudBuy(data) {
      try {
        const res = await community_pay({
          id: data.base.id
        })
        if (res.code === 200 && res.data.code === 200) {
          this.$emit('getNewDetail')
          this.showBuy = false
          return this.$toast('购买成功')
        } else {
          return this.$toast(res.data.msg)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求错误，请稍后再试！')
      }
    },
    // 详情活动跳转
   async txtLink(url) {
      const code = handleURlParams(url)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-info {
  padding: 0.12rem 0.16rem;
  border-radius: 0.08rem;
  border: 2px solid #00f6f8;
  margin: 0 auto 0.64rem;
  width: fit-content;
  color: #00f6f8;
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.34rem; /* 141.667% */
  > div:nth-child(1) {
    display: flex;
    align-items: center;
  }
  img {
    width: 0.3rem;
    margin-right: 0.1rem;
  }
  .real-contact {
    > span:nth-child(1) {
      margin-right: 0.2rem;
    }
  }
}
.detail-foot {
  padding-left: 0.4rem;
  .foot-l {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
    }
    img {
      width: 0.28rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }
}
.cardc {
  display: flex;
  padding: 0px 0.12rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(90deg, #00bbbc 0%, #0070c0 100%);
  color: #fff !important;
  margin-right: 0.13rem;
}
.callNum {
  padding: 0.2rem;
  margin: 0.23rem auto;
  font-size: 0.28rem;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    background-color: #ff669d;
    color: #fff;
    padding: 0.1rem;
    border-radius: 4px;
  }
}
.callMe {
  width: 100%;
  height: 0.64rem;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($color: #000000, $alpha: 0.6);
  border-radius: 4px;
}
.wantToSee {
  width: 100%;
  justify-content: center !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0 !important;
}
.hotcomment {
  width: 100%;
  height: 0.58rem;
  border-radius: 5px;
  background: rgba(255, 239, 239, 0.5);
  display: flex;
  align-items: center;
  padding: 0 0.24rem;
  margin-bottom: 0.24rem;
  img {
    width: 0.6rem;
    margin-right: 0.1rem;
    margin-top: -0.02rem;
  }
  p {
    font-size: 0.25rem;
    color: #2e2e2e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.community-default {
  position: relative;

  margin-bottom: 0.2rem;
  padding-bottom: 0.3rem;
  border-radius: 0.12rem;
  border: 1px solid #383838;
  background: rgba(0, 0, 0, 0.5);
  .dplayer-wrap {
    z-index: 9999;
  }
}
.item-data {
  // width: 7rem;
  // margin: 0.25rem auto;
  padding: 0.25rem;
  // background: #000;
  border-radius: 14px;
  // 文本
  .desc-txt {
    font-size: 0.26rem;
    color: #939496;
    white-space: pre-line;
  }
}
.noDetail {
  border-bottom: 0.15rem solid #f2f2f2;
  background: #fff;
  // border-bottom: 0.01rem solid #5b5b6f;
  // 文本
  .desc-txt {
    @include textoverflow(3);
    font-size: 0.26rem;
    color: #939496;
    white-space: pre-line;
    position: relative;
  }
}
// 顶部头像
.top-avatar {
  @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
  font-size: 0.22rem;
  margin-bottom: 0.2rem;
  white-space: nowrap;
  // border-bottom: 0.01rem solid #4e4e4e;
  padding-bottom: 0.24rem;

  .up_info_l {
    display: flex;
    align-items: center;
    .avatarPack {
      min-width: 0.9rem;
      max-width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      overflow: hidden;
    }
    .avatar_name {
      margin-left: 0.19rem;
      p {
        margin: 0;
        font-size: 0.28rem;
        font-weight: 600;
      }
      div {
        font-size: 0.22rem;
        color: #adadad;
      }
    }
  }
  .up_info_r {
    span {
      padding: 0.1rem 0.23rem;
      border: 0.02rem solid $btnBg;
      color: $btnBg;
      border-radius: 1rem;
      margin-left: 0.3rem;
      display: flex;
      align-items: center;
      img {
        height: 0.19rem;
        margin-right: 0.04rem;
      }
    }
    .avatar {
      border: 0.02rem solid #7a7a87;
      color: #7a7a87;
    }
    &.wantTo {
      span {
        padding: 0.07rem 0.18rem;
        background: $btnBg;
        color: #fff;
        img {
          height: 0.25rem;
          margin-right: 0.07rem;
        }
      }
      .avatar {
        border: 0.02rem solid #fff;
        background: #fff;
      }
    }
  }
}
.hasBorder {
  border-bottom: 0.01rem solid #4e4e4e;
}
.more {
  margin: 0.2rem 0;
  color: $btnBg;
  font-size: 0.26rem;
  text-align: right;
  margin: 0;
}
.img-list {
  @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
  margin: 0.3rem 0;
  .img-item {
    width: 2.26rem;
    height: 2.26rem;
    margin-right: 0.1rem;
    margin-top: 0.1rem;
  }
  .img-item:nth-child(3n) {
    margin-right: 0;
  }
}
.video-cover {
  margin: 0 auto;
}
.sm-video {
  position: relative;
  :deep() {
    .warpNoPadding {
      img {
        object-fit: contain;
        max-height: 6.9rem;
      }
    }
  }
  .van-icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transformCenter();
  }
}
// 底部信息
.footer {
  font-size: 0.24rem;
  display: flex;
  justify-content: space-between;
  // border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  .foot-l {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      margin-right: 0.64rem;
    }
    img {
      width: 0.28rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }
  .foot-r {
    flex: none;
    display: flex;
    padding: 0.02rem 0.08rem;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #00f6f8;
    background: rgba(0, 246, 248, 0.15);
    color: #fff;
    font-size: 0.24rem;
  }

  .comment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 0.1rem;
      height: 0.3rem;
    }
  }
  &-left {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);
    color: #939496;
    width: 100%;
    margin-top: 0.2rem;
    div {
      display: flex;
      align-items: center;
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 0.1rem;
        height: 0.3rem;
      }
    }
    &.wantTo {
      @include flexbox($jc: right, $ai: center, $fd: row, $fw: nowrap);
    }
  }
  &-right {
    color: #fff;
    font-size: 0.24rem;
    // background: linear-gradient(to right, #fd9c3a, #fc342d);
    background: $btnBg;
    padding: 0.03rem 0.27rem;
    border-radius: 0.34rem;

    span {
      font-size: 0.24rem;
    }
    img {
      width: 0.22rem;
      height: 0.22rem;
      margin: 0 0.03rem;
    }
  }
}
.audit-status {
  padding-top: 0.18rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff3a3a;
  font-family: PingFang SC;
  font-size: 0.2rem;
  font-style: normal;
  font-weight: 400;
  border-top: 1px solid #4e4e4e;
  margin-top: 0.36rem;
  &.is-success {
    > span {
      &:nth-child(2) {
        border: 1px solid #00f6f8;
        background: rgba(0, 246, 248, 0.15);
      }
    }
  }
  &.is-inprogress {
    > span {
      &:nth-child(2) {
        border: 1px solid #00f6f8;
        background: rgba(190, 145, 85, 0.3);
      }
    }
  }

  > span {
    &:nth-child(2) {
      flex: none;
      height: 0.42rem;
      border-radius: 0.04rem;
      background: #862424;
      padding: 0 0.16rem;
      margin-left: 0.4rem;
      line-height: 0.42rem;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.24rem;
      font-style: normal;
      font-weight: 400;
    }
  }
}
// 评论数量
.comments-count {
  // border-top: 0.15rem solid #f2f2f2;
  // border-bottom: 0.01rem solid #f2f2f2;
  padding: 0.14rem 0.24rem;
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .comments-count-l {
    color: #2e2e2e;
  }
  .comments-count-r {
    color: #bfbfbf;
  }
  span {
    margin-left: 0.1rem;
    display: inline-block;
  }
}
.img-link {
  width: 100%;
}
.txt-link {
  @include textoverflow();
  font-size: 0.26rem;
  padding: 0.02rem 0.3rem;
  text-align: center;
  width: 3.64rem;
  border: 0.02rem solid #9493b1;
  color: $btnBg;
  margin: 0.3rem auto;
  border-radius: 0.12rem;
}
.status {
  font-size: 0.24rem;
  margin-left: 0.32rem;
  width: 1.5rem;

  :deep() {
    .van-popover__wrapper {
      padding: 0;
      white-space: nowrap;
    }
  }
  span {
    border: none;
  }
  .tg {
    color: #00bc6c;
  }
  .jj {
    color: #ff1919;
  }
}
</style>
<style lang="scss">
.van-popover--dark {
  .van-popover__content {
    font-size: 0.28rem !important;
    padding: 0.1rem;
    color: #ff1919;
  }
}
// 选择视频
.sel-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  border-radius: 0.12rem;
  img {
    width: 0.4rem;
    height: 0.4rem;
  }
}
.watches_num {
  // @include flexbox();
  color: rgba(255, 255, 255, 0.5);
  font-family: PingFang SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
