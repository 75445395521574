<!-- home -->
<template>
    <div class="AnHeiYou-container">
        <EBackTop :title="$route.query.title" background="#000" color="#fff">
        </EBackTop>
        <!-- 列表 -->
        <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
            :refreshing="refreshing">
            <ul class="ah_list">
                <li v-for="item in mainList" :key="item.id">
                    <AnHeiYouDefaultOneCard :item="item" />
                </li>
            </ul>
        </PullUp>
    </div>
</template>
  
<script>
import scrollMixin from '@/utils/scrollMixin'
import { dating_category } from '@/api/dating'

export default {
    mixins: [scrollMixin],
    data() {
        return {
           
            pageNum: 1,
            pageSize: 10,
            loading: false,
            finished: false,
            refreshing: false,
            mainList: []
        }
    },
    components: {
        AnHeiYouDefaultOneCard: () => import('@/components/AnHeiYou/components/AnHeiYouDefaultOneCard.vue'),
        PullUp: () => import('@/components/PullUp.vue'),
    },
    computed: {
        imgCDN({ $store }) {
            return $store.getters['cdn']
        }
    },

    async mounted() {
        await this.refreshData()
    },
    methods: {
       
        // 列表请求 type 2 上门/嫩模 (帝王调教) 帖子（暗网交易）1楼风 (暗黑游)
        async getDatingList() {
            const res = await dating_category({
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
                "type": 1,
                louFengTypeID: +this.$route.query.louFengTypeID
            })
            if (res.code === 200) {
                if (res.data.modelList && res.data.modelList.length && res.data.modelList.length <= this.pageSize) {
                    this.mainList = [...this.mainList, ...res.data.modelList]
                    this.loading = false
                    this.refreshing = false
                } else {
                    this.loading = false
                    this.refreshing = false
                    this.finished = true
                }
            }
        },
       
        refreshData(refreshing) {
            this.refreshing = refreshing
            this.loading = false
            this.pageNum = 1
            this.mainList = []
            this.getDatingList()
        },
        moreData(loading) {
            this.loading = loading
            this.pageNum += 1
            this.getDatingList()
        },
     

    },
    beforeDestroy() { }
}
</script>
<style lang="scss" scoped>

.announcement {
    font-size: 0.26rem;
    @include flexbox($jc: flex-start);
    margin: 2.26rem 0 0.48rem 0;


    .title {
        padding: 0.02rem 0.12rem;
        border-radius: 0.08rem;
        background: #00F6F8;
        white-space: nowrap;
    }


    .scroll-text {
        overflow: hidden;
        margin-left: 0.12rem;

        .text {
            white-space: nowrap;
            animation: scroll-text 10s linear infinite;

        }
    }

    @keyframes scroll-text {

        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }
}

.ah_list {
    @include flexbox($fw: wrap,$ai:flex-start);
    padding:0.36rem;
    // min-height: $minHeight;
    // background: url('../../../assets/imgs/mine/mine_top_bg.png') no-repeat;
    // background-size: cover;
}
</style>
  