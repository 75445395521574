<template>
  <div class="exchange-container">
    <EBackTop :title="'兑换中心'" background="#000" color="#fff" />
    <van-pull-refresh style="min-height: calc(100vh - 1rem)" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="redeemcodeList.length ? '' : ''" @load="onLoad">
        <div class="exchange-codeWrap">
          <input v-model="redeemcode" placeholder="请输入兑换码" type="text" />
        </div>
        <div @click="submitRedeemcode" class="submit">立刻兑换</div>
        <ul class="exchange-listWrap">
          <li class="item item-title">
            <div class="left top">
              <div>兑换码</div>
            </div>

            <div class="center top">
              <div>兑换类型</div>
            </div>
            <div class="right top">
              <div>兑换时间</div>
            </div>
          </li>
          <li class="item item-decial" v-for="(item, index) in redeemcodeList" :key="index">
            <div class="left left-add">
              {{ item.code }}
            </div>
            <div class="center center-add">
              {{ item.desc }}
            </div>
            <div class="right right-add">
              {{ item.activedAt.split('T')[0] }}
            </div>
          </li>
          <div class="nodata" v-if="!redeemcodeList.length">暂时没有数据</div>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  <script>
import { redeemcode_user, redeemcode_list } from 'api/user'
export default {
  data() {
    return {
      redeemcode: '',
      loading: false,
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 兑换列表
      redeemcodeList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 50
    }
  },

  components: {},
  methods: {
    // 获取关注列表
    async getRedeemcodeList(type) {
      const res = await redeemcode_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        if (!res.data.list) {
          res.data.list = []
        }
        if (type === 'pull') this.redeemcodeList = []
        this.redeemcodeList = [...this.redeemcodeList, ...res.data.list]
        if (res.data && res.data.list.length < this.pageSize) {
          this.finished = true
        }
      } else {
        this.$toast(res.tip)
        this.refreshing = false
        this.loading = false
        this.finished = true
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.getRedeemcodeList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.getRedeemcodeList('pull')
    },
    // 提交兑换码
    async submitRedeemcode() {
      if (!this.redeemcode) return this.$toast('请输入兑换码！！!')
      const res = await redeemcode_user({
        code: this.redeemcode.trim()
      })
      if (res.code === 200) {
        this.redeemcode = ''
        this.$toast('恭喜兑换成功！！！')
        this.onRefresh()
      } else {
        this.redeemcode = ''
        this.$toast(res.tip)
      }
    }
  },
  mounted() {
    this.onRefresh()
  },
  created() {},
  computed: {}
}
</script>
  <style lang="scss" scoped>
.exchange-container {
  color: #333;
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f1f5fb;
  .exchange-codeWrap {
    position: relative;
    box-sizing: border-box;
    margin: 0.5rem 0.3rem;
    text-align: center;
    border-radius: 0.09rem;
    box-sizing: border-box;
    font-size: 0;
    height: 0.8rem;
    input {
      width: 100%;
      border: 0;
      outline: none;
      font-size: 0.26rem;
      line-height: 0.6rem;
      border-radius: 0.05rem;
      text-indent: 0.15rem;
      height: 100%;
      border-radius: 0.64rem;
      border: 2px solid $btnBg;
      background: transparent;
      color: #fff;
    }
  }
  .exchange-listWrap {
    min-height: 40vh;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.26rem;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      line-height: 0.48rem;
      color: #787979;
      padding: 0.15rem 0.64rem;
      font-size: 0.24rem;
      &-title {
        color: #fff;
        font-size: 0.28rem;
      }
      &:nth-child(odd) {
        background: #2c2d2d;
      }
      &:nth-child(even) {
        background: #000;
      }
      .left {
        width: 33.33%;
      }
      .center {
        width: 33.33%;
      }
      .right {
        width: 33.33%;
      }
    }
    .nodata {
      line-height: 40vh;
      font-size: 0.26rem;
      color: #fff;
      text-align: center;
    }
  }
}
.submit {
  border-radius: 0.46rem;
  background: $btnBg;
  width: 4.8rem;
  height: 0.72rem;
  flex-shrink: 0;
  margin: 0.44rem auto 0.88rem auto;
  line-height: 0.72rem;
  text-align: center;
  color: #333;
}
</style>
  