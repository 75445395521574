var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list && _vm.list.length > 0)?_c('div',{staticClass:"long-four"},[_vm._l((_vm.videoList.length ? _vm.videoList : _vm.list),function(item,index){return _c('div',{key:index,staticClass:"short-six-item",on:{"click":function($event){return _vm.toPlay(item)}}},[(item.cover)?_c('div',[_c('div',{staticClass:"advertise-item"},[_c('EDecryptImg',{attrs:{"imgCDN":_vm.imgCDN,"imgURL":item.cover}})],1),_c('div',{staticClass:"advertise-desc"},[_vm._v("视频广告")])]):[_c('LongVideoCards',{attrs:{"item":item}})]],2)}),_c('i'),_c('i'),(_vm.showType == 3 || _vm.showType == 4 || _vm.showType == 2)?_c('div',{staticClass:"btn"},[_c('div',{on:{"click":_vm.fn_videoTopicChange}},[_c('img',{attrs:{"src":require("@/assets/imgs/index/exchangeIcon.png"),"alt":""}}),_vm._v("换一批")]),_c('div',{on:{"click":function($event){return _vm.$router.push({
          path: `/subpage/morePage`,
          query: {
            id: _vm.id,
            name: _vm.name
          }
        })}}},[_vm._v(" 查看更多 ")])]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }