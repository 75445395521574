<template>
  <div class="awjy-item">
    <!-- 用户信息 我的订单单独样式-->
    <div class="top_order" v-if="cardType === 'order'">
      <div class="order_left">
        <p>订单编号:{{ item.base.orderId }}</p>
        <p>创建时间:{{ item.base.orderAt | timeYmd }}</p>
      </div>
      <div
        class="order_right"
        v-clipboard:success="onCopy"
        v-clipboard:copy="'官方邮箱 jjjjj.@gmail.com'"
        v-clipboard:error="onError"
      >
        复制
      </div>
    </div>
    <!-- 记录列表单独样式 -->
    <div class="top-user" v-else>
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.base.userAvatar ? item.base.userAvatar : ''" class="item-avatar">
      </EDecryptImg>
      <div class="user-right">
        <div class="user_right_top">
          <p>{{ item.base.userName }}</p>
          <p class="type">{{ item.base.isSeller ? '卖方' : '买方' }}</p>
        </div>
        <div class="creat_time">{{ item.base.addedTime | dateFormat08 }}</div>
      </div>
    </div>
    <!-- 话题 -->
    <div class="tip-list">
      <span v-for="item in 4">#{{ item.base.postCategory }}#</span>
    </div>
    <!-- 内容 -->
    <div class="jy_content">
      <p :class="showAll ? 'all_content' : 'content_txt'">
        {{ item.node ? item.node.text : '' }}
      </p>
      <span class="more_btn" @click="showAll = !showAll">{{ showAll ? '收起' : '全文' }}</span>
    </div>
    <!-- 视频 -->
    <div v-if="item.base.videoUrl" class="img_list video_list" @click="playVideo(item.base.videoUrl)">
      <EDecryptImg :imgCDN="imgCDN" :imgURL="item.base.videoCover ? item.base.videoCover : ''" class="item_cover">
        <div class="play_box">
          <img class="play_icon" src="@/assets/imgs/trade/play.png" alt="" />
        </div>
      </EDecryptImg>
      <i></i>
      <i></i>
      <i></i>
    </div>
    <!-- 图片 -->
    <div v-else class="img_list">
      <EDecryptImg
        v-for="(sItem, index) in item.node.imgs"
        :key="index"
        :imgCDN="imgCDN"
        :imgURL="sItem ? sItem : ''"
        class="item_cover"
      >
      </EDecryptImg>
      <i></i>
      <i></i>
      <i></i>
    </div>
    <!-- 交易状态 -->
    <div class="jy_type">
      <div>
        已缴纳 <span class="active_color">{{ item.base.price | changeGold }}</span> 保证金
      </div>
      <!-- 交易中 完成交易 -->
      <div :class="item.base.tradeStatus === 0 ? 'wait' : item.base.tradeStatus === 1 ? 'jyIng' : 'end'">
        {{ `${item.base.tradeStatus === 0 ? '等待交易' : item.base.tradeStatus === 1 ? '交易中' : '完成交易'}` }}
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="bot_btn">
      <div class="bot_btn_left">
        <img src="@/assets/imgs/mine/watchs.png" alt="" />
        {{ item.base.watches | numberFilter('w') }}
      </div>
      <div class="bot_order_btn_right" v-if="cardType === 'order'">
        <EDecryptImg :imgCDN="imgCDN" :imgURL="item.base.userAvatar ? item.base.userAvatar : ''" class="item-avatar">
        </EDecryptImg>
        <p>{{ item.base.userName }}</p>
      </div>
      <div v-else>
        <div class="bot_btn_right" @click="payBtn(item)" v-if="!item.base.isBought && item.base.price">我要交易</div>
      </div>
    </div>
    <!-- 遮罩层 minRecharge最少充值金额-->
    <div v-if="!item.base.rechargeStatus" class="overlay" @click="buyPost">
      <img src="@/assets/imgs/trade/lock.png" alt="" />
      充值{{ item.base.minRecharge | changeGold }}元能预览本帖
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
    <ComPlayVideo ref="playVideo" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAll: false
    }
  },
  name: 'AwJiaoYi',
  props: {
    item: {
      type: Object,
      default() {
        return {
          node: {},
          base: {}
        }
      }
    },
    cardType: {
      type: String,
      default() {
        return {}
      }
    }
  },
  components: {
    ComPlayVideo: () => import('@/components/Community/video.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },

  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    },
    payBtn(item) {
      if (this.userInfo.balance < this.item.base.price * 100) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '当前余额不足',
          type: 'up_post',
          ok: '购买VIP',
          img: '',
          cancel: '充值金币',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/vip')
          },
          close: async returnData => {
            this.$router.push('/mine/myWallet')
          }
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '我要交易',
          showBalance: true,
          type: 'up_post',
          ok: '缴纳交易保证金',
          price: this.item.base.price,
          position: 'center',
          tip: '买家/卖家已要求缴纳等额交易保证金',
          cb: async returnData => {
            const res = await post_pay({
              id: this.item.base.id
            })
            if (res.data.code === 200) {
              item.base.isBought = true
              this.$router.push('/mine/setting/kf')
            } else {
              return this.$toast(res.tip)
            }
          }
        }
      }
    },
    playVideo(url) {
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], { typeCode: 13, url: url }, this.$store)
    }
  }
}
</script>

<style lang="scss" scoped>
.awjy-item {
  border-radius: 0.12rem;
  border: 1px solid #383838;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.24rem 0.38rem;
  margin-bottom: 0.24rem;
}

.top-user {
  @include flexbox($jc: flex-start);

  .item-avatar {
    width: 0.88rem;
    height: 0.88rem;
    border-radius: 50%;
    margin-right: 0.16rem;

    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }

  .user-right {
    .user_right_top {
      @include flexbox($jc: flex-start);

      .type {
        border-radius: 0.04rem;
        background: #00f6f8;
        padding: 0rem 0.12rem;
        margin-left: 0.1rem;
      }
    }

    .creat_time {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.24rem;
      font-weight: 400;
    }
  }
}

.tip-list {
  color: #00a3ff;
  font-size: 0.28rem;
  font-weight: 400;
  margin-top: 0.23rem;

  span {
    margin-bottom: 0.06rem;
    margin-right: 0.06rem;
  }
}

.jy_content {
  font-size: 0.26rem;
  margin-bottom: 0.24rem;

  .content_txt {
    @include textoverflow(5);
  }

  .more_btn {
    color: #00f6f8;
    font-size: 0.28rem;
  }
}

.img_list {
  @include flexbox($fw: wrap);

  .item_cover {
    width: 1.94rem;
    height: 1.94rem;
    margin-bottom: 0.12rem;

    :deep() {
      img {
        border-radius: 0.08rem;
      }
    }
  }

  i {
    width: 1.94rem;
  }
}

.video_list {
  .item_cover {
    position: relative;
    width: 2.96rem;
    height: 2.96rem;
    margin-bottom: 0.12rem;
    background: rgba(0, 0, 0, 0.5);

    :deep() {
      img {
        border-radius: 0.08rem;
      }
    }

    .play_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      @include flexbox($jc: center);
      background: rgba(0, 0, 0, 0.5);

      .play_icon {
        width: 0.72rem;
        height: 0.72rem;
      }
    }
  }
}

.jy_type {
  font-size: 0.26rem;
  @include flexbox();
  margin: 0.3rem 0 0.28rem 0;

  .active_color {
    color: #00f6f8;
  }

  div:last-child {
    border-radius: 0.08rem;
    padding: 0.04rem 0.08rem;
  }

  .wait {
    background: rgba(255, 84, 84, 0.2);
    padding: 0.04rem 0.08rem;
    color: #f9c006;
  }

  .jyIng {
    border-radius: 0.08rem;
    background: rgba(190, 145, 85, 0.2);
    color: #00f6f8;
  }

  .end {
    border-radius: 0.08rem;
    background: rgba(62, 255, 139, 0.2);
    color: #70be55;
  }
}

.bot_btn {
  padding: 0.28rem 0;
  border-top: 0.01rem solid #4e4e4e;
  @include flexbox();

  &_left {
    @include flexbox($jc: flex-start);

    img {
      width: 0.28rem;
      height: 0.2rem;
      margin-right: 0.09rem;
    }
  }

  &_right {
    border-radius: 0.08rem;
    background: #00f6f8;
    padding: 0.04rem 0.08rem;
    gap: 0.1rem;
    color: #1d1d1d;
  }
}

// 我的订单单独样式
.top_order {
  @include flexbox();
  padding-bottom: 0.36rem;
  border-bottom: 0.02rem solid #4e4e4e;

  .order_left {
    P:last-child {
      color: #787979;
      margin-top: 0.02rem;
    }
  }

  .order_right {
    padding: 0.04rem 0.16rem;

    border-radius: 0.08rem;
    background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
  }
}

.bot_order_btn_right {
  @include flexbox($jc: flex-start);

  .item-avatar {
    width: 0.38rem;
    height: 0.38rem;
    margin-right: 0.12rem;

    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }
}
</style>
