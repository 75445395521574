<template>
  <div class="anHeiYou-item" @click="toDetail(item)">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.images.length ? item.images[0] : ''" class="item-bg">
      <!-- 视频分类标签 -->
      <div class="anHeiYou-type">
        <!--  -->
        <div class="anHeiYou-type-vip" v-if="item.sellerType === 2">官方自营</div>
        <div class="anHeiYou-type-free" v-if="item.sellerType === 1">入驻商家</div>
      </div>
      <!-- 标题 -->
      <div class="anHeiYou-title">
        {{ item.title }}
      </div>
    </EDecryptImg>
    <!-- 影片描述 -->
    <div class="item-desc">
      <div class="desc">{{ item.girlHeight }} . {{ item.girlWeight }} . {{ item.girlAge }}岁 . {{ item.cupType }}</div>
      <div class="tagsPack">
        <div class="tags">{{ item.price }}</div>
        <div class="tags">约</div>
      </div>
    </div>
    <div class="options">
      <img src="@/assets/imgs/options_icon.png" alt="" />
      <span>{{ item.cityName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'anHeiYou',
  props: ['item'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push(`/trade/AnHeiYouDetail/?id=${item.id}`)
      this.$store.dispatch('setAHYlist', {
        type: 'add',
        item: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 封面公告样式
.item-bg {
  position: relative;
  border-radius: 0.12rem;
  overflow: hidden;
  height: 4.2rem !important;

  :deep() {
    .warpNoPadding {
      border-radius: 0.12rem;
    }
  }

  .anHeiYou-type {
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: #fff;
    font-size: 0.24rem;

    &-vip {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: $btnBg;
      color: #333;
    }

    &-free {
      text-align: center;
      height: 0.36rem;
      padding: 0.01rem 0.16rem;
      line-height: 0.36rem;
      border-radius: 0rem 0rem 0.12rem 0rem;
      background: #ffcf86;
      color: #333;
    }
  }
}

// 记录样式
.anHeiYou-item {
  width: 3.2rem;
  margin-bottom: 0.36rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;

  // 底部时间
  .anHeiYou-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.64rem;
    padding: 0.155rem 1.04rem 0.145rem 0.24rem;
    line-height: 0.5rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.94%);
  }

  .item-desc {
    font-size: 0.24rem;

    .desc {
      margin: 0.16rem 0;
    }

    .tagsPack {
      height: 0.32rem;
      @include flexbox();
      margin-bottom: 0.1rem;
      .tags {
        &:first-child {
          color: #ffcf86;
          font-size: 0.34rem;
          font-weight: 500;
        }

        &:last-child {
          border-radius: 0.08rem;
          background: #ffcf86;
          padding: 0.02rem 0.24rem;
          color: #333;
        }
      }
    }
  }
}

.options {
  border-radius: 0.06rem;
  background: #373737;
  @include flexbox($jc: flex-start);
  padding: 0.04rem 0.08rem;
  flex: none;
  display: inline;

  img {
    width: 0.18rem;
    height: 0.22rem;
    margin-right: 0.08rem;
  }
}
</style>
