<!--
使用方法
    <EGenerateImg ref="EGenerateImg" :link="URL" name='aaa' :config="config">
      <div class="promote-bg" ref="html2canvas">
        <div class="bgImg"></div>
        <p class="descP1">点击立即分享好基友</p>
        <p class="descP2">与基友一起鸡儿邦邦硬</p>
        <div class="btn-wrap">
          <div @click="onCopy">复制链接</div>
          <div @click="clickSaveImg">保存图片</div>
        </div>
      </div>
    </EGenerateImg>
    
    name:导出文件名称
    link:生成二维码或复制 链接
    config:{
        qrSize: 100 * (window.innerWidth / 750) * 2.24, 二维码大小(需要换算成px)
        qrBgwidth: '2.6rem', 二维码边框大小(可传px或者rem)
        qrPadding: '0.18rem',二维码边框内边距大小
        qrRight: 0,二维码边框距右侧距离
        qrBottom: '1.43rem',二维码边框距底部距离
    }

    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg();
    },
    onCopy() {
      this.$refs['EGenerateImg'].doCopy();
    },

    注：插槽优先级高于 组件内定义属性(圆角属性在最外层包裹，不要传到组件里)

-->
<template>
  <div class="template">
    <div ref="html2canvas">
      <slot> </slot>
      <div class="qrBg" :style="styleVar">
        <qrcode-vue :value="link" level="H" :size="config.qrSize" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EGenerateImg',
  components: {
    QrcodeVue: () => import('qrcode.vue'),
  },
  props: {
    link: {
      //二维码链接
      type: String,
      default() {
        return '';
      },
    },
    name: {
      //二维码链接
      type: String,
      default() {
        return '保存图片';
      },
    },
    config: {
      typeof: Object,
      default() {
        return {
          qrSize: 0,
          qrPadding: '10px',
          qrRight: undefined,
          qrBottom: undefined,
          qrBgwidth: undefined,
          qrBgColor: undefined,
          scale: 10,
          qrRadius: 0,
        };
      },
    },
  },
  data() {
    return {};
  },

  computed: {
    styleVar() {
      return {
        padding: this.config.qrPadding,
        right: this.config.qrRight,
        bottom: this.config.qrBottom,
        width: this.config.qrBgwidth,
        height: this.config.qrBgwidth,
        background: this.config.qrBgColor,
        borderRadius: this.config.qrRadius,
      };
    },
  },
  mounted() {},
  methods: {
    //保存图片
    async clickSaveImg() {
      import('html2canvas').then(({ default: html2canvas }) => {
        html2canvas(this.$refs.html2canvas, {
          scale: this.config.scale,
          dpi: 300,
          width: this.width,
          height: this.height,
          backgroundColor: null,
          useCORS: true, // 解决文件跨域问题
        }).then(canvas => {
          canvas.toBlob(
            blob => {
              const url = URL.createObjectURL(blob);
              // 可以上传后端或者直接显示
              this.downloadIamge(url, this.name);
            },
            'image/jpeg',
            0
          );
        });
      });
    },

    downloadIamge(href, name) {
      const eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.href = href;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
      this.$toast('如果无法下载图片，请截图保存');
    },
    async doCopy() {
      this.$copyText(this.link)
        .then(() => {
          this.$toast('复制剪贴板成功');
        })
        .catch(err => {
          this.$toast('复制剪贴板失败');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.template {
  position: relative;
  .qrBg {
    width: 1.68rem;
    height: 1.68rem;
    position: absolute;
    right: 0.2rem;
    bottom: 0.2rem;
    // background-color: #ffffff;
    > div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
