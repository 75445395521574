<template>
  <div class="setting">
    <EBackTop
      class="mine-head"
      background="transparent"
      color="#fff"
      :fixed="true"
      :title="'我的'"
      @click-right="submit"
    >
      <slot slot="right" name="right">
        <div class="right-txt">保存</div>
      </slot>
    </EBackTop>
    <div class="setting-main">
      <EDecryptImg
        :imgCDN="imgCDN"
        class="avatar"
        @click.native="$router.push(`/mine/setting/avatar?userAvatarUrl=${userAvatarUrl}`)"
        :imgURL="userAvatarUrl ? userAvatarUrl : ''"
      >
      </EDecryptImg>
      <p class="change_ava" @click="$router.push(`/mine/setting/avatar?userAvatarUrl=${userAvatarUrl}`)">
        点击更换头像
      </p>
      <!-- 基本信息 -->
      <div class="update-info">
        <div class="name">
          <div class="lable">{{ `昵\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0称` }}</div>
          <p class="line"></p>

          <div class="linebox" style="display: flex; align-items: center">
            <van-field maxlength="10" v-model="nickName" placeholder="请输入昵称" />
            <span class="count">{{ nickName ? nickName.length : 0 }}/10</span>
          </div>
        </div>
        <div class="info">
          <div class="lable">{{ `简\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0介` }}</div>
          <p class="line"></p>

          <div class="linebox" style="display: flex; align-items: center">
            <van-field maxlength="30" v-model="introduction" placeholder="请输入个人简介" />
            <span class="count">{{ introduction ? introduction.length : 0 }}/30</span>
          </div>
        </div>
        <div class="gander">
          <div class="lable" @click="show = true">{{ `性\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0别` }}</div>
          <p class="line"></p>

          <div class="input" @click="show = true">
            {{ gender ? gender : '请选择性别' }}
          </div>
        </div>
        <!-- 绑定手机号 -->
        <div class="bind-phone" @click="bindPhone">
          绑定手机
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div>
        <!-- 找回账号 -->
        <div class="accout" @click="accout">
          找回账号
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div>
        <!-- 账号凭证 -->
        <div class="accout-code" @click="accoutCode">
          账号凭证
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div>
      </div>
      <!-- 修改性格 -->
      <van-popup v-model="show" position="bottom" :style="{ backgroundColor: 'transparent' }">
        <div class="change-gander" @click="changeGander(1)">
          <img src="@/assets/imgs/mine/man.png" alt="" />
          男
        </div>
        <div class="change-gander" @click="changeGander(2)">
          <img src="@/assets/imgs/mine/wuman.png" alt="" />
          女
        </div>
      </van-popup>
    </div>
  </div>
</template>
  
  <script>
import { update_info, userInfo } from 'api/user'
export default {
  name: 'Setting',
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue')
  },
  computed: {
    newUserInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  data() {
    return {
      show: false,
      type: 0,
      gender: '',
      introduction: '',
      nickName: '',
      userAvatarUrl: '',
      mobile: ''
    }
  },
  async mounted() {
    await this.$store.dispatch('get_userInfo')
    if (this.newUserInfo.gender === 2) {
      this.gender = '女'
    } else if (this.newUserInfo.gender === 1) {
      this.gender = '男'
    } else {
      this.gender = ''
    }
    this.type = this.newUserInfo.gender.toString()
    this.introduction = this.newUserInfo.introduction
    this.nickName = this.newUserInfo.nickName
    this.userAvatarUrl = this.newUserInfo.avatarUrl
    this.mobile = this.newUserInfo.mobile
  },
  methods: {
    // 提交
    async submit() {
      if (!this.isMember) {
        return this.$toast('亲，您还不是会员，不能修改噢')
      }
      try {
        const res = await update_info({
          gender: +this.type,
          introduction: this.introduction,
          nickName: this.nickName,
          avatar: this.userAvatarUrl
        })
        if (res.code === 200) {
          this.$store.dispatch('get_userInfo')
          this.$toast('修改成功')
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    // 更改性别
    changeGander(type) {
      this.type = type
      if (this.type === 1) {
        this.gender = '男'
      } else {
        this.gender = '女'
      }
      this.show = false
    },
    // 绑定手机号
    bindPhone() {
      this.$router.push('/mine/setting/bindPhone')
    },
    // 找回账号
    accout() {
      this.$router.push('/mine/setting/accout')
    },
    // 账号凭证
    accoutCode() {
      this.$router.push('/mine/setting/accoutCode')
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.mine-head {
  :deep() {
    .right-txt {
      height: 0.5rem;
      width: 0.92rem;
      text-align: center;
      line-height: 0.5rem;
      background: $btnBg;
      color: #333;
    }
  }
}

.setting {
  background: url('../../../assets/imgs/mine/mine_top_bg.png') center center no-repeat;
  min-height: 100vh;
}
.setting-main {
  padding: 0.4rem 0.52rem;
  .avatar {
    width: 1.8rem;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 0.3rem;
    :deep() {
      img {
        border-radius: 50%;
      }
    }
  }
  .change_ava {
    text-align: center;
  }
}

// 个人信息
.name,
.info,
.gander {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.28rem;
  .lable {
    margin-right: 0.28rem;
    width: 1.12rem;
    box-sizing: border-box;
  }
  .input {
    width: 100%;
    color: #fff;
    font-size: 0.24rem;
  }
  .van-cell {
    width: 100%;
    background: #ffffff;
    font-size: 0.24rem;
    font-weight: 400;
    padding: 0.15rem 0;
    :deep() {
      .field-placeholder-text-color {
        font-weight: 400;
        color: #fff;
      }
      .van-field__control {
        color: #fff;
      }
    }
  }
  :deep() {
    .van-cell::after {
      display: none;
    }
    .van-radio__label {
      margin-left: 0.2rem;
      color: #848494;
      font-size: 0.24rem;
    }
    .van-radio__icon .van-icon {
      border: 3px solid #848494;
    }

    .van-radio__icon--checked .van-icon {
      background-color: #848494;
      border-color: #848494;
      color: #848494;
    }
    .van-icon-success:before {
      content: none;
    }
  }
}

.update-info {
  // padding: 0 0.5rem;
  .linebox {
    :deep() {
      .van-cell {
        background-color: transparent;
        color: #9493b1;
      }
      .van-field__control {
        color: #9493b1;
      }
    }
  }
}
// 账号凭证等
.bind-phone,
.accout-code,
.accout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  margin-top: 0.5rem;
}
// 性别弹窗
.change-gander {
  &:first-child {
    border-radius: 0.32rem 0.32rem 0rem 0rem;
    border: 1px solid #535353;
  }

  background: #333;
  @include flexbox($jc: center, $ai: center);
  height: 1rem;
  img {
    width: 0.3rem;
    height: 0.29999rem;
    margin-right: 0.02rem;
  }
}
.line {
  width: 0.02rem;
  height: 0.32rem;
  background: #9493b1;
  margin: 0 0.48rem 0 0.64rem;
}
</style>
  