<template>
  <div class="exchange-container">
    <EBackTop :title="'会员兑换'" background="#000" color="#fff" />
    <div class="vip_bg">
      <p>当前积分</p>
      <p>{{ userInfo.points }}</p>
    </div>

    <van-pull-refresh style="min-height: calc(100vh - 1rem)" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="redeemcodeList.length ? '' : ''" @load="onLoad">
        <!-- <div class="exchange-codeWrap">
       
          <input v-model="redeemcode" placeholder="请输入兑换码" type="text">
        </div>
        <div @click="submitRedeemcode" class="submit">立即兑换</div> -->
        <ul class="lotter_list">
          <li v-for="item in lotteryList" :key="item.id" class="lotter_item">
            <div>
              <img src="@/assets/imgs/mine/vip_code_icon.png" alt="" />
              <p class="point_num">{{ item.pointNum }}</p>
              <p class="no-wrap">{{ item.name }}</p>
            </div>
            <p class="check_btn" @click="checkRedeem(item)">兑换</p>
          </li>
        </ul>
        <ul class="exchange-listWrap">
          <li>兑换记录</li>
          <li class="item" v-for="(item, index) in redeemcodeList" :key="index">
            <div class="left">
              {{ item.prizeName }}
            </div>
            <div class="right">
              {{ item.updatedAt ? item.updatedAt.split('T')[0] : '' }}
            </div>
          </li>
          <div class="nodata" v-if="!redeemcodeList.length">暂时没有数据</div>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getLotteryList, getLotteryRedeem, getLotteryRedeemHistory, userInfo } from 'api/user'

export default {
  data() {
    return {
      redeemcode: '',
      loading: false,
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 兑换列表
      redeemcodeList: [],
      lotteryList: [], //奖品列表
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 50
    }
  },

  components: {},
  methods: {
    // 获取兑换历史
    async getRedeemcodeList(type) {
      const res = await getLotteryRedeemHistory({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        if (!res.data.list) {
          res.data.list = []
        }
        if (type === 'pull') this.redeemcodeList = []
        this.redeemcodeList = [...this.redeemcodeList, ...res.data.list]
        if (res.data && res.data.list.length < this.pageSize) {
          this.finished = true
        }
      } else {
        this.$toast(res.tip)
        this.refreshing = false
        this.loading = false
        this.finished = true
      }
    },
    // 获取奖品列表
    async getLotteryList(type) {
      const res = await getLotteryList({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        if (!res.data.list) {
          res.data.list = []
        }
        this.lotteryList = res.data.list
      } else {
        this.$toast(res.tip)
      }
    },
    // 奖品兑换
    async checkRedeem(item) {
      const res = await getLotteryRedeem({
        prizeId: item.id
      })
      if (res.code == 200) {
        await this.$store.dispatch('get_userInfo')
        return this.$toast('兑换成功')
      } else {
        return this.$toast(res.tip)
      }
    },

    // 下拉加载
    onLoad() {
      this.pageNum++
      this.getRedeemcodeList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.getRedeemcodeList('pull')
    },
    // 提交兑换码
    async submitRedeemcode() {
      if (!this.redeemcode) return this.$toast('请输入兑换码！！!')
      const res = await redeemcode_user({
        code: this.redeemcode.trim()
      })
      if (res.code === 200) {
        this.redeemcode = ''
        this.$toast('恭喜兑换成功！！！')
        this.onRefresh()
      } else {
        this.redeemcode = ''
        this.$toast(res.tip)
      }
    }
  },
  mounted() {
    this.getLotteryList()
    this.onRefresh()
  },
  created() {},
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  }
}
</script>
<style lang="scss" scoped>
.exchange-container {
  color: #333;
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f1f5fb;

  .exchange-listWrap {
    min-height: 40vh;
    overflow: auto;
    border-radius: 0.12rem;
    border: 1px solid #535353;
    background: #292929;
    text-align: center;
    padding: 0 0.31rem;
    margin: 0.6rem;
    li:first-child {
      border-radius: 0.04rem;
      background: #00f6f8;
      padding: 0.06rem 0.24rem;
      color: #333;
      display: inline-block;
    }

    .item {
      padding: 0.16rem 0rem;
      border-bottom: 0.01rem solid #535353;
      color: #fff;
      @include flexbox();
      .right {
        color: #787979;
        font-size: 0.24rem;
        font-weight: 400;
      }
      .left {
        color: #ffcf86;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .nodata {
      line-height: 40vh;
      font-size: 0.26rem;
      color: #fff;
      text-align: center;
    }
  }
}

.vip_bg {
  background: url('../../../assets/imgs/mine/vip_top_bg.png') no-repeat;
  background-size: cover;
  width: 6.22rem;
  height: 2.4rem;
  flex-shrink: 0;
  border-radius: 0.12rem;
  margin: 0.33rem auto 0.36rem auto;
  text-align: center;
  padding: 0.53rem 0;

  p {
    color: #fff;
    font-size: 0.32rem;

    &:last-child {
      color: #00f6f8;
      font-size: 0.64rem;
      font-weight: 700;
      margin-top: 0.16rem;
    }
  }
}

.lotter_list {
  @include flexbox();
  padding: 0 0.6rem;
}

.lotter_item {
  @include flexbox($fd: column, $jc: flex-start);
  color: #ffcf86;

  img {
    width: 0.56rem;
    height: 0.56rem;
  }

  div {
    @include flexbox($fd: column, $jc: flex-start);
    border-radius: 0.08rem;
    padding: 0.32rem 0.27rem 0.32rem 0.41rem;
    border: 2px solid #535353;
    background: linear-gradient(180deg, #313131 0%, #121212 79.17%);

    .point_num {
      font-weight: 500;
      font-size: 0.3rem;
      font-weight: 500;
      margin: 0.2rem 0;
    }
    .no-wrap {
      white-space: nowrap;
    }

    p:last-child {
      border-radius: 0.04rem;
      border: 1px solid #ffcf86;
      background: rgba(255, 207, 134, 0.15);
      padding: 0.02rem 0.24rem;
    }
  }

  .check_btn {
    border-radius: 0.04rem;
    background: #ffcf86;
    padding: 0.02rem 0.36rem;
    margin-top: 0.32rem;
    color: #333;
  }
}

.submit {
  border-radius: 0.46rem;
  background: $btnBg;
  width: 4.8rem;
  height: 0.72rem;
  flex-shrink: 0;
  margin: 0.44rem auto 0.88rem auto;
  line-height: 0.72rem;
  text-align: center;
  color: #333;
}
</style>
  