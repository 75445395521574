<!-- home -->
<template>
  <div>
    <EBackTop :background="'#000'" class="mine-head" color="#fff" title="推广数据"> </EBackTop>
    <div class="pro-container">
      <div class="top_bg">
        <div class="top_info">
          <div>
            <p>可提现余额</p>
            <p>¥ {{ userIncomeInfo.balance | changeGold }}</p>
          </div>
          <div class="line"></div>
          <div>
            <p>总收益</p>
            <p>¥ {{ userIncomeInfo.totalIncome | changeGold }}</p>
          </div>
        </div>
        <p class="withdraw_btn" @click="$router.push(`/mine/myShouyi/withdraw?title=代理提现`)">立即提现</p>
      </div>
      <!-- 收益数据 -->
      <div class="pro_info">
        <ul class="shouyi_info">
          <li>
            <p>当月收益</p>
            <p>{{ userIncomeInfo.currentMonthIncome | changeGold }}</p>
          </li>
          <li>
            <p>当月推广数</p>
            <p>{{ userIncomeInfo.currentMonthProxy }}</p>
          </li>
          <li>
            <p>今日收益</p>
            <p>{{ userIncomeInfo.currentDayIncome | changeGold }}</p>
          </li>
          <li>
            <p>今日推广数</p>
            <p>{{ userIncomeInfo.currentDayProxy }}</p>
          </li>
        </ul>
        <img src="@/assets/imgs/mine/withdraw_icon.png" alt="" />
        <ul class="shouyi_info">
          <li>
            <p>累计推广用户</p>
            <p>{{ userInfo.invites }}</p>
          </li>
          <li>
            <p>累计付费用户</p>
            <p>{{ userIncomeInfo.proxyTotalIncomeUser }}</p>
          </li>
        </ul>
      </div>
      <div class="pro_bot_info">
        <img src="@/assets/imgs/mine/pro_bot_title.png" alt="" />
        <img src="@/assets/imgs/mine/pro_bot_table.png" alt="" />
      </div>
    </div>
  </div>
</template>
    
<script>
import { user_income, withdrawal_submit } from '@/api/user'
export default {
  data() {
    return {
      title: '代理提现',
      userIncomeInfo: {},
      form: {
        accountName: undefined, // 账户持有人
        accountNo: undefined, // 交易账户
        bankName: undefined, // 银行名
        bankSite: undefined, //开户行
        money: undefined // 金额
      }
    }
  },
  mounted() {
    this.title = this.$route.query.title
    this.getUserIncome()
  },
  components: {},
  computed: {
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取顶部分类
    category({ $store }) {
      return $store.state.config.config.mediaCategory
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  methods: {
    // 获取用户代理信息
    async getUserIncome() {
      const res = await user_income({})
      if (res.code === 200) {
        this.userIncomeInfo = res.data
      } else {
        return this.$toast('请求错误，请联系后台管理人员！')
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.pro-container {
  padding: 0 0.36rem;
}
.top_bg {
  background: url('../../../assets/imgs/mine/pro_top_bg.png') no-repeat;
  background-size: cover;
  width: 6.78rem;
  height: 3.2rem;
  flex-shrink: 0;
  margin: 0.79rem auto;
  text-align: center;

  .top_info {
    @include flexbox();
    color: #5f1c07;
    padding: 0.56rem 0.9rem;
    P:last-child {
      color: #fff;
      padding: 0.02rem 0.16rem;
      font-size: 0.26rem;
      border-radius: 0.08rem;
      background: rgba(0, 0, 0, 0.25);
      display: inline-block;
      margin-top: 0.08rem;
    }
  }

  .line {
    background: #5f1c07;
    width: 0.007rem;
    height: 1rem;
  }
}

.withdraw_btn {
  border-radius: 0.08rem;
  background: #36221c;
  padding: 0.1rem 2rem;
  display: inline-block;
}

.pro_info {
  border-radius: 0.12rem;
  border: 1px solid #ffcf86;
  padding: 0.64rem 0.44rem;
  img {
    width: 6rem;
    height: 0.38rem;
    margin: 0.5rem 0;
  }
}
.shouyi_info {
  @include flexbox();
  li {
    text-align: center;
    margin-right: 0.2rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    p:first-child {
      border-radius: 0.06rem;
      border: 1px solid #ffcf86;
      background: rgba(255, 207, 134, 0.1);
      padding: 0.06rem 0.1rem;
      display: inline-block;
      color: #ffcf86;
      white-space: nowrap;
      font-size: 0.24rem;
    }
    p:last-child {
      margin-top: 0.1rem;
      color: #fff;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 700;
    }
  }
}
.pro_bot_info {
  text-align: center;
  img:first-child {
    width: 6.24rem;
    margin-bottom: 0.8rem;
    margin-top: 0.56rem;
  }
  img:last-child {
    width: 6.77rem;
  }
}
</style>
    