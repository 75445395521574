<!--
 * @Author:
 * @Mail:
 * @Date: 2023-07-03 22:54:45
 * @LastEditTime: 2023-07-24 17:49:50
 * @LastEditors: Please set LastEditors
 * @FilePath: /bliblih5/web_base/component_base/ERefreshingPage/index.vue
-->
<!--
结合 van-pull-refresh 的刷新状态进行使用
<van-pull-refresh v-model="refreshing" @refresh="onRefresh">

使用方法  <RefreshingPage v-if="refreshing">{{中间插入img,不插入直接默认展示}} </RefreshingPage>
-->
<template>
  <div class="refreshingPage" ref="refreshingPage">
    <slot>
      <svg width="60" height="60" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="#712EFF">
          <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3" fill="#DE59FF">
          <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5"
            calcMode="linear" repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15" fill="#EA009A">
          <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
      </svg>
    </slot>
  </div>
</template>
<style lang="scss" scoped>
.refreshingPage {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
<script>
export default {
  name: 'ERefreshingPage',
  mounted() {
    let toTop = this.$refs['refreshingPage'].getBoundingClientRect().top;
    let footerHeight = 100 * (window.innerWidth / 750) * 2;
    let height = window.innerHeight - toTop - footerHeight;
    this.$refs['refreshingPage'].style.height = height + 'px';
  },
};
</script>
