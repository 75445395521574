
<template>
  <div class="adds-index">
    <EBackTop background="#000" color="#fff"> </EBackTop>
    <!-- 切换按钮 -->
    <van-tabs class="wallet_tabs" v-model="orderTypeActive" sticky @change="change">
      <van-tab title="关注"></van-tab>
      <van-tab title="话题"></van-tab>
    </van-tabs>
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <ul class="human-box" v-if="orderTypeActive == 0">
        <li v-for="item in listData" :key="item.id" class="item">
          <div class="left" @click="$router.push(`/up/index/${item.id}`)">
            <div class="avatar">
              <EDecryptImg :imgCDN="imgCDN" :imgURL="item.avatar" />
            </div>
            <div class="name">
              <div>{{ item.name }}</div>
              <div>{{ item.introduction }}</div>
            </div>
          </div>
          <div @click="removeCare(item)" class="right">
            <div>取消关注</div>
          </div>
        </li>
      </ul>
      <ul class="community-tag-list" v-if="orderTypeActive == 1">
        <li v-for="item in listData" :key="item.id" class="item">
          <div class="left" @click="$router.push(`/up/index/${item.id}`)">
            <div class="avatar">
              <EDecryptImg :imgCDN="imgCDN" :imgURL="item.imageUrl" />
            </div>
            <div class="name">
              <div>{{ item.name }}</div>
              <div class="name-in">
                <div>帖子{{ item.posts }}</div>
                <div>浏览{{ item.watches | numberFilter('w') }}</div>
              </div>
            </div>
          </div>
          <div @click="removeCare(item)" class="right">
            <div>取消关注</div>
          </div>
        </li>
      </ul>
    </PullUp>
  </div>
</template>
  
<script>
import { collect_list, care_list } from 'api/user'
import { collect } from 'api/home'
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      orderTypeActive: 0,
      isSeller: false,
      listData: [],
      finished: false // 每次请求到的数据长度
    }
  },

  components: {
    PullUp: () => import('@/components/PullUp.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    change(id) {
      this.orderTypeActive = id
      this.refreshData()
    },
    // 关注列表
    async getCareList() {
      const res = await care_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        if (res.data.list && res.data.list.length ) {
          this.listData = [...this.listData, ...res.data.list]
          this.refreshing = false
          this.loading = false
           if (res.data.list.length < this.pageSize) {

            this.finished = true
          }
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
        }
      }
    },
    // 收藏列表
    async getList() {
      const res = await collect_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        collectType: 12,
        type: 1 // 收藏
      })
      if (res.code === 200) {
        if (
          res.data.postSectionList &&
          res.data.postSectionList.length &&
          res.data.postSectionList.length <= this.pageSize
        ) {
          this.listData = [...this.listData, ...res.data.postSectionList]
          this.refreshing = false
          this.loading = false
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
        }
      }
    },

    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      if (this.orderTypeActive == 0) {
        this.getCareList()
      } else {
        this.getList()
      }
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.listData = []
      this.pageNum = 1
      if (this.orderTypeActive == 0) {
        this.getCareList()
      } else {
        this.getList()
      }
    },
    async removeCare(item) {
      // 关注用户
      try {
        const res = await collect({
          object_id: item.id,
          collectType: 12,
          type: 1,
          flag: false
        })
        this.refreshData()
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.adds-index {
  :deep() {
    .van-nav-bar {
      z-index: 98 !important;
    }
  }
}

// .awjy-index{

// }
.wallet_tabs {
  :deep() {
    .van-tabs__wrap {
      @include flex-center;
    }

    .van-sticky {
      position: fixed;
      top: 0.2rem;
      left: 50%;
      right: 0;
      z-index: 99;
      width: 3rem;
      transform: translate(-50%, 0);
      max-width: $pcMaxWidth;
    }

    .van-tabs__nav--line {
      height: auto;
    }

    .van-tabs__nav {
      padding: 0.08rem;
      width: 50%;
      background: transparent !important;
      padding-bottom: 0;
      border-radius: 0.58rem;
      border: 0.01rem solid $btnBg;

      //border: 1px solid #F9C006;
      .van-tab {
        font-size: 0.24rem !important;
        z-index: 2;
        color: rgba(255, 255, 255, 0.6);
        padding: 0;
      }

      .van-tab--active {
        color: #333;
      }

      .van-tabs__line {
        width: 0.7rem;
        height: 0.4rem;
        border-radius: 0.24rem;
        background: $btnBg;
        top: 0.03rem;
      }
    }
  }
}

.list-box {
  .item {
    padding: 0.32rem 0;
    @include flexbox;
    border-radius: 0.08rem;
    border-bottom: 0.01rem solid #535353;
    .left {
      @include flexbox;

      .avatar {
        flex-shrink: 0;
        overflow: hidden;
        height: 1.28rem;
        width: 1.28rem;
        :deep() {
          img {
            border-radius: 10px;
            height: 1.28rem;
          }

          .imgTag {
            height: 100%;
          }
        }
      }

      .name {
        padding-left: 0.25rem;
        font-size: 0.28rem;

        > div {
          &:last-child {
            padding-top: 0.1rem;
            font-size: 0.24rem;
            color: #a7a7a7;
            display: flex;
            > div:nth-child(1) {
              margin-right: 0.24rem;
            }
          }
        }
      }
    }

    .right {
      @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
      margin-left: 0.1rem;

      div {
        @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
        font-size: 0.28rem;
        color: #fff;
        border-radius: 0.44rem;
        border: 1px solid #535353;
        background: #292929;
        padding: 0.1rem 0.2rem;
      }
    }
  }
}
.community-list-box {
  border-radius: 0.24rem;
  border: 1px solid #535353;
  background: #2f2f2f;
  margin: 0.44rem 0.36rem 0;
  padding: 0 0.36rem;
}
.human-box {
  margin: 0 0.36rem;
  padding-top: 0.32rem;
  :deep() img {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
  }
  .avatar {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.16rem;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.32rem 0.36rem;
    border-bottom: 0.01rem solid #535353;
    background: #2f2f2f;
    border-radius: 0.24rem;
    margin-bottom: 0.24rem;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      &:nth-child(1) {
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:nth-child(2) {
        color: rgba(255, 255, 255, 0.5);
        font-family: PingFang SC;
        font-size: 0.22rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.name-in {
          display: flex;
          > div {
            margin-right: 0.24rem;
          }
        }
      }
    }
  }
  .right {
    @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
    margin-left: 0.1rem;

    div {
      @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
      font-size: 0.28rem;
      color: #fff;
      border-radius: 0.44rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.1rem 0.2rem;
    }
  }
}
.community-tag-list {
  margin: 0.44rem 0.36rem 0;
  padding: 0.32rem 0.36rem 0;
  border-radius: 0.24rem;
  border-radius: 0.24rem;
  border: 1px solid #535353;
  background: #2f2f2f;

  .avatar {
    width: 1.28rem;
    height: 1.28rem;
    border-radius: 10px;
    margin-right: 0.16rem;

    :deep() img {
      width: 1.28rem;
      height: 1.28rem;
      border-radius: 10px;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #535353;
    background: #2f2f2f;
    margin-bottom: 0.24rem;
    padding-bottom: 0.32rem;
    &:nth-last-child(1) {
      border: none;
    }
  }
  .left {
    display: flex;
  }
  .name-in {
    display: flex;
    color: #999;
    font-family: PingFang SC;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .name {
    > div:nth-child(1) {
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.08rem;
    }
  }
  .right {
    @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
    margin-left: 0.1rem;

    div {
      @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
      font-size: 0.28rem;
      color: #fff;
      border-radius: 0.44rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.1rem 0.2rem;
    }
  }
}
</style>
  