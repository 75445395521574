<template>
  <div class="index-more">
    <EBackTop :background="'#000'" :color="'#fff'" :title="$route.query.name" />
    <!--切换面板 -->
    <div class="more-pack">
      <ul>
        <li :class="{'active': sort == 0}" @click="fn_sort(0)">最新</li>
        <div></div>
        <li :class="{'active': sort == 1}" @click="fn_sort(1)">最热</li>
      </ul>
      <ETab
        class="more-tab"
        @change="change"
        :sticky="true"
        :tabList="category"
        :v-model="type ? type : category && category[0] ? category[0].id : 0"
        :animated="false"
        :lineSwitch="true"
        :lineBg="'#00F6F8'"
        :lineHeight="'0.08rem'"
        :line-width="'0.4rem'"
        :title-active-color="'#fff'"
        :title-inactive-color="'#fff'"
        :sizeTab="'0.32rem'"
        :sizeActive="'.38rem'"
      >
        <template>
          <div class="more-scroll">
            <ERefreshLoad
              @refreshData="refreshData"
              @moreData="moreData"
              :finished="finished"
              :loading="loading"
              :refreshing="refreshing"
            >
              <component :list="mediaList" :is="compComponent(type)"></component>
            </ERefreshLoad>
          </div>
        </template>
      </ETab>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin';
import { mediaTopicList } from 'api/comics'; //api列表
export default {
  mixins: [scrollMixin],
  data() {
    return {
      category: [
        { name: '长视频', id: 1 },
        // { name: '短视频', id: 2 },
      ],
      sort: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      finished: false, // 每次请求到的数据长度
      mediaList: [],
      type: 1,
    };
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'), //长视频
    JavShortSix: () => import('@/components/stencil/JavShortSix.vue'), //短视频
  },
  computed: {},

  mounted() {
    this.refreshData();
  },
  methods: {
    fn_sort(sort){
      this.sort = sort
      this.refreshData()
    },
    async fn_mediaTopicList() {
      const res = await mediaTopicList({
        id: +this.$route.query.id,
        mediaType: this.type,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort //0:最新 1:最热 2:最受好评
      });
      if (res.code === 200 && res.data) {
        this.loading = false;
        this.refreshing = false;
        this.mediaList = [...this.mediaList, ...res.data.mediaList];
        if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
          this.finished = true;
        }
      } else {
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
        return this.$toast(res.tip);
      }
    },
    change(type) {
      if (this.type !== type) {
        this.type = type;
        this.refreshData();
      }
    },
    compComponent(showType) {
      switch (showType) {
        case 1: // 长视频*
          return 'JavFourCard';
        case 2: // 短视频
          return 'JavShortSix';
        default:
          return 'JavFourCard';
      }
    },
    // 上拉加载更多
    moreData() {
      this.loading = true;
      this.pageNum += 1;
      this.fn_mediaTopicList();
    },
    // 下啦刷新
    refreshData() {
      this.refreshing = true;
      this.loading = true;
      this.finished = false;
      this.mediaList = [];
      this.pageNum = 1;
      this.fn_mediaTopicList();
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.index-more {
  // background: url('~@/assets/imgs/bg_home_light.jpg') no-repeat;
  // background-size: cover;
  .more-pack {
    height: calc(100vh - 1rem);
    position: relative;
    overflow-y: auto;
    ul{
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0.24rem;
      right: 0.3rem;
      z-index: 999;
      .active{
        color: #00F6F8;
      }
      li{
        padding: 0 0.1rem;
      }
      div{
        width: 0.03rem;
        height: 0.2rem;
        background: #fff;
      }
    }
    .more-tab {
      :deep() {
        .van-sticky {
          z-index: 99;
          padding: 0 0.16rem;
          .van-tab {
            flex: inherit;
          }
        }
        .van-tabs__nav {
          background: transparent !important;
        }
      }
    }
  }
  .moreBg {
    img {
      width: 100%;
    }
  }
  .more-scroll {
    height: calc(100vh - 2.1rem);
    padding: 0 0.26rem;
    overflow-x: auto;
    margin-top: 0.2rem;
  }
}
</style>
