<template>
  <div class="home-index">
    <div class="tab-main">
      <PullUp
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
        :skeleton="skeleton"
      >
      <Actor :list="recomendActorList" />
      <!-- 列表 -->
      <p class="all-title">全部女优</p>
        <ul class="all-list">
          <li @click="$router.push(`/actor/detail/${item.id}`)" v-for="item in actorList" :key="item.id">
            <EDecryptImg :imgCDN="imgCDN" :imgURL="item.avatar" />
            <p>{{ item.name }}</p>
            
          </li>
        </ul>
      </PullUp>
    </div>
  </div>
</template>

<script>
import { index_home } from 'api/home'

export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 30,
      actorList: [],
      recomendActorList: [],
      loading: false,
      skeleton: false,
      refreshing: false,
      finished: false // 每次请求到的数据长度
    }
  },
  props: ['type'],
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getCartoonPage()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.actorList = []
      this.pageNum = 1
      this.skeleton = true
      this.getCartoonPage()
    },

    // 获取首页数据
    async getCartoonPage() {
      try {
        const res = await index_home({
          id: this.type,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          if (this.pageNum === 1) {
            this.recomendActorList = res.data.recomendActorList
          } else {
            res.data.recomendActorList
              ? (this.recomendActorList = res.data.recomendActorList)
              : (this.recomendActorList = this.recomendActorList)
          }
          this.actorList = [...this.actorList, ...res.data.actorList]
          if (!res.data.actorList || res.data.actorList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        this.refreshing = false
        this.loading = false
        console.log(error)
        this.$toast('请求错误，请稍后再试!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-main {
  // margin: 0 0.25rem;
  .all-title {
    margin: 0.3rem 0 0.1rem 0;
    font-size: 0.3rem;
    font-weight: 600;
     color: transparent;
      background: $txtActive;
      -webkit-background-clip: text;
  }
  
  .all-list {
    border-radius: 0.05rem;
    @include flexbox($jc: flex-start, $ai: flex-start, $fd: row, $fw: wrap);
    padding: 0.3rem 0.25rem 0 0.25rem;
    li {
      margin-bottom: 0.3rem;
      width: 19%;
      text-align: center;
      margin-right: 8%;
      p {
        margin: 0;
        margin-top: 0.2rem;
        font-size: 0.24rem;
      }
      .default {
        :deep() {
          img {
            border-radius: 50%;
          }
        }
      }
    }
    li:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
