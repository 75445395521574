<template>
    <div class="short-six" v-if="list && list.length > 0">
      <div class="short-six-item" v-for="(item, index) in (videoList.length?videoList:list)" :key="item.id" @click="toPlay(item, index, list)">
        <shortVideoCard :item="item"/>
      </div>
      <i></i>
      <i></i>
      <div class="btn" v-if="showType == 3 || showType == 4">
        <div @click="fn_videoTopicChange">
            <img src="@/assets/imgs/index/exchangeIcon.png" alt="">
            换一批
        </div>
        <div @click="$router.push({ path: `/subpage/morePage`,query:{
          id:id,
          name:name,
        }})">查看更多</div>
      </div>
    </div>
  </template>
  
  <script>
  import { videoTopicChange } from 'api/home'
  export default {
    name: 'ShortVideo',
    props: ['list', 'showType','name', 'id'],
    components: {
      shortVideoCard: () => import('@/components/VideoCard/shortVideoCard.vue')
    },
    data() {
        return {
            videoList: []
        }
    },
    mounted() {
        // this.videoList = this.list;
    },
    methods: {
      toPlay(item, index, list) {
        if (this.isLike) {
          this.$router.replace(`/subpage/play/longVideo/${item.id}`)
        } else {
          // 如果是小视频 则这个列表的数据和id index 都存入vuex
          if (item.videoType === 2) {
            this.$router.push({ path: `/subpage/play/shortVideo/${this.id}`, query: { id: this.id, sort: this.sort, detailId: item.id, typeTxt: this.typeTxt }})
          } else {
            this.$router.push(`/subpage/play/longVideo/${item.id}`)
          }
        }
      },
      async fn_videoTopicChange(){
            const res = await videoTopicChange({
                id: this.id,
            });
            if (res.code === 200 && res.data) {
                this.videoList = res.data.mediaList;
            } else {
                return this.$toast(res.tip)
            }
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .short-six {
    border-radius: 0.05rem;
    @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);
    i {
      width: 2.18rem;
    }
    &-item {
      width: 2.18rem;
      ::v-deep{
        .item-bg {
          height: 2.8rem;
        }
      }
    }
    .btn{
        display: flex;
        width: 6.78rem;
        background: #000;
        margin: 0 auto;
        margin-top: 0.1rem;
        border-radius: 1rem;
        margin-bottom: 0.1rem;
        div{
            width: 50%;
            height: 0.84rem;
            line-height: 0.84rem;
            font-size: 0.28rem;
            margin: 0 auto;  
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 0.26rem;
                height: 0.26rem;
                margin-right: 0.05rem;
            }
        }
    }
  }
  </style>
  